import React from 'react';
export default class PopOverGiftCard extends React.Component {
	state = {
		hover: false
	};
	render() {
		return (
			<span
				onMouseEnter={() => {
					this.setState({ hover: true });
				}}
				onMouseLeave={() => {
					this.setState({ hover: false });
				}}
			>
				<i className="fas fa-gift" />
				{this.state.hover ? (
					<div className="popover_gift">
						<div className="fade in popover bottom pop_over_left">
							<div className="arrow" />
							<div className="popover-content">
								<div className="popover_custom">
									Eligibility: {this.props.Amount} {this.props.Currency}
								</div>
							</div>
						</div>
					</div>
				) : null}
			</span>
		);
	}
}
