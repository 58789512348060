import React from "react";
import createClass from 'create-react-class';
let DispRailLeg = createClass({
    getInitialState: function () {
          return {

          }
      },
render:  function () {
  var Railleg=this.props.Railleg;
  var showtime;
  if(this.props.noOfLegs==1){
    showtime=false;
  }else if(this.props.noOfLegs==this.props.iterator+1){
      showtime=false;
  }else{
    showtime=true;
  }
  var SeatClass="seat_e";
  /*switch(specificTD.cabinClass)
  {
    case "ECONOMY":
    SeatClass="seat_e";
    break;
    case "T":
    SeatClass="seat_t";
    break;
    case "BUSINESS":
    SeatClass="seat_b";
    break;
    case "CLASS":
    SeatClass="seat_c";
    break;
    default:
    SeatClass="seat_e";
    break;
  }*/

  return(
    <div className="flight_segment">
    <div className="side-icon"><div className="inner-line">&nbsp;</div></div>
<div className="flight-time">

                <p className="time">{Railleg.railDepartureTimeFormat} hrs</p>
                <p className="date rail_date">{typeof(Railleg.railDepartureDate)!="undefined" && Railleg.railDepartureDate!=null?Railleg.railDepartureDate.substring(0,7):null}</p>
                <div className="duration_proxy">
&nbsp;
                    </div>
                <p className="time">{Railleg.railArrivalTimeFormat} hrs</p>
                <p className="date rail_date">{typeof(Railleg.railArrivalDate)!="undefined" && Railleg.railArrivalDate!=null? Railleg.railArrivalDate.substring(0,7):null}</p>
</div>
<div className="details-line"></div>
<div className="flight-details">
              <p className="airport">{Railleg.departureCity}</p>
                <p className="city rail_city">{Railleg.departureLocation}</p>
                  <div className="duration rail_date">
                  <span className="clock_icon">{Railleg.tripDuration}</span> <span className="duration_icon"></span> <span> {Railleg.milesDistance} mi</span>
                  </div>
                <p className="airport">{Railleg.arrivalCity}</p>
                <p className="city rail_city">{Railleg.arrivalLocation}</p>
                <p className="terminal">&nbsp;</p>
</div>

<div className="extra-info">
  <p className={SeatClass}> {this.props.specificTD.SeatLocation} {this.props.specificTD.cabinClass}/{this.props.specificTD.CabinType}</p>
  <p className="rail_bus_no">{Railleg.equipmentName}</p>
    <p className="food">{this.props.specificTD.mealName}</p>
</div>

<div className="clearfix"></div>
{showtime ? <div className="multileg_divider"><span class="text clock_icon">{this.props.railLegsTime[this.props.noOfLegs+this.props.iterator-2]}</span><hr/> </div>:null}

</div>

  )
}
});
export default DispRailLeg;
