import React, { Component } from 'react';
import {Checkbox, CheckboxGroup} from 'react-checkbox-group';
import {
    Modal,
} from 'react-bootstrap';
import {createRequestJson} from '../../../commons/utils';

class SendModal extends Component {
    constructor(props){
        super(props);
          this.state = {
              value:'',
              emailList:[],
        };
    }
    onChanged(emailList){
        this.setState({emailList });
    }
    render() {

        const {isOpen,hideModal,createNotification,row,loading} = this.props;
        var emailList = row.reduce(function(accumulator , currentValue){
            return accumulator.concat(currentValue.EmailList.map(function(item,i){
                return item.emailAddressTx
            }));
        },[]);

        let options = emailList.filter(function(elem, index, self) {
            return index == self.indexOf(elem);
        }).map(function(item,i){
            return <label><Checkbox value={item}/> {" "+item}<br/></label>
        });
        let docIdValue;
        let heading = row.map(function(item,i){
            docIdValue="";
            if(item.docType == "Invoice")
                docIdValue = item.id;
            return  <span key = {i} id="flex"><label className="pdfIcon"></label>{item.docType} {docIdValue} <span className="client-name">{item.pnr}</span></span>
        })
        let sendclass="btn button-primary-disabled";
        if(this.state.emailList!=""||this.state.value!="") {
            sendclass="btn button-primary";
        }
        
        return (
            <Modal show={isOpen} onHide={hideModal} className="sendEmail">
                <Modal.Header closeButton>
                <Modal.Title>Send</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p className="text-bold">Send Documents:</p>
                        <div id="main">
                             {heading}
                        </div>
                    </div>
                    <br /><br />
                    <div>
                        <p className="text-bold">Email Address:</p>
                        <div>
                         <CheckboxGroup
                            name="emailList"
                            value={this.state.emailList}
                            onChange={this.onChanged.bind(this)}>
                            {options}
                        </CheckboxGroup>
                        </div>
                        <br /><br />
                        <textarea id="noter-text-area" name="textarea" value={this.state.value} onChange={(e) => this.setState({value:e.target.value})}></textarea><br />
                        <label>You can enter more email addresses separated by comma.</label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                {sendclass=="btn button-primary"?
                    <button className={sendclass} onClick={() => createNotification(createRequestJson(row,this.state.emailList,this.state.value))}>
                    {loading ? <div className="loading"></div>:null}Send
                    </button>
                    :
                    <button className={sendclass}>
                    Send
                    </button>
                  }
                    <button className='btn btn-default' onClick={hideModal}>
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}



export default SendModal;
