'use strict';
import React from "react";

export default class RadioButton extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      selectedOption:this.props.RadioSelected,
    };
  }
  focusModal(){
    var self=this;
    if(this.props.isCallStatus) {
      setTimeout(function(){window.$('#comments-radio-button-id-'+self.props.uniqueId).focus();},1000);
    } else {
      setTimeout(function(){window.$('#callstatus-radio-button-id-'+self.props.uniqueId).focus();},1000);
    }

  }
  onClickRadioButton(changeEvent){
    if(!this.props.isCallStatus){
      if(changeEvent.target.value!=this.state.selectedOption){
        this.props.RadioButton(changeEvent.target.value);
      }
      this.props.RadioButton(changeEvent.target.value);
      this.setState({selectedOption: changeEvent.target.value});
    }
  }
  componentWillReceiveProps(newProps){
    this.state = {
      selectedOption:newProps.RadioSelected,
    };
  }
  render () {
    return(
      <div className="col-sm-12">
      <br/><br/>
      <div className="margin_btm_5">{this.props.placeHolder.SELECT}</div>
      <div className="width21per inline-block">
      <input  id={"callstatus-radio-button-id-"+this.props.uniqueId} type="radio"  disabled={this.props.isCallStatus} value="CallStatus" checked={this.state.selectedOption === 'CallStatus'} onChange={this.onClickRadioButton.bind(this)}   selected/><label>{this.props.placeHolder.CALL_STATUS}</label>
      </div>
      <div className="width21per inline-block">
      <input ref={"qwerty1_"+this.props.uniqueId} type="radio" id={"comments-radio-button-id-"+this.props.uniqueId}  value="Comment" checked={this.state.selectedOption === 'Comment'} onChange={this.onClickRadioButton.bind(this)}/><label>{this.props.placeHolder.COMMENTS}</label>
      </div>
      </div>
    );
  }
}
