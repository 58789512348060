import React from 'react';
import CallStatusIcon from '../CallStatusCommons/CallStatusIcon';
import _ from 'underscore';

export default class CallStatusInPlans extends React.Component {
	CallStatusClick(e) {
		e.stopPropagation();
	}
	state = {
		hover: false
	};
	render() {
		if (this.props.CallStatusArr == null || this.props.CallStatusArr.length == 0) {
			return <div />;
		}
		var onPNRClick = this.props.onPNRClick;
		let self = this;
		let tempCallStatusArray = this.props.CallStatusArr.map(function(CallStatus) {
			if (CallStatus == null || CallStatus == 'null') {
				return {
					className: '',
					CallStatus: '',
					onPNRClick: onPNRClick
				};
			}
			return {
				className: getClassName(CallStatus['@code']),
				CallStatus: self.props.placeHolder[getLangVariable(CallStatus['@abbr'])],
				onPNRClick: onPNRClick
			};
		});
		let CallStatusArray = {
			tempCallStatusArray: tempCallStatusArray
		};
		CallStatusiconFilterAndSort(CallStatusArray);
		let callStatus = CallStatusArray.tempCallStatusArray.map((tempCallStatus, key) => {
			if (tempCallStatus.className != '') {
				return (
					<CallStatusIcon
						fromPlans={true}
						onClickIcon={tempCallStatus.onPNRClick}
						key={key}
						text="&nbsp;"
						className={tempCallStatus.className}
						hover={true}
						hoverText={tempCallStatus.CallStatus}
					/>
				);
			} else {
				return null;
			}
		});
		return (
			<div>
				<ul className="pnrs_list_summary" onClick={this.CallStatusClick}>
					{callStatus}
				</ul>
			</div>
		);
	}
}
function getClassName(code) {
	switch (code) {
		case 'H':
			return 'held_flight';
			break;
		case 'O':
			return 'outstanding_issue';
			break;
		case 'U':
			return 'urgent_issue';
			break;
	}
}
function getLangVariable(status) {
	switch (status) {
		case 'Outstanding Issue':
			return 'OUTSTANDING_ISSUE';
			break;
		case 'Held Flight':
			return 'HELD_FLIGHT';
			break;
		case 'Urgent Issue':
			return 'URGENT_ISSUE';
			break;
	}
}

function CallStatusiconFilterAndSort(CallStatusArray) {
	//Filtering the Duplicate Icons
	var UniqueCallStatusArray = _.uniq(CallStatusArray.tempCallStatusArray, function(CallStatusitem, key, CallStatus) {
		return CallStatusitem.CallStatus;
	});

	UniqueCallStatusArray = _.without(
		UniqueCallStatusArray,
		_.findWhere(UniqueCallStatusArray, {
			CallStatus: null
		})
	);

	//ordering the icon in urgentissue,outstanding_issue,held_flight
	UniqueCallStatusArray = _(UniqueCallStatusArray).sortBy(function(UniqueCallStatusitem) {
		return UniqueCallStatusitem.CallStatus;
	});
	UniqueCallStatusArray.reverse();
	CallStatusArray.tempCallStatusArray = UniqueCallStatusArray;
}
