import React from "react";
import createClass from 'create-react-class';
var MoreTravellersModal = createClass({
  render: function () {
    var tableData = [];
    var tempEmp=[];
    var EmpId;
    for(var i = 0; i < this.props.CustomFieldValue.length; i++){
      var name = this.props.CustomFieldValue[i].SurName+' '+this.props.CustomFieldValue[i].GivenName;
      var phonetemp = this.props.CustomFieldValue[i].Phone;
      if(i==0){
        tempEmp.push(this.props.CustomFieldValue[i].EmployeeId);
        EmpId=this.props.CustomFieldValue[i].EmployeeId;
      }else{
        if(tempEmp.indexOf(this.props.CustomFieldValue[i].EmployeeId)==-1){
          tempEmp.push(this.props.CustomFieldValue[i].EmployeeId);
          EmpId=this.props.CustomFieldValue[i].EmployeeId;
        }
          else {
            EmpId="";
          }
      }

      var phone;
      if(phonetemp != null){
        if(phonetemp.indexOf("|") != -1){
          var phonetempArr = phonetemp.split("|");
          phone = phonetempArr.map(function(phoneno){
          return (<p>{phoneno}<br></br></p>);
          });
        }else{
          phone=phonetemp;
        }
      }else{
        phone="";
      }

      var temp = (
        <tr key={i}>
          <td>
            <div key={i} className="more_traveller_name">{name}<br></br>{EmpId}</div>
            <br></br>

          </td>
          <td>
            <div key={i} className="more_traveller_email"><a href="#"> {this.props.CustomFieldValue[i].BestEmail}</a> <br></br></div>
          </td>
          <td>
            <div key={i}  className="more_traveller_name">{phone}<br></br></div>
          </td>
        </tr>
      );
      tableData.push(temp);
    }


    return (
      <div className="modal fade" id="myMoreTravellersModal" role="dialog" data-backdrop="static">
        <div className="modal-dialog">
          <div className="modal-content more_traveller">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">&times;</button>
              <h4 className="modal-title">{this.props.placeHolder.MORE_TRAVELERS}</h4>
            </div>
            <div className="modal-body">
              <table className="table">
                <thead>
                  <tr>
                    <th>{this.props.placeHolder.NAME}</th>
                    <th>Email Add</th>
                    <th width="150">Contact No.</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
});


export default MoreTravellersModal;
