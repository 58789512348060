import React from "react";
import Modal from "../CommonModels/Modal.jsx"
import TicketModel from "../Models/ticketmodel"
import MoreTravellersModal from "../CommonModels/moretravellersmodel"
import DispFlightLeg from "../TripComponents/DispFlightLeg";
import NotificationSegment from "../TripComponents/notificationSegment"
import createClass from 'create-react-class';
var AirTripDetails = createClass({
    getInitialState: function () {
        return {popupmi: false}
    },
    popupclick: function () {
        if (this.state.popupmi) {
            this.setState({popupmi: false});
        } else {
            this.setState({popupmi: true});
        }
    },
    handleDocsClick: function () {
        let openTab = this.props.openDocsTab;
        openTab(this.props.recordLocatorDocs);
    },
    render: function () {
        var AirlineDisplay = "";
        var SeatAssignmentList = this.props.SeatAssignmentList;
        if (this.props.specificTD.OperatingAirline == "" || this.props.specificTD.OperatingAirline == null) 
            textDisplay = "";
        else {
            textDisplay = this.props.placeHolder.OPERATED_BY + " " + this
                .props
                .specificTD
                .OperatingAirline
                .split(",")[0];
        }
        var textDisplay = (
            <span className="disp">{textDisplay}</span>
        );
        var airdetail = (
            <span className="marketing">
                <b>Marketing Airline:
                </b>
            </span>
        )
        if ((this.props.specificTD.OperatingAirline == "" || this.props.specificTD.OperatingAirline == null) && this.props.specificTD.MarketingAirline != " " && this.props.specificTD.MarketingAirline != null) {
            AirlineDisplay = (
                <div>{airdetail}{this
                        .props
                        .specificTD
                        .MarketingAirline
                        .split(",")[0]}</div>
            );
        } else if (this.props.specificTD.OperatingAirline != null && this.props.specificTD.MarketingAirline != null && this.props.specificTD.OperatingAirline != this.MarketingAirline) {
            AirlineDisplay = (
                <div>{this
                        .props
                        .specificTD
                        .MarketingAirline
                        .split(",")[0]}<br/>{textDisplay}</div>
            );
        }
        var FlightLegArr = this.props.specificTD.flightLegArr;
        var specificTD = this.props.specificTD;
        var mealSSRCode = this.props.specificTD.mealSSRCode;

        var FlightLegsTime = [];
        var MealAssignmentList = this.props.MealAssignmentList;
        for (var i = 1; i < FlightLegArr.length; i++) {
            if (FlightLegArr[i].DepartureDateTimeleg != null) 
                var DepartureDateTime = FlightLegArr[i].DepartureDateTimeleg.split("T");
            if (FlightLegArr[i - 1].ArrivalDateTimeleg != null) 
                var ArrivalDateTime = FlightLegArr[i - 1].ArrivalDateTimeleg.split("T");
            if(DepartureDateTime) {
                var Departure = new Date(DepartureDateTime[0].substring(0, 4), (DepartureDateTime[0].substring(4, 6) - 1), DepartureDateTime[0].substring(6, 8), DepartureDateTime[1].substring(0, 2), DepartureDateTime[1].substring(2, 4));
            }

            if(ArrivalDateTime) {
                var Arrival = new Date(ArrivalDateTime[0].substring(0, 4), (ArrivalDateTime[0].substring(4, 6) - 1), ArrivalDateTime[0].substring(6, 8), ArrivalDateTime[1].substring(0, 2), ArrivalDateTime[1].substring(2, 4));
            }
        
            var hourDiff = Departure - Arrival;

            var minDiff = hourDiff / 60 / 1000; //in minutes
            var hDiff = hourDiff / 3600 / 1000; //in hours
            var humanReadable = {};
            var endAirportTerminal = FlightLegArr[i]
                .startAirportCode
                .split(",");
            humanReadable.hours = Math.floor(hDiff);
            humanReadable.minutes = minDiff - 60 * humanReadable.hours;

            FlightLegsTime.push(humanReadable.hours + " hours " + humanReadable.minutes + " mins  Stop at " + endAirportTerminal[0]);
        }
        var self = this;
        var FlightLegs = FlightLegArr.map(function (fltObj, j) {
            if (SeatAssignmentList != null) {
                return (<DispFlightLeg
                    placeHolder={self.props.placeHolder}
                    MealAssignmentList={MealAssignmentList}
                    SeatAssignment={SeatAssignmentList[i]}
                    FlightLegsTime={FlightLegsTime}
                    flightleg={fltObj}
                    mealSSRCode={mealSSRCode}
                    noOfLegs={FlightLegArr.length}
                    specificTD={specificTD}
                    iterator={j}
                    key={j}/>);
            }
            return (<DispFlightLeg
                MealAssignmentList={MealAssignmentList}
                FlightLegsTime={FlightLegsTime}
                flightleg={fltObj}
                mealSSRCode={mealSSRCode}
                noOfLegs={FlightLegArr.length}
                specificTD={specificTD}
                iterator={j}
                key={j}/>);
        });

        this.props.temp;
        var tickstate = false;
        var miinfostate = false;
        var disableclassTicket;
        var disableclassmoreinformation;
        var disableclassMoreTravellers;
        var moreinformation = true;
        // var slashoperator = "/";
        // if (typeof(this.props.specificTD.CabinType) == "undefined" || typeof(this.props.specificTD.classService) == "undefined") {
        //     slashoperator = "";
        // }
        // function decimalToTime(decimalTime) {
        //     var hrs = parseInt(Number(decimalTime));
        //     var min = Math.round((Number(decimalTime) - hrs) * 60);
        //     return hrs + 'h ' + min + 'm ';
        // }

        // var ticketArray;   var ticket;   var storefareArrr; var StoredFare=null;
        // if(storefarejson!=null){
        // storefareArrr=toArrayObject(storefarejson.StoredFare);
        // StoredFare=storefareArrr[0];  }
        // if(typeof(this.props.Ticketing)!="undefined"){
        // if(check(this.props.Ticketing.AirTicketList)!=" "){
        // ticketArray=toArrayObject(this.props.Ticketing.AirTicketList.Ticket);
        // for(var i=0;i<ticketArray.length;i++){
        // if(check(ticketArray[i].SegRefList)!=" "){
        // specificTDetails.RPH=specificTDetails.RPH.replace(/^0+/, '')         for(var
        // j=0;j<ticketArray[i].SegRefList.SegRef.length;j++){
        // if(ticketArray[i].SegRefList.SegRef[j]==specificTDetails.RPH)           {
        //         tickstate=true;             ticket=ticketArray[i];              break
        //           }         }       }     }   } }
        // if(this.props.specificTD.MoreTravelers.length > 1){   miinfostate=true; }
        // var depdatetime = new Date(this.props.specificTD.DepartureDateTime + " " + this.props.specificTD.depTime);
        // var arrdatetime = new Date(this.props.specificTD.ArrivalDateTime + " " + this.props.specificTD.arrTime);
        // var duration = ((arrdatetime - depdatetime) / 3600000).toFixed(1);
        // duration = decimalToTime(duration);

        // if(tickstate==false){   disableclassTicket="disable" }else{
        // disableclassTicket="" }
        if (this.props.specificTD.MoreTravelers.length > 1) {
            disableclassMoreTravellers = "";
           // moreinformation = true;
        } else {
            disableclassMoreTravellers = "disable";
            moreinformation = false;
        }

        if (this.props.specificTD.MIInformation.ShowColumnAs != null || this.props.specificTD.MIInformation.Text != null) {
            disableclassmoreinformation = "";
            miinfostate = true //else if(Array.isArray(this.props.specificTD.MIInformation.MultiMIInfo) && this.props.specificTD.MIInformation.MultiMIInfo[0].ShowColumnAs != null || Array.isArray(this.props.specificTD.MIInformation.MultiMIInfo) && this.props.specificTD.MIInformation.MultiMIInfo[0].Text != null){
            //Checks available MI Information;
        } else if (Array.isArray(this.props.MiInfoList) && this.props.MiInfoList.length > 0) {
            disableclassmoreinformation = "";
            miinfostate = true;
        } else {
            disableclassmoreinformation = "disable";
            //miinfostate = false;
        }
        if (this.props.specificTD.TicketInfo.ticket == true) {
            disableclassTicket = "";
            tickstate = true;
        } else {
            disableclassTicket = "disable";
           // tickstate = false;
        }
        // miinfostate = true; if(this.props.customerInfo==null){
        // moreinformation=false; } else
        // if(toArrayObject(this.props.customerInfo).length <= 1){   var
        // customer=toArrayObject(this.props.customerInfo);
        // if(toArrayObject(customer[0].Customer.PersonName).length <= 1){
        // moreinformation=false;   } } if(moreinformation==false){
        // disableclassmoreinformation="disable" }else{   disableclassmoreinformation=""
        // }
        return (
            <div>

                <div className="trip-details air">

                    {// Checking notificationObject for null, if not null displaying notification
                    // component
                     this.props.notificationObject != null && (this.props.notificationObject.flightNumber === this.props.popupdata.Code + this.props.popupdata.flightNo)
                        ? <NotificationSegment
                                openChatWindowMethod={this.props.openChatWindowMethod}
                                openedChatList={this.props.openedChatList}
                                orgId={this.props.orgId}
                                userId={this.props.userId}
                                userName={this.props.userName}
                                placeHolder={this.props.placeHolder}
                                callApiNotification={this.props.callApiNotification}
                                DispTripId={this.props.DispTripId}
                                specificTD={specificTD}
                                tripmodeclass={this.props.tripmodeclass}
                                userProfileData={this.props.userProfileData}
                                Segkey={this.props.Segkey}
                                user={this.props.user}
                                popupdata={this.props.popupdata}
                                notificationObject={this.props.notificationObject}
                                />
                        : null
}
                    <div className="link-holder air">
                        <div className="btns">
                            <div className="col-sm-3 col-xs-3">
                                <a
                                    href="#"
                                    className={disableclassmoreinformation}
                                    data-toggle="modal"
                                    data-target="#myModal">{this.props.placeHolder.MI_INFORMATION}</a>
                                {miinfostate
                                    ? <Modal
                                            placeHolder={this.props.placeHolder}
                                            CustomFieldValue={this.props.MiInfoList}/>
                                    : null}
                            </div>
                            <div className="col-sm-3 col-xs-3">
                                <a
                                    href="#"
                                    data-toggle="modal"
                                    className={disableclassMoreTravellers}
                                    data-target="#myMoreTravellersModal">{this.props.placeHolder.MORE_TRAVELERS}</a>
                                {moreinformation
                                    ? <MoreTravellersModal
                                            placeHolder={this.props.placeHolder}
                                            CustomFieldValue={this.props.specificTD.MoreTravelers}/>
                                    : null}
                            </div>
                            <div className="col-sm-3 col-xs-3 padding_left">
                                <a
                                    href="#"
                                    className={disableclassTicket}
                                    data-toggle="modal"
                                    data-target="#myTicketModal">{this.props.placeHolder.TICKET}</a>
                                {tickstate
                                    ? <TicketModel
                                            placeHolder={this.props.placeHolder}
                                            Ticketing={this.props.specificTD.TicketInfo}
                                            specificTD={this.props.specificTD}/>
                                    : null}

                            </div>
                            <div className="col-sm-3 col-xs-3">
                                <a
                                    href="#"
                                    onClick={this
                                    .handleDocsClick
                                    .bind(this)}>Docs</a>
                            </div>
                            <div className="airlines">
                                {AirlineDisplay}
                            </div>
                        </div>
                        <div className="add_info">
                            <p>{this.props.placeHolder.BOOKING_REF}.: {this.props.specificTD.airlineBookRef}
                                <br/> {this.props.placeHolder.FF_NO}.: {this.props.specificTD.MembershipID}
                            </p>
                        </div>
                    </div>
                    {FlightLegs}
                </div>
            </div>

        );
    }
});

export default AirTripDetails;
