import amplitude from 'amplitude-js';
import {
  amplitudeApiKey
} from '../components/constants';

export const initAmplitude = () => {
  console.log(amplitudeApiKey());
  amplitude.getInstance().init(amplitudeApiKey());
};

export const setAmplitudeUserId = userId => {
  amplitude.getInstance().setUserId(userId);
  console.log('amplitude: setUserId():  ', userId);
};

export const setAmplitudeUserProperties = properties => {
  amplitude.getInstance().setUserProperties(properties);
};

export const setAmplitudelogEvent = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
  console.log('amplitude: logEvent():  ', eventType, eventProperties);
};