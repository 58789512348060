import React from "react";
import SendModal from './SendModal';
import HTTP from "../../../services/httpservice.js";
export default class SendIcon extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      sendModal : false,
    }
  }
  createNotification = (output) => {
    let self=this;
    let url="/triggerEmail";
    self.setState({
        sendModal: false
        });
    HTTP.postJSON(url,output.requestJson).then(function(response){
    });
  }
  render(){
    let {sendModal} =this.state;
    let {selectedRows} = this.props;
    return(
      <div  className="margin_rgt_5 icon_filter_history">
        <button onClick={() => this.setState({sendModal:true})}className="btn btn-primary btn-history active" tabIndex="-1">
          <div className="edituser"></div>
          <label for="History"  className="sendClass">
            Send
          </label>
        </button>
        {sendModal ? <SendModal isOpen={sendModal} hideModal={() => this.setState({sendModal:false})} row={selectedRows} createNotification = {(reqjson) => this.createNotification(reqjson)} /> : null}
      </div>
    )
  }
}
