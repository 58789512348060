import React, { Component }  from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, IndexRoute, hashHistory} from 'react-router';
import Registration from './components/Login/registration'
import Forgot from './components/Login/ForgotUserPassword'
import SignupConfirmation from './components/signupConfirmation'
import TpnRegistration from './components/Login/TpnRegistration'
import ActivationPage from './components/Login/ActivationPage'
import Index from './Index';
import Main from './main'
import ConfirmReset from "./components/confirmReset"
import alertType from "./components/alertType";


class App extends Component {
    render() {
        return (
        <div>
        <Router ref="router" history={hashHistory}>
            <Route path="/" component={Main}>
                <IndexRoute component={Index}></IndexRoute>
                <Route path="/Forgot" component={Forgot} ></Route>
                <Route path="/Signup" component={Registration} ></Route>
                <Route path="/?activationCode" component={ActivationPage} ></Route>
                <Route  path="/Registration" component={TpnRegistration}> </Route>
                <Route path="/confirmReset/:action/:email" component={ConfirmReset} />
                <Route  path="/Locked" component={alertType}> </Route>
                <Route path="/SignupConfirmation/:email" component={SignupConfirmation} ></Route>
            </Route>
        </Router>
        
        </div>
    )
    }   
}
export default App;

ReactDOM.render(
    <App/>,
    document.getElementById('app')
);
