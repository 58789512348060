import React from "react";
import { toArrayObject } from '../../../services/utils';
import PopOverGiftCard from './TripsItinerary/PopOverGiftCard';
import TravelerItineraryItem from './TravelerItineraryItem';
/*
* Renders the Trip Recommender Details block
* @File - TripRecDetails.js
* @dependencies - Child Component(TravelerItineraryItem)
*/
export default class TripRecDetails extends React.Component {

    constructor(props) {
        // This is called before render function
        //  The object that is returned is assigned to  this.state
        super(props);
    }

    render() {

        // Render trip recommender details
        // Child Component - TravelerItineraryItem
        var hotelRecObj = this.props.hotelRecObj.HotelRecommendationArray;
        var hotelDetails = [];
        var emailDetails = [];
        if (hotelRecObj.length != 0) {
            for (var i = 0; i < hotelRecObj.length; i++) {
                var hotelAddress = hotelRecObj[i].HotelAddress.toString();
                hotelDetails.push(<div className="delivered_info">
                    <div className="address_block">
                        <h2>{hotelRecObj[i].Chain},{hotelRecObj[i].HotelName},{hotelRecObj[i].CityCode}</h2>
                        <h6>{hotelAddress}<br />
                            <span className="margin_top_5 inline-block">{hotelRecObj[i].phone}</span></h6>
                    </div>
                    <div className="cost">
                        <span className="color_dark_grey">{hotelRecObj[i].Amount != null ? hotelRecObj[i].Amount + " " + hotelRecObj[i].Currency : ''}</span>
                        <br />
                        <span className="currency_exchange">{hotelRecObj[i].AmountBeforeTax != null ? hotelRecObj[i].AmountBeforeTax + " " + hotelRecObj[i].CurrencyCode : ''}</span>
                        {hotelRecObj[i].Gift=="Y"?
                            <PopOverGiftCard Amount={hotelRecObj[i].GiftAmount} Currency={hotelRecObj[i].GiftCurrency}/>
                    :
                    null
            }


                    </div>
                    <div className="room_details">
                        <p>{this.props.placeHolder.PROPERTY_CODE}: {hotelRecObj[i].PropertyCode}</p>
                        <p>{this.props.placeHolder.ROOM_TYPE}: {hotelRecObj[i].RoomType}</p>
                        <p>{this.props.placeHolder.BOOKING_CODE}: {hotelRecObj[i].RoomBkgCode}</p>
                        <p>{this.props.placeHolder.RATE_TYPE}: {hotelRecObj[i].RoomDescription}</p>
                    </div>
                </div>)
            }

            // Display Email Details
            // Handling Delivered,not delivered and Pending email status
            if (this.props.hotelRecObj.HotelRecommendationEmailStatus != null) {
                if (this.props.hotelRecObj.HotelRecommendationEmailStatus.RecommendationEmailDeliveryStatus.Emails.Email != null) {
                    var emailsArray = toArrayObject(this.props.hotelRecObj.HotelRecommendationEmailStatus.RecommendationEmailDeliveryStatus.Emails.Email);
                    var emailsLength = emailsArray.length;
                    if (emailsLength != 0) {

                        for (var j = 0; j < emailsLength; j++) {
                            if (emailsArray[j].EmailStatus != "undefined") {
                                var str,emailId;
                                if (emailsArray[j].EmailStatus.toLowerCase() == "delivered") {
                                    str = emailsArray[j].EmailId;
                                    emailId = str.replace(/(\|)/g, ",");
                                    emailDetails.push(<div className="delivered_status ">
                                        <TravelerItineraryItem mailStatusText="Delivered" emailId={emailId} emailDate={emailsArray[j].EmailDate} />
                                    </div>);
                                } else if (emailsArray[j].EmailStatus.toLowerCase() == "acknowledgement received") {
                                    str = emailsArray[j].EmailId;
                                    emailId = str.replace(/(\|)/g, ",");
                                    emailDetails.push(<div className="pending_status ">
                                        <TravelerItineraryItem mailStatusText="Acknowledgement Received" emailId={emailId} emailDate={emailsArray[j].EmailDate} />
                                    </div>);
                                } else if (emailsArray[j].EmailStatus.toLowerCase() == "failed") {
                                    str = emailsArray[j].EmailId;
                                    emailId = str.replace(/(\|)/g, ",");
                                    emailDetails.push(<div className="Failed_status ">
                                        <TravelerItineraryItem mailStatusText="Failed" emailId={emailId} emailDate={emailsArray[j].EmailDate} />
                                    </div>);
                                }
                            }
                        }
                    }
                }
            }
        }
        return (<div>
            <div className="trip_recommender_details">
                <div className="trip-details ">
                    <div className="side-icon">
                        <div className="inner-line">&nbsp;</div>
                    </div>
                    <div className="mail_status">
                        <div className="delivered_info">
                            {emailDetails}
                        </div>
                    </div>
                    {hotelDetails}
                </div>
            </div>
        </div>);
    }
}


