import { PHONE, RECORD_LOCATOR } from '../components/Login/SearchBox';
import HTTP from '../services/httpservice';
import config from '../../config';

export function validateEmail(email) {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9&_\.-]/;
	return re.test(email);
}

export function hasWhiteSpace(s) {
	return s.indexOf(' ') >= 0;
}
export function validateDate(date) {
	var re = /^((0?[1-9]|[12][0-9]|3[01])(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))$/i;
	return re.test(date);
}

export function validatephone(phone) {
	var phoneRegex = /^[\s()+-]*([0-9][\s()+-]*){5,16}$/;
	if (phoneRegex.test(phone)) {
	}
	return phoneRegex.test(phone);
}

export function validateTicketNumber(ticket) {
	var re = /^[A-Za-z0-9-@:/*.]*$/;
	return re.test(ticket);
}

export function toArrayObject(path) {
	var arrayObj = [];
	if (typeof path != 'undefined') {
		if (path.length >= 0) {
			arrayObj = path;
		} else {
			arrayObj.push(path);
		}
	}
	return arrayObj;
}

export function checkDateStatus(traveldate, startingDate, segmentType, sanction, summarySanction) {
	var d = new Date();
	var currentTime = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);
	var arrdatetime;
	var startdatetime;
	var tripmodeclass = '';
	var result = [];

	switch (segmentType) {
		case 'air':
			if (traveldate == null) {
				tripmodeclass = 'icon icon-traveling-air past';
			} else {
				//console.log("traveldate Object length"+traveldate.length);
				arrdatetime = new Date(
					traveldate[0].substring(0, 4),
					traveldate[0].substring(4, 6) - 1,
					traveldate[0].substring(6, 8),
					traveldate[1].substring(0, 2),
					traveldate[1].substring(2, 4)
				);
				startdatetime = new Date(
					startingDate[0].substring(0, 4),
					startingDate[0].substring(4, 6) - 1,
					startingDate[0].substring(6, 8),
					startingDate[1].substring(0, 2),
					startingDate[1].substring(2, 4)
				);
				if (currentTime - startdatetime >= 0 && currentTime - arrdatetime < 0) {
					tripmodeclass = 'icon icon-traveling';
				} else if (currentTime - arrdatetime > 0) {
					if (
						sanction !== null &&
						sanction !== 'false' &&
						sanction !== false &&
						sanction !== 'None' &&
						sanction !== 'none' &&
						summarySanction != null &&
						summarySanction != 'None' &&
						summarySanction != 'none'
					) {
						tripmodeclass = 'icon-sanction icon-traveling-air-sanction future';
					} else {
						tripmodeclass = 'icon icon-traveling past';
					}
				} else {
					if (
						sanction !== null &&
						sanction !== 'false' &&
						sanction !== false &&
						sanction !== 'None' &&
						sanction !== 'none' &&
						summarySanction != null &&
						summarySanction != 'None' &&
						summarySanction != 'none'
					) {
						tripmodeclass = 'icon-sanction icon-traveling-air-sanction future';
					} else {
						tripmodeclass = 'icon icon-traveling future';
					}
				}
			}
			break;

		case 'car':
			if (traveldate == null) {
				tripmodeclass = 'icon icon-traveling-car past';
			} else {
				arrdatetime = new Date(
					traveldate[0].substring(0, 4),
					traveldate[0].substring(4, 6) - 1,
					traveldate[0].substring(6, 8),
					traveldate[1].substring(0, 2),
					traveldate[1].substring(2, 4)
				);
				//startdatetime= new Date(startingDate[0].substring(0,4),(startingDate[0].substring(4,6)-1),startingDate[0].substring(6,8),startingDate[1].substring(0,2),startingDate[1].substring(2,4));
				if (currentTime - arrdatetime == 0) {
					tripmodeclass = 'icon icon-traveling-car';
				} else if (currentTime - arrdatetime > 0) {
					tripmodeclass = 'icon icon-traveling-car past';
				} else {
					tripmodeclass = 'icon icon-traveling-car future';
				}
			}
			break;
		case 'hotel':
			if (traveldate == null) {
				tripmodeclass = 'icon icon-traveling-hotel past';
			} else {
				arrdatetime = new Date(
					traveldate[0].substring(0, 4),
					traveldate[0].substring(4, 6) - 1,
					traveldate[0].substring(6, 8),
					traveldate[1].substring(0, 2),
					traveldate[1].substring(2, 4)
				);
				if (currentTime - arrdatetime == 0) {
					tripmodeclass = 'icon icon-traveling-hotel';
				} else if (currentTime - arrdatetime > 0) {
					tripmodeclass = 'icon icon-traveling-hotel past';
				} else {
					tripmodeclass = 'icon icon-traveling-hotel-sanction future';
				}
			}
			break;
		case 'rail':
			arrdatetime = new Date(
				traveldate[0].substring(0, 4),
				traveldate[0].substring(4, 6) - 1,
				traveldate[0].substring(6, 8),
				traveldate[1].substring(0, 2),
				traveldate[1].substring(2, 4)
			);
			startdatetime = new Date(
				startingDate[0].substring(0, 4),
				startingDate[0].substring(4, 6) - 1,
				startingDate[0].substring(6, 8),
				startingDate[1].substring(0, 2),
				startingDate[1].substring(2, 4)
			);
			if (currentTime - startdatetime >= 0 && currentTime - arrdatetime < 0) {
				tripmodeclass = 'icon icon-traveling-rail';
			} else if (currentTime - arrdatetime > 0) {
				tripmodeclass = 'icon icon-traveling-rail past';
			} else {
				tripmodeclass = 'icon icon-traveling-rail future';
			}
			break;

		case 'bus':
			arrdatetime = new Date(
				traveldate[0].substring(0, 4),
				traveldate[0].substring(4, 6) - 1,
				traveldate[0].substring(6, 8),
				traveldate[1].substring(0, 2),
				traveldate[1].substring(2, 4)
			);
			startdatetime = new Date(
				startingDate[0].substring(0, 4),
				startingDate[0].substring(4, 6) - 1,
				startingDate[0].substring(6, 8),
				startingDate[1].substring(0, 2),
				startingDate[1].substring(2, 4)
			);
			if (currentTime - startdatetime >= 0 && currentTime - arrdatetime < 0) {
				tripmodeclass = 'icon icon-traveling-bus';
			} else if (currentTime - arrdatetime > 0) {
				tripmodeclass = 'icon icon-traveling-bus past';
			} else {
				tripmodeclass = 'icon icon-traveling-bus future';
			}
			break;

		case 'limo':
			arrdatetime = new Date(
				traveldate[0].substring(0, 4),
				traveldate[0].substring(4, 6) - 1,
				traveldate[0].substring(6, 8)
			);
			//startdatetime=new Date(startingDate[0].substring(0,4),(startingDate[0].substring(4,6)-1),startingDate[0].substring(6,8),startingDate[1].substring(0,2),startingDate[1].substring(2,4));
			if (currentTime - arrdatetime == 0) {
				tripmodeclass = 'icon icon-traveling-limo';
			} else if (currentTime - arrdatetime > 0) {
				tripmodeclass = 'icon icon-traveling-limo past';
			} else {
				tripmodeclass = 'icon icon-traveling-limo future';
			}
			break;

		case 'other':
			arrdatetime = new Date(
				traveldate.substring(0, 4),
				traveldate.substring(4, 6) - 1,
				traveldate.substring(6, 8)
			);
			if (currentTime - arrdatetime == 0) {
				tripmodeclass = 'icon icon-traveling-other';
			} else if (currentTime - arrdatetime > 0) {
				tripmodeclass = 'icon icon-traveling-other past';
			} else {
				tripmodeclass = 'icon icon-traveling-other future';
			}
			break;
	}
	return tripmodeclass;
}

export function checkDateForActiveLink(traveldate) {
	var d = new Date();
	var d1 = new Date();
	d1.setFullYear(d.getFullYear() + 1);
	var currentTime = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);
	var arrdatetime;
	var sixMonths = (d1 - d) / 2;
	if (traveldate == null) {
		return false;
	} else if (typeof traveldate[0] != 'undefined' && typeof traveldate[1] != 'undefined') {
		arrdatetime = new Date(
			traveldate[0].substring(0, 4),
			traveldate[0].substring(4, 6) - 1,
			traveldate[0].substring(6, 8),
			traveldate[1].substring(0, 2),
			traveldate[1].substring(2, 4)
		);
		if (currentTime - arrdatetime >= sixMonths) {
			return false;
		}
	} else if (typeof traveldate[0] != 'undefined' && typeof traveldate[1] == 'undefined') {
		var currentDate = new Date();
		var date1 = new Date(
			traveldate[0].substring(0, 4),
			traveldate[0].substring(4, 6) - 1,
			traveldate[0].substring(6, 8)
		);
		var date2 = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
		var months;
		months = (date2.getFullYear() - date1.getFullYear()) * 12;
		months -= date1.getMonth() + 1;
		months += date2.getMonth();
		if (months >= 6) {
			return false;
		}
	}
	return true;
}
export function getIconsForCancelled(segmentType) {
	var tripmodeclass = '';

	switch (segmentType) {
		case 'aircancel':
			tripmodeclass = 'icon icon-traveling cancelled';
			break;

		case 'hotelcancel':
			tripmodeclass = 'icon icon-traveling-hotel cancelled';
			break;

		case 'carcancel':
			tripmodeclass = 'icon icon-traveling-car cancelled';
			break;

		case 'railcancel':
			tripmodeclass = 'icon icon-traveling-rail cancelled';
			break;

		case 'limocancel':
			tripmodeclass = 'icon icon-traveling-limo cancelled';
			break;

		case 'othercancel':
			tripmodeclass = 'icon icon-traveling-other cancelled';
			break;
		case 'buscancel':
			tripmodeclass = 'icon icon-traveling-bus cancelled';
			break;

		default:
	}
	return tripmodeclass;
}

export function formatDate(dateStr) {
	var ArrDate = [];
	ArrDate[0] = dateStr.substring(2, 4);
	ArrDate[1] = dateStr.substring(4, 6);
	ArrDate[2] = dateStr.substring(6, 8);
	var weekday = new Array(7);
	weekday[0] = 'Sunday';
	weekday[1] = 'Monday';
	weekday[2] = 'Tuesday';
	weekday[3] = 'Wednesday';
	weekday[4] = 'Thursday';
	weekday[5] = 'Friday';
	weekday[6] = 'Saturday';
	var date = new Date('20' + ArrDate[0], ArrDate[1] - 1, ArrDate[2]);
	if (typeof weekday[date.getDay()] != 'undefined') {
		ArrDate[3] = weekday[date.getDay()].substring(0, 3);
	}
	if (date == 'Invalid Date') {
		return 'Invalid Date';
	}
	ArrDate[3] = weekday[date.getDay()].substring(0, 3).toUpperCase();
	if (ArrDate[1] == '01') {
		ArrDate[1] = 'JAN';
	} else if (ArrDate[1] == '02') {
		ArrDate[1] = 'FEB';
	} else if (ArrDate[1] == '03') {
		ArrDate[1] = 'MAR';
	} else if (ArrDate[1] == '04') {
		ArrDate[1] = 'APR';
	} else if (ArrDate[1] == '05') {
		ArrDate[1] = 'MAY';
	} else if (ArrDate[1] == '06') {
		ArrDate[1] = 'JUN';
	} else if (ArrDate[1] == '07') {
		ArrDate[1] = 'JUL';
	} else if (ArrDate[1] == '08') {
		ArrDate[1] = 'AUG';
	} else if (ArrDate[1] == '09') {
		ArrDate[1] = 'SEP';
	} else if (ArrDate[1] == '10') {
		ArrDate[1] = 'OCT';
	} else if (ArrDate[1] == '11') {
		ArrDate[1] = 'NOV';
	} else if (ArrDate[1] == '12') {
		ArrDate[1] = 'DEC';
	}
	return ArrDate;
}

export function check(str, type) {
	if (typeof str == 'undefined' || str == null) {
		return ' ';
	} else {
	}
	return str;
}

export function ReferenceCheck(arr, text, size, option) {
	if (text.length > size) {
		var i = arr.indexOf(option);
	}
	if (i != -1) {
		arr.splice(i, 1);
	} else {
		var i = arr.indexOf(option);
		if (i == -1) {
			arr.push(option);
			return arr;
		}
	}
}

export function ReferenceCheckatleast(arr, text, size, option) {
	if (option == PHONE) {
		var phoneRegex = /^([0-9]){5,16}$/;
		if ((text.length > size) & phoneRegex.test(text)) {
			var i = arr.indexOf(option);
			if (i == -1) {
				arr.push(option);
				return arr;
			}
		} else {
			var i = arr.indexOf(option);
			if (i != -1) {
				arr.splice(i, 1);
				return arr;
			}
		}
	} else if (option === 'RECORD_LOCATOR') {
		var i = arr.indexOf(option);
		if (text.length >= 6 && text.length <= size) {
			if (i == -1) {
				arr.push(option);
				return arr;
			}
		} else {
			if (i != -1) {
				arr.splice(i, 1);
				return arr;
			}
		}
	} else if (text.length > size) {
		var i = arr.indexOf(option);
		if (i == -1) {
			arr.push(option);
			return arr;
		}
	} else {
		var i = arr.indexOf(option);
		if (i != -1) {
			arr.splice(i, 1);
			return arr;
		}
	}
}

function getCallStatus(arr1,emailId) {

    var result = [];
    for(var i=0;i<arr1.length;i++) {
        var description,userId,icon,scope;
        if(arr1[i].CallStatusCode == 'O') {
            description = "Outstanding Issue,";
            scope       = "Not Resolved"
            icon        = "outstanding_issue";
        } else if(arr1[i].CallStatusCode == 'H') {
            description = "Held Flight,";
            scope       = "Not Ticketed";
            icon        = "held_flight";
        } else if(arr1[i].CallStatusCode == 'U') {
            description = "Urgent Issue,";
            scope       = "Not Resolved"
            icon        = "urgent_issue";
        }
        userId = arr1[i]._UserId;
        result.push({
           id           : arr1[i]._Id,
           RecordLocator : arr1[i].RecordLocator,
           index        : arr1[i].RecordLocator,
           fullIndex    : arr1[i].RecordLocator,
           description  : description,
           scope        : scope,
           level        :"Trip",
           notes        : arr1[i].Note,
           user         : userId,
           datetime     : changeDateFormat(arr1[i]._InsertedTime),
           icon         : icon,
           title        : 'callstatus',
           tripid       : arr1[i].TripId,
           source       :arr1[i].Source,
           emailId      : emailId
        });
    }
    return result;
}

export function CodeCheck(arr, text, size, option) {
	if (option == RECORD_LOCATOR) {
		var phoneRegex = /^[a-zA-Z0-9]*$/;
		if (phoneRegex.test(text) & (text.length == size)) {
			var i = arr.indexOf(option);
			if (i == -1) {
				arr.push(option);
				return arr;
			}
		} else {
			var i = arr.indexOf(option);
			if (i != -1) {
				arr.splice(i, 1);
				return arr;
			}
		}
	} else if (text.length == size) {
		var i = arr.indexOf(option);
		if (i == -1) {
			arr.push(option);
			return arr;
		}
	} else {
		var i = arr.indexOf(option);
		if (i != -1) {
			arr.splice(i, 1);
			return arr;
		}
	}
}

export function EmailCheck(arr, text, value, option) {
	if (text.indexOf(value) != -1) {
		var i = arr.indexOf(option);
		if (i == -1) {
			arr.push(option);
			return arr;
		}
	} else {
		var i = arr.indexOf(option);
		if (i != -1) {
			arr.splice(i, 1);
			return arr;
		}
	}
}

export function isActive(item, mode) {
	var d = new Date();
	var currentDate = new Date(d.valueOf() + d.getTimezoneOffset() * 60000);
	switch (mode) {
		case 'Air':
			var DepartureDateTime;
			var FlightLegArr = toArrayObject(item.Reservation.FlightLeg);
			var date;
			DepartureDateTime = FlightLegArr[FlightLegArr.length - 1]['@EndGMTTime'];
			var Departure = '';
			if (typeof DepartureDateTime != 'undefined') {
				Departure = DepartureDateTime.split('T');
				date = new Date(
					Departure[0].substring(0, 4),
					Departure[0].substring(4, 6) - 1,
					Departure[0].substring(6, 8),
					Departure[1].substring(0, 2),
					Departure[1].substring(2, 4)
				);

				if (date >= currentDate) {
					return true;
				} else {
					return false;
				}
			}
			break;

		case 'Car':
			var DepartureDateTime = '';
			var Departure = '';
			var date;
			if (item.PickUp) {
				if (item.VehRentalCore.PickUpLocation['@GMTTime'] != null)
					DepartureDateTime = item.VehRentalCore.PickUpLocation['@GMTTime'];
				else {
					DepartureDateTime = item.VehRentalCore.PickUpLocation['@Time'];
				}
			} else {
				if (item.VehRentalCore.ReturnLocation['@GMTTime'] != null)
					DepartureDateTime = item.VehRentalCore.ReturnLocation['@GMTTime'];
				else {
					DepartureDateTime = item.VehRentalCore.ReturnLocation['@Time'];
				}
			}

			if (typeof DepartureDateTime != 'undefined' && DepartureDateTime != null && DepartureDateTime != '') {
				Departure = DepartureDateTime.split('T');
				date = new Date(
					Departure[0].substring(0, 4),
					Departure[0].substring(4, 6) - 1,
					Departure[0].substring(6, 8),
					Departure[1].substring(0, 2),
					Departure[1].substring(2, 4)
				);
				if (date >= currentDate) {
					return true;
				} else {
					return false;
				}
			}
			break;

		case 'Hotel':
			var Departure;
			var date;

			//var date = new Date(Departure[0].substring(0,4),(Departure[0].substring(5,6)-1),Departure[0].substring(8,10));
			if (item.Reservation.TimeSpan['@GMTEnd'] != null && item.Reservation.TimeSpan['@GMTStart'] != null) {
				if (item.Reservation.TimeSpan['@GMTEnd'].indexOf('-') == -1) {
					if (item.checkInDate) {
						Departure = item.Reservation.TimeSpan['@GMTStart'].split('T');
					} else {
						Departure = item.Reservation.TimeSpan['@GMTEnd'].split('T');
					}
					date = new Date(
						Departure[0].substring(0, 4),
						Departure[0].substring(4, 6) - 1,
						Departure[0].substring(6, 8),
						Departure[1].substring(0, 2),
						Departure[1].substring(2, 4)
					);
				} else {
					if (item.checkInDate) {
						Departure = item.Reservation.TimeSpan['@GMTStart'].split('-');
					} else {
						Departure = item.Reservation.TimeSpan['@GMTEnd'].split('-');
					}
					date = new Date(Departure[0], Departure[1] - 1, Departure[2]);
				}
			} else if (item.Reservation.TimeSpan['@End'] != null && item.Reservation.TimeSpan['@Start'] != null) {
				if (item.Reservation.TimeSpan['@End'].indexOf('-') == -1) {
					if (item.checkInDate) {
						Departure = item.Reservation.TimeSpan['@Start'].split('T');
					} else {
						Departure = item.Reservation.TimeSpan['@End'].split('T');
					}
					date = new Date(
						Departure[0].substring(0, 4),
						Departure[0].substring(4, 6) - 1,
						Departure[0].substring(6, 8),
						Departure[1].substring(0, 2),
						Departure[1].substring(2, 4)
					);
				} else {
					if (item.checkInDate) {
						Departure = item.Reservation.TimeSpan['@Start'].split('-');
					} else {
						Departure = item.Reservation.TimeSpan['@End'].split('-');
					}
					date = new Date(Departure[0], Departure[1] - 1, Departure[2]);
				}
			}
			if (date >= currentDate) {
				return true;
			} else {
				return false;
			}
			break;

		case 'Rail':
			var Departure;
			if (item.RailItinerarySeg.DateDetails['@ArrivalGMTTime'] != null)
				Departure = item.RailItinerarySeg.DateDetails['@ArrivalGMTTime'];
			else {
				Departure = item.RailItinerarySeg.DateDetails['@ArrivalTime'];
			}
			Departure = Departure.split('T');
			var date = new Date(
				Departure[0].substring(0, 4),
				Departure[0].substring(4, 6) - 1,
				Departure[0].substring(6, 8),
				Departure[1].substring(0, 2),
				Departure[1].substring(2, 4)
			);
			if (date >= currentDate) {
				return true;
			} else {
				return false;
			}
			break;
		case 'Limo':
			var PickUpDate;

			if (item.LimoItinerarySeg.DateDetails == 'undefined' || item.LimoItinerarySeg.DateDetails == null) {
				// console.log("Data null");
			} else {
				PickUpDate = item.LimoItinerarySeg.DateDetails['@DropOffDate'];
				var date = new Date(
					PickUpDate.substring(0, 4),
					PickUpDate.substring(4, 6) - 1,
					PickUpDate.substring(6, 8)
				);
				if (date >= currentDate) {
					return true;
				} else {
					return false;
				}
			}
			break;

		case 'Other':
			var startDate;
			// console.log(item.OtherItinerarySeg);
			startDate = item.OtherItinerarySeg.ServiceDetails['StartGMTTime'];
			var date = new Date(startDate.substring(0, 4), startDate.substring(4, 6) - 1, startDate.substring(6, 8));
			if (date >= currentDate) {
				return true;
			} else {
				return false;
			}
			break;
	}
}

function Dateformating(datestring) {
	var TempDate = datestring.split('-');
	var date = TempDate[0] + TempDate[1] + TempDate[2];
	TempDate = formatDate(date);
	return TempDate[2] + TempDate[1] + TempDate[0] + ' ' + TempDate[3];
}

export function gtrViewerStatus(StatusCode, tripMode) {
	var status;
	switch (StatusCode) {
		case 'HK':
		case 'HK1':
		case 'IK':
		case 'IS':
		case 'KK':
		case 'KL':
		case 'PK':
		case 'MK':
		case 'OX':
		case 'RR':
		case 'SC':
		case 'TK':
		case 'YG':
		case 'CG':
			status = 'CONFIRMED_A';
			break;
		case 'AK':
		case 'BK':
		case 'GK':
		case 'YK':
		case 'MZ':
			status = 'CONFIRMED_P';
			break;
		case 'HL':
		case 'MM':
		case 'TL':
		case 'UU':
		case 'US':
			status = 'WAITLISTED_A';
			break;
		case 'GL':
			status = 'WAITLISTED_P';
			break;
		case 'GN':
		case 'HN':
		case 'TN':
		case 'AN':
			status = 'ON_REQUEST_A';
			break;
		case 'HX':
			status = 'CANCELLED_A';
			break;
		case 'NO':
		case 'SS':
		case 'UC':
		case 'UN':
			status = 'UNCONFIRMED_A';
			break;
		case 'PN':
			status = 'PENDING_CONFIRMATION_A';
			break;
		case 'WK':
		case 'WL':
			status = 'SCHEDULE_CHANGE_A';
			break;
		case 'SB':
			status = 'STAND_BY_A';
			break;
	}
	return status;
}
export function gtrViewerSegment(SegType) {
	var State;
	if (SegType == 'AUTOMATIC') {
		State = 'A';
	} else if (SegType == 'MANUAL') {
		State = 'P';
	}
	return State;
	//console.log(State);
}

export function findLatestEmailStatus(EmailArray) {
	var EmailData = EmailArray;
	var latestEmailDetails = [];
	if (EmailData.length == undefined) {
		return EmailData;
	}
	EmailData.sort(function(a, b) {
		var expectedFirstDateTime = formatDateTime(changeDateFormat(a.EmailDate));
		var expectedSecondDateTime = formatDateTime(changeDateFormat(b.EmailDate));
		return expectedSecondDateTime - expectedFirstDateTime;
	});
	latestEmailDetails = EmailData[0];
	return latestEmailDetails;
}

export function changeDateFormat(datetime) {
	var year = datetime.substring(2, 4);
	var monthNames = {
		'01': 'JAN',
		'02': 'FEB',
		'03': 'MAR',
		'04': 'APR',
		'05': 'MAY',
		'06': 'JUN',
		'07': 'JUL',
		'08': 'AUG',
		'09': 'SEP',
		'10': 'OCT',
		'11': 'NOV',
		'12': 'DEC'
	};

	var monthNumber = datetime.substring(4, 6);
	var month = monthNames[monthNumber];
	var date = datetime.substring(6, 8);
	var timeformat = datetime.substring(9, 11) + ':' + datetime.substring(11, 13);
	var result = date + month + year + '/' + timeformat;
	return result;
}

export function changeNewDateFormat(datetime) {
	if (datetime == null) {
		return null;
	}
	var year = datetime.substring(2, 4);
	var monthNames = {
		'01': 'JAN',
		'02': 'FEB',
		'03': 'MAR',
		'04': 'APR',
		'05': 'MAY',
		'06': 'JUN',
		'07': 'JUL',
		'08': 'AUG',
		'09': 'SEP',
		'10': 'OCT',
		'11': 'NOV',
		'12': 'DEC'
	};

	var monthNumber = datetime.substring(4, 6);
	var month = monthNames[monthNumber];
	var date = datetime.substring(6, 8);
	var timeformat = datetime.substring(9, 11) + ':' + datetime.substring(11, 13);
	var result = date + month + year;
	return result;
}

export function formatDateTime(dt) {
	var dt = dt.trim();
	var dateValue = dt.substring(0, 2);
	var monthNames = {
		JAN: '01',
		FEB: '02',
		MAR: '03',
		APR: '04',
		MAY: '05',
		JUN: '06',
		JUL: '07',
		AUG: '08',
		SEP: '09',
		OCT: '10',
		NOV: '11',
		DEC: '12'
	};
	var monthNumber = dt.substring(2, 5);
	var month = monthNames[monthNumber];
	var year = '20' + dt.substring(5, 7);
	var hourValue = dt.substring(8, 10);
	var minuteValue = dt.substring(11, 13);
	var secondsValue = 0;
	var result = new Date(year, month, dateValue, hourValue, minuteValue, secondsValue);
	return result;
}

export function otaSummaryException(temporaryResponse,query){
	const exceptionObj = {
		"exceptionObject": {
			"reasonCode": `ERROR_NO_BOOKINGINFO`,
			"reasonText": `BookingInfo field not defiend`,
			"request": {
				"url": `${config.apiURL}/api/${query}`,
				"headers": {
					"accept": "application/json",
					"content-type": "application/xml"
				}
			},
		}
	}

	function postException(){
		HTTP.postJSON('/log/error/v1', exceptionObj);
	}

	temporaryResponse.forEach(data => {
		if (data.BookingInfo.length === 0) {
			data.BookingInfo.AtlasGDS = "";
			data.BookingInfo.RecordLocator = "";
			data.BookingInfo.Source = "";
			data.BookingInfo.pcc = "";
			data.BookingInfo.Interactive = "false";
			postException();
		}
	})
} 


function addElipses(text,n) {

    return (text.length > n) ? text.substr(0,n-1)+'...' : text;
}

function getComments(arr2,travelerName,emailId) {
    var result = [];
    for (var j=0; j< arr2.length;j++) {
        var description,index,userId,icon,scope;
        if(arr2[j]._scope.toUpperCase() == "TRAVELER") {
          description = "Comment,";
          scope       = "Traveler";
          index       = travelerName;
        } else if(arr2[j]._scope.toUpperCase() == "TRIP") {
          description = "Comment,";
          scope       = "Trip";
          index = arr2[j].RecordLocator;
        } else if(arr2[j]._scope.toUpperCase() == "SEGMENT") {
          description = "Comment,";
          scope       = "Segment";
          index = arr2[j].RecordLocator+"/"+arr2[j].SegmentId;
        }
        userId = arr2[j]._UserId;

        result.push({
              id          : arr2[j]._Id,
              index       : addElipses(index,14),
              fullIndex   : index,
              description : description,
              scope       : scope,
              level        :scope,
              notes       : arr2[j].Note,
              user        : userId,
              datetime    : changeDateFormat(arr2[j]._InsertedTime),
              icon        : "comment_icon",
              title       : 'comments',
              emailId     : emailId,
              SegmentId   : arr2[j].SegmentId,
              tripid       :arr2[j].TripId,
              source       :arr2[j].Source,
              RecordLocator   :arr2[j].RecordLocator
        });
    }
    return result;

}

export function filterCallStatusAndCommentsResponse(data,filterOption,traveller,RecordLoc) {
    var travelerName  = traveller.Name[0];
    var emailId       = traveller.EmailId[0];
    var arr1 = [];
    var arr2 = [];
    var arr3 = [];
    RecordLoc = _.uniq(RecordLoc, function(item, key) {
      return item;
    });
    if(data.length != 0) {
      //var arr1 = data.Traveler.CallStatuses;
      //  var arr2 = data.Traveler.Comments;
      if(RecordLoc.length!=0){
        for(var i=0;i<data.Traveler.Comments.length;i++){
          for(var j=0;j<RecordLoc.length;j++){
            if(data.Traveler.Comments[i]._scope!="Traveler"){
              if(data.Traveler.Comments[i].RecordLocator==RecordLoc[j]){
                arr2.push(data.Traveler.Comments[i]);
              }
            }else{
              arr2.push(data.Traveler.Comments[i]);
              break;
            }
          }
        }

        for(var k=0;k<data.Traveler.CallStatuses.length;k++){
          for(var l=0;l<RecordLoc.length;l++){
            if(data.Traveler.CallStatuses[k]._scope!="TRAVELER"){
              if(data.Traveler.CallStatuses[k].RecordLocator==RecordLoc[l]){
                arr1.push(data.Traveler.CallStatuses[k]);
              }
            }else{
              arr1.push(data.Traveler.CallStatuses[k]);
            }
          }
        }
      }


      if(filterOption == "all") {
          var callStatusData  = getCallStatus(arr1,emailId);
          var commentsData    = getComments(arr2,travelerName,emailId);
          arr3                = callStatusData.concat(commentsData);
          arr3.sort(function(a,b) {
            var first_dt = a.datetime;
            var second_dt = b.datetime;
            var expectedFirstDateTime = formatDateTime(first_dt);
            var expectedSecondDateTime = formatDateTime(second_dt);
            return expectedSecondDateTime-expectedFirstDateTime;
          });
          return arr3;
      } else if(filterOption == "callstatus") {
          arr3 = getCallStatus(arr1,emailId);
          arr3.sort(function(a,b) {
            var first_dt = a.datetime;
            var second_dt = b.datetime;
            var expectedFirstDateTime = formatDateTime(first_dt);
            var expectedSecondDateTime = formatDateTime(second_dt);
            return expectedSecondDateTime-expectedFirstDateTime;
          });
          return arr3;
      } else if (filterOption == "comments") {
          arr3 = getComments(arr2,travelerName,emailId);
          arr3.sort(function(a,b) {
            var first_dt = a.datetime;
            var second_dt = b.datetime;
            var expectedFirstDateTime = formatDateTime(first_dt);
            var expectedSecondDateTime = formatDateTime(second_dt);
            return expectedSecondDateTime-expectedFirstDateTime;
          });
          return arr3;
      }
    }
}