import _ from 'underscore';
export function getClassName(code) {
	switch (code) {
		case 'H':
			return 'held_flight';
			break;
		case 'O':
			return 'outstanding_issue';
			break;
		case 'U':
			return 'urgent_issue';
			break;
	}
}

export function CallStatusiconFilterAndSort(CallStatusArray) {
	let SortedArray;
	SortedArray = _(CallStatusArray).sortBy(function(UniqueCallStatusitem) {
		return UniqueCallStatusitem.text;
	});
	SortedArray.reverse();
	//ordering the icon in urgentissue,outstanding_issue,held_flightl
	SortedArray = _(SortedArray).sortBy(function(UniqueCallStatusitem) {
		return UniqueCallStatusitem.CallStatus;
	});
	SortedArray.reverse();
	return SortedArray;
}

export function createRequestJson(row, emailList, value) {
	if (value !== '' && value.indexOf(',') === -1) {
		emailList.push(value);
	} else if (value !== '') {
		emailList = emailList.concat(value.split(','));
	}
	let requestJson = {
		tripStatusIn: row[0].tripStatusIn,
		emailList: emailList.map(function(item, i) {
			return {
				emailAddressTx: item
			};
		})
	};
	let output = {
		requestJson,
		values: row.map(function(item, i) {
			let docIdValue = '';
			if (item.docType == 'Invoice') docIdValue = item.id;
			return item.docType + ' ' + docIdValue + ' ' + item.pnr;
		})
	};
	for (let i = 0; i < row.length; i++) {
		switch (row[i].docType) {
			case 'Itinerary':
				requestJson['itineraryDocumentDetails'] = {
					pnrId: row[i].pnrId,
					docTypeId: row[i].docTypeId,
					docTypeDs: 'ITINERARY',
					createdDt: row[i].createdDate,
					docStoreId: row[i].docStoreId
				};
				break;
			case 'Invoice':
				requestJson['invoiceDocumentDetails'] = [];
				requestJson['invoiceDocumentDetails'].push({
					pnrId: row[i].pnrId,
					docTypeId: row[i].docTypeId,
					docTypeDs: 'INVOICE',
					createdDt: row[i].createdDate,
					docStoreId: row[i].docStoreId
				});
				break;
			case 'Eticket':
				requestJson['eticketDocumentDetails'] = [];
				requestJson['eticketDocumentDetails'].push({
					pnrId: row[i].pnrId,
					docTypeId: row[i].docTypeId,
					docTypeDs: 'ETICKET',
					createdDt: row[i].createdDate,
					docStoreId: row[i].docStoreId
				});
				break;
		}
	}

	return output;
}

export function findSegmentIdByTripId(TripSegmentArray, tripId) {
	for (let i = 0; i < TripSegmentArray.length; i++) {
		if (TripSegmentArray[i].tripId == tripId) {
			return TripSegmentArray[i];
		}
	}
}

export function getCallStatusCount(CallStatusOrComments) {
	var CallStatusCount = [];
	var temp = {
		O: 0,
		U: 0,
		H: 0
	};
	let CallStatuses = CallStatusOrComments.Traveler.CallStatuses;
	for (var i = 0; i < CallStatuses.length; i++) {
		temp[CallStatuses[i].CallStatusCode]++;
	}
	for (var key in temp) {
		CallStatusCount.push({
			type: key,
			number: temp[key]
		});
	}
	return CallStatusCount;
}
export function getIconAndNumberClass(type) {
	switch (type) {
		case 'U':
			return {
				iconClass: 'urgent_issue',
				numberClass: 'urgent-issue-count'
			};
		case 'O':
			return {
				iconClass: 'outstanding_issue',
				numberClass: 'outstanding-issue-count'
			};
		case 'H':
			return {
				iconClass: 'held_flight',
				numberClass: 'held-flight-issue-count'
			};
	}
}
