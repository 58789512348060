export function CallStatusAndComments(){

  this.Traveler=new Traveler();
  this.Trip=new Trip();
  this.isDeleted="";
  this.Type="";
  this.Scope=""
  this.UserId="";
  this.CallStatusOrComment=[];



  this.setCallStatusAndCommentsValues=(userId,dropDownResult,noteText,Selected,isDeleted)=>{
    this.UserId=userId;
    this.Type=Selected.toLowerCase();
    this.Scope=dropDownResult.toLowerCase();
    this.Scope=this.Scope.substring(0,1).toUpperCase()+this.Scope.substring(1);

    if(this.Type=="callstatus" && !isDeleted){
      this.CallStatusCode=dropDownResult.slice(0, 1);
      this.Scope="Trip";
    }else if(this.Type=="callstatus" && isDeleted){
      this.CallStatusCode=dropDownResult.slice(0, 1);
      this.Scope="Trip";
    }

    this.isDeleted=isDeleted ?"Yes":"No";
    if(this.isDeleted=="No"){
      this.CallStatusOrComment.push(new CallStatusOrComment(noteText));
    }
  }



  this.setCommentId=(noteText,CommentId)=>{
    if(CommentId!=null){
      if(Array.isArray(CommentId)){
        for(var i=0;i<CommentId.length;i++){
          this.CallStatusOrComment.push(new CallStatusOrComments(noteText,CommentId[i]));
        }
      }else{
          this.CallStatusOrComment.push(new CallStatusOrComments(noteText,CommentId));
      }
    }
  }


  this.setTripInfo=(tripId,SegmentId,RecordLocator,Source)=>{
    this.Trip.tripid=tripId;
    this.Trip.source=Source;
    if(SegmentId!=null){
      if(Array.isArray(SegmentId)){
        SegmentId = SegmentId.filter( function( item, index, inputArray ) {
           return inputArray.indexOf(item) == index;
         });
        for(var i=0;i<SegmentId.length;i++){
          if(SegmentId[i]!=null)
          this.Trip.setSegment(SegmentId[i]);
        }
      }else{
        this.Trip.setSegment(SegmentId);
      }
    }
    this.Trip.recordlocator=RecordLocator
  }



  this.setUserInfo=(EmailId,TravelerId)=>{
    this.Traveler.email=EmailId[0];
    this.Traveler.Id=TravelerId;
  }



  this.setUserInforDel=(EmailId,TravelerId)=>{
    this.Traveler.email=EmailId;
    this.Traveler.Id=TravelerId;
  }
}










function Trip(){
  this.tripid="";
  this.recordlocator="";
  this.Segment=[];
  this.setSegment=(note)=>{
    this.Segment.push(new Segment(note));
  }
}
function Segment(note){
  this.segmentid=note;
}
function Traveler(){
  this.email="";
  this.Id="";
}
function CallStatusOrComment(noteText){
  this.note=noteText;
}
function CallStatusOrComments(noteText,CommentId){
  this.note=noteText;
  this.Id=CommentId
}
