import React from "react";
import ReactDOM from "react-dom";
import Login from "../js/components/Login/login";
var HTTP = require('./services/httpservice');
import SearchBox from "../js/components/Login/SearchBox";
import TpnRegistration from "../js/components/Login/TpnRegistration";
import ActivationPage from "../js/components/Login/ActivationPage";
import {hashHistory} from 'react-router';
import {Environment} from './components/constants';
import createClass from 'create-react-class';
var Base64 = {
    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
    decode: function (e) {
        var t = "";
        var n,
            r,
            i;
        var s,
            o,
            u,
            a;
        var f = 0;
        e = e.replace(/[^A-Za-z0-9+/=]/g, "");
        while (f < e.length) {
            s = this
                ._keyStr
                .indexOf(e.charAt(f++));
            o = this
                ._keyStr
                .indexOf(e.charAt(f++));
            u = this
                ._keyStr
                .indexOf(e.charAt(f++));
            a = this
                ._keyStr
                .indexOf(e.charAt(f++));
            n = s << 2 | o >> 4;
            r = (o & 15) << 4 | u >> 2;
            i = (u & 3) << 6 | a;
            t = t + String.fromCharCode(n);
            if (u != 64) {
                t = t + String.fromCharCode(r)
            }
            if (a != 64) {
                t = t + String.fromCharCode(i)
            }
        }
        t = Base64._utf8_decode(t);
        return t
    },
    _utf8_encode: function (e) {
        e = e.replace(/rn/g, "n");
        var t = "";
        for (var n = 0; n < e.length; n++) {
            var r = e.charCodeAt(n);
            if (r < 128) {
                t += String.fromCharCode(r)
            } else if (r > 127 && r < 2048) {
                t += String.fromCharCode(r >> 6 | 192);
                t += String.fromCharCode(r & 63 | 128)
            } else {
                t += String.fromCharCode(r >> 12 | 224);
                t += String.fromCharCode(r >> 6 & 63 | 128);
                t += String.fromCharCode(r & 63 | 128)
            }
        }
        return t
    },
    _utf8_decode: function (e) {
        var c1,
            c2,c3;
        var t = "";
        var n = 0;
        var r = c1 = c2 = 0;
        while (n < e.length) {
            r = e.charCodeAt(n);
            if (r < 128) {
                t += String.fromCharCode(r);
                n++
            } else if (r > 191 && r < 224) {
                c2 = e.charCodeAt(n + 1);
                t += String.fromCharCode((r & 31) << 6 | c2 & 63);
                n += 2
            } else {
                c2 = e.charCodeAt(n + 1);
                c3 = e.charCodeAt(n + 2);
                t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
                n += 3
            }
        }
        return t
    }
}

var Index = createClass({
    getInitialState: function () {
        return {
            login: false,
            logout:false,
            viewerRelatedGroups: "",
            page: "",
            userId: "",
            userName:"",
            registration: false,
            organizationId: "",
            loginOrgId: "",
            reason: "",
            premAccess: "",
            orgName:"",
            viewerGroupAccess:false,
            elevatedInfo:{
                elevatedInsertedBy : null,
                elevatedPurpose: null,
                elevatedCountries : null,
                elevatedCountryScope:null
            },
            elevatedPermissions:"",
            elevatedLogout:false
        }
        this.pnr = "",
        this.email = "",
        this.activecode = "",
        this.authToken = false
    },

    UpdateLogIn(loginstate, data, page, userId, orgId, premAccess,orgName,userName,response) {
        if (data === undefined || userId==="svc_gtrautomation") {
            data = "";
        }

        var elevatedInfoObj = null;
        var elevatedCountries = null;
        var elevatedInsertedBy = null;
        var elevatedPurpose = null;
        var filteredCountryScope = null;
        var filteredIndividualScope = null;
        if(Environment==='INTRANET' && response.elevatedPermissions && response.elevatedInfo !== null) {

            // Code Red elevated functionality
            filteredCountryScope = response.elevatedInfo.elevatedRights.elevatedRight.filter(item => item.type === "C");
            filteredIndividualScope = response.elevatedInfo.elevatedRights.elevatedRight.filter(item => item.type === "U");
            if(filteredCountryScope.length>0) {
                elevatedCountries = filteredCountryScope.map((item)=>{return item.countryName;}).join(',');
                elevatedInsertedBy = filteredCountryScope.map((item)=>{return item.insertedBy;}).join(',');
                elevatedPurpose = "CODE RED"
            } else if(filteredIndividualScope.length>0) {
                elevatedCountries = filteredIndividualScope.map((item)=>{return item.countryName;}).join(',')
                elevatedPurpose = response.elevatedInfo.elevatedRights.elevatedRight[0].purpose;
                elevatedInsertedBy = filteredIndividualScope.map((item)=>{return item.insertedBy;}).join(',');
            }
            // elevatedInfoObj = {
            //     elevatedInsertedBy : response.elevatedInfo.elevatedRights.elevatedRight[0].insertedBy,
            //     elevatedPurpose :response.elevatedInfo.elevatedRights.elevatedRight[0].purpose,
            //     elevatedCountries :response.elevatedInfo.elevatedRights.elevatedRight.map((item)=>{return item.countryName;}).join(','),
            //     elevatedCountryScope:response.elevatedInfo.elevatedRights.elevatedRight.map((item)=>{return item.type ==="C" ? item.countryCode+'-C':  item.countryCode;}).join(',')
            // }
            elevatedInfoObj = {
                elevatedInsertedBy,
                elevatedPurpose :elevatedPurpose,
                elevatedCountries :elevatedCountries,
                elevatedCountryScope:response.elevatedInfo.elevatedRights.elevatedRight.map((item)=>{return item.type ==="C" ? item.countryCode+'-C':  item.countryCode;}).join(',')
            }
        } 
        this.setState({
            login: loginstate,
            logout:false,
            countryCode:response.countryCode,
            viewerRelatedGroups: data,
            page: page,
            userId: userId,
            loginOrgId: orgId,
            orgName:orgName,
            userName,
            premAccess:premAccess,
            elevatedPermissions:Environment==='INTRANET' ? response.elevatedPermissions:"",
            elevatedInfo:elevatedInfoObj,
            codeRed:filteredCountryScope!== null ? filteredCountryScope.length>0 ? true:false:false,
            elevatedLogout:false,
            viewerGroupAccess:Environment==='INTRANET' ? (response.viewerRelatedGroups === "" ? false:true) : false
        })
    },
    resetPasswordExpiry(val, userId, orgId, reason) {
        //console.log(PasswordExpiry);
        this.setState({
            resetPasswordExpiry: true,
            login: true,
            viewerRelatedGroups: "",
            page: "search",
            userId: userId,
            loginOrgId: orgId,
            reason: reason
        })
    },
    updateActivePage(loginstate) {
        this.setState({login: loginstate})
        this.activecode = "";
        hashHistory.push('/');
    },
    updateRegistration(regStatus, organizationId) {
        this.setState({registration: regStatus, organizationId: organizationId})
    },
    logPageView: function () {
        this
            .props
            .children
            .ReactGA
            .set({page: window.location.pathname});
        this
            .props
            .children
            .ReactGA
            .pageview(window.location.pathname);
    },
    componentWillMount: function () {
        var self = this;
        var field = 'recordlocator';
        var emailField = 'email';
        var travelerField = 'knowmeid';
        var emailListField = 'emaillist';
        var keyName = "key"
        var url = window.location.href;
        if (this.props.location.state) {
            this.pnr = this.props.location.state.pnr;
            this.authToken = this.props.location.state.authToken;
        }
        if (url.indexOf('?' + field + '=') != -1 || url.indexOf('&' + field + '=') != -1) {
            this.pnr = getParameterByName('recordlocator');
        } else if (url.indexOf('?' + keyName + '=') != -1) {
            let decodedValue = Base64.decode(getParameterByName('key'));
            this.pnr = decodedValue.match(/recordlocator=(.*)&/)[1];
            this.authToken = decodedValue.match(/authtoken=(.*)/)[1];
            hashHistory.push({
                pathname: '/',
                state: {
                    authToken: this.authToken,
                    pnr: this.pnr
                }
            })
        } else if (url.indexOf('?' + emailField + '=') != -1 || url.indexOf('&' + emailField + '=') != -1) {
            this.email = getParameterByName('email');
        } else if (url.indexOf('?' + travelerField + '=') != -1 || url.indexOf('&' + travelerField + '=') != -1) {
            this.knowmeid = getParameterByName('knowmeid');
        } else if (url.indexOf('?' + emailListField + '=') != -1 || url.indexOf('&' + emailListField + '=') != -1) {
            this.emaillist = getParameterByName('emaillist');
        } else if (url.indexOf('?activationCode=') != -1) {
            this.activecode = getParameterByName('activationCode');
        }
        this.logPageView();
    },

    logout:function(data){
        HTTP.get('/destroy').then(function(response){
            console.log('session destroyed');
        });
        this.setState({logout:data})
    },

    grantElevatedPermission:function() {  
        var self =this;
        HTTP
        .get('/elevatedAuth/' + self.state.userId+'/'+self.state.countryCode)
        .then(function (response) {
            var elevatedCountryList = null;
            var elevatedPurposeList = null;
            var elevatedCountryScope = null;
            var elevatedIndividualScope=null;
            var elevatedInsertedBy = null;
            if(response.success === "true") {
                elevatedCountryScope = response.elevatedRights.elevatedRight.filter(item => item.type === "C");
                elevatedIndividualScope = response.elevatedRights.elevatedRight.filter(item => item.type === "U");
                if(elevatedCountryScope.length>0) {
                    elevatedCountryList = elevatedCountryScope.map((item)=>{return item.countryName;}).join(',');
                    elevatedInsertedBy = elevatedCountryScope.map((item)=>{return item.insertedBy;}).join(',');
                    elevatedPurposeList = "CODE RED"
                } else if(elevatedIndividualScope.length>0) {
                    elevatedCountryList = elevatedIndividualScope.map((item)=>{return item.countryName;}).join(',')
                    elevatedPurposeList = response.elevatedRights.elevatedRight[0].purpose;
                    elevatedInsertedBy = elevatedIndividualScope.map((item)=>{return item.insertedBy;}).join(',');
                }
                self.setState({
                    elevatedPermissions:true,
                    elevatedLogout:false,
                    codeRed:elevatedCountryScope!==null ? elevatedCountryScope.length>0?true:false:false,
                    // elevatedInfo : {
                    //     elevatedInsertedBy : response.elevatedRights.elevatedRight[0].insertedBy,
                    //     elevatedPurpose: response.elevatedRights.elevatedRight[0].purpose,
                    //     elevatedCountries : response.elevatedRights.elevatedRight.map((item)=>{return item.countryName;}).join(','),
                    //     elevatedCountryScope:response.elevatedRights.elevatedRight.map((item)=>{return item.countryCode;}).join(',')
                    // }
                    elevatedInfo : {
                        elevatedInsertedBy,
                        elevatedPurpose: elevatedPurposeList,
                        elevatedCountries : elevatedCountryList,
                        elevatedCountryScope:response.elevatedRights.elevatedRight.map((item)=>{return item.type ==="C" ? item.countryCode+'-C':  item.countryCode;}).join(',')
                    }
                }) 
            } else {
                if(self.state.viewerGroupAccess) {
                    self.setState({
                        elevatedPermissions:false,
                        elevatedLogout:false,
                        elevatedInfo:{
                            elevatedInsertedBy : null,
                            elevatedPurpose: null,
                            elevatedCountries : null,
                            elevatedCountryScope:null
                        }
                    })
                } else {
                    self.setState({
                        elevatedPermissions:false,
                        elevatedLogout:true,
                        elevatedInfo:{
                            elevatedInsertedBy : null,
                            elevatedPurpose: null,
                            elevatedCountries : null,
                            elevatedCountryScope:null
                        }
                    })
                    //self.logout(true);
                }  
            }         
        }); 
    },

    render: function () {
        // console.log(this.state.pnrEmail) viewerRelatedGroups to be sent to
        // SearchBox.js and added to api call as "restrict2" parameter
        if (Environment === "INTRANET") {

            if(this.state.logout)
                return (<Login
                    logout={this.state.logout}
                    authToken={this.authToken}
                    pnr={this.pnr}
                    ReactGA={this.props.children.ReactGA}
                    {...this.props}
                    placeHolder={this.props.children.placeHolder}
                    UpdateLogIn={this.UpdateLogIn}/>)

            if (this.pnr != "" && this.pnr != null) {
                if (!this.authToken && this.authToken !== "") {
                    return (<SearchBox
                        logout={this.logout}
                        codeRed={this.state.codeRed}
                        placeHolder={this.props.children.placeHolder}
                        countryScope ={this.state.elevatedInfo !== null ? this.state.elevatedInfo.elevatedCountryScope:null}
                        grantElevatedPermission={this.grantElevatedPermission}
                        elevatedPermissions={this.state.elevatedPermissions}
                        elevatedLogout={this.state.elevatedLogout}
                        ReactGA={this.props.children.ReactGA}
                        Login={this.state.login}
                        pnr={this.pnr}
                        restrict=""/>)
                } else {
                    if (this.state.login) 
                        return (<SearchBox
                            pnr={this.pnr}
                            codeRed={this.state.codeRed}
                            logout={this.logout}
                            countryScope ={this.state.elevatedInfo !== null ? this.state.elevatedInfo.elevatedCountryScope:null}
                            grantElevatedPermission={this.grantElevatedPermission}
                            elevatedPermissions={this.state.elevatedPermissions}
                            elevatedLogout={this.state.elevatedLogout}
                            authToken={this.authToken}
                            placeHolder={this.props.children.placeHolder}
                            ReactGA={this.props.children.ReactGA}
                            Login={this.state.login}
                            pnr={this.pnr}
                            restrict=""/>)
                    else 
                        return (<Login
                            logout={this.state.logout}
                            authToken={this.authToken}
                            pnr={this.pnr}
                            ReactGA={this.props.children.ReactGA}
                            {...this.props}
                            placeHolder={this.props.children.placeHolder}
                            UpdateLogIn={this.UpdateLogIn}/>)
                }
            } else if (this.email != "" && this.email != null) {
                return (<SearchBox
                    logout={this.logout}
                    codeRed={this.state.codeRed}
                    placeHolder={this.props.children.placeHolder}
                    countryScope ={this.state.elevatedInfo !== null ? this.state.elevatedInfo.elevatedCountryScope:null}
                    grantElevatedPermission={this.grantElevatedPermission}
                    elevatedPermissions={this.state.elevatedPermissions}
                    elevatedLogout={this.state.elevatedLogout}
                    ReactGA={this.props.children.ReactGA}
                    Login={this.state.login}
                    mail={this.email}
                    restrict=""/>)
            } else if (this.knowmeid != "" && this.knowmeid != null) {
                return (<SearchBox
                    logout={this.logout}
                    codeRed={this.state.codeRed}
                    placeHolder={this.props.children.placeHolder}
                    countryScope ={this.state.elevatedInfo !== null ? this.state.elevatedInfo.elevatedCountryScope:null}
                    grantElevatedPermission={this.grantElevatedPermission}
                    elevatedLogout={this.state.elevatedLogout}
                    elevatedPermissions={this.state.elevatedPermissions}
                    ReactGA={this.props.children.ReactGA}
                    Login={this.state.login}
                    knowmeid={this.knowmeid}
                    restrict=""/>)
            } else if (this.emaillist != "" && this.emaillist != null) {
                return (<SearchBox
                    logout={this.logout}
                    codeRed={this.state.codeRed}
                    placeHolder={this.props.children.placeHolder}
                    countryScope ={this.state.elevatedInfo !== null ? this.state.elevatedInfo.elevatedCountryScope:null}
                    grantElevatedPermission={this.grantElevatedPermission}
                    elevatedPermissions={this.state.elevatedPermissions}
                    elevatedLogout={this.state.elevatedLogout}
                    ReactGA={this.props.children.ReactGA}
                    Login={this.state.login}
                    emaillist={this.emaillist}
                    restrict=""/>)
            }
        }
        if(Environment === "INTERNET") {
            if(this.state.logout)
                return (<Login
                    logout={this.state.logout}
                    authToken={this.authToken}
                    pnr={this.pnr}
                    ReactGA={this.props.children.ReactGA}
                    {...this.props}
                    placeHolder={this.props.children.placeHolder}
                    UpdateLogIn={this.UpdateLogIn}/>)
        }
        
        if (this.activecode != "" && this.activecode != null) {
            return (<ActivationPage
                placeHolder={this.props.children.placeHolder}
                userId={this.state.userId}
                ReactGA={this.props.children.ReactGA}
                activationcode={this.activecode}
                updateActivePage={this.updateActivePage}/>)
        } else if (!this.state.login) {
            return (<Login
                logout={this.state.logout}
                pnr={this.pnr}
                resetPasswordExpiry={this.resetPasswordExpiry}
                ReactGA={this.props.children.ReactGA}
                {...this.props}
                placeHolder={this.props.children.placeHolder}
                UpdateLogIn={this.UpdateLogIn}/>)
        } else if (this.state.page == "search") {
            if (Environment === "INTERNET") 
                return (<SearchBox
                    logout={this.logout}
                    codeRed={this.state.codeRed}
                    countryScope ={this.state.elevatedInfo !== null ? this.state.elevatedInfo.elevatedCountryScope:null}
                    grantElevatedPermission={this.grantElevatedPermission}
                    reason={this.state.reason}
                    userId={this.state.userId}
                    resetPasswordExpiry={this.state.resetPasswordExpiry}
                    placeHolder={this.props.children.placeHolder}
                    ReactGA={this.props.children.ReactGA}
                    Login={this.state.login}
                    mail={this.email}
                    orgName={this.state.orgName}
                    restrict={this.state.loginOrgId}
                    premAccess={this.state.premAccess}
                    elevatedPermissions={this.state.elevatedPermissions}
                    elevatedLogout={this.state.elevatedLogout}
                    elevatedInfo={this.state.elevatedInfo}
                    pnr={this.pnr}
                    />)
            else 
                return (<SearchBox
                    codeRed={this.state.codeRed}
                    logout={this.logout}
                    grantElevatedPermission={this.grantElevatedPermission}
                    countryScope ={this.state.elevatedInfo !== null ? this.state.elevatedInfo.elevatedCountryScope:null}
                    reason={this.state.reason}
                    userId={this.state.userId}
                    countryCode={this.state.countryCode}
                    userName={this.state.userName}
                    resetPasswordExpiry={this.state.resetPasswordExpiry}
                    placeHolder={this.props.children.placeHolder}
                    ReactGA={this.props.children.ReactGA}
                    orgName={this.state.orgName}
                    Login={this.state.login}
                    mail={this.email}
                    orgName={this.state.orgName}
                    restrict={this.state.viewerRelatedGroups}
                    elevatedPermissions={this.state.elevatedPermissions}
                    elevatedLogout={this.state.elevatedLogout}
                    elevatedInfo={this.state.elevatedInfo}
                    premAccess={this.state.premAccess}
                    />)
        } else if (this.state.registration) {
            return (<SearchBox
                codeRed={this.state.codeRed}
                logout={this.logout}
                grantElevatedPermission={this.grantElevatedPermission}
                countryScope ={this.state.elevatedInfo !== null ? this.state.elevatedInfo.elevatedCountryScope:null}
                reason={this.state.reason}
                placeHolder={this.props.children.placeHolder}
                ReactGA={this.props.children.ReactGA}
                Login={this.state.login}
                orgName={this.state.orgName}
                mail={this.email}
                userId={this.state.userId}
                orgName={this.state.orgName}
                restrict={this.state.organizationId}
                elevatedPermissions={this.state.elevatedPermissions}
                elevatedLogout={this.state.elevatedLogout}
                elevatedInfo={this.state.elevatedInfo}
                premAccess={this.state.premAccess}
                />)
        } else {
            return (<TpnRegistration
                placeHolder={this.props.children.placeHolder}
                updateRegistration={this.updateRegistration}
                UpdateLogIn ={this.UpdateLogIn}
                ReactGA={this.props.children.ReactGA}
                userId={this.state.userId}
                Login={this.state.login}
                mail={this.email}
                restrict=""
                />)
        }
    }
});

export default Index;
function getParameterByName(name, url) {
    if (!url) 
        url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) 
        return null;
    if (!results[2]) 
        return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
