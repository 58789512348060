import React from "react";
import createClass from 'create-react-class';
let SingleLegRail=createClass({
    render:function(){
      var SeatClass;
      SeatClass="seat_e";
      return (    <div className="flight_segment">
          <div className="side-icon"><div className="inner-line">&nbsp;</div></div>
          <div className="flight-time">
                      <p className="time">{this.props.specificTD.railDepartureTimeFormat} hrs</p>
                      <p className="date">{this.props.specificTD.railDepartureDate.substring(0,7)}</p>
                      <div className="duration_proxy">
  &nbsp;
                          </div>
                      <p className="time">{this.props.specificTD.railArrivalTimeFormat} hrs</p>
                      <p className="date">{this.props.specificTD.railArrivalDate.substring(0,7)}</p>
      </div>
      <div className="details-line"></div>
      <div className="flight-details">
                    <p className="airport">&nbsp;{this.props.specificTD.railLegArr[0].departureCity}</p>
                      <p className="city">&nbsp;{this.props.specificTD.railLegArr[0].departureLocation}</p>
                      <p className="terminal">&nbsp;</p>
                        <div className="duration">
                        <span className="clock_icon">{this.props.specificTD.tripDuration}</span> <span className="duration_icon"></span> <span> {this.props.specificTD.milesDistance} mi</span>
                        </div>
                      <p className="airport">{this.props.specificTD.railLegArr[0].arrivalCity}</p>
                      <p className="city">{this.props.specificTD.railLegArr[0].arrivalLocation}</p>
                      <p className="terminal">&nbsp;</p>
      </div>
  
      <div className="extra-info">
        <p className={SeatClass}> {this.props.specificTD.SeatLocation} {this.props.specificTD.cabinClass}/{this.props.specificTD.CabinType}</p>
        <p className="rail_bus_no">{this.props.specificTD.equipmentName}</p>
          <p className="food">{this.props.specificTD.mealName}</p>
      </div>
  
      <div className="clearfix"></div></div>);
    }
  });

  export default SingleLegRail;