import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const GroupedCommunication = ({ groupedCommunicationLogs }) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [sortedGroupedCommunicationLog, setSortedGroupedCommunicationLog] = useState([]);

    const toggleAccordian = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null)
        } else {
            setActiveIndex(index)
        }
    }

    const showType = (messageFlow) => {
        return <div className="channel-class"><div>{messageFlow === "O" ? 'Sent' : 'Received'}</div><div className={messageFlow === "O" ? "sent-type" : "received-type"}></div></div>
    }

    const showChannel = (row) => {
        var channel = '';
        if (row.channel === 'WEB') {
            switch (row.status) {
                case 'R':
                    channel = 'TC-Released';
                    row.channel = 'TC-Released';
                    break;
                case 'A':
                    channel = 'TC-Accepted';
                    row.channel = 'TC-Accepted';
                    break;
                case 'C':
                    channel = 'TC-Closed';
                    row.channel = 'TC-Closed';
                    break;
            }
        } if (row.channel === 'MOBILE') {
            channel = 'Mobile App'
            row.channel = 'MobileApp';
        } else {
            channel = row.channel;
        }
        return <div><div className="break-word-class">{channel}</div><div className="break-word-class">{row.channelAddress}</div></div>
    }

    useEffect(() => {
        if (groupedCommunicationLogs.length > 0) {
            const sortedData = groupedCommunicationLogs.sort((a, b) => {
                let bValue = new Date(b[b.length - 1].insertedDateTime);
                let aValue = new Date(a[a.length - 1].insertedDateTime);
                return bValue - aValue;
            })
            const dummyData = JSON.parse(JSON.stringify(sortedData))
            dummyData.forEach((a)=>a.sort((first,second)=>{
                if(!first.hasOwnProperty('unique') && !second.hasOwnProperty('unique')){
                    return new Date(second.insertedDateTime) - new Date(first.insertedDateTime);
                }
            }))
            setSortedGroupedCommunicationLog(dummyData)
        }
    }, [groupedCommunicationLogs])


    const formatDate = (insertedDate, insertedTime) => {
        if (insertedDate !== undefined && insertedDate !== "") {
            const stringifyDate = insertedDate.toString();
            const splittedDate = stringifyDate.split('/');
            const year = splittedDate[2];
            const month = splittedDate[0];
            const day = splittedDate[1];
            const newDate = `${year}/${month}/${day}`;
            return `${newDate} ${insertedTime}`
        } else {
            return "";
        }
    }
    const showDate = (row) => {
        return <div>{formatDate(row.insertedDateTime.substring(0, 10), row.insertedDateTime.substring(11, 16))}</div>
    }

    const showDesc = (row) => {
        return row.message !== undefined && row.message.length > 100
            ?
            <OverlayTrigger
                trigger={['hover']}
                placement="bottom"
                overlay={<Tooltip id="tooltip-bottom" className="show">
                    {row.message}
                </Tooltip>}
            >
                <p className="com-message">{row.message} </p>
            </OverlayTrigger>
            : <p className="com-desc"> {row.message} </p>
    }
    const displayStatus = (row) => {
        return <div>
            <OverlayTrigger
                trigger={['hover']}
                placement="bottom"
                overlay={
                    <Tooltip id="tooltip-bottom" className="show">
                        <div>Status:  {
                            row.status === 'S' ? <b className='success-hlt'>Delivered</b> :
                                row.status === 'ST' ? <React.Fragment><b className='success-hlt'>In Progress</b><br /><label className='msg-disp'>ST - Success TEA</label></React.Fragment> :
                                    row.status === 'S3' ? <React.Fragment><b className='success-hlt'>Delivered</b><br /><label className='msg-disp'>S3 - Success 30STF</label></React.Fragment> :
                                        row.status === 'FT' ? <React.Fragment><b className='failure-hlt'>Not Delivered</b><br /><label className='msg-disp'>FT - Failure TEA</label></React.Fragment> :
                                            row.status === 'F3' ? <React.Fragment><b className='failure-hlt'>Not Delivered</b><br /><label className='msg-disp'>F3 - Failure 30STF'</label></React.Fragment> :
                                                row.channel === 'MOBILE' && row.status === 'NR' ? <React.Fragment><b className='failure-hlt'>Not Delivered</b><br /><label className='msg-disp'>NR - App not loaded</label></React.Fragment> :
                                                    row.status === 'NR' ? <React.Fragment><b className='failure-hlt'>Not Delivered</b><br /><label className='msg-disp'>NR - Not Registered</label></React.Fragment> :
                                                        row.status === 'PM' ? <React.Fragment><b className='failure-hlt'>Not Delivered</b><br /><label className='msg-disp'>PM - Previous Match</label></React.Fragment> :
                                                            row.status === 'IP' ? <React.Fragment><b className='success-hlt'>In Progress</b></React.Fragment> :
                                                                row.status === 'A' ? <b>Accepted</b> :
                                                                    row.status === 'R' ? <b>Released</b> :
                                                                        row.status === 'C' ? <b>Closed</b> : ''
                        }
                        </div>
                    </Tooltip>
                }
            >
                <div className="client-id-style">{(row.status === 'S' || row.status === 'ST' || row.status === 'S3') ? 'S' : (row.status === 'FT' || row.status === 'F3' || row.status === 'NR' || row.status === 'PM') ? 'F' : row.status === 'IP' ? 'I' : (row.status)}</div>
            </OverlayTrigger>
        </div>
    }

    return (
        <div className='communication_accordian_table'>
            <table className="groupedcommunication_table" >
                <thead>
                    <tr>
                        <th style={{ width: "24%" }}>
                            Date/Time(GMT) YYYY/MM/DD HH:MM
                        </th>
                        <th style={{ width: "100%" }}>
                            Flight Number
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sortedGroupedCommunicationLog.length > 0 ? sortedGroupedCommunicationLog.map((communication, groupedIndex) => (
                            <React.Fragment key={groupedIndex}>
                                <tr style={{ cursor: "pointer" }} onClick={() => toggleAccordian(groupedIndex)} className={groupedIndex === activeIndex ? "active_grouped_communicationtab" : ""}>
                                    <React.Fragment>
                                        <td style={{ padding: "2px 10px" }}>{showDate(communication[communication.length - 1])}</td>
                                        <td style={{ padding: "2px 10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}><p>{communication[communication.length - 1].hasOwnProperty('unique') &&  (communication[communication.length - 1].unique === 1 ? communication[communication.length - 1].flight : communication[communication.length -1].flight)}</p> <span style={{ width: "60px", padding: "10px" }} className={groupedIndex === activeIndex ? "caret-up-travel-group-communication" : "caret-down-group-communication"}>&nbsp;</span></td>
                                    </React.Fragment>
                                </tr>
                                {
                                    activeIndex === groupedIndex &&
                                    <td colspan="3">
                                        <table className="groupedcommunication_table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Type
                                                    </th>
                                                    <th>
                                                        Activity
                                                    </th>
                                                    <th style={{ display: "flex" }}>
                                                        <p>Date/Time(GMT) YYYY/MM/DD HH:MM</p>
                                                    </th>
                                                    <th>
                                                        Description
                                                    </th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    communication.map((comm, index) => (
                                                        !comm.hasOwnProperty('unique') && <tr key={index}>
                                                            <td>{showType(comm.messageFlow)}</td>
                                                            <td>{showChannel(comm)}</td>
                                                            <td>{showDate(comm)}</td>
                                                            <td>{showDesc(comm)}</td>
                                                            <td>{displayStatus(comm)}</td>
                                                        </tr>

                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </td>
                                }
                            </React.Fragment>
                        ))
                            :
                            <tr>
                                <td> No data to show</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default GroupedCommunication;