import React from 'react';
import Modal from '../CommonModels/Modal.jsx';
import TicketModel from '../Models/ticketmodel';
import MoreTravellersModal from '../CommonModels/moretravellersmodel';
import DispRailLeg from './DispRailLeg';
import SingleLegRail from './SingleLegRail';
import createClass from 'create-react-class';
let RailTripDetails = createClass({
	getInitialState: function() {
		return {
			openDetails: false,
			class: 'travel-plan row',
			classDetails: 'trip',
			classTripDetails: 'trip-details',
			typeOfTraval: '',
			popUpleft: false,
			popUpmidl: false,
			popUprgt: false
		};
	},
	onOpenClick: function(event) {
		if (this.state.openDetails) {
			this.setState({
				openDetails: false,
				classDetails: 'trip row ',
				classTripDetails: 'col-lg-12 trip-details hidden'
			});
		} else {
			this.setState({
				openDetails: true,
				classDetails: 'trip row open',
				classTripDetails: 'col-lg-12 trip-details'
			});
		}
	},
	handleDocsClick: function() {
		let openTab = this.props.openDocsTab;
		openTab(this.props.recordLocatorDocs);
	},
	render: function() {
		var specificTD = this.props.specificTD;
		var railLegFlag = false;
		var RailLegs;
		var RailLegArr = [];
		RailLegArr = this.props.specificTD.railLegArr;
		var railLegsTime = [];
		if (typeof RailLegArr != 'undefined') {
			if (RailLegArr.length > 1) {
				for (var i = 1; i < RailLegArr.length; i++) {
					if (RailLegArr[i].railDeparture != null && RailLegArr[i].railDeparture.length != 0) {
						var DepartureDateTime = RailLegArr[i].railDeparture.split('T');
					}

					if (RailLegArr[i - 1].railArrival != null && RailLegArr[i - 1].railArrival.length != 0) {
						var ArrivalDateTime = RailLegArr[i - 1].railArrival.split('T');
					}

					if (
						typeof DepartureDateTime != 'undefined' &&
						DepartureDateTime.length != 0 
					) {
						var Departure = new Date(
							DepartureDateTime[0].substring(0, 4),
							DepartureDateTime[0].substring(4, 6) - 1,
							DepartureDateTime[0].substring(6, 8),
							DepartureDateTime[1].substring(0, 2),
							DepartureDateTime[1].substring(2, 4)
						);
					}

					if (
						typeof ArrivalDateTime != 'undefined' &&
						ArrivalDateTime.length != 0
					) {
						var Arrival = new Date(
							ArrivalDateTime[0].substring(0, 4),
							ArrivalDateTime[0].substring(4, 6) - 1,
							ArrivalDateTime[0].substring(6, 8),
							ArrivalDateTime[1].substring(0, 2),
							ArrivalDateTime[1].substring(2, 4)
						);
					}

					var hourDiff = Departure - Arrival;

					var minDiff = hourDiff / 60 / 1000; //in minutes
					var hDiff = hourDiff / 3600 / 1000; //in hours
					var humanReadable = {};
					var endAirportTerminal = RailLegArr[i].departureCity;
					humanReadable.hours = Math.floor(hDiff);
					humanReadable.minutes = minDiff - 60 * humanReadable.hours;

					railLegsTime.push(
						humanReadable.hours + ' hours ' + humanReadable.minutes + ' mins  Stop at ' + endAirportTerminal
					);
				}

				railLegFlag = true;
				RailLegs = RailLegArr.map(function(railObj, j) {
					return (
						<DispRailLeg
							railLegsTime={railLegsTime}
							Railleg={railObj}
							noOfLegs={RailLegArr.length}
							specificTD={specificTD}
							iterator={j}
							key={j}
						/>
					);
				});
			} 
		}

		var miinfostate = false;
		var disableclassmoreinformation;
		var disableclassMoreTravellers;
		var moreinformation = true;

		var disableclassTicket;
		var tickstate = false;

		if (this.props.specificTD.MoreTravelers.length > 1) {
			disableclassMoreTravellers = '';
			//moreinformation = true;
		} else {
			disableclassMoreTravellers = 'disable';
			moreinformation = false;
		}

		if (
			this.props.specificTD.MIInformation.ShowColumnAs != null ||
			this.props.specificTD.MIInformation.Text != null
		) {
			disableclassmoreinformation = '';
			miinfostate = true;
		} else if (Array.isArray(this.props.MiInfoList) && this.props.MiInfoList.length > 0) {
			//else if(Array.isArray(this.props.specificTD.MIInformation.MultiMIInfo) && this.props.specificTD.MIInformation.MultiMIInfo[0].ShowColumnAs != null || Array.isArray(this.props.specificTD.MIInformation.MultiMIInfo) && this.props.specificTD.MIInformation.MultiMIInfo[0].Text != null){
			//Checks available MI Information
			disableclassmoreinformation = '';
			miinfostate = true;
		} else {
			disableclassmoreinformation = 'disable';
			//miinfostate = false;
		}
		if (this.props.specificTD.TicketInfo.ticket == true) {
			disableclassTicket = '';
			tickstate = true;
		} else {
			disableclassTicket = 'disable';
			//tickstate = false;
		}
		return (
			<div>
				<div className="trip-details air">
					<div className="link-holder air">
						<div className="btns">
							<div className="col-sm-3 col-xs-3">
								<a
									href="#"
									className={disableclassmoreinformation}
									data-toggle="modal"
									data-target="#myModal"
								>
									{this.props.placeHolder.MI_INFORMATION}
								</a>
								{miinfostate ? (
									<Modal
										placeHolder={this.props.placeHolder}
										CustomFieldValue={this.props.specificTD.MIInformation}
									/>
								) : null}
							</div>
							<div className="col-sm-3 col-xs-3">
								<a
									href="#"
									data-toggle="modal"
									className={disableclassMoreTravellers}
									data-target="#myMoreTravellersModal"
								>
									{this.props.placeHolder.MORE_TRAVELERS}
								</a>
								{moreinformation ? (
									<MoreTravellersModal
										placeHolder={this.props.placeHolder}
										CustomFieldValue={this.props.specificTD.MoreTravelers}
									/>
								) : null}
							</div>
							<div className="col-sm-3 col-xs-3 padding_left">
								<a
									href="#"
									className={disableclassTicket}
									data-toggle="modal"
									data-target="#myTicketModal"
									tabIndex="-1"
								>
									{this.props.placeHolder.TICKET}
								</a>
								{tickstate ? (
									<TicketModel
										placeHolder={this.props.placeHolder}
										Ticketing={this.props.specificTD.TicketInfo}
									/>
								) : null}
							</div>
							<div className="col-sm-3 col-xs-3">
								<a href="#" onClick={this.handleDocsClick.bind(this)}>
									Docs
								</a>
							</div>
						</div>
						<div className="add_info">
							<p>
								{this.props.placeHolder.RAIL_CONF_NO}.: {this.props.specificTD.ConfirmationNumber}
								<br /> {this.props.placeHolder.MEMBERSHIP_NO}.:<br />Transaction ID:{this.props.specificTD.TransactionID}
							</p>
						</div>
					</div>
					{railLegFlag ? RailLegs : <SingleLegRail specificTD={this.props.specificTD} />}
				</div>
			</div>
		);
	}
});

export default RailTripDetails;
