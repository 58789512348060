/**
  * @ Business Email Component
  * @ File - businessEmail.js
  * Business Email validation
  */
import React, { Component } from 'react';
import HTTP from '../../services/httpservice';

/* Define constants */
const MIN_CHARACTER_ERROR = "Business Email should have min 7 characters";
const MAX_CHARACTER_ERROR = "Business Email can have max 256 characters";
const VALID_EMAIL_ERROR = "Please enter a valid email address";
const INVALID_DOMAIN = 'This is an invalid email domain'
const FORMCLASS = 'form-control';
const ERROR_SAME_AS_USER = 'Email address cannot be same as Username';
const FORM_ERROR_CLASS = 'form-control error-icon';
const FORM_SUCESS_CLASS = 'form-control success-icon';
const EMAIL_VALIDATE_URL = '/utilities/users/validation/v1';


class BusinessEmail extends Component {

    // Constructor called before render function
    // The object that is returned is assigned to  this.state
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            formClass: FORMCLASS
        }
        this.propagation=false;
    }

    // Validate Email field.
    // Check Valid Email address (Min & Max characters, @ symbol validation)
    _validateEmail() {
        let x = this.state.email;
        let atpos = x.indexOf("@");
        let dotpos = x.lastIndexOf(".");
        let self = this;

        if (this.state.email.length < 7) {
            this.setState({
                error: MIN_CHARACTER_ERROR,
                formClass: FORM_ERROR_CLASS
            })
            this.focusToNextInput('#pwd')
        } else if (this.state.email.length > 256) {
            this.setState({
                error: MAX_CHARACTER_ERROR,
                formClass: FORM_ERROR_CLASS
            })
            this.focusToNextInput('#pwd')
        }
        else if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= x.length) {
            this.setState({
                error: VALID_EMAIL_ERROR,
                formClass: FORM_ERROR_CLASS
            })
            this.focusToNextInput('#pwd')
        }
        else if (this.props.emailDomain.indexOf(x.split('@')[1]) === -1) {
            this.setState({
                error: INVALID_DOMAIN,
                formClass: FORM_ERROR_CLASS
            })
            this.props.changeOrganisationToRed();
            this.focusToNextInput('#pwd')
        }
        else if(this.props.userValue==this.state.email){
          this.setState({
              error: ERROR_SAME_AS_USER,
              formClass: FORM_ERROR_CLASS
          })
          this.focusToNextInput('#pwd')
        }

        else {
            HTTP.get(EMAIL_VALIDATE_URL + '?email=' + x)
                .then(function (response) {
                    if (response.Success==="false") {
                        self.setState({
                            error: response.SuccessMessage,
                            formClass: FORM_ERROR_CLASS
                        });
                        self.focusToNextInput('#pwd')
                    } else {
                        self.setState({
                            error: '',
                            formClass: FORM_SUCESS_CLASS
                        })
                        self.props.setValue('email', self.state.email);
                        self.props.changeOrganisationToNormal();
                        if(window.$('#userName').is(':disabled')){
                             self.focusToNextInput('#pwd')
                        }
                        else
                            self.focusToNextInput('#userName')
                    }
                });
        }
         
    }
    focusToNextInput(id){
        if(this.propagation){ 
            this.propagation=false;  
            window.$(id).focus();
        } 
    }
    _resetEmail(response) {
        var self = this;
          self.setState({
              email: '',
              error: response,
              formClass: FORM_ERROR_CLASS
          });
          if (self.props.valid) {
              self.props.setFalse('email');
          }
    }

    // Set Email value to textbox.
    _setEmail(e) {
        this.setState({ email: e.target.value });
        if (this.props.valid) {
            this.props.setFalse('email');
        }
    }
    handle(e){
        if (e.keyCode === 9) {
            e.preventDefault();
            this.propagation=true;
            this._validateEmail(e)    
        }else{
            this.propagation=false;
        }
    }
    // Render businessEmail Component
    render() {
        return (<div className="form-group">
            <label for="bemail">Business Email Address*:</label>
            <input type="email" value={this.state.email} className={this.state.formClass} onKeyDown={this.handle.bind(this)}  onChange={this._setEmail.bind(this)} onBlur={this._validateEmail.bind(this)} id="bemail" placeholder="e.g., johnsmith@aexp.com" />
            <span className='error'>{this.state.error}</span>
            <p className="info-text">Must be your company domain email address </p>
        </div>);
    }
}

export default BusinessEmail;
