import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

class CommunicationTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowIndex:''
        }
    }
    showType(cell,row) {
        return <div className="channel-class"><div>{row.messageFlow==="O" ? 'Sent' : 'Received'}</div><div className={row.messageFlow==="O" ? "sent-type" : "received-type"}></div></div>
    }
    showChannel(cell,row) {
        var channel = '';
        if(row.channel === 'WEB') {
            switch(row.status) {
                case 'R':
                    channel = 'TC-Released';
                    row.channel = 'TC-Released';
                break;
                case 'A':
                    channel = 'TC-Accepted';
                    row.channel = 'TC-Accepted';
                break;
                case 'C':
                    channel = 'TC-Closed';
                    row.channel = 'TC-Closed';
                break;
            }
        } else if (row.channel === 'MOBILE') {
            row.channel = 'MobileApp';
        } else {
            channel = row.channel;
        }
        return <div><div className="break-word-class">{channel }</div><div className="break-word-class">{row.channelAddress}</div></div>
    }
    formatDate(insertedDate,insertedTime) {
        if(insertedDate !== undefined && insertedDate !== "") {
            let monthNames = [
                "","JAN","FEB", "MAR",
                "APR", "MAY", "JUN", "JUL",
                "AUG", "SEP", "OCT",
                "NOV", "DEC"
            ];
            var holidaySplit = insertedDate.split('/')
            var dateValue = holidaySplit[1];
            var year = holidaySplit[2].substring(2,4);
            let monthVal = holidaySplit[0].substring(0,1) == 0 ? monthNames[holidaySplit[0].substring(1,2)] : monthNames[holidaySplit[0]];
            return dateValue+monthVal+year+'/'+insertedTime;
        } else {
            return "";
        }
    }        
    showDate(cell,row) {
        return <div>{this.formatDate(row.insertedDateTime.substring(0,10),row.insertedDateTime.substring(11,16))}</div>
    }
    showDesc(cell,row,enumObject,index) {
        let rowValue = this.state.rowIndex;
        return row.message !== undefined && row.message.length >100
                 ?  
                    <p onMouseOver={() => this.setState({rowIndex:index})} onMouseOut={() => this.setState({rowIndex:''})} className="com-message">{row.message}
                        <div className= {rowValue === index ? 'show fade in popover bottom pop_over_right extra_width' : 'hide fade in popover bottom pop_over_right extra_width'}>
                            <div className='arrow'></div>
                            <div className='popover_custom'>
                                {row.message}
                            </div>
                        </div>
                    </p>
                :   <p className="com-desc"> {row.message} </p>
    }
    componentDidUpdate(){
        let noDataDiv = document.getElementsByClassName('react-bs-table-no-data')[0]
        if (noDataDiv) {
            noDataDiv.innerHTML = this.props.placeHolder.NO_DATA_TO_DISPLAY;
        }
    }
    componentDidMount(){
        let noDataDiv = document.getElementsByClassName('react-bs-table-no-data')[0]
        if (noDataDiv) {
            noDataDiv.innerHTML = this.props.placeHolder.NO_DATA_TO_DISPLAY;
        }
    }
    
    render() {
        const options = {
            defaultSortName: 'insertedDateTime',
            defaultSortOrder: 'desc',
        };
        return (<div>
                    <BootstrapTable data={this.props.communicationLogs} className={this.props.communicationLogs !== undefined ? "communication-table com-table-height":"communication-table"} striped hover options={options}>
                        <TableHeaderColumn dataSort dataField='messageFlow' dataFormat={this.showType.bind(this)} >Type </TableHeaderColumn>
                        <TableHeaderColumn dataSort dataField='channel' dataFormat={this.showChannel.bind(this)} isKey>{this.props.placeHolder.ACTIVITY}</TableHeaderColumn>
                        <TableHeaderColumn dataSort dataField='insertedDateTime' dataFormat={this.showDate.bind(this)}>{this.props.placeHolder.DATE_TIME}</TableHeaderColumn>
                        <TableHeaderColumn dataSort dataField='message' dataFormat={this.showDesc.bind(this)}>{this.props.placeHolder.DESCRIPTION}</TableHeaderColumn>
                    </BootstrapTable>
                </div>
        );
    }
}
export default CommunicationTable;