'use strict';
import React from 'react';
import Forgot from '../Login/ForgotUserPassword';
import Reset from '../Login/ActivationPage';
import HTTP from '../../services/httpservice';
import { Environment } from '../constants';
import FooterContent from '../FooterContent';

export default class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			error: false,
			errorMessage: '',
			validUser: false,
			validPwd: false,
			emptyUserField: false,
			emptyPswdField: false,
			actionType: '',
			newPageMessage: '',
			userId: '',
			mailId: '',
			confirmPage: '',
			page: '',
			uservalue: '',
			resetPasswordMessage: '',
			resetPasswordExpiry: false
		};
	}

	_setusername(e, event) {
		if (this.state.emptyUserField) {
			this.setState({ emptyUserField: false });
		}
		this.setState({
			username: e.target.value.toLowerCase()
		});
		if (e.target.value.length >= 3) {
			this.setState({ validUser: true });
		} else {
			this.setState({ validPwd: false });
		}
	}
	_setpassword(e, event) {
		if (this.state.emptyPswdField) {
			this.setState({ emptyPswdField: false });
		}
		this.setState({ password: e.target.value });
		if (e.target.value.length > 3) {
			this.setState({ validPwd: true });
		} else {
			this.setState({ validPwd: false });
		}
	}
	_loginhelper() {
		if (this.state.username.length >= 3 && this.state.password.length > 3) {
			if (this.state.username == '') {
				this.setState({ emptyUserField: true });
			} else {
				this.setState({ emptyUserField: false });
			}
			if (this.state.password == '') {
				this.setState({ emptyPswdField: true });
			} else {
				this.setState({ emptyPswdField: false });
			}
			var self = this;
			var user = {
				username: this.state.username,
				password: this.state.password
			};
			if (this.state.username == 'gtradmin' && this.state.password == 'secret') {
				self.setState({ validUser: true, validPwd: true });

				self.props.UpdateLogIn(true, '', 'search', 'gtradmin', '', '', '', 'gtradmin', '');

				return;
			} else if (this.state.validUser && this.state.validPwd) {
				HTTP.postJSON('/api/login', user).then(function(data) {
					if (data.success) {
						var strg = data.viewerRelatedGroups.toString();
						localStorage.setItem('jwtToken', data.token);
						self.props.UpdateLogIn(
							true,
							strg,
							'search',
							self.state.username,
							'',
							'',
							'',
							data.userName,
							data
						);
					} else {
						console.log('false');
						self.setState({ error: true, errorMessage: data.reason, actionType: data.actionType });
					}
				});
			} else {
				self.setState({ validUser: false, validPwd: false });
			}
		}
	}
	_loginValidation() {
		if (this.state.username.length >= 3 && this.state.password.length > 3) {
			if (this.state.username == '') {
				this.setState({ emptyUserField: true });
			} else {
				this.setState({ emptyUserField: false });
			}

			if (this.state.password == '') {
				this.setState({ emptyPswdField: true });
			} else {
				this.setState({ emptyPswdField: false });
			}
			var self = this;
			const args = {
				"username": this.state.username,
				"password": encodeURIComponent(this.state.password)
			}
			var loginUrl = '/auth/userlogin/v1';
			HTTP.postJSON(loginUrl, args).then(function(data) {
				localStorage.setItem('organizationName',data.orgName);
				localStorage.setItem('premAccessName',data.premAccess);
				if (data.success == 'true') {
					if (data.actionType == 'search') {
						self.setState({ error: '' });
						self.props.UpdateLogIn(
							true,
							'',
							'search',
							self.state.username,
							data.orgId,
							data.premAccess,
							data.orgName,
							data,
							''
						);
					} else if (data.actionType == 'alert-password-expiry') {
						// console.log('calling'); self.setState({   resetPasswordExpiry:true });
						self.props.resetPasswordExpiry(true, data.userId, data.orgId, data.reason);
					} else {
						self.setState({ error: '' });
						self.props.UpdateLogIn(true, '', '', self.state.username, '', '', '', '', '');
					}
				} else {
					if (data.actionType == 'alert-alert') {
						self.setState({ newPageMessage: true });
					} else if (data.actionType == 'alert-password') {
						self.setState(
							{ newPageMessage: true, userId: data.userId } //TODO
						);
					} else if (data.actionType == 'alert-password-expired') {
						self.setState({ page: 'Reset', uservalue: data.userId });
					} else if (data.actionType == 'alert-reset-password') {
						self.setState({ page: 'Reset', uservalue: data.userId, resetPasswordMessage: true });
					} else {
						self.setState({ newPageMessage: '' });
					}
					self.setState({ error: true, errorMessage: data.reason, actionType: data.actionType });
				}
			});
			//}
		}
	}
	_EventClick() {
		var self = this;
		if (this.state.actionType == 'alert-password') {
			let request = {
				userId: this.state.userId,
				action: 'NEWPASSWORD'
			};
			HTTP.postJSON('/user/recovery/v1', request).then(function(response) {
				if (response.success == 'true') {
					self.setState({ newPageMessage: true, confirmPage: true, mailId: response.emailId });
				} else {
					self.setState({ newPageMessage: true, errorMessage: response.reason, confirmPage: '' });
				}
			});
		} else {
			self.setState({ newPageMessage: '', username: '', password: '' });
		}
	}

	_CompleteClick() {
		this.setState({ actionType: '', confirmPage: '', newPageMessage: '', username: '', password: '' });
	}

	_chkEnterbtn(event) {
		if (this.state.username != '' && this.state.password != '') {
			if (event.key == 'Enter' || event.keyCode == 13) {
				if (Environment === 'INTERNET') this._loginValidation();
				else this._loginhelper();
			}
		}
	}

	_openForgot(event) {
		this.setState({ page: 'Forgot' });
	}

	render() {
		var loginButton = 'disabled_login';
		if (this.state.page === 'Forgot') {
			return <Forgot placeHolder={this.props.placeHolder} />;
		} else if (this.state.page === 'Reset') {
			return (
				<Reset
					placeHolder={this.props.placeHolder}
					userId={this.state.uservalue}
					resetPasswordMessage={this.state.resetPasswordMessage}
				/>
			);
		}
		if (this.state.username.length >= 3 && this.state.password.length > 3) loginButton = 'form-submit';
		var logoClass = '';
		if (this.props.placeHolder.ACTIVE == 'Actif') {
			logoClass = 'logoCanadian';
		} else {
			logoClass = 'logoEnglish';
		}
		var message = '';
		var headermessage = '';
		if (
			this.state.errorMessage ==
			'For security reasons, your account has been locked. Please contact your American' +
				' Express Global Business Travel support manager to unlock your account'
		)
			headermessage = 'ID Locked';
		else if (
			this.state.errorMessage ==
			'Your user credentials have been deleted. please contact your manager to request ' + 'a new ID'
		)
			headermessage = 'ID Deleted';
		else headermessage = '';
		var errMsgCenter = 'submit-errors err-center';
		if (this.state.errorMessage === 'Invalid user name or password') {
			errMsgCenter = 'submit-errors err-invalid-center';
		}
		var unathError = 'submit-errors unath-err-en';
		if (this.props.placeHolder.NOT_AUTHORIZED === "Vous n'êtes pas autorisé!!!") {
			unathError = 'submit-errors unath-err-fr';
		}
		return (
			<div className="resolution_container_reg container">
				<div id="containerreg">
					<header>
						<span id={logoClass} />
						<a href="../gbt_viewer_quick_reference.pdf" target="_blank" className="help_icon">
							&nbsp;
						</a>
					</header>
					{!this.state.newPageMessage ? (
						<div className="login-box">
							<form className="login-form">
								<div className="login-text">{this.props.placeHolder.LOGIN}</div>
								<div className="form-group">
									{this.state.actionType !== 'alert-message' && this.props.logout ? (
										<div className="submit-error-block">
											<p className={errMsgCenter}>
												{this.props.placeHolder.YOU_HAVE_BEEN_SUCCESSFULLY_LOGGED_OUT}
											</p>
										</div>
									) : null}
									{this.state.actionType == 'alert-message' ? (
										<div className="submit-error-block">
											{this.state.errorMessage === 'You are not authorized!!!' ? (
												<p className={unathError}>
													{this.props.placeHolder.NOT_AUTHORIZED} <br />
													<a
														className="authorized-err"
														href="https://gbt.jiveon.com/docs/DOC-70816"
														target="_blank"
													>
														{this.props.placeHolder.CLICK_HERE}
													</a>{' '}
													{this.props.placeHolder.FOR_ACCESS}{' '}
												</p>
											) : (
												<p className={errMsgCenter}>{this.state.errorMessage}</p>
											)}
										</div>
									) : null}
									<div className={this.state.emptyUserField ? 'emptyFieldCheck' : ''}>
										<label for="edit-name">
											{this.props.placeHolder.USERNAME}:<span className="form-required" title="This field is required." />
										</label>
										<input
											className="form-control form-text required user"
											type="text"
											value={this.state.username}
											onChange={this._setusername.bind(this)}
											onKeyUp={this._chkEnterbtn.bind(this)}
											size="60"
											maxLength="60"
										/>
									</div>
								</div>
								<div className="form-group">
									<div className={this.state.emptyPswdField ? 'emptyFieldCheck' : ''}>
										<div className="form-type-password form-item-pass form-item form-group">
											<label for="edit-pass">
												{this.props.placeHolder.PASSWORD}:<span className="form-required" title="This field is required." />
											</label>
											<input
												autoComplete="off"
												className="form-control form-text required"
												type="password"
												value={this.state.password}
												onChange={this._setpassword.bind(this)}
												onKeyUp={this._chkEnterbtn.bind(this)}
												id="edit-pass"
												size="60"
												maxLength="128"
											/>
										</div>
									</div>
								</div>
								<br />{' '}
								{Environment === 'INTERNET' ? (
									<button
										className={loginButton}
										onClick={this._loginValidation.bind(this)}
										type="button"
									>
										{this.props.placeHolder.LOGIN}
									</button>
								) : (
									<button
										className={loginButton}
										onClick={this._loginhelper.bind(this)}
										type="button"
										data-toggle="modal"
									>
										{this.props.placeHolder.LOGIN}
									</button>
								)}
							</form>

							{Environment === 'INTERNET' && (
								<div className="forgot-user-password">
									<a>
										<span className="sign-up" onClick={this._openForgot.bind(this)}>
											{this.props.placeHolder.FORGOT_USER_NAME_OR_PASSWORD}?
										</span>
									</a>
								</div>
							)
							
							}
						</div>
					) : (
						<div className="registration-container container error-body" id="registration-container">
							{this.state.confirmPage ? (
								<ConfirmPage
									mailId={this.state.mailId}
									_CompleteClick={this._CompleteClick.bind(this)}
									placeHolder={this.props.placeHolder}
								/>
							) : (
								<ErrorPage
									errorMessage={this.state.errorMessage}
									_EventClick={this._EventClick.bind(this)}
									actionType={this.state.actionType}
									headermessage={headermessage}
									placeHolder={this.props.placeHolder}
								/>
							)}
						</div>
					)}
				</div>
				<FooterContent/>
			</div>
		);
	}
}

class ConfirmPage extends React.Component {
	render() {
		return (
			<div className="hide_scroll">
				<div>
					<p className="thank-header">
						<b>Thank You</b>
					</p>
					<p className="thank-message">
						We have sent a new temporary password to
						<span className="mailId">{this.props.mailId}</span>. If you do not receive an email in your inbox,
						please check your spam folder.
					</p>
					<button type="button" className="btn btn-default confirmbutton" onClick={this.props._CompleteClick}>
						OK
					</button>
				</div>
			</div>
		);
	}
}

class ErrorPage extends React.Component {
	render() {
		return (
			<div className="hide_scroll">
				<div className="error-header">
					<b>{this.props.headermessage}</b>
				</div>
				<br />
				<div className="col-sm-1 errorimage" />
				<div className="col-sm-11">
					<p className="error-message">{this.props.errorMessage}</p>
				</div>
				{this.props.actionType == 'alert-password' ? (
					<button type="button" className="btn btn-default resetbutton" onClick={this.props._EventClick}>
						Request new Password
					</button>
				) : (
					<button type="button" className="btn btn-default confirmbutton" onClick={this.props._EventClick}>
						OK
					</button>
				)}
			</div>
		);
	}
}
