import React from "react";
import TripRecDetails from "../TripRecDetails.js"
import {changeNewDateFormat,findLatestEmailStatus} from '../../../../services/utils';

/*
* Renders the Trip Recommender block
* @File - TripRecommender.js
* @dependencies - Child Component(TripRecDetails)
*/
export default class TripRecommender extends React.Component {

    constructor(props) {
        // This is called before render function
        //  The object that is returned is assigned to  this.state
        super(props);
        this.state = {
            openRecommendation  :  false
        };
    }

    _onClickCaret() {
        // Collapsed format expansion - state change
        if(this.state.openRecommendation) {
            this.setState({
                openRecommendation  : false
            });
        } else {
            this.setState({
                openRecommendation  : true
            });
        }
    }

    _ShowPopOver(travelerId) {
        // Show popovers when user mouseover on Email icon
        window.$('#'+travelerId).removeClass('hidden');
    }

    _DisablePopOver(travelerId) {
        // Disable popovers when user mouseout on Email icon
         setTimeout(function () {
            if (!window.$(".popover_all:hover").length) {
                window.$('#'+travelerId).addClass('hidden');
            }
         }, 200);
    }

    _remainPopoverOpen (travelerId) {
        // Remain popover open when user click on it
        window.$('#'+travelerId).removeClass('hidden');
        window.$("#"+travelerId).on("mouseleave", function () {
            window.$('#'+travelerId).addClass('hidden');
        });
    }

    render () {
        //  Render tripRecommender block
        //  Child component - TripRecDetails
        var hotelRecObj;
        if(this.props.hotelRecObj !=  null) {
            var hotelRecObj = this.props.hotelRecObj;
        }
        var classDetails;
        var key             = this.props.travellerId+'email';
        var deliveredStaus  = "";
        if(this.state.openRecommendation) {
            classDetails = "trip row";
        } else {
            classDetails = "trip row open";
        }
        if(hotelRecObj!=null) {
            if(typeof(hotelRecObj.HotelRecommendation)!="undefined" && hotelRecObj.HotelRecommendation !=null) {

                // Email status Icon and hover functionality
                // Delivered,pending and not_delivered email status handling

                if(this.props.hotelRecObj.HotelRecommendationEmailStatus != null && this.props.hotelRecObj.HotelRecommendationEmailStatus.RecommendationEmailDeliveryStatus.Emails.Email != null && this.props.hotelRecObj.HotelRecommendationEmailStatus.RecommendationEmailDeliveryStatus.Emails.Email.length !=0) {
                    var emailsArray       =  this.props.hotelRecObj.HotelRecommendationEmailStatus.RecommendationEmailDeliveryStatus.Emails.Email;
                    var latestEmailArray  = findLatestEmailStatus(emailsArray);
                    var mailStatus        = '';
                    var mailStatusText    = '';
                    var hoverEmailText    = latestEmailArray.EmailId.toLowerCase();
                    if(typeof latestEmailArray != undefined) {
                        if(latestEmailArray.EmailStatus.toLowerCase() == "delivered") {
                            mailStatus      = "mail_sent";
                            mailStatusText  = "Delivered";
                        } else if(latestEmailArray.EmailStatus.toLowerCase()  == "failed") {
                            mailStatus      = "mail_not_delivered";
                            mailStatusText  = "Failed";
                        }else if(latestEmailArray.EmailStatus.toLowerCase() == "acknowledgement received") {
                            mailStatus      = "mail_pending";
                            mailStatusText  = "Acknowledgement Received";
                        }
                        var latestEmailData =   <span className={mailStatus} onMouseEnter={this._remainPopoverOpen.bind(this,key)} onMouseOut={this._DisablePopOver.bind(this,key)} onMouseOver={this._ShowPopOver.bind(this,key)}>
                                                    <div className="popover_all hidden" id={key}>
                                                        <div className='fade in popover_email popover bottom pop_over_right trip_recommender_popover'>
                                                            <div className='arrow'></div>
                                                            <div className='popover-content'>
                                                                <div className='popover_custom '>
                                                                    <span className="color_blue_normal"> {mailStatusText} : </span>
                                                                    <span className="color_dark_grey"> {changeNewDateFormat(latestEmailArray.EmailDate)} {this.props.placeHolder.TO} &nbsp;</span>
                                                                    <span className="delivered_email"> {hoverEmailText}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                   }
                } else {
                    var latestEmailData = <span></span>
                }
                return (<div>
                            <div className="trip_recommender">
                                <div className={classDetails} onClick={this._onClickCaret.bind(this)}>
                                    <span className="hotel_rec">&nbsp;</span>
                                  {this.props.placeHolder.HOTEL_RECOMMENDATIONS} ({hotelRecObj.hotelNights} {hotelRecObj.hotelNights >1?this.props.placeHolder.NIGHTS:this.props.placeHolder.NIGHT})
                                    {latestEmailData}
                                    <a href="#" className="caret-down-travel col-sm-3" tabindex="0">&nbsp;</a>
                                </div>
                            </div>
                            {this.state.openRecommendation?<TripRecDetails placeHolder={this.props.placeHolder} hotelRecObj={this.props.hotelRecObj}/>:null}
                      </div>);
            } else {
                return(<div></div>);
            }
          }

          // Missing Recommendations functionality
          if(this.props.missingRecObj!=null) {
              if(this.props.missingRecObj.status) {
                  return(<div>
                            <div className="trip_recommender">
                                <div className="trip">
                                    <span className="hotel_rec">&nbsp;</span>
                                    {this.props.placeHolder.MISSING_ACCOMMODATIONS} ({this.props.missingRecObj.PotentialHotelNights} {this.props.missingRecObj.PotentialHotelNights>1?this.props.placeHolder.NIGHTS:this.props.placeHolder.NIGHT})
                                </div>
                            </div>
                        </div>);
              } else {
                  return(<div></div>);
              }
          }
      }
}
