import React from "react";
import Modal from "../CommonModels/Modal.jsx"
import MoreTravellersModal from "../CommonModels/moretravellersmodel";
import createClass from 'create-react-class';
var CarTripDetails = createClass({
      getInitialState: function () {
          return {
              openDetails: false,
              class:  "travel-plan row",
              classDetails: "trip",
              classTripDetails: "trip-details",
              typeOfTraval:"",
              popUpleft:false,
              popUpmidl:false,
              popUprgt:false
          }
      },
      onOpenClick: function(event){
        if(this.state.openDetails) {
              this.setState({
                              openDetails: false,
                              classDetails: "trip row ",
                              classTripDetails: "col-lg-12 trip-details hidden"
              });
        }else{
            this.setState({
                            openDetails: true,
                            classDetails: "trip row open",
                            classTripDetails: "col-lg-12 trip-details"
            });
        }
      },
      popUpfunleft:function(){
        if(this.state.popUpleft){
          this.setState({popUpleft:false});
        }else{
          this.setState({popUpleft:true});
        }
      },
      popUpfunmiddle:function(){
        if(this.state.popUpmidl){
        this.setState({popUpmidl:false});
        }else{
        this.setState({popUpmidl:true});
        }
      },
      popUpfunright:function(){
      if(this.state.popUprgt){
      this.setState({popUprgt:false});
      }else{
      this.setState({popUprgt:true});

      }

      },
      handleDocsClick : function(){
        let openTab = this.props.openDocsTab;
        openTab(this.props.recordLocatorDocs);
      },
      render:function(){
        var miinfostate=false;
        var disableclassmoreinformation ;
        var disableclassMoreTravellers;
        var moreinformation=true;
        var RateType="";
        if(this.props.specificTD.RateType!=null){
          RateType="/"+this.props.specificTD.RateType;
        }
        if(this.props.specificTD.RateCurrencyCode==null){
          this.props.specificTD.RateCurrencyCode="";
        }
        if(this.props.specificTD.MoreTravelers.length > 1){
              disableclassMoreTravellers = "";
             // moreinformation = true;
          }else{
            disableclassMoreTravellers = "disable";
            moreinformation = false;
          }

          if(this.props.specificTD.MIInformation.ShowColumnAs != null || this.props.specificTD.MIInformation.Text != null || (Array.isArray(this.props.MiInfoList) && this.props.MiInfoList.length >0)){
              disableclassmoreinformation = "";
              miinfostate = true;
          }else{
            disableclassmoreinformation = "disable";
           // miinfostate = false;
          }
        return(
          <div className="car_section">
            <div className={this.state.classTripDetails}>
            <div className="side-icon">
              <div className="inner-line"></div>
            </div>
            <div className="link-holder">
                  <div className="btns">
                  <div className="col-sm-4">
                    <a  href="#" className={disableclassmoreinformation} data-toggle="modal" data-target="#myModal">{this.props.placeHolder.MI_INFORMATION}</a>
                    {miinfostate ? <Modal placeHolder={this.props.placeHolder} CustomFieldValue={this.props.specificTD.MIInformation}/>:null}
                  </div>
                  <div className="col-sm-4">
                    <a  href="#"  data-toggle="modal" className={disableclassMoreTravellers} data-target="#myMoreTravellersModal">{this.props.placeHolder.MORE_TRAVELERS}</a>
                    {moreinformation ? <MoreTravellersModal placeHolder={this.props.placeHolder} CustomFieldValue={this.props.specificTD.MoreTravelers}/>:null}
                  </div>
                  <div className="col-sm-4">
                        <a  href="#" onClick={this.handleDocsClick.bind(this)}>Docs</a>
                  </div>
            </div>
            <div className="add_info">
              <p>{this.props.placeHolder.CAR_MEMBERSHIP}:  {this.props.specificTD.CarMembership} </p><p> {this.props.placeHolder.CORPORATE_ID}: {this.props.specificTD.CoporateID} </p>
            </div>
            </div>
          <div className="flight-time car_details">
              <div className="tab1">
                  <p>{this.props.placeHolder.RATE}: </p>
                  <p>{this.props.specificTD.RateAmount!=null ? this.props.specificTD.RateAmount+" "+this.props.specificTD.RateCurrencyCode+RateType :null}</p>
                  <p>{this.props.specificTD.ExtraHour!=null ? this.props.specificTD.ExtraHour+" "+this.props.specificTD.RateCurrencyCode+" "+this.props.placeHolder.EXTRA_HR : null}</p>
                  <p>{this.props.specificTD.ExtraDay!=null ? this.props.specificTD.ExtraDay+" "+this.props.specificTD.RateCurrencyCode+" "+this.props.placeHolder.EXTRA_DAY : null}</p>
                  <p>{this.props.specificTD.ApproxRate!=null ? this.props.specificTD.ApproxRate+" "+ this.props.specificTD.RateCurrencyCode+" "+this.props.placeHolder.APPROX_TOTAL : null}</p>
              </div>
          <div className="tab2">
              <p>&nbsp;</p>
              <p>{this.props.placeHolder.MILEAGE}: {this.props.specificTD.CarMileage}</p>
              <p>{this.props.placeHolder.RATE_CODE}: {this.props.specificTD.RateCode}</p>
          </div>
          <div className="tab3">
            <p>&nbsp;</p>
            <p className="car_des">{this.props.specificTD.NumOfCars} {this.props.specificTD.CarType} </p>
            <p><span className="col-sm-6">{this.props.placeHolder.SPECIAL_REQ}</span> : {this.props.specificTD.SpecialRequest} </p>
            <p><span className="col-sm-6">{this.props.placeHolder.SPECIAL_INFO}</span>: {this.props.specificTD.SpecialInfo} </p>
          </div>
          <div className="col-sm-6">
          <p>&nbsp;</p>
          <p><span className="col-sm-6">{this.props.placeHolder.VOUCHER_INFO}</span> : {this.props.specificTD.VoucherInfo} </p>
          <p><span className="col-sm-6">{this.props.placeHolder.REASON_CODE}</span> : {this.props.specificTD.ReasonCode}</p>
          </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
        );
      }
});

export default CarTripDetails;
