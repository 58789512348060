import React from "react";
import CallStatusAndCommentsTable from "../CallStatusAndCommentsTable"
import CallStatusTable from "../CallStatusTable"
import CommentsTable from "../CommentsTable"
import _ from "underscore";
import HTTP from "../../../../services/httpservice.js";


export default class CallStatusAndComments extends React.Component {
  constructor() {
    super();
    this.state = {
      allCallFlg: true,
      callFlg: false,
      commentsFlg: false,
      historyFlag: false,
      filterOption: 'all',
      callStatusAndCommentsData: [],
      loader: true,
      innerSrchTxt: "",
      innersrchFlg: false,
      againCallFlg: false,
    };
    this.TripArraySegments = [];
  }
  componentWillReceiveProps(newProps) {
    if (newProps.PnrSearchText != this.props.PnrSearchText || newProps.PnrSearchText != this.state.innerSrchTxt)
      this.setState({
        innerSrchTxt: newProps.PnrSearchText
      })
  }

  componentDidMount() {

    // Implement API call to get call status and comments data
    // Get the response and assign it into the state
    var apiUrl;
    var travelerId;
    travelerId = this.props.travelerId;
    apiUrl = '/api/callstatusandcomments/';
    this.TripArraySegments = {};
    var tripIdObject = [];
    for (var i = 0; i < this.props.TripIdDetailsArray.length; i++) {
      tripIdObject.push({
        "tripid": this.props.TripIdDetailsArray[i]
      });
    }
    var postBody = {
      "Traveler": {
        "Id": this.props.traveller.TravelerId,
        "email": this.props.traveller.EmailId[0]
      },
      "TripIds": tripIdObject
    };
    HTTP.postJSON(apiUrl, postBody).then(function (response) {
      if (response.CallStatusOrComments.Success == "True") {
        var emptyCheck = _.isEmpty(response.CallStatusOrComments);
        if (emptyCheck == false) {
          this.setState({
            callStatusAndCommentsData: response.CallStatusOrComments,
            loader: false
          });

        } else {
          this.setState({ loader: false })
        }
      } else if (response.CallStatusOrComments.Success == "False") {
        this.setState({ loader: false })
      }

    }.bind(this));
  }

  _sortCallStatus(key) {
    switch (key) {
      case "all":
        if (!this.state.allCallFlg) {

          this.setState({ allCallFlg: true, againCallFlg: true, callFlg: false, commentsFlg: false, filterOption: 'all' });

        }

        break;
      case "call":

        if (!this.state.callFlg) {

          this.setState({ allCallFlg: false, callFlg: true, commentsFlg: false, filterOption: 'callstatus' });

        }
        break;
      case "comments":
        if (!this.state.commentsFlg) {

          this.setState({ allCallFlg: false, callFlg: false, commentsFlg: true, filterOption: 'comments' });

        }
        break;
    }
  }

  _onClose() {
    this.setState({ innersrchFlg: false });
    this.setState({ innerSrchTxt: "" });
    //this.props.PnrSearchText="";
    this.props.clearFilter("");
    window.$('.pnr_searchbar').val("");
    window.$('.secondary').hide();
    window.$('.pnr_searchbar').focus();
  }
  _onKeyUp(event) {

    var val = event.target.value;
    if (val != "" || val != " ") {

      this.setState({ innerSrchTxt: val });
      if (event.key == 'Enter') {
        this.setState({

          innersrchFlg: true

        });
        if (document.getElementById('callStatusSrch').value != "" && document.getElementById('callStatusSrch').value != " ") {
          window.$('.secondary').show();
          window.$('.secondary span').text(val);
          window.$('.pnr_searchbar').val(val);
          window.$('.pnr_searchbar').blur();
        }
      } else {
        this.setState({
          innerSrchTxt: "",
          innersrchFlg: false

        });

      }
    } else {
      this.setState({
        //cancelFlg:false,
        innersrchFlg: false
      });
    }
  }

  _showSearchText(val, key) {


    if (key == 'Enter') {
      this.setState({ innerSrchTxt: val });
      this.props.PnrSearchText = "";
      setTimeout(function () {
        window.$('.secondary').show();
        window.$('.secondary span').text(val);


        window.$(".close_greybox").click(function () {
          window.$('.secondary').hide();
          window.$('.pnr_searchbar').focus();
        });
        window.$(".pnr_searchbar").click(function () {
          window.$('.secondary').hide();
          window.$('.pnr_searchbar').focus();
        });

      }
        , 100);



      this.setState({ innersrchFlg: true });
    }
  }

  _onclicksrch() {
    var val = document.getElementById('callStatusSrch').value;
    if (val != "" && val != " " || this.props.PnrSearchText != "") {
      this.setState({
        innerSrchTxt: val,
        innersrchFlg: true
      });

      window.$('.secondary').show();
      window.$('.secondary span').text(val);
      window.$('.pnr_searchbar').val(val);
    } else {
      this.setState({

        innersrchFlg: false
      });
    }
  }



  UpdateTable(ResponseJson) {
    let tempJson = this.state.callStatusAndCommentsData;
    tempJson.Traveler = ResponseJson;
    this.setState({
      callStatusAndCommentsData: tempJson
    })
  }

  _onFocus() {
    window.$(".searchBox-grey-active.secondary").css('display', 'none');
  }

  _onChangeTxt() {
    this.props.clearFilter("");
    this.setState({ innerSrchTxt: "", innersrchFlg: false });
  }
  _onClickHistory() {
    let historyFlag = !this.state.historyFlag;
    var travelerId;
    travelerId = this.props.travelerId;
    let apiUrl = '/api/callstatusandcomments/';
    this.TripArraySegments = {};
    var tripIdObject = [];
    for (var i = 0; i < this.props.TripIdDetailsArray.length; i++) {
      tripIdObject.push({
        "tripid": this.props.TripIdDetailsArray[i]
      });
    }
    var postBody = {
      "Traveler": {
        "Id": this.props.traveller.TravelerId,
        "email": this.props.traveller.EmailId[0]
      },
      "TripIds": tripIdObject
    };
    if (historyFlag) {
     apiUrl = '/api/historycallstatusandcomments/';
    }
    HTTP.postJSON(apiUrl, postBody).then(function (response) {
        if (response.CallStatusOrComments.Success == "True") {
          var emptyCheck = _.isEmpty(response.CallStatusOrComments);
          if (emptyCheck == false) {
            this.setState({
              callStatusAndCommentsData: response.CallStatusOrComments,
              loader: false
            });

          } else {
            this.setState({ loader: false })
          }
        } else if (response.CallStatusOrComments.Success == "False") {
          this.setState({ loader: false })
        }
        this.setState({ historyFlag })
      }.bind(this));



}

render() {
  var innerSrchTxt = "";
  var innersrchFlg = false;
  let callStatusAndCommentsDatatemp = this.state.callStatusAndCommentsData;
  if (this.state.callStatusAndCommentsData.Traveler != null) {
    this.TripArraySegments = converttoTripsArray(this.state.callStatusAndCommentsData);
    callStatusAndCommentsDatatemp = RemoveDuplicatesTrips(this.state.callStatusAndCommentsData);
  }
  if ((this.state.innerSrchTxt != "" && this.state.innerSrchTxt != " ") || this.props.PnrSearchText != "") {
    //alert("raised");
    innersrchFlg = true;
    innerSrchTxt = (this.state.innerSrchTxt != "" && this.state.innerSrchTxt != " ") ? this.state.innerSrchTxt : this.props.PnrSearchText;
    if (this.props.PnrSearchText != "") {
      window.$('.secondary').show();
      window.$('.secondary span').text(innerSrchTxt);
      window.$('.pnr_searchbar').val(innerSrchTxt);
    }

  }


  var tableData = '';
  var allClass = "btn btn-primary btn-travel margin_left_5";
  var callstatusclass = "btn btn-primary btn-past";
  var commentsClass = "btn btn-primary btn-cancelled"
  let historyClass = "btn btn-primary btn-history"
  if (this.state.allCallFlg) {
    tableData = <CallStatusAndCommentsTable historyFlag={this.state.historyFlag} placeHolder={this.props.placeHolder} againCallFlg={this.state.againCallFlg} RecordLoc={this.props.RecordLoc} tripIdsegmentArray={this.TripArraySegments} innerSrchTxt={innerSrchTxt} innersrchFlg={innersrchFlg} DeleteCallStatus={this.props.DeleteCallStatus} UpdateTable={this.UpdateTable.bind(this)} UpdateCommentCount={this.props.UpdateCommentCount} SaveSucess={this.props.SaveSucess} loader={this.state.loader} data={callStatusAndCommentsDatatemp} allCallFlg={this.state.allCallFlg} filterOption={this.state.filterOption} traveller={this.props.traveller} travelerId={this.props.travelerId} />

    var allClass = "btn btn-primary btn-travel active margin_left_5";
  } else {
    var allClass = "btn btn-primary btn-travel margin_left_5";
  }

  if (this.state.callFlg) {
    tableData = <CallStatusTable historyFlag={this.state.historyFlag} placeHolder={this.props.placeHolder} RecordLoc={this.props.RecordLoc} againCallFlg={this.state.againCallFlg} innerSrchTxt={innerSrchTxt} innersrchFlg={innersrchFlg} DeleteCallStatus={this.props.DeleteCallStatus} UpdateTable={this.UpdateTable.bind(this)} UpdateCommentCount={this.props.UpdateCommentCount} SaveSucess={this.props.SaveSucess} loader={this.state.loader} data={callStatusAndCommentsDatatemp} allCallFlg={this.state.allCallFlg} filterOption={this.state.filterOption} traveller={this.props.traveller} travelerId={this.props.travelerId} />
    callstatusclass = "btn btn-primary btn-past active";

  } else {
    callstatusclass = "btn btn-primary btn-past";
  }
  if (this.state.commentsFlg) {

    tableData = <CommentsTable historyFlag={this.state.historyFlag} placeHolder={this.props.placeHolder} RecordLoc={this.props.RecordLoc} againCallFlg={this.state.againCallFlg} tripIdsegmentArray={this.TripArraySegments} innerSrchTxt={innerSrchTxt} innersrchFlg={innersrchFlg} DeleteCallStatus={this.props.DeleteCallStatus} UpdateTable={this.UpdateTable.bind(this)} UpdateCommentCount={this.props.UpdateCommentCount} SaveSucess={this.props.SaveSucess} loader={this.state.loader} data={callStatusAndCommentsDatatemp} allCallFlg={this.state.allCallFlg} filterOption={this.state.filterOption} traveller={this.props.traveller} travelerId={this.props.travelerId} />

    commentsClass = "btn btn-primary btn-cancelled active";
  } else {
    commentsClass = "btn btn-primary btn-cancelled";
  }
  if (this.state.historyFlag) {
    historyClass += ' active'
  }

  return (
    <div>
      <div className="sort">
        <div className="sort_inner">
          <button className={allClass} onClick={this._sortCallStatus.bind(this, "all")}>{this.props.placeHolder.ALL}</button>
          <button className={callstatusclass} onClick={this._sortCallStatus.bind(this, "call")}>{this.props.placeHolder.CALL_STATUS}</button>
          <button className={commentsClass} onClick={this._sortCallStatus.bind(this, "comments")}>{this.props.placeHolder.COMMENTS}</button>
        </div>
        <div className="summary_search">
        <div  className="margin_rgt_5 icon_filter_history">
           {this.state.historyFlag ? <span className="history-checkbox" >&#252;</span>:null}
          <button className={historyClass} onClick={this._onClickHistory.bind(this)}>
            <div className="checkbox" for="History">
              <label className="history_checkBox_label"for="History">
                {this.props.placeHolder.HISTORY}
              </label>
            </div>
          </button>
        </div>

          <input type="text" id="callStatusSrch" className="form-control pnr_searchbar" onClick={this._onFocus.bind(this)} onFocus={this._onFocus.bind(this)} onChange={this._onChangeTxt.bind(this)} onKeyUp={this._onKeyUp.bind(this)} tabIndex="-1" placeholder={this.props.placeHolder.FIND_DATE_PNR} ref='srchBox' />
          <span className="input-group-btn">
            <button className="summary_search_button" tabIndex="-1" type="button" onClick={this._onclicksrch.bind(this)}></button>
          </span>
          <span className="searchBox-grey-active secondary" onClick={this._onFocus.bind(this)}>
            <span>&nbsp;</span>
            <img alt="close" className="close_greybox" src="/icons/close.png" onClick={this._onClose.bind(this)} />
          </span>
        </div>
        {tableData}
      </div>
    </div>
  );
}
}
function RemoveDuplicatesTrips(CallStatusOrComments) {
  var temp = CallStatusOrComments.Traveler.Comments;
  temp = _.uniq(temp, function (item) {
    if (item["_scope"].toUpperCase() == "TRIP") {
      return item["_Id"];
    } else {
      return item["_scope"] + item["_Id"];
    }
  });
  CallStatusOrComments.Traveler.Comments = temp;
  return CallStatusOrComments;
}
function converttoTripsArray(CallStatusOrComments) {
  let TripSegmentArray = [];
  function trip() {
    this.tripId = "",
      this.SegmentId = [],
      this.commentId = []
  }
  for (var i = 0; i < CallStatusOrComments.Traveler.Comments.length; i++) {
    if (CallStatusOrComments.Traveler.Comments[i]["_scope"].toUpperCase() == "TRIP") {
      var duplicate = false;
      if (TripSegmentArray.length == 0) {
        let object = new trip();
        object.tripId = CallStatusOrComments.Traveler.Comments[i].TripId;
        object.SegmentId.push(CallStatusOrComments.Traveler.Comments[i].SegmentId);
        object.commentId.push(CallStatusOrComments.Traveler.Comments[i]["_Id"]);
        TripSegmentArray.push(object);
        duplicate = true;
      } else {
        _.each(TripSegmentArray, function (trip) {
          if (trip.tripId == CallStatusOrComments.Traveler.Comments[i].TripId) {
            trip.SegmentId.push(CallStatusOrComments.Traveler.Comments[i].SegmentId);
            trip.commentId.push(CallStatusOrComments.Traveler.Comments[i]["_Id"]);
            duplicate = true;
          }
        });
      }
      if (!duplicate) {
        let object = new trip();
        object.tripId = CallStatusOrComments.Traveler.Comments[i].TripId;
        object.SegmentId.push(CallStatusOrComments.Traveler.Comments[i].SegmentId);
        object.commentId.push(CallStatusOrComments.Traveler.Comments[i]["_Id"]);
        TripSegmentArray.push(object);
      }
    }
  }
  return TripSegmentArray;
}
