import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import SendModal from './SendModal';
import HistoryModal from './HistoryModal';
import HTTP from '../../../services/httpservice.js';

export default class DocsTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rowIndex: '',
			sendModal: false,
			historyModal: false,
			historyModalData: null,
			showPopOver: false,
			loading: false,
			documentType: '',
			documentId: '',
			rowPNR: '',
			row: []
		};
	}
	onRowSelect(row, isSelected, e) {
		let selectedRows = this.props.selectedRows;
		if (isSelected) {
			selectedRows.push(row);
		} else {
			for (var i = 0; i < selectedRows.length; i++) {
				if (selectedRows[i].tableid === row.tableid) {
					selectedRows.splice(i, 1);
					break;
				}
			}
		}
		this.props.selectRow(selectedRows);
	}
	onRowSelectAll(isSelected, rows) {
		let selectedRows;
		if (isSelected) {
			selectedRows = rows;
		} else {
			selectedRows = [];
		}
		this.props.selectRow(selectedRows);
	}

	msgFunction(cell, row, enumObject, index) {
		return <div className="edituser" onClick={() => this.setState({ sendModal: true, row: [ row ] })} />;
	}
	showPNRFunction(cell, row, enumObject, index) {
		let docIcon = '';
		if (row.templateTypeId == 1) docIcon = 'pdfIcon';
		else docIcon = 'wordIcon';
		let rowValue = this.state.rowIndex;
		return (
			<div className="pnr">
				<div
					onMouseOver={() => this.setState({ rowIndex: index })}
					onMouseOut={() => this.setState({ rowIndex: '' })}
					onClick={this.showPdf.bind(this, row)}
					for="pdf"
				>
					{' '}
					<label className={docIcon} />
					{row.docType}
				</div>
				<div
					className={
						rowValue === index ? (
							'show fade in popover bottom pop_over_right extra_width'
						) : (
							'hide fade in popover bottom pop_over_right extra_width'
						)
					}
				>
					<div className="arrow" />
					<div className="popover_custom">{row.docTypeId == 3 ? row.docStoreId : row.id}</div>
				</div>
			</div>
		);
	}
	createNotification = (output) => {
		let self = this;
		let url = '/triggerEmail';
		self.setState({ loading: true });
		HTTP.postJSON(url, output.requestJson).then(function(response) {
			self.setState({
				sendModal: false,
				loading: false
			});
		});
	};

	showPdf(row) {
		let { templateTypeId } = row;
		HTTP.get('/getDocument?uuid=' + row.uuid).then(function(response) {
			var uri = 'data:application/octet-stream;base64,' + escape(response.data);
			var xhr = new XMLHttpRequest();
			xhr.open('GET', uri);
			xhr.responseType = 'arraybuffer';
			xhr.onload = function() {
				if (this.status === 200) {
          var blob;
          if (templateTypeId == 1) 
              blob = new Blob([ xhr.response ], { type: 'application/pdf' });
					else {
						  blob = new Blob([ xhr.response ], { type: 'application/msword' });
					}
					var objectUrl = URL.createObjectURL(blob);
					window.open(objectUrl);
					URL.revokeObjectURL(objectUrl);
				}
			};
			xhr.send();
		});
	}

	statusFunction(cell, row, enumObject, index) {
		let { showPopOver, rowValue } = this.state;
		let displayData = row;
		let classNameStatus = '';
		switch (row.status) {
			case 'In Progress':
				classNameStatus = 'inprogress_color';
				break;
			case 'Failed':
				classNameStatus = 'failed_color';
				break;
			case 'Delivered':
				classNameStatus = 'delivered_color';
				break;
		}
		return (
			<div
				className="Docs"
				onMouseOver={() => this.setState({ showPopOver: true, rowValue: index })}
				onMouseOut={() => this.setState({ showPopOver: false, rowValue: index })}
			>
				<div className={classNameStatus}>{row.status}</div>
				<div
					className={
						rowValue === index && showPopOver === true ? (
							'show fade in popover bottom pop_over_right'
						) : (
							'hide fade in popover bottom pop_over_right'
						)
					}
				>
					<div style={{ position: 'absolute', top: '-10%', left: '53%', color: 'white' }}>EMPTY</div>
					<div className="arrow" />
					<div className="">
						<div className="popover_custom">
							Status: <label className={'sendClass ' + classNameStatus}>{displayData.status}</label>
							<br />
							To: <label>{displayData.emailAddressTx}</label>
							<br />
							Trigger Date: <label> {displayData.triggeredDate}</label>
							<br />
							Last Modified Date: <label> {displayData.lastModifiedDate}</label>
							<br />
							Source: <label>{displayData.srcSystemNm}</label>
							<br />
							Comm ID: {displayData.id} <br />
							<br />
							<a href="#" onClick={() => this.setState({ historyModalData: row, historyModal: true })}>
								<u>Show full history</u>
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
	depDateSortFunc(a, b, order) {
		if (order === 'desc') {
			return new Date(a.depDate) - new Date(b.depDate);
		} else {
			return new Date(b.depDate) - new Date(a.depDate);
		}
	}

	genDateSortFunc(a, b, order) {
		if (order === 'desc') {
			return new Date(a.genDate) - new Date(b.genDate);
		} else {
			return new Date(b.genDate) - new Date(a.genDate);
		}
	}
	render() {
		let { data } = this.props;
		let { sendModal, historyModal, historyModalData, row, loading } = this.state;
		const selectRowProp = {
			mode: 'checkbox',
			onSelect: this.onRowSelect.bind(this),
			onSelectAll: this.onRowSelectAll.bind(this)
		};
		let sortedData = [];
		if (data != 'Error' && data.length != 0) {
			sortedData = data.filter(function(a) {
				return a.latestElement;
			});
		}
		const options = {
			defaultSortName: 'genDate',
			defaultSortOrder: 'asc',
			noDataText: this.props.placeHolder.TABLE_NO_DATA_TO_DISPLAY
		};
	
		return (
			<div className="all-Itinerary-Invoice">
				<div className="docs-table-content">
					{data != 'Error' ? (
						<div>
							<BootstrapTable data={sortedData} selectRow={selectRowProp} striped hover options={options}>
								<TableHeaderColumn dataField="docType" dataFormat={this.showPNRFunction.bind(this)}>
									Doc Type{' '}
								</TableHeaderColumn>
								<TableHeaderColumn hidden={true} dataField="tableid" isKey>
									PNRS
								</TableHeaderColumn>
								<TableHeaderColumn dataField="pnr" dataSort editable={false}>
									PNR
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="depDate"
									dataSort
									sortFunc={this.depDateSortFunc.bind(this)}
								>
									Dep. Date
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="genDate"
									dataSort
									sortFunc={this.genDateSortFunc.bind(this)}
								>
									Gen. Date
								</TableHeaderColumn>
								<TableHeaderColumn
									className="statusClass"
									dataField="status"
									dataFormat={this.statusFunction.bind(this)}
								>
									Status
								</TableHeaderColumn>
								<TableHeaderColumn className="visibleClass" dataFormat={this.msgFunction.bind(this)}>
									Message
								</TableHeaderColumn>
							</BootstrapTable>
							{sendModal ? (
								<SendModal
									loading={loading}
									isOpen={sendModal}
									hideModal={() => this.setState({ sendModal: false })}
									row={row}
									createNotification={(reqjson) => this.createNotification(reqjson)}
								/>
							) : null}
						</div>
					) : (
						<div className="docs_service_error">GDD Service is Down</div>
					)}
					{historyModal ? (
						<HistoryModal
							isOpen={historyModal}
							data={data}
							historyModalData={historyModalData}
							closeModal={() => this.setState({ historyModal: false })}
						/>
					) : null}
				</div>
			</div>
		);
	}
}
