import React from 'react';

class FooterContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLanguge: navigator.language,
            privacyLink: 'https://privacy.amexgbt.com/',
            termAndServiceLink: 'https://www.amexglobalbusinesstravel.com/terms-service/',
        }
    }

    getUserSelectedLanguage = () => {
        this.setState({ selectedLanguge: navigator.language });
    }

    componentDidUpdate() {
        window.addEventListener('languageChange', this.getUserSelectedLanguage);
    }

    componentWillUnmount() {
        window.removeEventListener('languagechange', this.getUserSelectedLanguage);
    }

    render() {
        return (
            <div id='footer-container'>
                {
                    this.state.selectedLanguge === "fr-CA" ?
                        <p className="footer_text">All users of our online services are subject to our <a className='main_footer_links' href={this.state.privacyLink} target='blank'>Privacy Statement</a> and agree to be bound by our <a className='main_footer_links' href={this.state.termAndServiceLink} target='blank'>Terms of Service</a>.
                            GBT Travel Services UK Limited (GBT UK) et ses sous-titulaires de licence autorisés (y compris Ovation Travel Group et Egencia) utilisent certaines marques déposées et marques de service d’American Express Company ou de ses filiales (American Express) dans les marques « American Express Global Business Travel » et « American Express GBT Meetings & Events » et dans le cadre de ses activités pour des utilisations autorisées uniquement en vertu d’une licence limitée d’American Express (Marques sous licence). Les Marques sous licence sont des marques déposées ou des marques de service et la propriété d’American Express. GBT UK est une filiale de Global Business Travel Group, Inc. (NYSE: GBTG). American Express détient une participation minoritaire dans GBTG, qui opère en tant que société distincte d’American Express.
                            <span className='footer_copyright'>© 2023 GBT Travel Services UK Limited d/b/a/American Express Global Business Travel. Tous droits réservés.</span></p>
                        : <p className="footer_text">All users of our online services are subject to our <a className='main_footer_links' href={this.state.privacyLink} target='blank'>Privacy Statement</a> and agree to be bound by our <a className='main_footer_links' href={this.state.termAndServiceLink} target='blank'>Terms of Service</a>.
                            GBT Travel Services UK Limited (GBT UK) and its authorized sublicensees (including Ovation Travel Group and Egencia) use certain trademarks and service marks of American Express Company or its subsidiaries (American Express) in the “American Express Global Business Travel” and “American Express GBT Meetings & Events” brands and in connection with its business for permitted uses only under a limited license from American Express (Licensed Marks). The Licensed Marks are trademarks or service marks of, and the property of, American Express. GBT UK is a subsidiary of Global Business Travel Group, Inc. (NYSE: GBTG). American Express holds a minority interest in GBTG, which operates as a separate company from American Express.
                            <span className='footer_copyright'>© 2023 GBT Travel Services UK Limited d/b/a/ American Express Global Business Travel. All rights reserved.</span>
                        </p>
                }
            </div>
        )
    }
}

export default FooterContent;