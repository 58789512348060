import React from "react";
import HTTP from "../../../services/httpservice.js";
import DocsTable from './DocsTable'
import SendIcon from './SendIcon';
//import {NotificationContainer, NotificationManager} from './react-notifications/src';


export default class DocsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeButton: "All",
      docsData : null,
      selectedRows:[],
      innerSrchTxt: this.props.docsPNR,
      innersrchFlg: this.props.docsPNR===""? false : true,
      value:''
    }
  }
  componentDidMount() {
    let self = this;
    HTTP.get('/getTripDetails?emailAddress='+self.props.email).then(function(response){
    if(response!=undefined){
      let docsData = response;
      self.setState({docsData})
    }
     else {
          self.setState({docsData: "Error"})
     }
    });
  }
  selectRow(selectedRows){
    this.setState({selectedRows})
  }


  _onKeyUp(event) {
    var val = event.target.value;
    if (val != "" || val != " ") {

      this.setState({ innerSrchTxt: val });
      if (event.key == 'Enter') {
        this.setState({

          innersrchFlg: true

        });
        if (document.getElementById('docsStatusSrch').value != "" && document.getElementById('docsStatusSrch').value != " ") {
          window.$('.secondary').show();
         window.$('.secondary span').text(val);
          window.$('.pnr_searchbar').val(val);
          window.$('.pnr_searchbar').blur();
        }
      } else {
        this.setState({
          innerSrchTxt: "",
          innersrchFlg: false

        });

      }
    } else {
      this.setState({
        //cancelFlg:false,
        innersrchFlg: false
      });
    }
  }
  _onclicksrch() {
    var val = document.getElementById('docsStatusSrch').value;
    if (val != "" && val != " " || this.props.PnrSearchText != "") {
      this.setState({
        innerSrchTxt: val,
        innersrchFlg: true
      });

      window.$('.secondary').show();
      window.$('.secondary span').text(val);
      window.$('.pnr_searchbar').val(val);
    } else {
      this.setState({
        innersrchFlg: false
      });
    }
  }
   _onClose() {
    this.setState({ innersrchFlg: false });
    this.setState({ innerSrchTxt: "" });
    //this.props.PnrSearchText="";
    this.props.clearFilter("");
    window.$('.pnr_searchbar').val("");
    window.$('.secondary').hide();
    window.$('.pnr_searchbar').focus();
  }
  _onFocus() {
         window.$(".searchBox-grey-active.secondary").css('display', 'none');
         this.setState({ innerSrchTxt: "", innersrchFlg: false });
    }

    _onChangeTxt() {
      this.setState({ innerSrchTxt: "", innersrchFlg: false });
   }
  render(){
    let { docsData, activeButton,selectedRows,innersrchFlg,innerSrchTxt} =this.state;
    let allButtonClass = 'btn btn-primary btn-travel';
    let itenaryButtonClass = 'btn btn-primary btn-travel'
    let invoiceButtonClass = 'btn btn-primary btn-travel'
    let displaydata = docsData;
    if(innersrchFlg && this.props.docsPNR !==""){
      window.$('.secondary').show();
      window.$('.secondary span').text(this.state.innerSrchTxt);
      window.$('.pnr_searchbar').val(this.state.innerSrchTxt);
    }
    if(displaydata!=undefined&&displaydata!=null){
      switch (activeButton){
        case 'All':
          allButtonClass += ' active';
          break;
        case 'Itinerary':
          itenaryButtonClass += ' active';
          displaydata = displaydata.filter(function(item,i){
            return item.docType === "Itinerary";
          })
          break;
        case 'Invoice':
          invoiceButtonClass += ' active';
          displaydata = displaydata.filter(function(item,i){
            return item.docType === "Invoice";
      })
    }
    if(innersrchFlg && displaydata !== null){
      displaydata = displaydata.filter(function(item){
        if(item.depDate!=undefined&&item.genDate!=undefined&&innerSrchTxt!=undefined&&item.pnr!=undefined){
        return item.depDate.toUpperCase().indexOf(innerSrchTxt.toUpperCase()) !== -1 || item.genDate.toUpperCase().indexOf(innerSrchTxt.toUpperCase()) !== -1 ||item.pnr.toUpperCase().indexOf(innerSrchTxt.toUpperCase()) !== -1 ||item.id.toUpperCase().indexOf(innerSrchTxt.toUpperCase()) !== -1
      }
      })
    }
  }
    return(
      <div className="sort">
        <div className="sort_inner">
          <button className = {allButtonClass} onClick={() => this.setState({activeButton:"All"})} >{this.props.placeHolder.ALL}</button>
          <button className = {itenaryButtonClass} onClick={() => this.setState({activeButton:"Itinerary"})} >{this.props.placeHolder.ITINERARY}</button>
          <button className = {invoiceButtonClass} onClick={() => this.setState({activeButton:"Invoice"})} >{this.props.placeHolder.INVOICE}</button>
        </div>
        <div className="summary_search">
          {selectedRows.length === 0 ? null :<SendIcon selectedRows={selectedRows}/>}
          <input type="text" id="docsStatusSrch" onClick={this._onFocus.bind(this)} onFocus={this._onFocus.bind(this)} onChange={this._onChangeTxt.bind(this)} onKeyUp={this._onKeyUp.bind(this)} className="form-control pnr_searchbar" tabIndex="-1" placeholder={this.props.placeHolder.FIND_DATE_PNR} ref='srchBox' />
          <span className="input-group-btn">
            <button className="summary_search_button" onClick={this._onclicksrch.bind(this)} tabIndex="-1" type="button"></button>
          </span>
          <span className="searchBox-grey-active secondary" onClick={this._onFocus.bind(this)} >
            <span>&nbsp;</span>
            <img alt="close" onClick={this._onClose.bind(this)} className="close_greybox" src="/icons/close.png" />
          </span>
        </div>
        {docsData !== null
          ?
          <DocsTable selectedRows = {this.state.selectedRows} data = {displaydata} selectRow={(selectRows) => this.selectRow(selectRows)} searchText = "" placeHolder={this.props.placeHolder}/>
          :
          <div className="loading_image trip_loader">&nbsp;</div>
        }
      </div>
    )
  }
}


