

import React from "react";
import PopElipsisName from "./PopElipsisName.js"
import PopElipsisClientName from "./PopElipsisClientName.js"
import PopOverExtraEmail from "./PopOverextraEmails";
import CallStatusInPlans from "./CallStatus/CallStatusInPlans"
import Comments from "./Itinerary/Models/TravelerComments.js";
import PopOvers from "./commons/PopOvers";
import createClass from 'create-react-class';
const fraud= {
  "SAB":["H46I","WV6J"],
  "APO":["1A06"],
  "GAL":["5N6R"],
  "AMA":["PHXAX2103"]
}

var PlansDetails = (createClass({

  getInitialState: function () {
    return {
      popUpleft: false,
      popUpname: false,
      popUpEmail: false,
      popUpclient: false,
      showPopOverState: false,
      hover:false,
      showSanctionsPopoverState:false
    }
  },
  disablepopUpemail: function () {
    this.setState({ popUpEmail: false });
  },
  popUpemail: function () {
    if (this.state.popUpemail) {
      this.setState({ popUpEmail: false }); 
    } else {
      this.setState({ popUpEmail: true });
    }
  },
  disablePopUpfunleft: function () {
    this.setState({ popUpleft: false });

  },
  popUpfunleft: function () {
    if (this.state.popUpleft) {
      this.setState({ popUpleft: false });
    } else {
      this.setState({ popUpleft: true });
    }
  },
  disablepopUpname: function () {
    this.setState({ popUpname: false });
  },
  popUpname: function () {
    if (this.state.popUpname) {
      this.setState({ popUpname: false });
    } else {
      this.setState({ popUpname: true });
    }
  },
  popUpclientname: function (travelerId) {
    window.$('#clientname_' + travelerId).removeClass('hidden');
  },
  disablepopUpclientname: function (travelerId) {
    window.$('#clientname_' + travelerId).addClass('hidden');
  },

  ShowPopOver: function (travelerId) {

    window.$('#' + travelerId).removeClass('hidden');

  },

  DisablePopOver: function (travelerId) {
    if (!window.$(".popover_all:hover").length) {
      window.$('#' + travelerId).addClass('hidden');

    }
  },

  remainPopoverOpen: function (travelerId) {
    window.$('#' + travelerId).removeClass('hidden');

    window.$("#" + travelerId).on("mouseleave", function () {

      window.$('#' + travelerId).addClass('hidden');

    }, 2000);


  },

  CallStatusClick: function (e) {
    e.stopPropagation();
  },
  showSanctionsPopover :function() {
    this.setState({showSanctionsPopoverState:true});
  },

  hideSanctionsPopover:function() {
    this.setState({showSanctionsPopoverState:false})
  },

  render: function () {
    
    var maininput = document.getElementById("Activesort");
    if (maininput != null) {
      maininput.focus()
    }
    var emaildomain = [];

    var onPNRClick = this.props.onPNRClick;    
    var comaseperator = ",";
    if (typeof (this.props.Details.ClientName) == "undefined" || typeof (this.props.Details.ClientId) == "undefined") {
      comaseperator = "";
    }
    var emailClass = "email_container";
    var mainemail = this.props.Details.EmailId[0];

    var mainemailpopup = false;
    if (typeof (this.props.Details.EmailId[0]) != "undefined") {
      if (this.props.Details.EmailId[0].length > 25) {
        mainemailpopup = true;
        var emailStarting = this.props.Details.EmailId[0].substring(0, 8);
        if (emailStarting.indexOf('@') == -1) {
          emaildomain = this.props.Details.EmailId[0].split("@");
          mainemail = this.props.Details.EmailId[0].substring(0, 2) + "...@" + emaildomain[1];
        }
      }
    }
    var namePopUp = false;

    if (this.props.Details.Name[0].length > 20 & this.state.popUpname) {
      namePopUp = true;
    }
    var clientname = '';
    if (typeof this.props.Details.ClientName != "undefined" && this.props.Details.ClientName != null) {
      var str = this.props.Details.ClientName;
      var trimmedstr = str.replace(/\s+$/, '');
      clientname = trimmedstr;
    }
    if (typeof this.props.Details.ClientId != "undefined" && this.props.Details.ClientId != null)
      clientname = clientname + comaseperator + this.props.Details.ClientId;
    var clientnameElipses = '';
    if (clientname != '') {
      clientnameElipses = addElipses(clientname.trim(), 21);
    }

    var showclientpopup = false;
    if (clientname.length > 20) {
      showclientpopup = true;
    }
    let fraudList = fraud[this.props.Details.Source];
    let pccList = this.props.Details.pcc;
    let isFraud = fraudList ? fraudList.some(element => pccList.includes(element)): false
    let hoverListArray = [];
    if(isFraud){
      hoverListArray.push(<div>Pending Quality Check</div>)
    }
    if(this.props.Details.DOB==null){
      hoverListArray.push(<div>{this.props.placeHolder.DOB_MISSING}</div>)
    }
    if(this.props.Details.Gender==null){
      hoverListArray.push(<div>{this.props.placeHolder.GENDER_MISSING}</div>)
    }
    
   /* if(this.props.Details.DOB==null && this.props.Details.Gender ==null){
      hoverText=
                <span>
                  <div>Date of Birth Missing! TC needs to add to PNR and Traveler needs to add to Profile.</div>
                 <div>Gender is Missing! TC needs to add to PNR and Traveler needs to add to Profile.</div></span>
              ;
    }else if(this.props.Details.DOB==null){
      hoverText="Date of Birth Missing! TC needs to add to PNR and Traveler needs to add to Profile.";
    }else if(this.props.Details.Gender==null){
      hoverText="Gender is Missing! TC needs to add to PNR and Traveler needs to add to Profile.";
    }else if(isFraud){
       hoverText="Pending Quality Check";
    }*/

    //Handling Sanctions
    var sanctionText;
    if(this.props.Details.Sanctions!==null && this.props.Details.Sanctions!==undefined){
    var sanctionsArray = this.props.Details.Sanctions.split('|');
    const sanctionMappings = {'Airline':this.props.placeHolder.PROHIBITED_AIRLINE,'Hotel':this.props.placeHolder.PROHIBITED_HOTEL,'Airport':this.props.placeHolder.PROHIBITED_DESTINATION};
    if(sanctionsArray.includes('C') ||sanctionsArray.includes('G') ) {
        if(sanctionsArray.includes('G')) {
          sanctionText = <span>
                          <div>{this.props.placeHolder.CUBA_ONLY_PERMISSIBLE}</div>
                          <p>{this.props.placeHolder.STOP_AND_READ_THE} <a href="https://gbt.jiveon.com/community/compliance-risk/sanctions" target="_blank"><label className='sanction-link-style'>{this.props.placeHolder.SACNTIONS_GUIDELINES}.</label></a></p>
                        </span>
        } else if(sanctionsArray.includes('C')) {
          sanctionText =  <span>
                             <div>{this.props.placeHolder.CUBA_AFFIDAVIT}</div>
                            <p>{this.props.placeHolder.STOP_AND_READ_THE} <a href="https://gbt.jiveon.com/community/compliance-risk/sanctions" target="_blank"><label className='sanction-link-style'>{this.props.placeHolder.SACNTIONS_GUIDELINES}.</label></a></p>
                          </span>
        } 
      } else {
        let thisRef = this;
        sanctionText = sanctionsArray.map(function(item,i){
          return <span>
                  <div>{sanctionMappings[item]} - {thisRef.props.placeHolder.DO_NOT_PROCEED}.</div>
                  {i===sanctionsArray.length-1 && <p>{thisRef.props.placeHolder.STOP_AND_READ_THE} <a href="https://gbt.jiveon.com/community/compliance-risk/sanctions" target="_blank"><label className='sanction-link-style'>{thisRef.props.placeHolder.SACNTIONS_GUIDELINES}.</label></a></p>}
                </span>
          }) 
      }  
    }   
    return (
      <div className={this.props.opentravelPlan} onClick={this.DisablePopOver}>
        <div className="col-sm-2 more_container">
          <div className="more" onMouseOver={this.popUpfunleft} onClick={this.popUpfunleft} onClickOutside={this.onClickOutside} onMouseOut={this.disablePopUpfunleft}></div>
          {this.state.popUpleft ? <PopOverExtraEmail email={this.props.Details.EmailId} phone={this.props.Details.phone} /> : null}
          <a href="#" tabIndex="0" className="caret-down" onClick={this.onClick}>&nbsp;</a>
        </div>
        <div className="col-md-11 name_email" onClick={this.onClick}>
          <div className="name_container">
            <h4 className="client" onMouseOver={this.popUpname} onMouseOut={this.disablepopUpname} onClick={this.onClick}>{addElipses(this.props.Details.Name[0], 21)} </h4> 
            {this.props.Details.CommentsCount >= 1 ? <div className="comment_icon" onClick={this.CallStatusClick} onMouseEnter={this.remainPopoverOpen.bind(this, this.props.Details.TravelerId)} onMouseOut={this.DisablePopOver.bind(this, this.props.Details.TravelerId)} onMouseOver={this.ShowPopOver.bind(this, this.props.Details.TravelerId)}>
              &nbsp;
                     {this.props.Details.CommentsCount}
              {this.props.Details.CommentsCount > 0 ? <Comments placeHolder={this.props.placeHolder} key={this.props.Details.TravelerId} gotoAllCommentsTab={this.props.gotoAllCommentsTab} test={this.props.test} travelerId={this.props.Details.TravelerId} count={this.props.Details.CommentsCount} text={this.props.Details.Comments} /> : null}
            </div> : null}
            
            {this.props.Details.Remarks === "PREM-1" || this.props.Details.Remarks === "PREM-2" || this.props.Details.Remarks === "PREM-4" ? <div className="crown-icon"></div>:null}
            {(this.props.Details.DOB==null || this.props.Details.Gender ==null || isFraud)?
               <div onMouseEnter={() => { this.setState({ hover: true }) }} onMouseLeave={() => { this.setState({ hover: false }) }} className="doc-icon"><a href="#" tabindex="-1">&nbsp;</a>
              {this.state.hover ? <PopOvers aign={2} text={hoverListArray}/> : null}             
            </div>             
              :
              null}             

                {/*Sactions impl */}
                {(this.props.Details.Sanctions!==null && this.props.Details.Sanctions!==undefined) ? 
                <div onMouseEnter={() => { this.setState({ showSanctionsPopover: true }) }} onMouseLeave={() => { this.setState({ showSanctionsPopover: false }) }} 
                className="sanctions-icon"><a href="#" tabindex="-1">&nbsp;</a>
                  {this.state.showSanctionsPopover ? <PopOvers aign={2} text={sanctionText}/> : null}
                </div>:null}

            
            {namePopUp ? <PopElipsisName email={this.props.Details.Name[0]} /> : null}
            <h4 class="employee_id">{this.props.Details.EmployeeId}</h4>
            <p className="client-name" onMouseOver={this.popUpclientname.bind(this, this.props.Details.TravelerId)} onMouseOut={this.disablepopUpclientname.bind(this, this.props.Details.TravelerId)}>{clientnameElipses} </p>
            {showclientpopup ? <PopElipsisClientName travelerId={this.props.Details.TravelerId} email={clientname} /> : null}
          </div>
          <div className={emailClass}>

            <p className="client-email" onMouseOver={this.popUpemail} onMouseOut={this.disablepopUpemail}>{mainemail}</p>
            {this.state.popUpEmail & mainemailpopup ? <PopOverExtraEmail email={this.props.Details.EmailId} /> : null}

            <p className="client-phone">{this.props.Details.phone[0]}</p>

          </div>
        </div>
        <CallStatusInPlans placeHolder={this.props.placeHolder} CallStatusArr={this.props.CallStatusArr} onPNRClick={onPNRClick} />
      </div>
    )
  }


}));

export default PlansDetails;

function addElipses(text, n) {
  return (text.length > n) ? text.substr(0, n - 1) + '...' : text;
}
