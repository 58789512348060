import React from "react";
import createClass from 'create-react-class';
let PopOverextraEmails  = createClass({
render: function () {
    var Emails=this.props.email.map(function(email,i){
      return(
        <a href='#' key={i} className="client-email">{email}</a>
      )
    });
    var Phonenums;
    if(this.props.phone!=null){
      Phonenums=this.props.phone.map(function(phonenum,i){
       return(
           <p key={i}>{phonenum}</p>
       )
     });
    }   
      return (
            <div>
                <div className='fade in popover bottom pop_over_right email_popup'>
                  <div className='arrow'></div>
                  <div className='popover-content'>
                      <div className='popover_custom'>
                        {Emails}
                        {Phonenums}
                      </div>
                  </div>
                </div>
            </div>
      );
      }
})
export default PopOverextraEmails;
