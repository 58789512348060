import React from "react";
import Sort from "../Itinerary/Sort";
import CallStatusAndComments from "../Call_Status_and_Comments/CallStatusComponents/CallStatusAndComments";
import Docs from "../Docs/DocTab";
import CallStatusInTravelerItinerary from './CallStatusInTravelerItinerary';
import HistoryCount from "../Itinerary/TripsItinerary/HistoryCount";
import SucessMessage from '../commons/SucessMessage'
import CommunicationTab from "../Communication/CommunicationTab";

export default class TravelerItinerary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            CallStatusAndComments: false,
            Docs: false,
            Sort: true,
            communication:false,
            saveMessage: false,
            docsPNR: ""
        };
    }

    componentDidMount() {
        window.addEventListener('mousedown', this._pageClick.bind(this), false);
    }

    //onclick of Itinary button
    _ShowItinerary() {
        this
            .props
            .onPNRClick("");
        this.setState({CallStatusAndComments: false});
        this.setState({Docs: false});
        this.setState({Sort: true});
        this.setState({communication: false});
        //  this.props.PnrSearchText="";
    }

    //onclick of ShowCallStatusAndComments button
    _ShowCallStatusAndComments() {
        this
            .props
            .onPNRClick("");
        // alert("call sttuus12344");
        this.setState({CallStatusAndComments: true});
        this.setState({Docs: false});
        this.setState({Sort: false,communication:false});
        //this.props.PnrSearchText="";
    }
    //onclick of ShowDocs button
    _ShowDocs(pnr) {
        this
            .props
            .onPNRClick("");
        this.setState({CallStatusAndComments: false, Docs: true, Sort: false,communication:false, docsPNR: pnr});
        //  this.props.PnrSearchText="";
    }
    _showCommunication() {
        this
            .props
            .onPNRClick("");
        this.setState({CallStatusAndComments: false, Docs: false, Sort: false, communication: true});
        //  this.props.PnrSearchText="";
    }
    _pageClick(e) {
        if (this.refs.sucessmsg != null) 
            this.refs.sucessmsg.disableSucessMessage();

            //  this.setState({saveMessage:false});
        }
    
    SaveSucess(selected, operation, sucessText) {
        let self = this;
        if (operation == "Active CallStatus Is Already Present") {
            self.text = "Active CallStatus Is Already Present";
            this
                .refs
                .sucessmsg
                .showSucessMessage(self.text);
            return
        }
        // let operationText= operation=="Add"?"saved":"deleted a";
        // if(selected=="comments"){   selected="comment."; }else
        // if(selected=="Comment"){   selected="comments"; } else   selected="Call
        // Status." self.text="You have "+operationText+" "+selected;
        // this.refs.sucessmsg.showSucessMessage(self.text);

        let operationText = operation == "Add"
            ? "saved"
            : "deleted a";
        if (operation == "Add") {
            if (selected == "Comment") 
                self.text = this.props.placeHolder.SAVED_COMMENTS;
            else 
                self.text = " You have saved Call Status.";
            }
        else {
            if (selected == "comments") 
                self.text = "You have Updated Information.";
            else 
                self.text = "You have Updated Information.";
            }
        this
            .refs
            .sucessmsg
            .showSucessMessage(self.text);
        //  self.setState({saveMessage:true});
    }
    GotoallComments() {
        this._ShowCallStatusAndComments();
    }

    gotoCallStatusAndComments() {
        this.setState({CallStatusAndComments: true});
    }
    DeleteCallStatus(e) {

        this
            .refs
            .HistoryCount
            .callApiHistoryCallStatusAndComments();
        this
            .props
            .DeleteCallStatus(e);
    }

    render() {
        let ItineraryClass = "";
        let CallStatusAndCommentsClass = "";
        let DocsClass = "";
        let segment = "";
        let communicationClass="";
        var onPNRClick = this.props.onPNRClick;
        var PnrSearchText = this.props.PnrSearchText;
        if (this.state.CallStatusAndComments) {
            CallStatusAndCommentsClass = "active";
            segment = <CallStatusAndComments
                openDocsTab={this
                ._ShowDocs
                .bind(this)}
                placeHolder={this.props.placeHolder}
                TripIdDetailsArray={this.props.TripIdDetailsArray}
                RecordLoc={this.props.RecordLoc}
                clearFilter={this.props.onPNRClick}
                SaveSucess={this
                .SaveSucess
                .bind(this)}
                DeleteCallStatus={(e) => this.DeleteCallStatus(e)}
                UpdateCommentCount={this.props.UpdateCommentCount}
                traveller={this.props.traveller}
                tID={this.props.tID}
                travelerId={this.props.tID}
                PnrSearchText={PnrSearchText}/>;
        } else if (this.state.Docs) {
            DocsClass = "active";
            segment = <Docs
                docsPNR={this.state.docsPNR}
                openDocsTab={this
                ._ShowDocs
                .bind(this)}
                placeHolder={this.props.placeHolder}
                PnrSearchText={PnrSearchText}
                clearFilter={this.props.onPNRClick}
                email={this.props.email}/>;
        } else if (this.state.communication) {
            communicationClass = "active";
            segment = <CommunicationTab knowmeId={this.props.tID} clearFilter={this.props.onPNRClick} {...this.props}/>;
        }
        
        else if (this.state.Sort) {
            ItineraryClass = "active";
            segment = <Sort
                openChatWindowMethod={this.props.openChatWindowMethod}
                openedChatList={this.props.openedChatList}
                activeTripIds={this.props.activeTripIds}
                pastTripIds={this.props.pastTripIds}
                orgId={this.props.orgId}
                SummarySanctions={this.props.SummarySanctions}
                premGroup={this.props.premGroup}
                ClientName={this.props.ClientName}
                openHreSegment={this.props.openHreSegment}
                setOfferAsFalse={this.props.setOfferAsFalse}
                HRESegments={this.props.HRESegments}
                previousclickkey={this.props.previousclickkey}
                openDocsTab={this
                ._ShowDocs
                .bind(this)}
                userId={this.props.userId}
                userName={this.props.userName}
                callApiNotification={this.props.callApiNotification}
                pnrOfNotification={this.props.pnr}
                notificationList={this.props.notificationList}
                selectedNotifiedObject={this.props.selectedNotifiedObject}
                user={this.props.user}
                settingsState={this.props.settingsState}
                gotoAllCommentsTab={this
                .gotoCallStatusAndComments
                .bind(this)}
                placeHolder={this.props.placeHolder}
                tID={this.props.tID}
                clearFilter={this.props.onPNRClick}
                travelerId={this.props.tID}
                UpdateCommentCount={this.props.UpdateCommentCount}
                socket={this.props.socket}
                sID={this.props.sID}
                traveller={this.props.traveller}
                RecordLoc={this.props.RecordLoc}
                CallStatusArr={this.props.CallStatusArr}
                socket={this.props.socket}
                sID={this.props.sID}
                traveller={this.props.traveller}
                EmployeeId={this.props.EmployeeId}
                TripIdDetailsArray={this.props.TripIdDetailsArray}
                Source={this.props.Source}
                RecordLoc={this.props.RecordLoc}
                TripId={this.props.TripId}
                DispTripId={this.props.DisTripId}
                searchData={this.props.searchData}
                ShowCallStatusAndComments={this
                ._ShowCallStatusAndComments
                .bind(this)}
                SaveSucess={this
                .SaveSucess
                .bind(this)}
                PnrSearchText={PnrSearchText}
                ProductScope={this.props.ProductScope}/>;
        }
        return (
            <div className="section">
                <CallStatusInTravelerItinerary
                    onPNRClick={onPNRClick}
                    RecordLoc={this.props.RecordLoc}
                    CallStatusArr={this.props.CallStatusArr}/>
                <HistoryCount
                    ref='HistoryCount'
                    traveller={this.props.traveller}
                    TripIdDetailsArray={this.props.TripIdDetailsArray}
                    placeHolder={this.props.placeHolder}/>

                <ul className="section_heading">
                    <li
                        className={ItineraryClass}
                        onClick={this
                        ._ShowItinerary
                        .bind(this)}>
                        <a href="#">{this.props.placeHolder.ITINERARY}
                        </a>
                    </li>
                    <li
                        className={CallStatusAndCommentsClass}
                        onClick={this
                        ._ShowCallStatusAndComments
                        .bind(this)}>
                        <a href="#">{this.props.placeHolder.CALL_STATUS_COMMENTS}</a>
                    </li>
                    <li className={DocsClass} onClick={() => this._ShowDocs("")}>
                        <a href="#">Docs</a>
                    </li>
                    <li className={communicationClass} onClick={() => this._showCommunication("")}>
                        <a href="#">{this.props.placeHolder.COMMUNICATION}</a>
                    </li>
                </ul>
                <SucessMessage ref="sucessmsg" text={this.text} /> {segment}
            </div>
        );
    }
}
