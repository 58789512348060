import React from "react";
import TripsItenary from "./TripsItinerary/TripsItenary";
import PastTripsItenary from "./TripsItinerary/PastTripsItenary";
import CancelledSegmentClass from "./TripsItinerary/CancelledSegmentClass";


export default class Sort extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ActiveFlg: true,
            PastFlg: false,
            initial: true,
            cancelFlg: false,
            ptcFlow: false,
            cancelledTripDetails: null,
            //  innerSrchTxt:this.props.PnrSearchText,
            innerSrchTxt: this.props.PnrSearchText,
            innersrchFlg: false,
            resetIconFilters: false,
            greybox: "searchBox-grey-disable secondary",
            greyWords: "searchBox-words-disable secondary",
            greyBoxclk: false,
            pastKey: null
        };
        this.text = "";
    }
    componentWillReceiveProps(newProps) {
        if (newProps.PnrSearchText != this.props.PnrSearchText || newProps.PnrSearchText != this.state.innerSrchTxt) 
            this.setState({innerSrchTxt: newProps.PnrSearchText})
    }
    _change_to_past() {
        this.setState({PastFlg: true, ActiveFlg: false, cancelFlg: false, innersrchFlg: false, initial: false});

    }

    _change_to_cancel() {
        this.setState({PastFlg: false, ptcFlow: true, ActiveFlg: false, cancelFlg: true, innersrchFlg: false, initial: false});
    }
    _sortTravelDetails(key) {

        this.setState({resetIconFilters: true});
        //  this.refs.srchBox.value="";
        switch (key) {
            case "past":
                if (!this.state.PastFlg) {

                    this.setState({PastFlg: true, ActiveFlg: false, cancelFlg: false, innersrchFlg: false, pastKey: key});

                }
                break;
            case "travelling":
                if (!this.state.ActiveFlg) {

                    this.setState({ActiveFlg: true, PastFlg: false, cancelFlg: false, innersrchFlg: false});

                }
                break;
            case "cancelled":
                if (!this.state.cancelFlg) {
                    
                    this.setState({cancelFlg: true, ptcFlow: false,PastFlg: false, ActiveFlg: false, innersrchFlg: false});

                }
                break;
        }
    }
    _onKeyUp(event) {
        var val = event.target.value;
        if (val != "" || val != " ") {

            if (event.key == 'Enter') {

                this.setState({innerSrchTxt: val});
                this.setState({innersrchFlg: true});
                if (document.getElementById('srchbox').value != "" && document.getElementById('srchbox').value != " ") {
                    window.$('.secondary').show();
                    window.$('.secondary span').text(val);
                    window.$('.pnr_searchbar').val(val);
                    window.$('.pnr_searchbar').blur();
                }
            } else {
                this.setState({innerSrchTxt: "", innersrchFlg: false});

            }
        } else {
            this.setState({innerSrchTxt: "", innersrchFlg: false});
        }
    }
    _showSearchText(val, key) {

        if (key == 'Enter') {
            this.setState({innerSrchTxt: val});
            this.props.PnrSearchText = "";
            setTimeout(function () {
                window.$('.secondary').show();
                window.$('.secondary span').text(val);

                window.$(".close_greybox").click(function () {
                    window.$('.secondary').hide();
                    window.$('.pnr_searchbar').focus();
                });
                window.$(".pnr_searchbar").click(function () {
                    window.$('.secondary').hide();
                    window.$('.pnr_searchbar').focus();
                });

            }, 100);

            this.setState({innersrchFlg: true});
        }
    }
    _onclicksrch() {
        var val = document
            .getElementById('srchbox')
            .value;
        if (val != "" && val != " " || this.props.PnrSearchText != "") {
            this.setState({innerSrchTxt: val, innersrchFlg: true});
            window.$('.secondary').show();
            window.$('.secondary span').text(val);
            window.$('.pnr_searchbar').val(val);
        } else {
            this.setState({innersrchFlg: false});
        }
    }

    _onClose() {
        this.setState({innerSrchTxt: "", innersrchFlg: false});

        this
            .props
            .clearFilter("");
        window.$('.pnr_searchbar').val("");
        window.$('.secondary').hide();

    }

    _onClearClick() {
        this.setState({greyBoxclk: true, greybox: "searchBox-grey-disable secondary"});

    }
    _clearClick() {
        document
            .getElementById('secSrchBox')
            .value = '';
        this.setState({
            greybox: "searchBox-grey-disable secondary", greyWords: "searchBox-words-disable", innerSrchTxt: ""

            // temp:null, tempf:null
        });
    }

    _onFocus(event) {
        window.$(".searchBox-grey-active.secondary").css('display', 'none');

        //this.setState({innerSrchTxt:event.target.value,innersrchFlg:false});
    }

    _onChangeTxt() {
        this
            .props
            .clearFilter("");
        this.setState({innerSrchTxt: "", innersrchFlg: false});
    }

    render() {
        var activeClass = "btn btn-primary btn-travel margin_left_5";
        var pastclass = "btn btn-primary btn-past";
        var cancelClass = "btn btn-primary btn-cancelled";
        var innersrchFlg = false;
        var PnrSearchText = this.props.PnrSearchText;
        //this.setState({innerSrchTxt:PnrSearchText});
        if (this.state.PastFlg) {

            pastclass = "btn btn-primary btn-past active";

        } else {

            pastclass = "btn btn-primary btn-past";

        }
        if (this.state.ActiveFlg) {

            activeClass = "btn btn-primary btn-travel active  margin_left_5";

        } else {

            activeClass = "btn btn-primary btn-travel  margin_left_5";

        }
        if (this.state.cancelFlg) {

            cancelClass = "btn btn-primary btn-cancelled active";

        } else {

            cancelClass = "btn btn-primary btn-cancelled";

        }
        var innerSrchTxt = "";
        if ((this.state.innerSrchTxt != "" && this.state.innerSrchTxt != " ") || PnrSearchText != "") {
            //alert("raised");
            innersrchFlg = true;
            innerSrchTxt = (this.state.innerSrchTxt != "" && this.state.innerSrchTxt != " ")
                ? this.state.innerSrchTxt
                : PnrSearchText;
            if (PnrSearchText != "") {
                window.$('.secondary').show();
                window.$('.secondary span').text(innerSrchTxt);
                window.$('.pnr_searchbar').val(innerSrchTxt);
            }
        }
        return (
            <div className="sort">
                <div className="sort_inner">
                    <button
                        className={activeClass}
                        onClick={this
                        ._sortTravelDetails
                        .bind(this, "travelling")}>{this.props.placeHolder.ACTIVE}</button>
                    <button
                        className={cancelClass}
                        onClick={this
                        ._sortTravelDetails
                        .bind(this, "cancelled")}>{this.props.placeHolder.CANCELLED}</button>
                    <button
                        className={pastclass}
                        onClick={this
                        ._sortTravelDetails
                        .bind(this, "past")}>{this.props.placeHolder.PAST}</button>
                </div>

                <div className="summary_search">
                    <input
                        type="text"
                        className="form-control pnr_searchbar"
                        id="srchbox"
                        tabIndex="-1"
                        placeholder={this.props.placeHolder.SEARCH_DATE_PNR}
                        ref='srchBox'
                        onChange={this
                        ._onChangeTxt
                        .bind(this)}
                        onFocus={this
                        ._onFocus
                        .bind(this)}
                        onClick={this
                        ._onFocus
                        .bind(this)}
                        onKeyUp={this
                        ._onKeyUp
                        .bind(this)}/>
                    <span className="input-group-btn">
                        <button
                            className="summary_search_button"
                            tabIndex="-1"
                            type="button"
                            onClick={this
                            ._onclicksrch
                            .bind(this)}></button>
                    </span>
                    <span
                        className="searchBox-grey-active secondary"
                        id="input-grey-box"
                        onClick={this
                        ._onFocus
                        .bind(this)}>
                        <span>&nbsp;</span>
                        <img
                            alt="close"
                            className="close_greybox"
                            src="/icons/close.png"
                            onClick={this
                            ._onClose
                            .bind(this)}/>
                    </span>
                </div>

                {this.state.cancelFlg
                    ? <CancelledSegmentClass
                            openChatWindowMethod={this.props.openChatWindowMethod}
                            openedChatList={this.props.openedChatList}
                            ptcFlow= {this.state.ptcFlow}
                            premGroup={this.props.premGroup}
                            tID={this.props.tID}
                            SummarySanctions={this.props.SummarySanctions}
                            openHreSegment={this.props.openHreSegment}
                            setOfferAsFalse={this.props.setOfferAsFalse}
                            HRESegments={this.props.HRESegments}
                            previousclickkey={this.props.previousclickkey}
                            openDocsTab={this.props.openDocsTab}
                            callApiNotification={this.props.callApiNotification}
                            userId={this.props.userId}
                            userName={this.props.userName}
                            pnrOfNotification={this.props.pnrOfNotification}
                            notificationList={this.props.notificationList}
                            selectedNotifiedObject={this.props.selectedNotifiedObject}
                            user={this.props.user}
                            settingsState={this.props.settingsState}
                            placeHolder={this.props.placeHolder}
                            gotoAllCommentsTab={this.props.gotoAllCommentsTab}
                            UpdateCommentCount={this.props.UpdateCommentCount}
                            SaveSucess={this.props.SaveSucess}
                            ShowCallStatusAndComments={this.props.ShowCallStatusAndComments}
                            Source={this.props.Source}
                            innerSrchTxt={innerSrchTxt}
                            innersrchFlg={innersrchFlg}
                            CallStatusArr={this.props.CallStatusArr}
                            traveller={this.props.traveller}
                            DispTripId={this.props.DispTripId}
                            RecordLoc={this.props.RecordLoc}
                            TripIdDetailsArray={this.props.TripIdDetailsArray}
                            resetIconFilters={this.state.resetIconFilters}/>
                    : 
                    this.state.ActiveFlg ?
                    <TripsItenary
                         openChatWindowMethod={this.props.openChatWindowMethod}
                        openedChatList={this.props.openedChatList}
                        activeTripIds={this.props.activeTripIds}
                        pastTripIds={this.props.pastTripIds}
                        SummarySanctions={this.props.SummarySanctions}
                        orgId={this.props.orgId}
                        premGroup={this.props.premGroup}
                        ClientName={this.props.ClientName}
                        openDocsTab={this.props.openDocsTab}
                        callApiNotification={this.props.callApiNotification}
                        pnrOfNotification={this.props.pnrOfNotification}
                        notificationList={this.props.notificationList}
                        selectedNotifiedObject={this.props.selectedNotifiedObject}
                        user={this.props.user}
                        settingsState={this.props.settingsState}
                        placeHolder={this.props.placeHolder}
                        gotoAllCommentsTab={this.props.gotoAllCommentsTab}
                        ShowCallStatusAndComments={this.props.ShowCallStatusAndComments}
                        UpdateCommentCount={this.props.UpdateCommentCount}
                        traveller={this.props.traveller}
                        SaveSucess={this.props.SaveSucess}
                        EmployeeId={this.props.EmployeeId}
                        socket={this.props.socket}
                        resetIconFilters={this.state.resetIconFilters}
                        sID={this.props.sID}
                        tID={this.props.tID}
                        innersrchFlg={innersrchFlg}
                        innerSrchTxt={innerSrchTxt}
                        active={this.state.ActiveFlg}
                        PastFlg={this.state.PastFlg}
                        cancelledTripDetails={this.state.cancelledTripDetails}
                        cancelFlg={this.state.cancelFlg}
                        Source={this.props.Source}
                        RecordLoc={this.props.RecordLoc}
                        CallStatusArr={this.props.CallStatusArr}
                        TripIdDetailsArray={this.props.TripIdDetailsArray}
                        TripId={this.props.TripId}
                        AirTripMode="true"
                        HotelTripMode="true"
                        CarTripMode="true"
                        RailTripMode="true"
                        DispTripId={this.props.DispTripId}
                        searchData={this.props.searchData}
                        userId={this.props.userId}
                        userName={this.props.userName}
                        FilterState={this.state.FilterState}
                        changeToCancel={this
                        ._change_to_cancel
                        .bind(this)}
                        changeToPast={this
                        ._change_to_past
                        .bind(this)}
                        initial={this.state.initial}
                        previousclickkey={this.props.previousclickkey}
                        setOfferAsFalse={this.props.setOfferAsFalse}
                        HRESegments={this.props.HRESegments}
                        ProductScope={this.props.ProductScope}
                        openHreSegment={this.props.openHreSegment}/>
                    : 
                    <PastTripsItenary
                        openChatWindowMethod={this.props.openChatWindowMethod}
                        openedChatList={this.props.openedChatList}
                        activeTripIds={this.props.activeTripIds}
                        pastTripIds={this.props.pastTripIds}
                        orgId={this.props.orgId}
                        SummarySanctions={this.props.SummarySanctions}
                        premGroup={this.props.premGroup}
                        ClientName={this.props.ClientName}
                        openDocsTab={this.props.openDocsTab}
                        callApiNotification={this.props.callApiNotification}
                        pnrOfNotification={this.props.pnrOfNotification}
                        notificationList={this.props.notificationList}
                        selectedNotifiedObject={this.props.selectedNotifiedObject}
                        user={this.props.user}
                        settingsState={this.props.settingsState}
                        placeHolder={this.props.placeHolder}
                        gotoAllCommentsTab={this.props.gotoAllCommentsTab}
                        ShowCallStatusAndComments={this.props.ShowCallStatusAndComments}
                        UpdateCommentCount={this.props.UpdateCommentCount}
                        traveller={this.props.traveller}
                        SaveSucess={this.props.SaveSucess}
                        EmployeeId={this.props.EmployeeId}
                        socket={this.props.socket}
                        resetIconFilters={this.state.resetIconFilters}
                        sID={this.props.sID}
                        tID={this.props.tID}
                        innersrchFlg={innersrchFlg}
                        innerSrchTxt={innerSrchTxt}
                        active={this.state.ActiveFlg}
                        PastFlg={this.state.PastFlg}
                        cancelledTripDetails={this.state.cancelledTripDetails}
                        cancelFlg={this.state.cancelFlg}
                        Source={this.props.Source}
                        RecordLoc={this.props.RecordLoc}
                        CallStatusArr={this.props.CallStatusArr}
                        TripIdDetailsArray={this.props.TripIdDetailsArray}
                        TripId={this.props.TripId}
                        AirTripMode="true"
                        HotelTripMode="true"
                        CarTripMode="true"
                        RailTripMode="true"
                        DispTripId={this.props.DispTripId}
                        searchData={this.props.searchData}
                        userId={this.props.userId}
                        userName={this.props.userName}
                        FilterState={this.state.FilterState}
                        changeToCancel={this
                        ._change_to_cancel
                        .bind(this)}
                        changeToPast={this
                        ._change_to_past
                        .bind(this)}
                        initial={this.state.initial}
                        previousclickkey={this.props.previousclickkey}
                        setOfferAsFalse={this.props.setOfferAsFalse}
                        HRESegments={this.props.HRESegments}
                        ProductScope={this.props.ProductScope}
                        openHreSegment={this.props.openHreSegment}
                        pastKey={this.state.pastKey}/>
                    }

            </div>
        );
    }
}
