import React from "react";
import TravelerItinerary from "./TravelerItinerary.js";
import PlansDetails from "../PlansDetails";
import createClass from 'create-react-class';

var Plans = (createClass({
    getInitialState: function () {
        return {open: false, Details: this.props.Details, PnrSearchText: ""}

    },

    componentWillMount: function () {
        var field = 'recordlocator';
        var emailField = 'email';
        var travelerField = 'knowmeid';
        var emailListField = 'emaillist';
        var url = window.location.href;
        if (url.indexOf('?' + field + '=') != -1 || url.indexOf('&' + field + '=') != -1) {
            var pnr = getParameterByName('recordlocator');
            if (pnr != "" && typeof pnr != "undefined") {
                var key = 1;
                this
                    .props
                    .Change(key)
                if (this.state.open) {
                    this.setState({open: false, PnrSearchText: ""});
                } else {
                    this.setState({open: true});
                }
            }
        } else if (url.indexOf('?' + travelerField + '=') != -1 || url.indexOf('&' + travelerField + '=') != -1) {
            var travellerId = getParameterByName('knowmeid');
            if (travellerId != "" && typeof travellerId != "undefined") {
                var key = 1;
                this
                    .props
                    .Change(key)
                if (this.state.open) {
                    this.setState({open: false, PnrSearchText: ""});
                } else {
                    this.setState({open: true});
                }
            }
        } else if (url.indexOf('?' + emailListField + '=') != -1 || url.indexOf('&' + emailListField + '=') != -1) {
            var emailListId = getParameterByName('emaillist');
            if (emailListId != "" && typeof emailListId != "undefined") {
                var key = 1;
                this
                    .props
                    .Change(key)
                if (this.state.open) {
                    this.setState({open: false, PnrSearchText: ""});
                } else {
                    this.setState({open: true});
                }
            }
        } else if (url.indexOf('?' + emailField + '=') != -1 || url.indexOf('&' + emailField + '=') != -1) {
            var email = getParameterByName('email');
            if (email != "" && typeof email != "undefined") {

                var key = 1;
                this
                    .props
                    .Change(key)
                if (this.state.open) {
                    this.setState({open: false, PnrSearchText: ""});
                } else {
                    this.setState({open: true});
                }
            }
        } else if (typeof this.props.pnr != "undefined" && this.props.pnr != "") {
            var key = 1;
            this
                .props
                .Change(key)
            if (this.state.open) {
                this.setState({open: false, PnrSearchText: ""});
            } else {
                this.setState({open: true});
            }
        }
    },

    contextTypes: function () {
        return {openDetails: false, test: false, GotoallCommentsstate: false}
    },
    UpdateCommentCount: function (Traveller) {
        var Detailstemp = this.state.Details;
        if (Traveller.Comments != null) {
            Detailstemp.CommentsCount = Traveller.Count;
            var TravellerComments = Traveller
                .Comments
                .map(function (Comment) {
                    return {"@id": Comment["_Id"], "#text": Comment.Note}
                });
            Detailstemp.Comments = TravellerComments;
            this.setState({Details: Detailstemp})
        } else if (Traveller.CallStatuses != null) {
            var TravellerComments = Traveller
                .CallStatuses
                .map(function (CallStatus) {
                    return {
                        "@abbr": getClassName(CallStatus.CallStatusCode),
                        "#text": CallStatus.Note,
                        "@code": CallStatus.CallStatusCode
                    }
                });
            this
                .props
                .addCallStatus(TravellerComments, Traveller.CallStatuses[0].RecordLocator);
            var pos = Detailstemp
                .RecordLoc
                .indexOf(Traveller.CallStatuses[0].RecordLocator);
            Detailstemp.CallStatusArr[pos] = TravellerComments[0];
            this.setState({Details: Detailstemp})
        }
    },
    DeleteCallStatus: function (Traveller) {
        var Detailstemp = this.state.Details;
        var RecordLocArray = this.state.Details.RecordLoc;
        var RecordLoc = Traveller.RecordLocator;
        var index = RecordLocArray.indexOf(RecordLoc);
        Detailstemp.CallStatusArr[index] = null;
        this
            .props
            .removeCallstatus(RecordLoc);
        this.setState({Details: Detailstemp})
    },
    onPNRClick: function (PNRtext) {
        //alert("pNR click item");
        this.setState({PnrSearchText: PNRtext})
    },
    onClick: function () {
        var plansNumber = "#Activesort" + this.props.plansNumber;
        if (window.$(plansNumber) != null) {
            setTimeout(function () {
                window.$('html, body').animate({
                    scrollTop:window.$(plansNumber)
                        .offset()
                        .top
                }, 600);
            }, 200);
        }
        // var y = event.clientY window.scrollTo(0,y);
        this
            .props
            .Change(this.props.Key)
        if (this.state.open) {
            this.setState({open: false, PnrSearchText: ""});
        } else {
            this.setState({open: true});
        }

    },
    setOfferAsFalse: function (item, seg) {
        if (item) {
            let {Details} = this.state;
            if (Array.isArray(Details.HRESegments[Details.TripId].HRESegment)) {
                let i = Details
                    .HRESegments[Details.TripId]
                    .HRESegment
                    .indexOf(seg.segtoken + ":" + seg.confirmationnumber);
                Details
                    .HRESegments[Details.TripId]
                    .HRESegment
                    .splice(i, 1);
            } else {
                delete Details.HRESegments[Details.TripId].HRESegment;
            }
            this.setState({Details})
        }
    },
    componentDidUpdate: function (prevProps, prevState) {
        if (this.context.GotoallCommentsstate && this.refs.traveleritinerary != undefined) {
            this
                .refs
                .traveleritinerary
                .GotoallComments();
            this.context.GotoallCommentsstate = false;
        }
    },
    componentWillReceiveProps: function (newProps) {
        if (newProps.openDetailsKey != this.props.openDetailsKey && newProps.previouskey != this.props.previouskey) {
            this.setState({PnrSearchText: ""})
        }
    },
    GotoallComments: function () {
        this.context.GotoallCommentsstate = true;
        if (!this.state.open) {
            this.onClick();
        } else {
            this
                .refs
                .traveleritinerary
                .GotoallComments();
        }
    },

    render: function () {
        var ProductScope = "";
        if (this.state.Details.ProductScope != undefined) 
            ProductScope = this.state.Details.ProductScope;
        var travel_plan = "travel-plan row";
        var emaildomain = [];
        var temp = "plans-container";
        if (this.props.Key != this.props.openDetailsKey) {
            this.context.openDetails = false;
            temp = "plans-container";
            travel_plan = "travel-plan row";
        } else {
            this.context.openDetails = true;
            temp = "plans-container margin_btm_20";
            travel_plan = "travel-plan row open";
        }
        if (this.props.previouskey == this.props.openDetailsKey) {
            this.context.openDetails = false;
            temp = "plans-container";
            travel_plan = "travel-plan row";
        }
        var tripId = this.props.Details.TripId;
        var searchData = this.props.searchData;
        var comaseperator = ",";
        if (typeof(this.props.Details.ClientName) == "undefined") {
            comaseperator = "";
        }
        var emailClass = "email_container";
        var clientnameUi = this.props.Details.Name[0];

        var mainemail = this.props.Details.EmailId[0];
        var mainemailpopup = false;
        if (typeof(this.props.Details.EmailId[0]) != "undefined") {
            if (this.props.Details.EmailId[0].length > 25) {
                mainemailpopup = true;
                var emailStarting = this
                    .props
                    .Details
                    .EmailId[0]
                    .substring(0, 8);
                if (emailStarting.indexOf('@') == -1) {
                    emaildomain = this
                        .props
                        .Details
                        .EmailId[0]
                        .split("@");
                    mainemail = this
                        .props
                        .Details
                        .EmailId[0]
                        .substring(0, 8) + "...@" + emaildomain[1];
                }
            }
        }

        var namePopUp = false;

        if (this.props.Details.Name[0].length > 20 & this.state.popUpname) {
            namePopUp = true;
        }
        var plansNumber = "Activesort" + this.props.plansNumber;
        return (
            <div id={plansNumber} className={temp}>
                <div onClick={this.onClick}>
                    <PlansDetails
                        placeHolder={this.props.placeHolder}
                        gotoAllCommentsTab={this.GotoallComments}
                        opentravelPlan={travel_plan}
                        Details={this.state.Details}
                        onPNRClick={this.onPNRClick}
                        email={this.props.Details.EmailId[0]}CallStatusArr={this.state.Details.CallStatusArr}
                        />
                </div>

                {this.context.openDetails
                    ? <TravelerItinerary
                            openChatWindowMethod={this.props.openChatWindowMethod}
                            openedChatList={this.props.openedChatList}
                            activeTripIds={this.props.Details.activeTripIds}
                            pastTripIds={this.props.Details.pastTripIds}
                            orgId={this.props.orgId}
                            SummarySanctions={this.state.Details.Sanctions}
                            premGroup={this.props.premGroup}
                            ClientName={this.props.Details.ClientId}
                            openHreSegment={this.props.openHreSegment}
                            setOfferAsFalse=
                            {(item,seg) =>this.setOfferAsFalse(item,seg)}
                            HRESegments={this.state.Details.HRESegments}
                            previousclickkey={this.props.previousclickkey}
                            userId={this.props.userId}
                            userName={this.props.userName}
                            placeHolder={this.props.placeHolder}
                            callApiNotification={this.props.callApiNotification}
                            pnr={this.props.pnr}
                            user={this.props.user}
                            selectedNotifiedObject={this.props.selectedNotifiedObject}
                            notificationList={this.props.notificationList}
                            settingsState={this.props.settingsState}
                            ref="traveleritinerary"
                            PnrSearchText={this.state.PnrSearchText}
                            DeleteCallStatus={this.DeleteCallStatus}
                            UpdateCommentCount={this.UpdateCommentCount}
                            onPNRClick={this.onPNRClick}
                            CallStatusArr={this.state.Details.CallStatusArr}
                            socket={this.props.socket}
                            sID={this.props.sID}
                            traveller={this.props.tData}
                            tID={this.props.tData.TravelerId}
                            EmployeeId={this.props.Details.EmployeeId}
                            TripIdDetailsArray={this.props.Details.TripIdArray}
                            Source={this.props.Details.Source}
                            RecordLoc={this.props.Details.RecordLoc}
                            TripId={this.props.Details.TripId}
                            DisTripId={this.props.Details.DisTripId}
                            searchData={this.props.searchData}
                            ProductScope={ProductScope}
                            email={this.props.Details.EmailId[0]}
                            previousclickkey={this.props.previousclickkey}
                            />
                    : null}

            </div>
        )
    }

}));

function getClassName(code) {
    switch (code) {
        case "H":
            return "Held Flight"
            break;
        case "O":
            return "Outstanding Issue"
            break;
        case "U":
            return "Urgent Issue"
            break;
    }
}
function getParameterByName(name, url) {
    if (!url) 
        url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) 
        return null;
    if (!results[2]) 
        return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default Plans;
