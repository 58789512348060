import React from "react";
import {toArrayObject} from '../../../../services/utils'
import createClass from 'create-react-class';
var Modal = createClass({
  render: function () {
     if(this.props.CustomFieldValue.MultiMIInfo != null || this.props.CustomFieldValue.MultiMIInfo != undefined){
      var CustomFieldValueMap=this.props.CustomFieldValue.MultiMIInfo.map(function(CustomField,a){
        return(
          <p key={a}>{CustomField.ShowColumnAs}: {CustomField.Text}</p>
        )
      });
      return (
        <div className="modal fade" id="myModal" role="dialog" data-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content mi_info">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">&times;</button>
                <h4 className="modal-title">{this.props.placeHolder.MI_INFORMATION}</h4>
              </div>
              <div className="modal-body">
                {CustomFieldValueMap}
              </div>
            </div>
          </div>
        </div>
      )
    }else{
      var CustomFieldValue=this.props.CustomFieldValue;
      var CustomFieldValueArr=toArrayObject(CustomFieldValue);
      var CustomFieldValueMap=CustomFieldValueArr.map(function(CustomField,a){
        return(
            <p key={a}>{CustomField.ShowColumnAs}: {CustomField.Text}</p>
        )
      });
      return (
        <div className="modal fade" id="myModal" role="dialog" data-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content mi_info">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">&times;</button>
                <h4 className="modal-title">{this.props.placeHolder.MI_INFORMATION}</h4>
              </div>
              <div className="modal-body">
                {CustomFieldValueMap}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
});


export default Modal;
