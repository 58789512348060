import React from "react";
export default class DeleteOrResolve extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      showPopOverState:"",
    };
  }
  _ShowPopOver(){
    this.setState({showPopOverState:true});
  }
  _DisablePopOver(){
    this.setState({showPopOverState:false});
  }
  render(){
    return(
    <div className={this.props.delete_icon_class} data-toggle="modal" data-target={this.props.uniqueId} onMouseOut={this._DisablePopOver.bind(this)} onMouseOver={this._ShowPopOver.bind(this)}>&nbsp;
     {this.state.showPopOverState? <PopOverForDeleteResolve text={this.props.hoverText} /> : null}
    </div>
    );
  }
}

class PopOverForDeleteResolve extends React.Component{
  render() {
    return (
      <div className="popover_all">
        <div className='fade in popover bottom pop_over_right'>
          <div className='arrow'></div>
          <div className='popover-content-callstatus'>
            <div className='popover_custom'>
              {this.props.text}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
