import React from "react";
import createClass from 'create-react-class';
let DispFlightLeg = createClass({
    getInitialState: function () {
          return {

          }
      },
render:  function () {
var SeatClass;
switch(this.props.specificTD.CabinType)
{
  case "Economy":
  SeatClass="seat_e";
  break;
  case "Business":
  SeatClass="seat_b";
  break;
  case "Class":
  SeatClass="seat_c";
  break;
  case "T":
  SeatClass="seat_t";
  break;
  default:
  SeatClass="seat_e";
  break;
}
var flightLeg=this.props.flightleg;
var slashoperator="/";
var showtime;
if(this.props.noOfLegs==1){
  showtime=false;
}else if(this.props.noOfLegs==this.props.iterator+1){
    showtime=false;
}else{
  showtime=true;
}
if(typeof(this.props.specificTD.CabinType)=="undefined"||typeof(this.props.specificTD.classService)=="undefined"){
slashoperator="";
}



  return(
    <div className="flight_segment">
    <div className="side-icon"><div className="inner-line">&nbsp;</div></div>

    <div className="flight-time">
                    <p className="time">{flightLeg.depTime} hrs</p>
                    <p className="date">{flightLeg.DepartureDateTime.substring(0,7)}</p>
                    <div className="duration_proxy">
&nbsp;
                        </div>
                    <p className="time">{flightLeg.arrTime} hrs</p>
                    <p className="date">{flightLeg.ArrivalDateTime.substring(0,7)}</p>
    </div>
    <div className="details-line"></div>
    <div className="flight-details">
                  <p className="airport">&nbsp;{flightLeg.startAirportTerminal}, {flightLeg.StartAirportName}</p>
                    <p className="city">&nbsp;{flightLeg.startAirportCode}</p>
                    <p className="terminal">&nbsp;{flightLeg.depTerminal}</p>
                      <div className="duration">
                      <span className="clock_icon">{flightLeg.xpathDuration}</span> <span className="duration_icon"></span> <span> {flightLeg.milesFlown} mi</span>
                      </div>
                    <p className="airport">{flightLeg.endAirportTerminal}, {flightLeg.EndAirportName}</p>
                    <p className="city">{flightLeg.endAirportCode}</p>
                    <p className="terminal">{flightLeg.arrTerminal}</p>
    </div>
    <div className="extra-info displaynone">
      <p>Operating Airline: {this.props.specificTD.OperatingAirline}</p>
      <p>Class: {flightLeg.CabinType}</p>
      <p>Airline booking Ref: {flightLeg.airlineBookRef}</p>
    </div>
    <div className="extra-info">
<p className={SeatClass}> {this.props.SeatAssignment} {this.props.specificTD.CabinType}{slashoperator} {this.props.specificTD.classService}</p>
      <p className="air_bus_no">{flightLeg.flightEquipment}</p>
        <p className="food"> {flightLeg.flightService} <br/> {this.props.MealAssignmentList}</p>

    </div>

    <div className="clearfix"></div>
    {showtime ? <div className="multileg_divider"><span class="text clock_icon">{this.props.FlightLegsTime[this.props.noOfLegs+this.props.iterator-2]}</span><hr/> </div>:null}

    </div>

  )

}
});

export default DispFlightLeg;
