'use strict';
import React from "react";

export default class SucessMessage extends React.Component{
  constructor(props) {
    super(props);
    this.state = {shomessage:false};
    this.text="";
    this.classname="add_comment_succes"
  }
  showSucessMessage(Test,className="add_comment_succes"){
    this.classname=className
    this.text=Test;
    this.setState({shomessage:true})
  }
  disableSucessMessage(){
    this.setState({shomessage:false})
  }
  render(){
    if(this.state.shomessage){
      return(
        <div className={this.classname}>{this.text}</div>
      );
    }else{
      return(
        <div></div>
      );
    }
  }
}
