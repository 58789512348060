'use strict';
import React from "react";
import FooterContent from "./FooterContent";

export default class Footer extends React.Component{
  constructor(){
    super()
  }
  render () {
    return(
      <FooterContent/>
    );
  }
}
