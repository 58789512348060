import React from "react";

export default class GameMessage extends React.Component{
   constructor(props) {
    super(props);
    this.state = {
      Result:{}
    };
  }

  _onClickOK(){
    var GameMessage='#GameMessage';
    window.$(GameMessage).modal('hide');

  }


  focusModal(){
    var key = "qwert_"+this.props.uniqueId;
    this.refs[key].focusModal();
  }
  onKeyPressEvent() {
    window.$('.modal_delete').keydown(function(e) {
      if(window.$('.last').is(":focus") && (e.which || e.keyCode) == 9) {
            e.preventDefault();
           window.AbortController$('.first').focus();
        }
     });
  }


  render () {


    return (
      <div className="modal modal_delete fade" onKeyUp={this.onKeyPressEvent.bind()} id="GameMessage" role="dialog" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog">
            <div className="modal-content confirmation">
              <div className="modal-header">
              <div className="col-sm-12">    <button ref={"qwert_"+this.props.uniqueId} type="button" className="close" onClick={this.props.onClickNo} data-dismiss="modal">&times;</button>
                <h4 className="modal-title">Error</h4>
              </div>  </div>
              <div className="modal-body">
                <div className="col-sm-12">
                    {this.props.message}


<div className="button_holder">
  <button className="save_button enabled" data-dismiss="modal" onClick={this._onClickOK.bind(this)}>OK</button>

  </div>



                </div>
              </div>
            </div>
          </div>
        </div>

    );
}
}

