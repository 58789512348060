import React, { Component } from 'react';

class CaseInteractions extends Component {
    render() {
        if(this.props.casesInteractionsData.length > 0) {
            var casesInteractions = this.props.casesInteractionsData.map(function(item,index){
                if(item.messageFlow === "O" && item.message !== undefined) {
                    return  <div className="outgoing-msg" key={index}>
                                <div className="sent-msg">
                                    <p className={(item.message.toLowerCase().includes("we received your flight selection")) || (item.message.toLowerCase().includes("it looks as though flight options are limited")) ? "msg-hlt" : ""}>{item.message}</p>
                                    <span className="time-date-left">{item.insertedDateTime} Delivered</span>
                                </div>
                            </div>
                } else if(item.messageFlow === "I" && item.message !== undefined) {
                    return  <div className="incoming-msg" key={index}>
                                <div className="received-msg">
                                    <p>{item.message}</p>
                                    <span className="time-date-right">{item.insertedDateTime} Receieved</span>
                                </div>
                            </div>
                }
            });
        }
        return (
            <div className="case-int-block">
                <div className="case-int-head-text">Case Interactions</div>
                {casesInteractions}
            </div>
            
        );
    }
}

export default CaseInteractions;