import React from "react";
import Plans from "./Plans/CallStatus/Plans.js"
var HTTP = require('../services/httpservice');
import createClass from 'create-react-class';

let TravelPlans = createClass({
    componentWillMount: function () {
        this
            .props
            .ReactGA
            .event({
                category: 'Results Count',
                action: "Number of results for search is:" + this.props.countresults
            });
    },
    getInitialState: function () {
        return {openDetailsKey: "", TravelDataJson: this.props.TravelDataJson, previousTravelerKey: ""}
    },
    contextTypes: function () {
        return {previousclickkey: "", secondpreviousclickkey: " "}
    },
    resetkeys: function (key) {
        this.setState({openDetailsKey: key, previousTravelerKey: ""})
        this.context.previousclickkey = "";
        this.context.previousclickkey = "";
    },
    hadlingdropdown: function (key) {
        this.context.secondpreviousclickkey = this.context.previousclickkey;
        this.context.previousclickkey = this.state.openDetailsKey;
        if (this.state.openDetailsKey == this.context.secondpreviousclickkey) {
            this.resetkeys(key);
        } else {
            this
                .props
                .socket
                .emit('close_details', this.props.sID);
            this.setState({openDetailsKey: key, previousTravelerKey: this.context.previousclickkey})
        }
        if (this.state.openDetailsKey == this.context.previousclickkey) {
            if (window.currentTraveller && window.currentTraveller !== "") {
                HTTP.socket('/socket', {
                    socketId: this.props.sID,
                    knowmeId: window.currentTraveller
                }, {action: 'delete'})
                    .then(function (result) {
                        console.log("deleted");
                    });
            }
        }

    },
    addCallstatus: function (callstatus, RecordLoc) {
        var TravelDataJsontemp = this.state.TravelDataJson;
        var Update = false;
        for (var i = 0; i < TravelDataJsontemp.length; i++) {
            if (TravelDataJsontemp[i].RecordLoc.indexOf(RecordLoc) != -1 && callstatus != null) {
                TravelDataJsontemp[i].CallStatusArr[
                    TravelDataJsontemp[i]
                        .RecordLoc
                        .indexOf(RecordLoc)
                ] = callstatus[0];
                Update = true;
            }
        }
        if (Update) {
            this.setState({TravelDataJson: TravelDataJsontemp})
        }
    },
    removeCallstatus: function (RecordLoc) {
        var TravelDataJsontemp = this.state.TravelDataJson;
        var Update = false;
        for (var i = 0; i < TravelDataJsontemp.length; i++) {
            if (TravelDataJsontemp[i].RecordLoc.indexOf(RecordLoc) != -1) {
                TravelDataJsontemp[i].CallStatusArr[
                    TravelDataJsontemp[i]
                        .RecordLoc
                        .indexOf(RecordLoc)
                ] = null;
                Update = true;
            }
        }
        if (Update) {
            this.setState({TravelDataJson: TravelDataJsontemp})
        }
    },
    render: function () {
        var hadlingdropdown = this.hadlingdropdown;
        var openDetailsKey = this.state.openDetailsKey;
        var previousclickkey = this.context.previousclickkey;
        var previousTravellrkey = this.state.previousTravelerKey;
        var previousTravellr = "";
        if (this.state.TravelDataJson != null) {
            previousTravellr = previousTravellrkey === ""
                ? ""
                : this.state.TravelDataJson[previousTravellrkey - 1].TravelerId;
            window.currentTraveller = openDetailsKey !== ""
                ? this.state.TravelDataJson[openDetailsKey - 1].TravelerId
                : "";
        }
        var self = this;
        function getDefaultObjectAt(array, index) {
            return array[index] = array[index] || {};
        }
        var Trips = (
            <div>No results found</div>
        );
        var temp = 0;
        var socketID = this.props.sID;
        var socket = this.props.socket
        var Details = this.state.TravelDataJson;
        Trips = Details.map(function (tData, i) {
            var fData = tData.Name[0];
            return (<Plans
                openChatWindowMethod={self.props.openChatWindowMethod}
                openedChatList={self.props.openedChatList}
                orgId={self.props.orgId}
                premGroup={self.props.premGroup}
                userId={self.props.userId}
                userName={self.props.userName}
                openHreSegment={self.props.openHreSegment}
                previousclickkey={previousTravellr}
                placeHolder={self.props.placeHolder}
                plansNumber={i}
                callApiNotification={self.props.callApiNotification}
                pnr={self.props.pnr}
                user={self.props.user}
                notificationList={self.props.notificationList}
                selectedNotifiedObject={self.props.selectedNotifiedObject}
                settingsState={self.props.settingsState}
                removeCallstatus={self.removeCallstatus}
                addCallStatus={self.addCallstatus}
                searchData={fData}
                socket={socket}
                sID={socketID}
                previouskey={previousclickkey}
                openDetailsKey={openDetailsKey}
                Change={hadlingdropdown}
                Details={getDefaultObjectAt(Details, i)}
                tData={tData}
                key={i}
                Key={i + 1}
                {...self.props}/>);
        });

        return (
            <div className="tripsList">
                <div className="clearfix"></div>
                {Trips}
            </div>
        );
    }
});

export default TravelPlans;
