import React, { Component } from 'react';
import {
    Modal,
} from 'react-bootstrap';

class HistoryModal extends Component {
    render() {
        const { isOpen, closeModal, data, historyModalData } = this.props;
        let historyList = data.filter(function(item,i){
            return item.docStoreId === historyModalData.docStoreId
        }).map(function (value, j) {
            let classNameStatus = "";
            switch(value.status){
              case 'In Progress':
                classNameStatus="inprogress_color"
                break;
              case 'Failed':
                classNameStatus="failed_color"
                break;
              case 'Delivered':
                classNameStatus="delivered_color"
                break;

            }
            return <div>
                Status: <label className={"sendClass "+classNameStatus}>{value.status}</label><br />
                To:   <label>{value.emailAddressTx}</label><br />
                Trigger Date:   <label>{value.triggeredDate}</label><br />
                Last Modified Date:  <label> {value.lastModifiedDate}</label><br />
                Source: <label>{value.srcSystemNm}</label><br />
                Comm ID:  {value.id} <br /><hr />
            </div>
        })

        return (
            <div className="history-pop-up">
                <Modal show={isOpen} onHide={closeModal} >
                    <Modal.Header closeButton>
                        <Modal.Title>{historyModalData.docType}&nbsp;&nbsp;{historyModalData.id}- full history</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {historyList}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default HistoryModal;
