'use strict';
import React from "react";

export default class AcceptRelease extends React.Component{
render(){
      var status="";
      let headingcss="notification_segment_heading"
      switch(this.props.status){
        case "INPROGRESS":
          status="Accepted"
        break;
        case "PENDING":
          status="Released"
        break;
        case "CLOSED":
          status="Closed";
          headingcss="notification_segment_heading_closed"
        break;
      }
      return(
        <div className="notification_segment_noborder">
          <div className="notification_segment_top_border"></div>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span  style={{"paddingLeft": 10+"px"}}><b className={headingcss}>{status}</b> by {this.props.userId} - {this.props.userName}</span>
          <br></br>
          <span  className="notification_segment_time"> {this.props.agedHours} hrs{this.props.agedMinutes} ago </span>
        </div>
      );
  }
}