'use strict';
import React from "react";

export default class UserInfo extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      UserInfo:"",
    };
  }
  shouldComponentUpdate(newProps,newState){
    if(newProps.Selected ==this.props.Selected && this.state==newState){
      return false;
    }
    return true
  }
  componentWillReceiveProps(newProps){
   if(newProps.reset !=this.props.reset){
      this.setState({UserInfo:""})
    }
  }
  _TextChange(event){
      this.setState({UserInfo:event.target.value});
      //call the parrent component only when user info is b/w 1 and 8{
      this.props.Detail(event.target.value);

  }


render(){
  let errormsg="";
  let message="";
  let messageClass="chracter_count";
if(this.state.UserInfo.length==7)
{
  message="7 characters limit";
  messageClass=messageClass+" error";
}
if(this.state.UserInfo.length<6)
  message=`${7-this.state.UserInfo.length} `+ this.props.placeHolder.CHARACTERS_COUNT;
else if (this.state.UserInfo.length==6) {
  message=this.props.placeHolder.ONE_CHARACTER;
}

  if(!validateUser(this.state.UserInfo)){
    errormsg=this.props.placeHolder.ERROR_CHARACTERS;
  }
  return(
<span>
    <div className={messageClass}>     {message}</div>
    <input type="text" className="custom_option width100per" maxLength={7} value={this.state.UserInfo} onChange={this._TextChange.bind(this)}/>
          <div className="error"><span>{errormsg}</span></div>
          </span>
    );
  }
}


//Validation for Aplhanumeric in User Info
function validateUser(Text) {
  if(Text!=""){
    //Regex Exp including caracters,Numbers
    let re =/^[a-zA-Z0-9]*$/;
    return re.test(Text);
  }else{
    return true;
  }
}
