'use strict';
import React from 'react';
import PopOvers from '../commons/PopOvers';
export default class CallStatusIcon extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showPopOverState: false
		};
	}
	_ShowPopOver() {
		this.setState({ showPopOverState: true });
	}
	_DisablePopOver() {
		this.setState({ showPopOverState: false });
	}
	_ClickPNR(event) {
		if (!this.props.fromPlans) {
			this.props.onClickIcon(this.props.text);
		}
	}
	render() {
		let hoverText = this.props.hoverText != null ? this.props.hoverText : '';
		if (hoverText.length == '') {
			hoverText = 'No Comment';
		}
		return (
			<li
				className={this.props.className}
				onClick={this._ClickPNR.bind(this)}
				onMouseOut={this._DisablePopOver.bind(this)}
				onMouseOver={this._ShowPopOver.bind(this)}
			>
				<a href="#" tabIndex="-1">
					{this.props.text}
				</a>
				{this.props.hover && this.state.showPopOverState ? <PopOvers text={hoverText} /> : null}
			</li>
		);
	}
}
