/**
  * @ Registration Component
  * @ File - registration.js
  * Registration Form
  */
 import React, { Component } from 'react';
 import Footer from '../footer';
 import Password from '../registration/passwordField';
 import SecurityQuestion from '../organization/securityQuestion';
 import HTTP from '../../services/httpservice';
 
 /* Define Constants */
 const SUBMIT_ERROR_MESSAGE = "Errors exist - please correct";
 
 class Registration extends Component {
 
     // Constructor called before render function
     // The object that is returned is assigned to  this.state
     constructor(props) {
         super(props);
         this.state = {
             username: {
                 valid: false,
                 value: ''
             },
             password: {
                 valid: false,
                 value: ''
             },
             name: {
                 fnameValid: false,
                 fnameValue: '',
                 lnameValid: false,
                 lnameValue: ''
             },
             organisation: {
                 valid: false,
                 value: ''
             },
             email: {
                 valid: false,
                 value: ''
             },
             comment: {
                 valid: false,
                 value: ''
             },
             jobTitle: {
                 valid: true,
                 value: ''
             },
             securityQuestion: {
                 valid: false,
                 value: []
             },
             emailDomain         : [],
             submitErrors        : '',
             captchaResponse     : '',
             captchaErrorMessage : ''
         }
     }
 
     // Success Validation for other fields
     // Change true state
     _setValue(state, value) {
         let temp = {
             valid: true,
             value: value
         }
         this.setState({
             [state]: temp
         });
     }
 
     // Failure Validation for other fields
     // Change false state
     _setFalse(state) {
         let temp = {
             valid: false,
             value: ''
         }
         this.setState({
             [state]: temp
         });
     }
 
     // Submit button functionality
     _handleSubmit() {
 
         let errorMessage = '';
 
         if (!this.state.password.valid) {
             this.refs.password._validatePasswordField();
             this.refs.password._validateconfirmPasswordField();
         }
         if (!this.state.securityQuestion.valid) {
 
             this.refs.sequrityqn._validateQuestion(0);
             this.refs.sequrityqn._validateQuestion(1);
             this.refs.sequrityqn._validateQuestion(2);
             this.refs.sequrityqn._validateAnswer(0);
             this.refs.sequrityqn._validateAnswer(1);
             this.refs.sequrityqn._validateAnswer(2);
         }
 
         if ( !this.state.password.valid || !this.state.securityQuestion.valid) {
             errorMessage = SUBMIT_ERROR_MESSAGE;
             this.setState({ submitErrors: errorMessage });
             this.refs.password._resetPasswordAndConfirmPassword();
             this._setFalse('password');
             window.scrollTo(0, 0);
         } else {
             this.setState({ submitErrors: '' });
             var self = this;
             let Answer1,Answer2,Answer3 = '';
             let Question1,Question2,Question3 = '';;
             Question1 = this.state.securityQuestion.value[0].Question;
             Question2 = this.state.securityQuestion.value[1].Question;
             Question3 = this.state.securityQuestion.value[2].Question;
             Answer1 = this.state.securityQuestion.value[0].Answer;
             Answer2 = this.state.securityQuestion.value[1].Answer;
             Answer3 = this.state.securityQuestion.value[2].Answer;
             let request = {
                 "password": this.state.password.value,
                 "securityQuestions": [{
                     "Answer1"  : Answer1,
                     "Answer2"  : Answer2,
                     "Answer3"  : Answer1,
                     "Question1": Question1,
                     "Question2": Question2,
                     "Question3": Question3
                 }],
                 "userId": this.props.userId
             }
             HTTP.postJSON('/user/registration/v1', request)
                 .then(function (response) {
                     if(response.success == "true"){
                        self.setState({ submitErrors: '' });
                        self.props.updateRegistration(true,response.orgId);
                        const args = {
                            "username": response.successfulUserId,
                            "password": encodeURIComponent(request.password)
                        };
                        HTTP
                        .postJSON('/auth/userlogin/v1', args)
                        .then(function (data) {
                            if (data.success == "true") {
                                if (data.actionType == "search") {
                                    self.setState({error: ''})
                                    let premAccessList = data.premAccess;
                                    let premAccessArray = premAccessList.split(',')
                                    localStorage.setItem('organizationName',data.orgName);
                                    localStorage.setItem('premAccessName',premAccessArray);
                                    self
                                        .props
                                        .UpdateLogIn(true, "", "search", response.successfulUserId, data.orgId, data.premAccess,data.orgName);
                                }
                            }
                        });
                     } else {
                         self.refs.password._resetPasswordAndConfirmPassword();
                         self.props.updateRegistration(false,"");
                         self.setState({ submitErrors: 'Unable to complete your request. Please contact system administrator' });
                     }
                 })
         }
     }
 
 
 
 
     // Render Registration Component
     // Child Components : Name, Organization, BusinessEmail, Username,Password, JobTitle, CommentBox, SecurityQuestion
     render() {
         return (
             <div className="resolution_container_reg container">
                 <div id="containerreg">
                     <header>
                         <span id="logoEnglish"></span>
                         <a href="../gbt_viewer_quick_reference.pdf" target="_blank" className="help_icon">&nbsp;</a>
                     </header>
                     <div className="tpn-registration-container container" id="registration-container">
                         <h2 className="text-center create-account">Complete Registration</h2>
                         <form className="registration-margin">
                             {this.state.submitErrors != '' ? <div className="submit-error-block"><p className="submit-errors">{this.state.submitErrors}</p></div> : null}
                             <br />
                             <Password ref='password' username={this.state.username} valid={this.state.password.valid} setFalse={this._setFalse.bind(this)} setValue={this._setValue.bind(this)} />
                             <SecurityQuestion ref='sequrityqn' valid={this.state.securityQuestion.valid} setFalse={this._setFalse.bind(this)} setValue={this._setValue.bind(this)} />
                             <br />
                             <button type="button" onClick={this._handleSubmit.bind(this)} className="btn btn-default custom-submit-button">Submit</button>
                         </form>
                     </div>
                 </div>
                 <Footer placeHolder={this.props.placeHolder} />
             </div>
         );
     }
 }
 
 export default Registration;
 