import React, { Component } from 'react';

class BusTripDetails extends Component {
    render() {
        return (
            <div>
                <div className="trip-details air">
                    <div className="flight_segment">
                        <div className="side-icon">
                            <div className="inner-line">&nbsp;</div>
                        </div>
                        <p className="conf-no">Conf.No.: {this.props.popupdata.BusConfirmationNumber}</p>
                        <br/>
                        <div className="airport bus-carrier">{this.props.popupdata.BusCarrier}</div>
                        <div className="flight-time">
                            <p className="time">{this.props.popupdata.BusDepartureTimeFormat} hrs</p>
                            <p className="date">{this.props.popupdata.BusDepartureDateOnly}</p>
                            <div className="duration_proxy">&nbsp;</div>
                            <p className="time">{this.props.popupdata.BusArrivalTimeFormat} hrs</p>
                            <p className="date">{this.props.popupdata.BusArrivalDateOnly}</p>
                        </div>

                        <div className="details-line"></div>

                        <div className="flight-details">
                            <p className="airport">&nbsp;{this.props.popupdata.BusDepartureStation} </p>
                            <p className="city">&nbsp;</p>
                            <p className="terminal">&nbsp;</p>
                            <div className="duration">
                                <span className="bus-icon"></span>  <span> </span>
                            </div>
                            <p>{this.props.popupdata.BusArrivalStation}</p>
                            <p className="city"></p>
                            <p className="terminal"></p>
                        </div>

                        <div className="extra-info">
                            <p className="bus">{this.props.popupdata.BusVehicleType}</p>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>   
            </div>
        );
    }
}

export default BusTripDetails;