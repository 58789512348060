var config = {};

config.serverURL = 'localhost';
config.environment = process.env.ENVIRONMENT || 'E2';
config.apiURL = process.env.API_URL || 'https://apicert.gbt.gbtad.com';
config.header = {apiKey: process.env.API_KEY || process.env.HEADER_KEY , Accept: 'application/json', recommendations: 'yes'}
config.recomendationheader = { apiKey: process.env.RECOMMENDATION_API_KEY || process.env.RECOMMANDATION_HEADER, Accept: 'application/json' };
config.httpPort = process.env.HTTP_PORT || '8085';
config.httpsPort = process.env.HTTPS_PORT || '443';
config.logLocation = './';
config.deployment = process.env.DEPLOYMENT || 'INTERNET';

/* E0 API Url and Header */
//config.apiURL =  "http://apidev.gbt.gbtad.com";
//config.header = { "apiKey": "yiWgznOtGsV7GTYMOZCkCjg6OEj3OgGv","Accept":"application/json","recommendations":"yes" };

/* E1 API Url and Header */
//config.apiURL =  "http://apidev.gbt.gbtad.com";
//config.header = { "apiKey": "yiWgznOtGsV7GTYMOZCkCjg6OEj3OgGv","Accept":"application/json","recommendations":"yes" };

/* E2 API Url and Header */
// config.apiURL = 'http://apicert.gbt.gbtad.com';
// config.header = { apiKey: 'yiWgznOtGsV7GTYMOZCkCjg6OEj3OgGv', Accept: 'application/json', recommendations: 'yes' };

/* E3 API Url and Header */
//config.apiURL =  "https://api.gbt.gbtad.com";
//config.header = { "apiKey": "LNPs6S5LGPeo9w4ZGFXLQqz7GQcpZ0NI","Accept":"application/json","recommendations":"yes" };

// config.recomendationheader = { apiKey: 'bvKHTdWewKQsv5JZxOKUhLyjxIe51V8U', Accept: 'application/json' };
// config.httpPort = 8080;
// config.httpsPort = 443;
// config.rethinkhost = 'localhost';
// config.rethinkport = 28015;
// config.languageTranslatorTableName = 'language';
// config.rethinkDataBase = 'gtr';

//config.deployment = 'INTRANET';
//config.deployment='INTERNET';

module.exports = config;
