import React from "react";
import HTTP from "../../../../services/httpservice.js";
import _ from "underscore";
import {getCallStatusCount,getIconAndNumberClass} from '../../../../commons/utils';
const HISTORY_CALLSTATUS_COMMENTS_API_URL = '/api/historycallstatusandcomments/';
export default class HistoryCount extends React.Component {
  constructor() {
    super();
    this.state = {
      callStatusAndCommentsData: null,
    };
  }
  componentWillMount() {
    this.callApiHistoryCallStatusAndComments();
  }
  callApiHistoryCallStatusAndComments() {
    let tripIdObject = [];
    for (let i = 0; i < this.props.TripIdDetailsArray.length; i++) {
      tripIdObject.push({
        "tripid": this.props.TripIdDetailsArray[i]
      });
    }
    let postBody = {
      "Traveler": {
        "Id": this.props.traveller.TravelerId,
        "email": this.props.traveller.EmailId[0]
      },
      "TripIds": tripIdObject
    };
    var self=this;
    HTTP.postJSON(HISTORY_CALLSTATUS_COMMENTS_API_URL, postBody).then(function (response) {
      if (response.CallStatusOrComments.Success == "True") {
        var emptyCheck = _.isEmpty(response.CallStatusOrComments);
        if (emptyCheck == false) {
          self.setState({
            callStatusAndCommentsData: getCallStatusCount(response.CallStatusOrComments),
          });
        }
      }
    });
  }
  render() {
    let histortList;
    if(this.state.callStatusAndCommentsData !=null){
        histortList = this.state.callStatusAndCommentsData.map((item, i) => {
          let cssclass = getIconAndNumberClass(item.type)
          if(item.number==0){
            return null
          }
          return <li key ={i} className={cssclass.iconClass} key={i}><a href="#" tabIndex="-1" >&nbsp;</a><span className={cssclass.numberClass}>{item.number}</span></li>
        }
      )
    }
    
    return (
      <div className="history-icons">
        <span className="history-text">{this.props.placeHolder.HISTORY}:</span>
        <ul className="history-list">
          {histortList}
        </ul>
      </div>
    );
  }
}
