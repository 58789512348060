import React, { Component } from 'react';
import Password from '../registration/passwordField';
import HTTP from '../../services/httpservice';
import FooterContent from '../FooterContent';

class ActivationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    password: {
        valid: false,
        value: '',
        loading:false
      },
      loading: false,
    submitErrors: ''
    }
  }
  _setValue(state, value) {
      let temp = {
          valid: true,
          value: value
      }
      this.setState({
          [state]: temp
      });
  }
  _setFalse(state) {
      let temp = {
          valid: false,
          value: ''
      }
      this.setState({
          [state]: temp
      });
  }

  _handleSubmit() {
      var url = window.location.href;
      if (!this.state.password.valid) {
          this.refs.password._validatePasswordField();
          this.refs.password._validateconfirmPasswordField();
          this.refs.password._resetPasswordAndConfirmPassword();
          this._setFalse('password');
          window.scrollTo(0, 0);
      } else {
          this.setState({
              submitErrors: '',
              loading:true
         });
          var self = this;
          let request = {};
          // Reset password request with Activation Code
          if(url.indexOf('?activationCode=') != -1) {
                request = {
                        "password": this.state.password.value,
                        "action":"UPDATEPASSWORD",
                        "activationCode":this.props.activationcode
                }
          // Reset password Request with userId
          } else {
                request = {
                    "password": this.state.password.value,
                    "action":"UPDATEPASSWORD",
                    "userId":this.props.userId
                }
          }
          HTTP.postJSON('/user/recovery/v1', request)
              .then((response) => {
                  if(response.success == "true"){
                    if(response.actionType == "login"){
                      self.setState({ submitErrors: '' ,loading:false});
                      if(self.props.updateActivePage!=null)
                      self.props.updateActivePage(false);
                      location.reload(window.location.href);
                    }
                    else{
                      self.refs.password._resetPasswordAndConfirmPassword();
                      self.setState({ submitErrors: response.reason,loading:false});
                    }
                  } else {
                        self.refs.password._resetPasswordAndConfirmPassword();
                        self.setState({ submitErrors: response.reason ,loading:false});
                  }
              })
      }
  }
  callbackFromParent() {
      this.setState({loading:false});
  }
    render () {
      var resetPasswordMessage="";
      if(this.props.resetPasswordMessage!=undefined)
      resetPasswordMessage=this.props.resetPasswordMessage;
      var url = window.location.href;
      console.log(this.state.submitErrors);
      return (
        <div className="resolution_container_reg container">
            <div id="containerreg">
                <header>
                    <span id="logoEnglish"></span>
                    <a href="../gbt_viewer_quick_reference.pdf" target="_blank" className="help_icon">&nbsp;</a>
                </header>
                {resetPasswordMessage!='' ?
                <div className="tpn-registration-container container expiry-header" id="registration-container">
                  <div className="col-sm-1 errorimage"></div>
                  <div className="col-sm-11">
                   <p className="reset-message">Your password has expired. For security reasons your password expires every 90 days. Please reset it now to login.</p>
                  </div>
                </div>:
                null
              }
                <div className="tpn-registration-container container" id="registration-container">
                    <h2 className="text-center create-account">Reset Password</h2>
                    <form className="registration-margin">
                        <ErrorMessage message={this.state.submitErrors}/> 
                        
                        <br />

                      
                        
                        
                        <Password ref="password"  username={this.state.username} valid={this.state.password.valid} setFalse={this._setFalse.bind(this)} setValue={this._setValue.bind(this)} />
                        
                        <button type="button" onClick={this._handleSubmit.bind(this)}  className="btn btn-default custom-submit-button">Submit</button>
                       

                    </form>
                    
                </div>
            </div>
            <FooterContent/>
        </div>
      )
    }
}

function ErrorMessage(props){
   
    return(
        props.message != '' ?<div className="submit-error-block"><p className="submit-errors">{props.message}</p></div>:null
    )
}
export default ActivationPage;
