import React from "react";
import Filter1 from "./Filter1.js";
import TravelPlans from "../TravelPlans.js";
import {validateEmail, validatephone, validateTicketNumber,validateDate} from '../../services/utils'
import Footer from "../footer"
import ActivationPage from './ActivationPage';
import NotificationComponent from "../Login/notificationcomponent"
import {Environment} from '../constants';
import ToasterNotification from '../toasternotification';
import {ToastContainer, ToastMessage} from "../Toaster";
import {ReferenceCheckatleast, CodeCheck} from '../../services/utils'
import ProfileComponent from "./ProfileComponent.jsx";
import NoClientMessage from "../NoClientMessage.js";
import  {setAmplitudeUserId,setAmplitudelogEvent} from '../../commons/amplitude.js';
import createClass from 'create-react-class';
var HTTP = require('../../services/httpservice');
var config = require('../../../config.js');

var ReactBootstrap = require("react-bootstrap");
var OverlayTrigger = ReactBootstrap.OverlayTrigger;
import { Tooltip } from 'react-bootstrap';

export const NAME = 'NAME';
export const EMPLOYEE_ID = 'EMPLOYEE_ID';
export const CONFIRMATION_NUMBER = 'CONFIRMATION_NUMBER';
export const LOCATION_CODE = 'LOCATION_CODE';
export const EMAIL = 'EMAIL';
export const PHONE = 'PHONE';
export const COMPANY = 'COMPANY';
export const DATE = 'DATE';
export const RECORD_LOCATOR = 'RECORD_LOCATOR';
export const TICKET = 'TICKET';
var io = require('socket.io-client');
var ToastMessageFactory = React.createFactory(ToastMessage.animation);

var Error = createClass({
    render: function () {
        return (
            <div className={this.props.classname} role="alert">{this.props.text}</div>
        )
    }
});

var LogoutPopup = createClass({
    handleLogout:function() {
        this.props.logout(true);
    },
    render : function() {
         return(<div className="modal show" id="cancelAction" role="dialog" data-backdrop="static">
         <div className="modal-dialog">
             <div className="modal-content confirmation">
                 <div className="modal-body logout-popup">
                          <div className="col-sm-12">
                          Your temporary PTC expanded permissions have been removed. You do not have GTR Viewer or connect client permissions therefore you will be logged out
                                 <div className="logout-ok-button">
                                     <button
                                         className="save_button enabled">
                                         <span onClick={this.handleLogout}>OK</span></button>
                                 </div>
                             </div>
                        </div>
                    </div>
                </div>
            </div>)
    }
});

var NoResultsErr = createClass({
    render: function () {
        return (
            <div>No Results found</div>
        )
    }
});

var PoorInternetNotifier = createClass({
    render: function () {
        console.log('props'+this.props.badInternet);
        const badInternet = this.props.badInternet;
        const showNetworkMessage = (
            <Tooltip id="tooltip" className={this.props.badInternet ? "toolTip_show" : "toolTip_hide"}>
                <strong>
                    Your internet connection is not stable enough to support PTC alerts.  
                    Please try to establish a stronger connection or let the system reestablish it for you,   Once your internet connection becomes stable alerts will resume. 
                    In meantime - you must click the bell to get updated disruption counts and you will not see alerts/pop ups during this time.
                </strong> 
            </Tooltip>
        );
        return (
            <div className={badInternet ? "wifi_button_icon wifi_icon_show" : "wifi_button_icon"}>
                <OverlayTrigger trigger="click" placement="bottom" overlay={showNetworkMessage} badInternet={badInternet}>
                   <img className="wifi_icon" src="/icons/wifi_icon_new.jpg" />
                </OverlayTrigger>
            </div>
        )
    }
});




var SearchBox = createClass({
    getInitialState: function () {
        return {
            isClientMessageOpen: false,
            searchData: "",
            Filter1: false,
            temp: null,
            Options: [],
            Validcheck: false,
            comboStr: "",
            tempf: null,
            greybox: "searchBox-grey-disable",
            greyWords: "searchBox-words-disable",
            category: '',
            socketID: '',
            resultsTxtFlag: false,
            ErrMsgFlag: false,
            socket: io({
                secure: true,
                'transports': ['websocket', 'polling']
            }),
            page: '',
            resetPasswordMessage: '',
            notificationList: [],
            errorRecordLocator: false,
            selectedNotifiedObject: null,
            openedChatList: {},
            searchDataInput: '',
            badInternet: false
        };
    },

    componentDidUpdate: function (prevState, prevProps) {

        if (prevProps.temp !== this.state.temp) {

            const getExceptionObject = (error) => {
                const exceptionObj = {
                    "exceptionObject": {
                        "reasonCode": `ERROR_NO_${error.toUpperCase()}`,
                        "reasonText": `${error} field not defiend`,
                        "request": {
                            "url": `${config.apiURL}/api/tripsummary/${this.state.searchCriteria}/${encodeURI(this.state.searchData)}?restrict=${this.props.restrict}`,
                            "headers": {
                                "accept": "application/json",
                                "content-type": "application/xml"
                            }
                        },
                    }
                }
                return exceptionObj;
            }

            const postErroMsg = (error) => {
                HTTP.postJSON('/log/error/v1', getExceptionObject(error));
            }

            if (this.state.temp) {
                const dataArray = this.state.temp;
                dataArray.forEach(data => {
                    if (data.EmailId[0] === " ") {
                        postErroMsg("EmailId")
                    }
                    if (data.Name[0] === " ") {
                        postErroMsg("Name")
                    }
                    if (data.phone[0] === " ") {
                        postErroMsg("phone")
                    }
                })
            } else {
                return
            }
        }
    },

    componentWillMount: function () {
        var field = 'recordlocator';
        var emailField = 'email';
        var travelerField = 'knowmeid';
        var emailListField = 'emaillist';
        var deleteSocket = this
            .deleteSocket
            .bind(this);
        var url = window.location.href;
        if (this.props.pnr != "" && this.props.pnr != null) {
            this.context.pnrValue = this.props.pnr;
            this.setState({searchData: this.props.pnr, greyWords: "searchBox-words-active", greybox: "searchBox-grey-active", searchDataInput: this.props.pnr});
            this.onClick();
        } else if (this.props.mail != "" && this.props.mail != null) {
            this.context.emailValue = this.props.mail;
            this.onClick();
        } else if (this.props.knowmeid != "" && this.props.knowmeid != null) {
            this.context.knowmeidValue = this.props.knowmeid;
            this.onClick();
        } else if (this.props.emaillist != "" && this.props.emaillist != null) {
            this.context.emailListValue = this.props.emaillist;
            this.onClick();
        }
        window.addEventListener("beforeunload", (ev) => {
            deleteSocket();
            //ev.preventDefault();
        });

    },

    closeClientMessage: function() {
        this.setState({
            isClientMessageOpen: false
        });
    },

    OnSearch: function (input) {
        if (input.value == "") {
            alert("You either clicked the X or you searched for nothing.");
        } else {
            alert("You searched for " + input.value);
        }
    },
    componentDidMount: function () { 
        if (Environment == "INTERNET") {
            this
                .props
                .ReactGA
                .event({
                    category: 'User',
                    action: 'User ID: ' + this.props.userId + ', ORG ID (TPN):' + this.props.restrict
                });
        } else {
            this
                .props
                .ReactGA
                .event({
                    category: 'User',
                    action: 'User ID: ' + this.props.userId + ', SSO (ADS):' + this.props.restrict
                });

                setAmplitudeUserId(this.props.userId);
                setAmplitudelogEvent("Active Users",
                    {   category: 'User',
                        action: 'User Id: ' + this.props.userId
                    }
                );
        }

        var that = this;
        var id;
        this.forceUpdate();
        var userName = this.props.userId
        if (userName == "") 
            userName = "gtradmin";
        var serverName = window.location.hostname;
        var portNumber = "8080"
        var premGroup = "";
        var Groups = "";
        var premGroupName = "";
        if (Environment == "INTERNET") {
            Groups = this.props.premAccess;
            if(Groups!=undefined){
                for (var i = 0; i < Groups.length; i++) {
                    if (Groups[i].indexOf("PREM") != -1) {
                        if (premGroupName != "") 
                            premGroupName = premGroupName + ",SSO_PTC_DEFAULT_GROUP_" + Groups[i].split("-")[0] + "" + Groups[i].split("-")[1];
                        else 
                            premGroupName = "SSO_PTC_DEFAULT_GROUP_" + Groups[i].split("-")[0] + "" + Groups[i].split("-")[1];
                    } 
                }
            }
        } else {
            Groups = this
                .props
                .restrict
                .split(",");
            for (var i = 0; i < Groups.length; i++) {
                if (Groups[i].indexOf("PREM") != -1) {
                    if (premGroupName != "") 
                        premGroupName = premGroupName + "," + Groups[i];
                    else 
                        premGroupName = Groups[i];
                } 
            }
        }
        if (Environment != "INTERNET" && premGroupName == "") 
            premGroup = "NO_GROUP";
        else if(Environment =="INTERNET" && premGroupName=="")
            premGroup ="NO_GROUP";
        else 
            premGroup = premGroupName;

        if(this.props.userId=='gtradmin'){
            premGroup='SSO_PTC_DEFAULT_GROUP_PREM3';
        } 
        /* Elevated Notification */
        if(this.props.elevatedPermissions && !this.props.codeRed) {
            let temp = "PTC elevated permissions have been granted.";
                that
                    .refs
                    .toasterNotificationRef
                    .showNotification(temp, "", "/icons/profile-elevated-icon.png", 'Permissions Notification');
        } else if(this.props.codeRed) {
            let temp = "PTC elevated permissions have been granted.";
            that
                .refs
                .toasterNotificationRef
                .showNotification(temp, "", "/icons/code-red-profile-icon.png", 'Permissions Notification');
        }

        this.state.socket.on('grantElevatedRightsNotification', grantPermissionObj => {
            that.props.grantElevatedPermission();
            let temp = grantPermissionObj.message;
            that
                .refs
                .toasterNotificationRef
                .showNotification(temp, "", "/icons/profile-elevated-icon.png", 'Permissions Notification');
        })

        this.state.socket.on('removeElevatedRightsNotification', removePermissionObj => {
            that.props.grantElevatedPermission();
            let temp =  removePermissionObj.message;
            that
                .refs
                .toasterNotificationRef
                .showNotification(temp, "", "/icons/profile-elevated-minus-icon.png", 'Permissions Notification');
        })

        this.state.socket.on('grantElevatedRightsCountryNotification', grantPermissionObj => {
            that.props.grantElevatedPermission();
            let temp = grantPermissionObj.message;
            that
                .refs
                .toasterNotificationRef
                .showNotification(temp, "", "/icons/code-red-profile-icon.png", 'Permissions Notification');
        })

        this.state.socket.on('removeElevatedRightsCountryNotification', removePermissionObj => {
            that.props.grantElevatedPermission();
            let temp =  removePermissionObj.message;
            that
                .refs
                .toasterNotificationRef
                .showNotification(temp, "", "/icons/code-red-profile-icon-minus.png", 'Permissions Notification');
        })

        this.state.socket.on('smpNotification', smpObj => {
            let temp = "Your cancellation request for hotel with record locator " + smpObj.smp.recordLocator + " is "+smpObj.smp.status;
            that
                .refs
                .toasterNotificationRef
                .showNotification(temp, smpObj.smp, "/icons/hotel_purple.png", 'SMP Cancellation Status Notification');
        })
        this.state.socket.on('agentBrodCastNotification', brodcastObject => {
            if (brodcastObject.messageType === 'Weather') 
                that.refs.toasterNotificationRef.showNotification(brodcastObject.message + " " + brodcastObject.messageSentTime, brodcastObject, "/icons/thunderstorms.png", `Message from ${brodcastObject.userFullName}`);
            else 
                that
                    .refs
                    .toasterNotificationRef
                    .showNotification(brodcastObject.message + " " + brodcastObject.messageSentTime, brodcastObject, "/icons/waiver3.png", `Message from ${brodcastObject.userFullName}`);
            }
        );

        this.state.socket.on('resetLogin', function (casesArr) {
            console.log('-------------------------------------------------------> received resetLogin event from backend !  ');
	        that.state.socket.close();
            that.props.logout(true);
        });
       
	this.state.socket.on('disconnect', () => {
            var d1 = new Date();
	    if (this.props.premAccess  != undefined) {		
                console.log('<----disconnect got called at %s:%s:%s.%s ',  d1.getHours() ,  d1.getMinutes() ,  d1.getSeconds() ,d1.getMilliseconds());
                this.setState({badInternet:true});
            }
        });

 
        this.state.socket.on('pushNotification', function (casesArr) {
            if(casesArr.cases.flag !== undefined && casesArr.cases.flag !== "" && casesArr.cases.flag !== null) {
                if(casesArr.cases.flag === 'A') {
                    that
                    .refs
                    .NotificationComponent
                    ._updateNotificationPending(casesArr.cases.cases[0],'A');
                } else if (casesArr.cases.flag === 'R') {
                    that
                    .refs
                    .NotificationComponent
                    ._updateNotificationPending(casesArr.cases.cases[0],'R');
                }
            }
            
            if (casesArr.cases.color === 'GREEN'&& casesArr.cases.flag === undefined) {
                that
                    .refs
                    .NotificationComponent
                    ._updateNotificationPending(casesArr.cases.cases[0],null);
            }
            if (typeof casesArr.cases.cases != undefined && casesArr.cases.flag === undefined) {
                for (let caseObj of casesArr.cases.cases) {
                    let temp = "";
                    if (caseObj.clientName != null) {
                        temp = caseObj.travelerFName + " " + caseObj.travelerLName + " needs help, " + caseObj
                            .clientName
                            .trim() + ", " + caseObj.clientId;
                    } else {
                        temp = caseObj.travelerFName + " " + caseObj.travelerLName + " needs help, " + caseObj.clientId;
                    }
                    let data = {
                        caseObj,
                        caseArr: casesArr.cases.cases
                    }
                    let icon = '';
                    if (casesArr.cases.color === 'GREEN' && caseObj.isFlightSelected === 'Y') {
                        icon = "/icons/flight_green.png";
                    } else if (casesArr.cases.color === 'RED' && caseObj.isFlightSelected === 'Y') {
                        icon = "/icons/flight_red.png";
                    } else if (casesArr.cases.color === 'YELLOW' && caseObj.isFlightSelected === 'Y') {
                        icon = "/icons/flight_yellow.png";
                    }  else if (casesArr.cases.color === 'GREEN') {
                        icon = "/icons/Green.png";
                    } else if (casesArr.cases.color === 'YELLOW') {
                        icon = "/icons/Yellow.png";
                    } else {
                        icon = "/icons/Red.png";
                    }
                    let scope = caseObj.scope.split("_");
                    var langArray = {
                        "EN-US":"EN",
                        "FR-FR":"FR",
                        "DE-DE":"DE",
                        "ES-ES":"ES",
                        "FR-CA":"CA",
                        "SE-SE":"SE"
                    }
                    if(scope[scope.length-1]==='PREM1' || scope[scope.length-1]==='PREM2' || scope[scope.length-1]==='PREM4'){
                        if(Environment === "INTRANET" && casesArr.cases.cases[0] && casesArr.cases.cases[0].replyLangCode!==undefined && 
                            casesArr.cases.cases[0].replyLangCode !== "" && langArray[casesArr.cases.cases[0].replyLangCode] !== undefined) {
                            var title = 'PTC FIRST ('+langArray[casesArr.cases.cases[0].replyLangCode]+')';
                        } else {
                            var title = 'PTC FIRST';
                        }
                        that
                        .refs
                        .toasterNotificationRef
                        .showNotification(temp, data, icon, title);
                    }else{                      
                        if(Environment === "INTRANET" && casesArr.cases.cases[0] && casesArr.cases.cases[0].replyLangCode!==undefined && 
                            casesArr.cases.cases[0].replyLangCode !== "" && langArray[casesArr.cases.cases[0].replyLangCode] !== undefined) {
                            var title = 'Proactive Traveler Care Notification ('+langArray[casesArr.cases.cases[0].replyLangCode]+')';
                        } else {
                            var title = 'Proactive Traveler Care Notification';
                        }
                        
                        that
                        .refs
                        .toasterNotificationRef
                        .showNotification(temp, data, icon, title);
                    }   
                }
            }
        })
        this.state.socket.on('connect', function (s) {
            id = this.id;
            //that.setState({socketID: id});
	        console.log(" connected back : %s", that.state.badInternet, that.props.restrict);	
            that.setState({socketID: id, badInternet: false});

            that.state.socket.emit('read_connection', {id: id});
            let request = {
                "userId": userName,
                "scope": premGroup,
                "socketId": that.state.socketID,
                "serverName": serverName,
                "portNumber": portNumber,
                "userName":that.props.userName,
                "userCountryCode": that.props.countryCode,
                "countryScope":that.props.countryScope
            }
            if (Environment === 'INTERNET') {
                request['orgId'] = that.props.restrict;
            }
            let headers = {
                "accept": "application/json",
                "content-type": "application/json",
                Environment
            }
            {
                window
                    .location
                    .href
                    .indexOf('knowmeid') == -1 && window
                    .location
                    .href
                    .indexOf('emaillist') == -1 && window
                    .location
                    .href
                    .indexOf('email') == -1 && window
                    .location
                    .href
                    .indexOf('recordlocator') == -1
                    ? HTTP
                        .postJSONHeader('/ad/user/v1', headers, request)
                        .then(function (response) {
                            if (response.success == "true") {
                                if ((!response.clientsCount || response.clientsCount === 0) &&
                                (request.scope.includes("PREM1") || request.scope.includes("PREM2") || request.scope.includes("PREM3"))) {
                                    that.setState({isClientMessageOpen: true})
                                }
                                if (response.clientsCount && response.clientsCount > 0) {
                                    that.refs.NotificationComponent.getNotification('all',"true",true);                                    
                                }
                            } else {
                                console.log("Error");
                            }
                        })
                    : null
            }
        });
        if (this.props.resetPasswordExpiry) 
        window.$('#passwordalert').modal('show');
        }
    ,
    contextTypes: function () {
        return {
            DetailArray: [],
            TravelPlans: false,
            error: "",
            valiadationError: "",
            loadingimage: false,
            text: "",
            greyBoxclk: false,
            pnrValue: '',
            emailValue: '',
            knowmeidValue: '',
            emailListValue: ''
        }
    },
    callApiNotification: function (notificationObject) {
        var that = this;
        this.setState({selectedNotifiedObject: notificationObject});
        this
            .refs
            .NotificationComponent
            ._callGetTRavellerQueries();
    },
    getTripSummaryByRecordLocator: function (recordLocator, notifiedObj) {
        var self = this;
        var notifiedObjList = null ;
        this.setState({temp: null, greybox: "searchBox-grey-disable", resultsTxtFlag: false})
        this.context.pnrValue = recordLocator;
        window.$('.notification_popup').addClass('hide');
        window.$('#searchInput').val(recordLocator);
        window.$('#seachbox-div input').val(recordLocator);
        var searchInfo = recordLocator;
        if (!notifiedObj) {
            searchInfo += "?restrict=" + this.props.restrict;
        } else {
            searchInfo += "?restrict";
        }
 
        if(notifiedObj.caseObj !== undefined) {
            notifiedObjList = notifiedObj.caseObj
        } else {
            notifiedObjList = notifiedObj
        }

        HTTP
            .get('/api/tripsummary/recordlocator/' + searchInfo)
            .then(function (response) {
                if (response == false) {
                    self.setState({Filter1: false, ErrMsgFlag: true, errorRecordLocator: true})
                } else {
                    self.setState({temp: response, Filter1: false, selectedNotifiedObject: notifiedObjList, ErrMsgFlag: false, errorRecordLocator: false});
                }
            });
    },

    click: function () {
        if (this.state.active) {
            this.setState({active: false});
        } else {
            this.setState({active: true});
        }
    },
    getNotificationList: function (notificationSummaryJson) {
        this.setState({notificationList: notificationSummaryJson})
    },

    onFocus: function (event) {
        this.setState({greybox: "searchBox-grey-disable"});
        this.context.text = "";
    },
    onClearClick: function () {
        this.setState({greyBoxclk: true, greybox: "searchBox-grey-disable"});

    },
    clearClick: function () {
        document
            .getElementById('searchInput')
            .value = '';
        this.setState({
            greybox: "searchBox-grey-disable",
            greyWords: "searchBox-words-disable",
            searchData: "",
            comboStr: "",
            temp: null,
            tempf: null
        });
    },
    getDefaultProps: function () {
        return {
            alphabets: [
                NAME, EMPLOYEE_ID, CONFIRMATION_NUMBER, LOCATION_CODE
            ],
            numbers: [
                EMPLOYEE_ID, CONFIRMATION_NUMBER, DATE
            ],
            alphaNumeric: [
                EMPLOYEE_ID, CONFIRMATION_NUMBER, DATE
            ],
            email: [EMAIL]
        };
    },

    onClick: function (event) {
        this
            .props
            .ReactGA
            .event({category: 'Search', action: "Searched based on Combo"});

        setAmplitudelogEvent("Search Combo",
            {   category: 'Search',
                action: "Combo"
            }
        );
        
        if (this.context.text != "" && typeof(this.context.text) != "undefined") {

            var summaryData;
            this.context.loadingimage = true;
            var self = this;
            var searchInfo = this.state.searchData;
            var searchData = this.state.searchData;
            var searchInfo = this.state.searchData;
            if (searchInfo.indexOf('/') != -1) 
                searchInfo = searchInfo.replace("/", "%2F");
            
            searchInfo += "?restrict=" + this.props.restrict;
            HTTP
                .get('/api/tripsummary/' + this.context.text + "/" + searchInfo)
                .then(function (response) {
                    summaryData = response;
                    self.setState({temp: summaryData, Filter1: false, greybox: "searchBox-grey-active", greyWords: "searchBox-words-active"});
                });

            if (this.state.temp != null && this.state.temp == "false") {
                this.setState({ErrMsgFlag: false});
            } else {
                this.setState({ErrMsgFlag: true});
            }

            this.setState({Filter1: false, errorRecordLocator: false});
        } else {
            //************
            this.context.valiadationError = "";
            this.setState({Validcheck: false, greyBoxclk: false});
            //var srchval=event.target.value;
            var summaryData;
            var alphaCheck = /^\d+$/i;
            if (this.state.searchData != "" && this.state.searchData != " ") {
                if (this.state.searchData.indexOf(" ") != -1) {
                    this.setState({Filter1: false, resultsTxtFlag: false, temp: null});
                }

                var start = new Date().getTime();
                this.context.loadingimage = true;
                var searchInfo = this.state.searchData;
                if (searchInfo.indexOf('/') != -1) {
                    searchInfo = searchInfo.replace("/", "%2F");

                } else if (searchInfo.indexOf(' ') != -1) {

                    //  searchInfo=searchInfo.replace(" ","%2F");
                }

                var self = this;
                this.setState({temp: null, searchData: searchInfo, comboStr: searchInfo, Filter1: false, errorRecordLocator: false});

                searchInfo += "?restrict=" + this.props.restrict;
                if (this.state.searchData.length == 6) {
                    HTTP
                        .get('/api/tripsummary/recordlocator/' + searchInfo)
                        .then(function (response) {
                            summaryData = response;

                            self.setState({temp: summaryData, Filter1: false});
                        });
                } else if (this.state.searchData.indexOf("@") != -1) {
                    HTTP
                        .get('/api/tripsummary/email/' + searchInfo)
                        .then(function (response) {

                            //
                            summaryData = response;

                            self.setState({temp: summaryData, Filter1: false});
                        });
                } else if (alphaCheck.test(this.state.searchData)) {
                    HTTP
                        .get('/api/tripsummary/phonenumber/' + searchInfo)
                        .then(function (response) {

                            //
                            summaryData = response;

                            self.setState({temp: summaryData, Filter1: false});
                        });
                } else if (this.state.searchData.length != 6 && this.state.searchData.length < 10) {
                    HTTP
                        .get('/api/tripsummary/travelername/' + searchInfo)
                        .then(function (response) {
                            //
                            summaryData = response;

                            self.setState({temp: summaryData, Filter1: false});
                        });
                } else {
                    HTTP
                        .get('/api/tripsummarytxt/' + searchInfo)
                        .then(function (response) {

                            //
                            summaryData = response;

                            self.setState({temp: summaryData, Filter1: false, errorRecordLocator: false});
                        });

                }

                if (this.state.temp != null && this.state.temp.GetTripSummaryResponse != null && this.state.temp.GetTripSummaryResponse.success != null) {

                    this.setState({ErrMsgFlag: false});
                } else {
                    this.setState({ErrMsgFlag: true});
                }
            } else if (this.context.pnrValue != "" && typeof this.context.pnrValue != "undefined") {
                if (this.context.pnrValue.indexOf(" ") != -1) {
                    this.setState({Filter1: false, resultsTxtFlag: false, temp: null});
                }
                var start = new Date().getTime();
                this.context.loadingimage = true;
                var searchInfo = this.context.pnrValue;
                if (searchInfo.indexOf('/') != -1) {
                    searchInfo = searchInfo.replace("/", "%2F");
                }

                var self = this;
                this.setState({temp: null, searchData: searchInfo, comboStr: searchInfo, Filter1: false, errorRecordLocator: false});
                searchInfo += "?restrict=" + this.props.restrict;
                HTTP
                    .get('/api/tripsummary/recordlocator/' + searchInfo)
                    .then(function (response) {

                        summaryData = response;
                        self.setState({temp: summaryData, Filter1: false});
                    });
                if (this.state.temp != null && this.state.temp.GetTripSummaryResponse.success != null) {
                    this.setState({ErrMsgFlag: false});
                } else {
                    this.setState({ErrMsgFlag: true});
                }
            } else if (this.context.emailValue != "" && typeof this.context.emailValue != "undefined") {
                if (this.context.emailValue.indexOf(" ") != -1) {
                    this.setState({Filter1: false, resultsTxtFlag: false, temp: null, errorRecordLocator: false});
                }

                var start = new Date().getTime();
                this.context.loadingimage = true;
                var searchInfo = this.context.emailValue;
                if (searchInfo.indexOf('/') != -1) {
                    searchInfo = searchInfo.replace("/", "%2F");
                }

                var self = this;
                this.setState({temp: null, searchData: searchInfo, comboStr: searchInfo, Filter1: false});

                searchInfo += "?restrict=" + this.props.restrict;
                HTTP
                    .get('/api/tripsummary/email/' + searchInfo)
                    .then(function (response) {

                        summaryData = response;
                        self.setState({temp: summaryData, Filter1: false});
                    });
                if (this.state.temp != null && this.state.temp.GetTripSummaryResponse.success != null) {
                    this.setState({ErrMsgFlag: false});
                } else {
                    this.setState({ErrMsgFlag: true});
                }
            } else if (this.context.knowmeidValue != "" && typeof this.context.knowmeidValue != "undefined") {
                if (this.context.knowmeidValue.indexOf(" ") != -1) {
                    this.setState({Filter1: false, resultsTxtFlag: false, temp: null});
                }

                var start = new Date().getTime();
                this.context.loadingimage = true;
                var searchInfo = this.context.knowmeidValue;
                if (searchInfo.indexOf('/') != -1) {
                    searchInfo = searchInfo.replace("/", "%2F");
                }

                var self = this;
                this.setState({temp: null, searchData: searchInfo, comboStr: searchInfo, Filter1: false});

                searchInfo += "?restrict=" + this.props.restrict;
                HTTP
                    .get('/api/tripsummary/knowmeid/' + searchInfo)
                    .then(function (response) {

                        summaryData = response;
                        self.setState({temp: summaryData, Filter1: false});
                    });
                if (this.state.temp != null && this.state.temp.GetTripSummaryResponse.success != null) {
                    this.setState({ErrMsgFlag: false});
                } else {
                    this.setState({ErrMsgFlag: true});
                }
            } else if (this.context.emailListValue != "" && typeof this.context.emailListValue != "undefined") {
                if (this.context.emailListValue.indexOf(" ") != -1) {
                    this.setState({Filter1: false, resultsTxtFlag: false, temp: null});
                }

                var start = new Date().getTime();
                this.context.loadingimage = true;
                var searchInfo = this.context.emailListValue;
                if (searchInfo.indexOf('/') != -1) {
                    searchInfo = searchInfo.replace("/", "%2F");
                }

                var self = this;
                this.setState({temp: null, searchData: searchInfo, comboStr: searchInfo, Filter1: false});

                searchInfo += "?restrict=" + this.props.restrict;
                HTTP
                    .get('/api/tripsummary/emaillist/' + searchInfo)
                    .then(function (response) {

                        summaryData = response;
                        self.setState({temp: summaryData, Filter1: false});
                    });
                if (this.state.temp != null && this.state.temp.GetTripSummaryResponse.success != null) {
                    this.setState({ErrMsgFlag: false});
                } else {
                    this.setState({ErrMsgFlag: true});
                }
            } else {
                this.setState({Filter1: false});
            }
        }
    },
    onClickfilterfunction: function (text) {
        this.setState({resultsTxtFlag: true, temp: null, errorRecordLocator: false});
        var summaryData;
        this
            .deleteSocket
            .bind(this)();
        this.context.text = text.toLowerCase();
        switch (this.context.text) {
            case "name":
                this.setState({category: this.props.placeHolder.NAME});
                this.context.text = "travelername";
                this.state.searchCriteria= "travelername";
                break;
            case "phone":
                this.setState({category: this.props.placeHolder.PHONE});
                this.context.text = "phonenumber";
                this.state.searchCriteria= "phonenumber"
                if (!validatephone(this.state.searchData)) {
                    this.setState({Validcheck: true});
                    this.setState({Filter1: false});
                    this.context.valiadationError = this.props.placeHolder.NO_RESULT;
                    return;
                } else {
                    this.setState({Validcheck: false});
                    this.setState({category: this.props.placeHolder.PHONE});
                    this.context.text = "phonenumber";
                }
                break;
            case "email":
                this.state.searchCriteria= "email"
                if (!validateEmail(this.state.searchData)) {
                    this.setState({Validcheck: true});
                    this.setState({Filter1: false});
                    this.context.valiadationError = "INVALID EMAIL ID.PLEASE TRY AGAIN"
                    return;
                } else {
                    this.setState({Validcheck: false});
                    this.setState({category: this.props.placeHolder.EMAIL});
                    this.context.text = "email";
                }
                break;
            case "company":
                this.setState({category: this.props.placeHolder.COMPANY});
                this.context.text = "clientname";
                this.state.searchCriteria= "clientname"
                break;
            case "date":
                this.state.searchCriteria= "date"
                if (!validateDate(this.state.searchData)) {
                    this.setState({Validcheck: true});
                    this.setState({Filter1: false});
                    this.context.valiadationError = "Please enter the correct date format( DDMMM ) in search box to find search resul" +
                            "ts in Dates"
                    return;
                } else {
                    this.setState({Validcheck: false});
                    this.setState({category: this.props.placeHolder.DATE});
                    this.context.text = "traveldate";
                }
                break;
            case "record_locator":
                this.setState({category: this.props.placeHolder.RECORD_LOCATOR});
                this.context.text = "recordlocator";
                this.state.searchCriteria= "recordlocator"
                break;
            case "employee_id":
                this.setState({category: this.props.placeHolder.EMPLOYEE_ID});
                this.context.text = "employeeid";
                this.state.searchCriteria= "employeeid"
                break;
            case "location_code":
                this.state.searchCriteria= "locationcode"
                if (this.state.searchData.length > 2) {
                    this.setState({category: this.props.placeHolder.LOCATION_CODE});
                    this.context.text = "locationcode";
                } else {
                    this.setState({Validcheck: true});
                    this.setState({Filter1: false});
                    this.context.valiadationError = "Please enter 3 digits into search box  to find search results in Code."
                    return;
                }

                break;
            case "confirmation_number":
                this.state.searchCriteria= "confirmationnumber"
                this.setState({category: this.props.placeHolder.CONFIRMATION_NUMBER});
                this.context.text = "confirmationnumber";
                break;
            case "ticket":
                this.state.searchCriteria="ticketnumber"
                if (!validateTicketNumber(this.state.searchData)) {
                    this.setState({Validcheck: true});
                    this.setState({Filter1: false});
                    this.context.valiadationError = "INVALID TICKET NUMBER FORMAT.PLEASE TRY AGAIN"
                    return;
                } else {
                    this.setState({Validcheck: false});
                    this.context.text = "ticketnumber";
                }

                this.setState({category: this.props.placeHolder.TICKET});
                this.context.text = "ticketnumber";

                break;
            default:

        }
        this.context.loadingimage = true;
        var self = this;
        var searchInfo = this.state.searchData;
        var searchData = this.state.searchData;
        var searchInfo = this.state.searchData;
        if (searchInfo.indexOf('/') != -1) 

            searchInfo = searchInfo.replace("/", "%2F");
        
        this
            .props
            .ReactGA
            .event({
                category: 'Search',
                action: "Searched  based on " + this.context.text
            });
        setAmplitudelogEvent("Search",
            {   category: 'Search',
                action: this.context.text
            }
        );
        searchInfo += "?restrict=" + this.props.restrict;
        HTTP
            .get('/api/tripsummary/' + this.context.text + "/" + searchInfo)
            .then(function (response) {
                summaryData = response;
                
                self.setState({temp: summaryData, Filter1: false, greybox: "searchBox-grey-active", greyWords: "searchBox-words-active"});
            });

        if (this.state.temp != null && this.state.temp == "false") {
            this.setState({ErrMsgFlag: false});
        } else {
            this.setState({ErrMsgFlag: true});
        }

        this.setState({Filter1: false});
    },
    deleteSocket: function () {
        if (window.currentTraveller && window.currentTraveller !== "") {
            HTTP.socket('/socket', {
                socketId: this.state.socketID,
                knowmeId: window.currentTraveller
            }, {action: 'delete'})
                .then(function (result) {
                    console.log("deleted");
                });
        }
    },
    onKeyUp: function (event) {
        window
            .addEventListener("keydown", function (e) {
                // space and arrow keys
                if ([38, 40].indexOf(e.keyCode) > -1) {
                    e.preventDefault();
                }
            }, false);
        this.setState({selectedNotifiedObject: undefined})

        if (event.keyCode == 38 || event.keyCode == 40) {
            var list = document.getElementById("filter_options");
            if (list != null) {
                var first = list.firstChild;
                var maininput = document.getElementById("searchInput");
                if (event.keyCode == 38) {
                    if (document.activeElement == (maininput || first) // stop the script if the focus is on the input or first element
                    ) {} else {
                        document
                            .activeElement
                            .parentNode
                            .previousSibling
                            .firstChild
                            .focus();
                    } // select the element before the current, and focus it;
                } else if (event.keyCode == 40) {
                    if (document.activeElement == maininput) {
                        first.focus( // if the currently focused element is the main input --> focus the first <li>
                        );
                    } else {
                        document
                            .activeElement
                            .parentNode
                            .nextSibling
                            .firstChild
                            .focus();
                    } // target the currently focused element -> <a>, go up a node -> <li>, select the next node, go down a node and focus it
                }
            }
        }
        this.setState({Validcheck: false, ErrMsgFlag: false, greyBoxclk: false});
        this.setState({greybox: "searchBox-grey-disable"});
        var summaryData;
        var val = event.target.value;
        if (val.indexOf("") > -1) {
            this.setState({Filter1: false, searchData: val, comboStr: val});
            val = val.trim();
        }

        ReferenceCheckatleast(this.props.numbers, val, 4, PHONE);
        ReferenceCheckatleast(this.props.numbers, val, 9, TICKET);
        CodeCheck(this.props.alphabets, val, 3, LOCATION_CODE);
        ReferenceCheckatleast(this.props.alphaNumeric, val, 3, COMPANY);
        ReferenceCheckatleast(this.props.alphabets, val, 3, COMPANY);
        ReferenceCheckatleast(this.props.numbers, val, 10, RECORD_LOCATOR);
        if (isNaN(val)) {
            if (val.match(/[^a-zA-Z]/g)) {
                this.setState({Options: this.props.alphaNumeric});

            }
            ReferenceCheckatleast(this.props.alphaNumeric, val, 10, RECORD_LOCATOR);
            ReferenceCheckatleast(this.props.alphabets, val, 10, RECORD_LOCATOR);
            ReferenceCheckatleast(this.props.numbers, val, 4, PHONE);
            ReferenceCheckatleast(this.props.alphaNumeric, val, 4, PHONE);
            if (isNaN(val)) {
                if (val.match(/[^a-zA-Z]/g)) {
                    if (val.indexOf("@") > -1) {
                        this.setState({Options: this.props.email});
                    } else {
                        this.setState({Options: this.props.alphaNumeric});
                    }

                } else {
                    this.setState({Options: this.props.alphabets});
                }
            } else if (!isNaN(val)) {
                this.setState({Options: this.props.numbers});
            } else {
                this.setState({Options: this.props.alphabets});
            }
        } else if (!isNaN(val)) {
            this.setState({Options: this.props.numbers});
        }
        if (val != "" && val != " ") {
            if (val.indexOf(",") == -1 && val.indexOf("*") == -1 && val.indexOf(";") == -1) {
                var firstSpaceOccur = val.indexOf(" ");
                this.setState({Filter1: true, searchData: val, comboStr: val});

                if (firstSpaceOccur != -1 && val.indexOf(" ", Number(firstSpaceOccur) + 1) == -1) {
                    this.setState({Options: [NAME]});
                } else if ((val.split(" ").length) > 2) {
                    this.setState({Filter1: false});
                }

            } else {
                // this.setState({Filter1: true,   searchData:val,   comboStr:val });
                var str;
                if (val.indexOf(" ") > 0) {
                    str = val;
                } else if (val.indexOf(",") > 0) {
                    str = val
                        .split(',')
                        .join('%20');
                } else if (val.indexOf("*") > 0) {
                    str = val
                        .split('*')
                        .join('%20');
                } else if (val.indexOf(";") > 0) {
                    str = val
                        .split(';')
                        .join('%20');
                }

                this.setState({searchData: val, comboStr: str, Filter1: false});
            }
        }
        if (event.key == 'Esc' || event.keyCode == 27) {
            this.setState({Filter1: false, resultsTxtFlag: false});
        }
        if (event.key == 'Enter' || event.keyCode == 13) {
            this
                .deleteSocket
                .bind(this)();

            this.setState({temp: null, Filter1: false, resultsTxtFlag: false, errorRecordLocator: false});

            if (this.state.comboStr != "") {
                this.context.loadingimage = true;
                var searchInfo = this.state.comboStr;
                if (searchInfo.indexOf('/') != -1) 
                    searchInfo = searchInfo.replace("/", "%2F");
                var self = this;
                var alphaCheck = /^\d+$/i;
                this
                    .props
                    .ReactGA
                    .event({category: 'Search', action: "Searched based on Combo"});

                setAmplitudelogEvent("Search",
                    {
                        category: 'Search', 
                        action: "Combo"
                    }
                );
                searchInfo += "?restrict=" + this.props.restrict;
                if (this.state.comboStr.length == 6) {
                    HTTP
                        .get('/api/tripsummary/recordlocator/' + searchInfo)
                        .then(function (response) {

                            //
                            summaryData = response;

                            self.setState({temp: summaryData, Filter1: false});
                        });
                } else if (this.state.comboStr.indexOf("@") != -1) {
                    HTTP
                        .get('/api/tripsummary/email/' + searchInfo)
                        .then(function (response) {

                            //
                            summaryData = response;

                            self.setState({temp: summaryData, Filter1: false});
                        });
                } else if (alphaCheck.test(this.state.comboStr)) {
                    HTTP
                        .get('/api/tripsummary/phonenumber/' + searchInfo)
                        .then(function (response) {

                            //
                            summaryData = response;

                            self.setState({temp: summaryData, Filter1: false});
                        });
                } else if (this.state.comboStr.length != 6 && this.state.comboStr.length < 10) {
                    HTTP
                        .get('/api/tripsummary/travelername/' + searchInfo)
                        .then(function (response) {
                            //
                            summaryData = response;

                            self.setState({temp: summaryData, Filter1: false});
                        });
                } else {
                    HTTP
                        .get('/api/tripsummarytxt/' + searchInfo)
                        .then(function (response) {

                            //
                            summaryData = response;

                            self.setState({temp: summaryData, Filter1: false});
                        });
                }

                if (this.state.temp != null && this.state.temp.GetTripSummaryResponse != null) {
                    this.setState({ErrMsgFlag: false});
                } else {
                    this.setState({ErrMsgFlag: true});
                }

            } else {
                this.setState({Filter1: false});
            }
        }
    },
    toasterClick: function (msg, caseArr) {
        var recordLocator = msg.recordLocator === undefined ? msg.caseObj.recordLocator:msg.recordLocator;
        if (caseArr === undefined) {
            //this.getTripSummaryByRecordLocator(msg.recordLocator);
            this.getTripSummaryByRecordLocator(recordLocator,msg);
        } else {
            this.setState({notificationList: caseArr})
            //this.getTripSummaryByRecordLocator(msg.recordLocator, msg);
            this.getTripSummaryByRecordLocator(recordLocator, msg);
        }
    },
    redirectResetPasswordPage: function () {
        this.setState({page: 'ResetWithUserId', resetPasswordMessage: ''});
        //hashHistory.push('/ResetPassword/'+this.props.userId);
    },
    onchange: function (event) {
        this.context.pnrValue = undefined;
        var val = event.target.value;
        if (val == "" || val == " ") {
            this.context.TravelPlans = false;
            this.setState({resultsTxtFlag: false, ErrMsgFlag: false, temp: null, tempf: null, Validcheck: false});
        }
    },

    getCasesList:function(){
        this.refs.NotificationComponent.getNotification('all',"true",true);
    },

    openChatWindowMethod: function(url,recordLocator,randomnumber){
        var openedWindow = window.open(url,"chatPopup"+recordLocator,'PopUp'+randomnumber+',toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resize=no,resizable=yes, width=400,height=600');
        let chatList = Object.assign({}, this.state.openedChatList);
        chatList[recordLocator] = openedWindow;
        this.setState({openedChatList: chatList})
    },

    render: function () {
        var premGroupName = "";
        let Groups = "";
        var dataAccessGroup =[];
        var proactiveGroup=[];
        var orgName = null;
        if (Environment == "INTERNET") {
            var organizationName = localStorage.getItem('organizationName');
            var premAccessNameLocal =localStorage.getItem('premAccessName');
            let premAccessArray = premAccessNameLocal.split(',');
            orgName=organizationName ? organizationName : this.props.orgName;
            Groups =premAccessNameLocal ? premAccessArray : this.props.premAccess;
            if(Groups != undefined){
                for (var i = 0; i < Groups.length; i++) {
                    if (Groups[i].indexOf("PREM") != -1) {
                        proactiveGroup.push("SSO_PTC_DEFAULT_GROUP_" + Groups[i].split("-")[0] + "" + Groups[i].split("-")[1])
                        if (premGroupName != "") 
                            premGroupName = premGroupName + ",SSO_PTC_DEFAULT_GROUP_" + Groups[i].split("-")[0] + "" + Groups[i].split("-")[1];
                        else 
                            premGroupName = "SSO_PTC_DEFAULT_GROUP_" + Groups[i].split("-")[0] + "" + Groups[i].split("-")[1];

                    } else {
                        dataAccessGroup.push(Groups[i]);

                        } 

                    }
                }
            }
        else {
            Groups = this.props.restrict.split(",");
            for (var i = 0; i < Groups.length; i++) {
                if (Groups[i].indexOf("PREM") != -1) {
                    proactiveGroup.push(Groups[i]);
                    if (premGroupName != "") 
                        premGroupName = premGroupName + "," + Groups[i];
                    else 
                        premGroupName = Groups[i];

                } else {
                    dataAccessGroup.push(Groups[i]);

                    } 

                }
            }
        /*
        If user has elevated rights, show PTC Bell Icon
        If user has no elevated rights,User have no PREM1,2,3 access, Hide PTC Bell Icon
        If user has no elevated rights,User have PREM1,2,3 access, Show PTC Bell Icon
        */
        premGroupName = premGroupName == "" ? "NO_GROUP":premGroupName;
        if(this.props.userId==="gtradmin")
            premGroupName = "SSO_PTC_DEFAULT_GROUP_PREM3";
        var grantPtcAccess = false;
        if(Environment === "INTRANET") {
            if(this.props.elevatedPermissions ===true) {
                grantPtcAccess = true;
            } else {
                grantPtcAccess =  premGroupName === "NO_GROUP" || premGroupName === ""  ? false : true 
            }
        }
        if (this.state.page === 'ResetWithUserId') {
            return (<ActivationPage
                placeHolder={this.props.placeHolder}
                userId={this.props.userId}
                resetPasswordMessage={this.state.resetPasswordMessage}/>)
        }
        var NoTrips = false;
        var resultsTxtFlag = this.state.resultsTxtFlag;
        var classname = "none";
        if (this.state.temp != null) {
            this.context.DetailArray = this.state.temp;
            var countresults = "";
            if (this.context.DetailArray.length != undefined) 
                countresults = this.context.DetailArray.length;
            else {
                countresults = 0;
            }

            this.context.loadingimage = false;
            if (this.context.DetailArray == false) {
                this.context.TravelPlans = false;
                this.context.DetailArray = null;
                NoTrips = true;
                classname = "alert alert-danger alert-align";
                this.context.error = this.props.placeHolder.NO_RESULT;
            } else {
                if (this.context.DetailArray.length == 100) {}
                this.context.TravelPlans = true;
                classname = "none";
            }
        } else {
            this.context.TravelPlans = false;
            this.context.DetailArray = null;
        }

        if (this.state.Validcheck) {
            classname = "alert alert-danger";
        }
        var field = 'recordlocator';
        var emailField = 'email';
        var travelerField = 'knowmeid';
        var emailListField = 'emaillist';
        var url = window.location.href;
        if (this.context.pnrValue != '' && typeof this.context.pnrValue != 'undefined' && url.indexOf('?' + field + '=') != -1) {
            if (document.getElementById("seachbox-div")) {
                document.getElementById("seachbox-div").style.display = 'none';
            }
        }
        if (this.context.emailValue != '' && typeof this.context.emailValue != 'undefined' && url.indexOf('?' + emailField + '=') != -1) {
            if (document.getElementById("seachbox-div")) {
                document.getElementById("seachbox-div").style.display = 'none';
            }
        }
        if (this.context.knowmeidValue != '' && typeof this.context.knowmeidValue != 'undefined' && url.indexOf('?' + travelerField + '=') != -1) {
            if (document.getElementById("seachbox-div")) {
                document.getElementById("seachbox-div").style.display = 'none';
            }
        }
        if (this.context.emailListValue != '' && typeof this.context.emailListValue != 'undefined' && url.indexOf('?' + emailListField + '=') != -1) {
            if (document.getElementById("seachbox-div")) {
                document.getElementById("seachbox-div").style.display = 'none';
            }
        }
        if (url.indexOf('key') != -1) {
            // $('#seachbox-div').css('display','none');
            // $('#search-link').css('display','none');
        }
        var logoClass = '';
        if (this.props.placeHolder.ACTIVE == "Actif") {
            logoClass = "logoCanadian";
        } else {
            logoClass = "logoEnglish";
        }
        var socketKey = this.state.socket.id;
        if (this.state.errorRecordLocator) {
            this.context.error = this.props.placeHolder.NO_RESULT;
            classname = "alert alert-danger";
        }
        
        return (
            <div className="resolution_container">
                {Environment === "INTRANET" && <NoClientMessage isClientMessageOpen={this.state.isClientMessageOpen} closeClientMessage={this.closeClientMessage}/>}
                <div>
                    <ToasterNotification
                        ref='toasterNotificationRef'
                        toasterClick={this.toasterClick}/>
                    <ToastContainer
                        ref="container"
                        preventDuplicates={false}
                        toastMessageFactory={ToastMessageFactory}
                        className="toast-bottom-right"/>
                </div>
                <div id="container" className="searchbox-container">
                    <header>
                        <span id={logoClass}></span>
                        <ProfileComponent 
                            grantPtcAccess={grantPtcAccess} 
                            premGroup={premGroupName}
                            getCasesList={this.getCasesList} userId={this.props.userId} 
                            codeRed={this.props.codeRed} placeHolder={this.props.placeHolder} 
                            elevatedInfo={this.props.elevatedInfo} 
                            elevatedPermissions={this.props.elevatedPermissions} 
                            socket={this.state.socket} logout={this.props.logout} 
                            proactiveGroup={proactiveGroup} 
                            countryScope={this.props.countryScope}
                            dataAccessGroup={dataAccessGroup} orgName={orgName} environment={Environment} orgId={this.props.restrict}/>

                        <a href="../gbt_viewer_quick_reference.pdf" target="_blank" className="help_icon">&nbsp;</a>
                        {window
                            .location
                            .href
                            .indexOf('knowmeid') == -1 && window
                            .location
                            .href
                            .indexOf('emaillist') == -1 && window
                            .location
                            .href
                            .indexOf('email') == -1 && window
                            .location
                            .href
                            .indexOf('recordlocator') == -1 
                            ? <NotificationComponent
                                    ref="NotificationComponent"
                                    grantPtcAccess={grantPtcAccess} 
                                    restrict={this.props.restrict}
                                    getNotificationList={this.getNotificationList}
                                    premGroup={premGroupName}
                                    userId={this.props.userId}
                                    countryScope={this.props.countryScope}
                                    socket={this.state.socket}
                                    placeHolder={this.props.placeHolder}
                                    getTripSummaryByRecordLocator={this.getTripSummaryByRecordLocator}
                                    openChatWindowMethod={this.openChatWindowMethod.bind(this)}
                                    openedChatList={this.state.openedChatList}/>
                            : null
                        }
			<PoorInternetNotifier badInternet={this.state.badInternet}/>
                    </header>
                    <div className="holder">
                        <div
                            className="modal modal_delete fade"
                            id="passwordalert"
                            role="dialog"
                            data-backdrop="static"
                            data-keyboard="false">
                            <div className="modal-dialog">
                                <div className="modal-content password">
                                    <div className="modal-header">
                                        <div className="col-sm-12">
                                            <button type="button" className="close alert-close" data-dismiss="modal">&times;</button>
                                        </div>
                                    </div>
                                    <div className="modal-body modal-custom-body">
                                        <div className="col-sm-12">
                                            <h4 className="modal-title reset-header-color password-error-icon">Password Alert</h4>
                                        </div>
                                        <div className="col-sm-12 password-body-text">
                                            <p className="hello-text">
                                                Hi {this.props.userId},
                                            </p>
                                            <p>{this.props.reason}</p>
                                        </div>
                                        <div className="col-sm-12 password-warning-text">
                                            <p>Please reset your password by clicking on 'Reset Password' or select 'Not Now'.</p>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="password-button-block">
                                                <button
                                                    className="save_button reset-save-button enabled"
                                                    data-dismiss="modal"
                                                    onClick={this.redirectResetPasswordPage}>Reset Password</button>
                                                <br/>
                                                <br/>
                                                <a data-dismiss="modal" data-dismiss="modal">Not Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="searchContainer">
                            {this.context.loadingimage
                                ? <div className="loading_image">&nbsp;</div>
                                : null}

                            <span className={this.state.greybox} onClick={this.onClearClick}>{this.state.searchData}&nbsp;<img alt="close" src="/icons/close.png" onClick={this.clearClick}/></span>

                            {this.context.TravelPlans && !NoTrips && resultsTxtFlag 
                                ? <span className={this.state.greyWords}>{stringConvertion(this.props.placeHolder.RESULTS_COUNT, {"%noOfResult%": this.context.DetailArray.length})}
                                        <b>{this.state.category}</b>
                                    </span>
                                : null}
                            {this.state.greyWords == "searchBox-words-active" && this.context.TravelPlans && !NoTrips && resultsTxtFlag && this.context.DetailArray.length == 500
                                ? <span className="searchBox-words-activeresulslimit">Max.(500) Trips are
                                        displayed for this Search, If required please modify your search to display the
                                        desired results.</span>
                                : null}

                            <div className="input-group" id="seachbox-div">
                                <input
                                    id={`searchInput ${this.state.searchDataInput}`}
                                    type="text"
                                    onsearch="OnSearch(this)"
                                    className="form-control"
                                    onFocus={this.onFocus}
                                    onKeyUp={this.onKeyUp}
                                    onChange={this.onchange}/>
                                <span className="input-group-btn">
                                    <button
                                        className="btn btn-primary btn-search"
                                        type="button"
                                        onClick={this.onClick}></button>
                                </span>
                            </div>
                            <div className="clearfix">

                                <div>

                                    <a
                                        href="https://forms.office.com/Pages/ResponsePage.aspx?id=KYZb8w37hEiPOxsTfpcjyB0z0ptYZzJKqnG5cx5kh11UMkJVUEtQVEcyQzNPSlI5Qk5GVlBIOFQwOS4u"
                                        target="_blank"
                                        className="rotate"
                                        tabIndex='-1'>{this.props.placeHolder.FEEDBACK}</a>

                                </div>

                            </div>
                            {this.props.elevatedLogout && <LogoutPopup logout={this.props.logout} />}
                            {this.state.ErrMsgFlag && (NoTrips | this.state.errorRecordLocator) && !this.state.greyBoxclk
                                ? <Error text={this.context.error} classname={classname}/>
                                : null}
                            {this.state.Validcheck && !this.state.greyBoxclk
                                ? <Error text={this.context.valiadationError} classname={classname}/>
                                : null}
                            {!this.state.Filter1
                                ? null
                                : <Filter1
                                    placeHolder={this.props.placeHolder}
                                    onClickfilterfunction={this.onClickfilterfunction}
                                    Options={this.state.Options}
                                    onclick={this.filterFun}
                                    searchData={this.state.searchData}/>}

                            {this.context.TravelPlans && !this.state.greyBoxclk
                                ? <TravelPlans
                                        orgId={this.props.restrict}
                                        premGroup={premGroupName}
                                        openHreSegment={this.props.authToken
                                        ? true
                                        : false}
                                        userId={this.props.userId}
                                        userName={this.props.userName}
                                        settingsState={this.state.active}
                                        selectedNotifiedObject={this.state.selectedNotifiedObject}
                                        notificationList={this.state.notificationList}
                                        callApiNotification={this.callApiNotification}
                                        placeHolder={this.props.placeHolder}
                                        ReactGA={this.props.ReactGA}
                                        countresults={countresults}
                                        settingsState={this.state.active}
                                        emailquery={this.context.emailValue}
                                        pnr={this.context.pnrValue}
                                        traverleridQuery={this.context.knowmeidValue}
                                        emailListQuery={this.context.emailListValue}
                                        socket={this.state.socket}
                                        sID={this.state.socketID}
                                        TravelDataJson={this.context.DetailArray}
                                        searchData={this.state.searchData}
                                        openChatWindowMethod={this.openChatWindowMethod.bind(this)}
                                        openedChatList={this.state.openedChatList}/>
                                : null}

                        </div>
                    </div>
                </div>
                <Footer placeHolder={this.props.placeHolder}/>
            </div>
        );
    }
});
function getParameterByName(name, url) {
    if (!url) 
        url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) 
        return null;
    if (!results[2]) 
        return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
function stringConvertion(str, replacements) {
    return str.replace(/%\w+%/g, function (key) {
        return replacements[key] || key;
    });

}

export default SearchBox;
