import React from "react";

export default class NotesInStatusComments extends React.Component{
  constructor(props) {
    super(props);
    this.state = {CommentText:""};
  }

  //user Defined Methords
  _handleChange(event){
    this.setState({CommentText:event.target.value});
    this.props.Data(event.target.value)
  }
  shouldComponentUpdate(newProps,newState){
    if(newProps.Selected ==this.props.Selected && this.state==newState){
      return false;
    }
    return true
  }
  componentWillReceiveProps(newProps){
    if(newProps.reset !=this.props.reset){
      this.setState({CommentText:""})
    }
  }
  _resetNotes() {
    this.setState({CommentText:""})
  }

  render(){
    let message="";
    let errormsg="";
    let messageClass="col-sm-5 textarea_info";
    const maxCommentText_length = 90; //
    if(this.state.CommentText.length==0){
      message=this.props.placeHolder.MIN_MAX_LIMIT;
    }
    else{
      message=`${maxCommentText_length-this.state.CommentText.length} `+this.props.placeHolder.CHARACTERS_COUNT;
      // if(maxCommentText_length-this.state.CommentText.length>1||maxCommentText_length-this.state.CommentText.length<-1){
      //   message=`${maxCommentText_length-this.state.CommentText.length} `+this.props.placeHolder.CHARACTERS_COUNT;
      // }else{
      //     message=`${maxCommentText_length-this.state.CommentText.length} `+this.props.placeHolder.CHARACTERS_COUNT;
      // }
    }


    let Textclass="textarea";
    if(this.state.CommentText.length>maxCommentText_length){
      errormsg=this.props.placeHolder.ERROR_COUNT;
            Textclass="textarea error_border";
    }

    if(this.state.CommentText.length>maxCommentText_length){
      messageClass=messageClass+" error";
    }
    if(!validateNotes(this.state.CommentText)){
      Textclass="textarea error_border";
      errormsg=this.props.placeHolder.ERROR_CHARACTERS;
    }


    return(
      <div className="col-sm-12"><br/><br/>
        <div className="col-sm-7 margin_btm_5">      {this.props.notesLabel} </div>
        <div className={messageClass}>     {message}</div>
        <br/>
        <textarea value={this.state.CommentText} onChange={this._handleChange.bind(this)} className={Textclass}></textarea>
        <div className="col-sm-8 error"> {errormsg} </div>
      </div>
    );
  }
}


//Validation for Special characters in notes TextBox
function validateNotes(Text) {
  if(Text!=""){

    //Regex Exp including caracters,Numbers,hypens,commas,periods
    let re =/^[a-zA-Z0-9\n .,-]*$/g;
    return re.test(Text);
  }else{
    return true;
  }
}
