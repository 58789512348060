import React, { Component } from 'react';
import CommunicationTable from './CommunicationTable';
import HTTP from "../../../services/httpservice.js";
import { groupByCommunicationId } from '../../../../utils/utils.js';
import GroupedCommunication from './GroupedCommunication.js';

class CommunicationTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeButton: "All",
            communicationLogs:null,
            innerSrchTxt: '',
            innersrchFlg: false,
            spinner: false,
            groupCommunicationToggle: true,
            groupedCommunicationLogs: "",
            disabledGroupCommunicaitonToggle: false
        };
    }
    componentDidMount() {
        let self = this;
        self.setState({ spinner: true })
        const getCommunicationLogs = async () => {
            self.setState({ spinner: true });
            const response = await HTTP.get('/communicationlogs/' + this.props.knowmeId + '/v1')
            if (response.success === "true") {
                self.setState({ communicationLogs: response.communicationLogs })
                self.setState({ groupedCommunicationLogs: groupByCommunicationId(response.communicationLogs) })
                self.setState({ spinner: false })
            } else {
                self.setState({ communicationLogs: [] })
                self.setState({ spinner: false })
            }
        }
        getCommunicationLogs();
    }
    _onFocus() {
        this.setState({ groupCommunicationToggle: false,disabledGroupCommunicaitonToggle:true });
        window.$(".searchBox-grey-active.secondary").css('display', 'none');
        this.setState({ innerSrchTxt: "", innersrchFlg: false });
    }

   _onChangeTxt() {
        this.setState({ innerSrchTxt: "", innersrchFlg: false });
    }
    _onKeyUp(event) {
        var val = event.target.value;
        if (val != "" || val != " ") {
            this.setState({ innerSrchTxt: val });
            if (event.key == 'Enter') {
                this.setState({
                innersrchFlg: true
                });
                if (document.getElementById('comStatusSrch').value != "" && document.getElementById('comStatusSrch').value != " ") {
                    window.$('.secondary').show();
                    window.$('.secondary span').text(val);
                    window.$('.pnr_searchbar').val(val);
                    window.$('.pnr_searchbar').blur();
                }
            } else {
                this.setState({innerSrchTxt: "",innersrchFlg: false});
            }
        } else {
            this.setState({innersrchFlg: false });
        }
    }
    _onClose() {
        this.setState({innersrchFlg: false,innerSrchTxt: ""});
        this.props.clearFilter("");
        window.$('.pnr_searchbar').val("");
        window.$('.secondary').hide();
        window.$('.pnr_searchbar').focus();
    }
    _onclicksrch() {
        var val = document.getElementById('comStatusSrch').value;
        if (val != "" && val != " ") {
            this.setState({ innerSrchTxt: val,innersrchFlg: true});
            window.$('.secondary').show();
            window.$('.secondary span').text(val);
            window.$('.pnr_searchbar').val(val);
        } else {
            this.setState({innersrchFlg: false});
        }
    }

      formatDate(insertedDate) {
        if(insertedDate !== undefined && insertedDate !== "") {
            let monthNames = [
                "","JAN","FEB", "MAR",
                "APR", "MAY", "JUN", "JUL",
                "AUG", "SEP", "OCT",
                "NOV", "DEC"
            ];
            var holidaySplit = insertedDate.split('/')
            var dateValue = holidaySplit[1];
            var year = holidaySplit[2].substring(2,4);
            let monthVal = holidaySplit[0].substring(0,1) == 0 ? monthNames[holidaySplit[0].substring(1,2)] : monthNames[holidaySplit[0]];
            return dateValue+monthVal+year;
        } else {
            return ""
        }
    }
    render() {
        let { activeButton,communicationLogs,innersrchFlg,innerSrchTxt} =this.state;
        let allButtonClass = 'btn btn-primary btn-travel com-button';
        let sentButtonClass = 'btn btn-primary btn-travel com-button';
        let receivedButtonClass = 'btn btn-primary btn-travel com-button';
        let displayData = communicationLogs;
        switch (activeButton){
            case 'All':
                allButtonClass += ' active';
            break;
            case 'Sent':
                sentButtonClass += ' active';
                if(displayData !== undefined && displayData!== null) 
                    displayData = displayData.filter(function(item,i){
                        return item.messageFlow === "O";
                    })
            break;
            case 'Received':
                receivedButtonClass += ' active';
                if(displayData !== undefined && displayData!== null) 
                    displayData = displayData.filter(function(item,i){
                        return item.messageFlow === "I";
                    })
            break;
        }
        if(innersrchFlg && displayData !== null) {  
            var self= this;
            displayData = displayData.filter(function(item) {
                if(item.insertedDateTime != undefined && item.channelAddress != undefined && innerSrchTxt != undefined){
                    return self.formatDate(item.insertedDateTime.substring(0,10)).toUpperCase().indexOf(innerSrchTxt.toUpperCase()) !== -1 ||
                    item.channelAddress.toUpperCase().indexOf(innerSrchTxt.toUpperCase()) !== -1 ||
                    item.channel.toUpperCase().indexOf(innerSrchTxt.toUpperCase()) !== -1
                }
            });
        }
        return (
            <div className="sort">
                        <div className="sort_inner communication-sort">
                    <button className={allButtonClass} onClick={() => this.setState({ activeButton: "All", groupCommunicationToggle: true, disabledGroupCommunicaitonToggle: false })}>{this.props.placeHolder.ALL}</button>
                    <button className={sentButtonClass} onClick={() => this.setState({ activeButton: "Sent", groupCommunicationToggle: false, disabledGroupCommunicaitonToggle: true })} >{this.props.placeHolder.SENT}</button>
                    <button className={receivedButtonClass} onClick={() => this.setState({ activeButton: "Received", groupCommunicationToggle: false, disabledGroupCommunicaitonToggle: true })}>{this.props.placeHolder.RECEIVED}</button>
                </div>
                {
                        !this.state.disabledGroupCommunicaitonToggle ?
                <div className='' >
                            <div className="col-md-1 col-sm-1" id="">
                                <label className="switch">{
                                    <input type="checkbox"
                                        id="togBtn"
                                        checked={this.state.groupCommunicationToggle}
                                        onChange={(e) => this.setState({ groupCommunicationToggle: !this.state.groupCommunicationToggle })}
                                    />
                                }
                                    <div className="langslider round">
                                        <span className="langon">ON</span>
                                        <span className="langoff">OFF</span>
                                    </div>
                                </label>
                            </div>
                            <p>Group Communications</p>
                </div>
                : null
            }
                    <div className="communication-search">
                        <input type="text" id="comStatusSrch" 
                            onClick={this._onFocus.bind(this)} 
                            onFocus={this._onFocus.bind(this)} 
                            onChange={this._onChangeTxt.bind(this)} 
                            onKeyUp={this._onKeyUp.bind(this)} 
                        className="form-control pnr_searchbar" tabIndex="-1" placeholder="Find Channel, Email, Phone Number" ref='srchBox' />
                        <span className="input-group-btn">
                            <button className="summary_search_button" onClick={this._onclicksrch.bind(this)} tabIndex="-1" type="button"></button>
                        </span>
                        <span className="searchBox-grey-active secondary" onClick={this._onFocus.bind(this)}>
                            <span>&nbsp;</span>
                            <img alt="close" onClick={this._onClose.bind(this)} className="close_greybox" src="/icons/close.png" />
                        </span>
                    </div>
                    {
                    this.state.spinner ? <div className="loading_image trip_loader">&nbsp;</div>
                        : <div>
                            {
                                this.state.communicationLogs !== null && this.state.communicationLogs !== undefined
                                    ?
                                    this.state.groupCommunicationToggle
                                        ?
                                        <GroupedCommunication groupedCommunicationLogs={this.state.groupedCommunicationLogs} {...this.props} groupCommunicationToggle={this.state.groupCommunicationToggle}></GroupedCommunication>
                                        :
                                        <CommunicationTable communicationLogs={displayData} {...this.props} />

                                    :
                                    <div className="alert alert-danger-notification" role="alert">
                                        No Communication logs found
                                    </div>
                            }
                        </div>
                }
                </div>
        );
    }
}

export default CommunicationTab;