import React from "react";
import createClass from 'create-react-class';
let PopElipsisClientName  = createClass({
render: function () {
      return (
            <div className="hidden" id={"clientname_"+this.props.travelerId}>
                <div className='fade in popover bottom pop_over_name email_popup'>
                  <div className='arrow'></div>
                  <div className='popover-content'>
                      <div className='popover_custom'>
                      {this.props.email}
                      </div>
                  </div>
                </div>
            </div>
      );
      }
})
export default PopElipsisClientName;
