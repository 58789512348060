export const Environment=process.env.REACT_APP_Environment;
export const EnvironmentDeployment=process.env.REACT_APP_EnvironmentDeployment;
export const Google_Analytics_Deployment=process.env.REACT_APP_Google_Analytics_Deployment;
export const Amplitude_Deployment =process.env.REACT_APP_Amplitude_Deployment;


console.log('print the env variables ', Environment, EnvironmentDeployment, Google_Analytics_Deployment, Amplitude_Deployment)
export const recapcha_site_key=function(){
    switch(EnvironmentDeployment){
        case "E1":
            return "6LeAdiQUAAAAAPFQk6mVPxDZEi_3aEOQGU-9Rvsq";
        case "E2":
            return "6LeAdiQUAAAAAPFQk6mVPxDZEi_3aEOQGU-9Rvsq";
        case "E3":
             return "6Lc8eCQUAAAAAK_bTJ2ZFARJ4pjtWr_ElTBEjLsg";
    }
}
export const hcapcha_site_key=function(){
    switch(EnvironmentDeployment){
        case "E1":
            return "bf5c3d50-d417-46d2-b846-4d3afff0d4ea";
        case "E2":
            return "bf5c3d50-d417-46d2-b846-4d3afff0d4ea";
        case "E3":
             return "9b41cd8e-bb6e-421d-9ac9-a1f861f4c949";
    }
}

export const chatUrl=function(){
    switch(EnvironmentDeployment){
        case "E0":
            return "http://localhost:8095";
        case "E1":
            return "https://smschat.dev.gbt.gbtad.com";
        case "E2":
             return "https://smschat.cert.gbt.gbtad.com";
        case "E3":
            return "https://smschat.gbt.gbtad.com";
    }
}

export const analytics_tracking_ID=function(){
  switch(Google_Analytics_Deployment){
    case "E1_INTRANET":
       return "UA-90107630-1";
    case "E1_INTERNET":
        return "UA-93076036-1"
    case "E2_INTERNET":
        return "UA-100961372-1";
    case "E2_INTRANET":
        return "UA-100962425-1";
    case "E3_INTERNET":
        return "UA-100943427-1";
    case "E3_INTRANET":
        return "UA-100934124-1";
  }
}

export const amplitudeApiKey=function(){
    switch(Amplitude_Deployment){
      case "E1":
         return "7024f9276693d058c3e219a81d6faddf";
      case "E2":
          return "7024f9276693d058c3e219a81d6faddf";
      case "E3":
          return "2747840bbf9e9e1a3af00982c13dbbaf";
    }
  }
