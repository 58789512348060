/**
  * @ UserName Component
  * @ File - userName.js
  * Username related functionality
  */
import React, { Component } from 'react';
import HTTP from '../../services/httpservice';
import UserNameSuggetion from './UserNameSuggetion';

const alphaNumerSpecRegex         =    /^[a-zA-Z0-9@#$.]+$/i;
/* Define Constants */
const USER_VALIDATE_URL           =   'utilities/users/validation/v1';
const ERROR_MESSAGE_FOR_NUM_CHAR  =   'Username must be between 7 - 10 characters';
const HELPER_TEXT                 =   'min. 7 & max. 10 characters, must contain alpha,numeric, and a special character(@,#,$,.) and can\'t be same as email address.';
const ERROR_OTHER_CHARACTERS      =   'Only @,#,$,. special characters are allowed';
const ERROR_SAME_AS_EMAIL         =   'Username cannot be same as email address';
const FORMCLASS                   =   'form-control';
const FORM_ERROR_CLASS            =   'form-control error-icon';
const FORM_SUCESS_CLASS           =   'form-control success-icon';

class Username extends Component {

  // Constructor called before render function
  // The object that is returned is assigned to  this.state
    constructor(props) {
        super(props);
        this.state = {
            userValue         : '',
            errorMsg          : '',
            userNameSugession : [],
            formClass         : FORMCLASS
        }
    }

    // Validation - Username field
    // Set error messages according to the criteria
    validateUsernameField() {
        let self = this;
        if (this.state.userValue.length < 7 || this.state.userValue.length > 10) {
            this.setState({
                errorMsg    : ERROR_MESSAGE_FOR_NUM_CHAR,
                formClass   : FORM_ERROR_CLASS
            })
        }
        else if(!alphaNumerSpecRegex.test(this.state.userValue)){
          this.setState({
              errorMsg    : ERROR_OTHER_CHARACTERS,
              formClass   : FORM_ERROR_CLASS
          })
        }
        else if(this.props.emailvalue==this.state.userValue){
          this.setState({
              errorMsg    : ERROR_SAME_AS_EMAIL,
              formClass   : FORM_ERROR_CLASS
          })
        }
        else {
            HTTP.get(USER_VALIDATE_URL+'?firstname='+this.props.fnamevalue+'&lastname='+this.props.lnamevalue+'&email='+this.props.emailvalue+'&userid='+this.state.userValue)
              .then(function (response) {
                if (response.Success==='false') {
                    self.setState({
                        userNameSugession : response.Suggestions,
                          errorMsg          : '',
                          formClass         : FORM_ERROR_CLASS
                      })
                  } else {
                      self.setState({
                          errorMsg  : '',
                          formClass : FORM_SUCESS_CLASS
                      });
                      self.props.setValue('username',self.state.userValue);
                  }
              })
        }
    }

    // Set Username value on change
    // Set state value
    _setUserName(e) {
        this.setState({ userValue: e.target.value });
        if (this.props.valid) {
            this.props.setFalse('username');
        }
    }
    _resetUserName(response) {
        let suggestions_array = response.split(',');
        this.setState({
            userValue         : '',
            errorMsg          : '',
            userNameSugession : suggestions_array,
            formClass         : FORM_ERROR_CLASS
        });
        if (this.props.valid) {
            this.props.setFalse('username');
        }
    }

    // Render username component
    // Child component - UserNameSuggetion
    render() {
        return (<div>
                    <div className="form-group">
                        <label for="userName">User Name*:</label>
                        <input type="text" value={this.state.userValue} disabled={!(this.props.fnamevalue!="" && this.props.lnamevalue!="" && this.props.emailvalue!="")} onBlur={this.validateUsernameField.bind(this)} onChange={this._setUserName.bind(this)} className={this.state.formClass} id="userName" placeholder="e.g., Smmith$1245" />
                        { this.state.errorMsg != '' ? <p className='error'>{this.state.errorMsg}</p> : null }
                        <p className="info-text">{HELPER_TEXT}</p>
                        { this.state.userNameSugession.length !== 0 ? <UserNameSuggetion setUserName={(userValue) => { this.setState({ userValue, userNameSugession: [], formClass: FORM_SUCESS_CLASS }); this.props.setValue('username',userValue); } } userNameSugession={this.state.userNameSugession} /> : null  }
                    </div>
                </div>);
    }
}


export default Username;
