import React from "react";
import Filter from "../Filter.js";
import createClass from 'create-react-class';
var Filter1 = createClass({
  onClickfunction:function(type){
    this.props.onClickfilterfunction(type)
  },
  onKeyUp: function (event) {

        if(event.keyCode==38||event.keyCode==40){
          var list=document.getElementById("filter_options");
          if(list!=null){
            var first=list.firstChild;
            var last=list.lastChild;
            var maininput=document.getElementById("searchInput");
            if(event.keyCode==38){
              if (document.activeElement == (maininput || first)) {

                } // stop the script if the focus is on the input or first element
              else {
                if(document.activeElement.previousSibling==null){
                  last.focus();
                }else{
                document.activeElement.previousSibling.focus();
              }
               } // select the element before the current, and focus it;
            }else if(event.keyCode==40){
                if (document.activeElement == maininput) {
                  first.focus();
                 } // if the currently focused element is the main input --> focus the first <li>
                else {
                  if(document.activeElement.nextSibling==null){
                    first.focus();
                  }else{
                    document.activeElement.nextSibling.focus();
                  }

                 } // target the currently focused element -> <a>, go up a node -> <li>, select the next node, go down a node and focus it
            }
          }
        }
          if(event.key == 'Enter' || event.keyCode==13){
            var type=document.activeElement.childNodes[0].getElementsByTagName("B")[0].getAttribute("name");
            this.props.onClickfilterfunction(type)
          }
      },
  render: function () {
    var temp=(<div></div>);
    var t=this.onClickfunction;
    var self=this;
    var searchData=this.props.searchData;
    temp=this.props.Options.map(function (tData ,i) {
      return (<Filter key={i} placeHolder={self.props.placeHolder} searchData={searchData} type={tData} updateFilter={t} />)
    });
    return(
      <div onKeyUp={this.onKeyUp} className="filter-search">
        <ul ref ="filter_options" id="filter_options">
          {temp}
        </ul>
      </div>
    )
  }
});

export default Filter1;
