/**
  * @ Password Component
  * @ File - passwordField.js
  * Password Functionality
  */
import React, { Component } from 'react';

/* Define Constants */
const alphaRegex                      = /[a-zA-Z]/i
const numberRegex                     = /[0-9]/i
const specialRegex                    = /[@#$]/i
const ERROR_MESSAGE_FOR_NUM_CHAR      = 'Password must be between 8 - 12 characters';
const ERROR_MESSAGE_CONFIRM_PASSWORD  = 'Confirm Password must match Password';
const ERROR_MESSAGE_FOR_PASSWORD      = "Password must contain 3 of these options:"
const ERROR_MESSAGE_FOR_PASSWORD_OPTION_ONE = "uppercaseletter";
const ERROR_MESSAGE_FOR_PASSWORD_OPTION_TWO = "lowercaseletter";
const ERROR_MESSAGE_FOR_PASSWORD_OPTION_THREE = "number";
const ERROR_MESSAGE_FOR_PASSWORD_OPTION_FOUR = "special characters of @ # $";
const ERROR_MESAGE_CANNOT_BE_USERNAME = 'Password cannot be the same as User Name';
const FORMCLASS                       = 'form-control';
const FORM_ERROR_CLASS                = 'form-control error-icon';
const FORM_SUCCESS_CLASS               = 'form-control success-icon';

class PasswordField extends Component {

    // Constructor called before render function
    // The object that is returned is assigned to  this.state
    constructor(props) {
        super(props);
        this.state = {
            passwordValue           : '',
            confirmpassword         : '',
            errrMsg                 : '',
            errrMsgConfirmPass      : '',
            formClassForPass        : FORMCLASS,
            formClassForConfirmPass : FORMCLASS,
            errorOption1 :'',
            errorOption2 :'',
            errorOption3 :'',
            errorOption4 :''
        }
    }

    // Validate Confirm password field
    //Check password & confirm password are same or not
    _validateconfirmPasswordField() {
        if (this.state.passwordValue !== this.state.confirmpassword || this.state.passwordValue == '' || this.state.confirmpassword == '') {
            this.setState({
                errrMsgConfirmPass      : ERROR_MESSAGE_CONFIRM_PASSWORD,
                formClassForConfirmPass : FORM_ERROR_CLASS
            })
        } else {
            this.setState({
                errrMsgConfirmPass        : '',
                formClassForConfirmPass   : FORM_SUCCESS_CLASS
            });
            this.props.setValue('password',this.state.passwordValue);
        }
    }

    // Password Field validation
    //Set messages depends on validation
    _validatePasswordField() {
        let count = this.validateOptionsCount(this.state.passwordValue);
        if (this.state.passwordValue.length < 8 || this.state.passwordValue.length > 12) {
            this.setState({
                errrMsg           : ERROR_MESSAGE_FOR_NUM_CHAR,
                formClassForPass  : FORM_ERROR_CLASS
            })
        } else if(count <3) {
            this.setState({
                errrMsg           : ERROR_MESSAGE_FOR_PASSWORD,
                errorOption1      : ERROR_MESSAGE_FOR_PASSWORD_OPTION_ONE,
                errorOption2      : ERROR_MESSAGE_FOR_PASSWORD_OPTION_TWO,
                errorOption3      : ERROR_MESSAGE_FOR_PASSWORD_OPTION_THREE,
                errorOption4      : ERROR_MESSAGE_FOR_PASSWORD_OPTION_FOUR,
                formClassForPass  : FORM_ERROR_CLASS
            })
        } else {
            this.setState({
                errrMsg           : '',
                errorOption1      : '',
                errorOption2      : '',
                errorOption3      : '',
                errorOption4      : '',
                formClassForPass  : FORM_SUCCESS_CLASS
            });
        }        
    }

    validateOptionsCount(passwordValue) {
        let count =0;
        if (/[a-z]/.test(passwordValue)) {
            count=count+1;
        } 
        if (/[A-Z]/.test(passwordValue)) {
            count=count+1;
        }  
        if (numberRegex.test(passwordValue)) {
            count=count+1;
        }  
        if (specialRegex.test(passwordValue)) {
            count=count+1;
        }
        return count;
    }

    // Enable or Disable confirm password field depending on the criteria
    ValidateDisable() {
        let count = this.validateOptionsCount(this.state.passwordValue)
        if (this.state.passwordValue.length < 8 || this.state.passwordValue.length > 12) {
            return false
        } else if (count <3) {
            return false
        } else {
            return true;
        }
    }

    // Set password value
    //Change state value
    _setPassword(e) {
        if (this.props.valid) {
            this.props.setFalse('password');
        }
        this.setState({
            passwordValue   : e.target.value,
            confirmpassword : ''
        })
        if(this.state.formClassForConfirmPass==FORM_SUCCESS_CLASS){
                this.setState({
                errrMsgConfirmPass      : ERROR_MESSAGE_CONFIRM_PASSWORD,
                formClassForConfirmPass : FORM_ERROR_CLASS
            })
        }
    }

    // Set confirm password value
    // Change state value
    _setConfirmPassword(e) {
        if (this.props.valid) {
            this.props.setFalse('password');
        }
        this.setState({ confirmpassword   : e.target.value })
    }

    _resetPasswordAndConfirmPassword() {
        this.setState({
            passwordValue           : '',
            confirmpassword         : '',
            formClassForPass        : FORM_ERROR_CLASS,
            formClassForConfirmPass : FORM_ERROR_CLASS,
            errrMsg                 : ERROR_MESSAGE_FOR_NUM_CHAR,
            errrMsgConfirmPass      : ERROR_MESSAGE_CONFIRM_PASSWORD,
        })
    }
    // Render password Component
    render() {
        return (<div>
                    <div className="form-group">
                        <label for="pwd">New Password:</label>
                        <input className={this.state.formClassForPass} type="password" onPaste={(e) => e.preventDefault()} value={this.state.passwordValue} onBlur={this._validatePasswordField.bind(this)} onChange={this._setPassword.bind(this)} id="pwd" />
                        <span className="password-helper-text">Minimum 8 Characters including 3 of these: 
                        uppercase letter,lowercase letter,number,or special characters of @ # $</span>
                        { this.state.errrMsg != '' ? <p className='error'>{this.state.errrMsg}</p> : null }
                        { this.state.errorOption1 != '' ? <p className='error error-option'>{this.state.errorOption1}</p> : null }
                        { this.state.errorOption2 != '' ? <p className='error error-option'>{this.state.errorOption2}</p> : null }
                        { this.state.errorOption3 != '' ? <p className='error error-option'>{this.state.errorOption3}</p> : null }
                        { this.state.errorOption4 != '' ? <p className='error error-option'>{this.state.errorOption4}</p> : null }
                    </div>
                    <div className="form-group">
                        <label for="cpwd">Confirm New Password:</label>
                        <input type="password" disabled={!(this.state.passwordValue !== '' && this.ValidateDisable())} onPaste={(e) => e.preventDefault()} onBlur={this._validateconfirmPasswordField.bind(this)} value={this.state.confirmpassword} onChange={this._setConfirmPassword.bind(this)} className={this.state.formClassForConfirmPass} id="cpwd" />
                        { this.state.errrMsgConfirmPass != '' ? <p className='error'>{this.state.errrMsgConfirmPass}</p> : null }
                    </div>
                </div>);
    }
}
export default PasswordField;
