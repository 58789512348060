import React from "react";
import createClass from 'create-react-class';
let Secondaryfilter = createClass({
  onclickiconAir: function(){
    this.props.onclickicon("Air");
  },
  onclickiconCar: function(){
    this.props.onclickicon("Car");
  },
  onclickiconHotel: function(){
      this.props.onclickicon("Hotel");
    },
  onclickiconOther: function(){
        this.props.onclickicon("Other");
  },

  render: function () {
    var temp;
    var self=this;
    if(typeof(this.props.array)!="undefined"){
        temp=this.props.array.map(function(com,i){
          switch(com){
            case "Air":
              return(<div key={i} className={self.props.iconCss.Air} onClick={self.onclickiconAir}></div>);
              break;
            case "Car":
              return(<div key={i} className={self.props.iconCss.Car} onClick={self.onclickiconCar}></div>);
              break;
            case "Hotel":
              return(<div key={i} className={self.props.iconCss.Hotel} onClick={self.onclickiconHotel}></div>);
              break;
            case "Other":
              return(<div key={i} className={self.props.iconCss.Other} onClick={self.onclickiconOther}></div>);
            break;
          }
        });
    }

    return (
      <div className="margin_rgt_5 icon_filter">
          {temp}
      </div>
    )
  }
});


export default Secondaryfilter;
