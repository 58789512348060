import React from "react";
import PopElipsisName from "../../PopElipsisName.js"
import CallStatusAndCommentsSelection from "../CommonModels/CallStatusAndCommentsSelection";

export default class AddStatusCommentsPopUp extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      popupheahing:false,
      popUpleft:false,
      reset:false,
      result : ''
    };
    this.popMover = this.popupheahing.bind(this);
    this.popMout = this.disablepopupheahing.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  focusModal(){
    var key = "qwert_"+this.props.uniqueId;
    this.refs[key].focusModal();
  }
  disablepopupheahing(){
          this.setState({popupheahing:false});

   }
   _onClickCancelButton(){
     this.setState({
       reset:!this.state.reset
     })
   }
  popupheahing(){
      if(this.state.popUpleft){
        this.setState({popupheahing:false});
      }else{
        this.setState({popupheahing:true});
      }
    }
     ClickCancel(){

       this.setState({reset:!this.state.reset});
    }

    handleClick(count) {
      this.setState({result : count});
    }
    onKeyPressEvent() {
      window.$('.modal-add').keydown(function(e) {
          if(window.$('.last').is(":focus") && (e.which || e.keyCode) == 9) {
              e.preventDefault();
              window.$('.first').focus();
          }
       });
    }
  render () {
    var popupData = this.props.popupdata;
    if(typeof(this.props.popupdata) != "undefined"){
      var popUpName = this.props.userProfileData.Name;
      var EmployeeId= this.props.userProfileData.EmployeeId;
      var popUpClientName = this.props.userProfileData.ClientName;
      var popUpClientId = this.props.userProfileData.ClientId;
      var intractive = "",popUpHeading="";
      //var state = this.props.state;
      var headingvar = "";
      var showpopup = false;
      if(popupData.BookingInfo.Interactive=="true"){
        intractive="Online"
      }else if(popupData.BookingInfo.Interactive=="false"){
        intractive="Offline"
      }

      var source = '';
     if(typeof popupData.BookingInfo.Source != "undefined") {
       source = popupData.BookingInfo.Source;
     } else {
       source = popupData.BookingInfo.AtlasGDS;
     }
      switch(popupData.Type){
      case "Air":
        popUpHeading = (<h4 className="travel-data">{popupData.DepartureAirport}<span className="to-arrow"></span>{popupData.ArrivalAirport}<span className="trip-pnr-info"><br/>{this.props.DispTripId}<br/>{popupData.BookingInfo.RecordLocator}/{popupData.BookingInfo.AtlasGDS}<br/>{popupData.BookingInfo.pcc}/{intractive} </span></h4>);
        break;
      case "Hotel-CheckIn":
      case "Hotel-CheckOut":
        if(this.state.popupheahing==true){
          headingvar =popupData.Chain+", "+popupData.Name+", "+popupData.CityCode;
          if(headingvar.length>20){
            showpopup=true;
          }
        }
        popUpHeading = (<h4 className="travel-data"><span className="hotel_width" onMouseOver={this.popMover}  onMouseOut={this.popMout}>{popupData.Chain}, {popupData.Name}, {popupData.CityCode}</span><span className="trip-pnr-info"><br/>{this.props.DispTripId}<br/>{popupData.BookingInfo.RecordLocator}/{popupData.BookingInfo.AtlasGDS}<br/>{popupData.BookingInfo.pcc}/{intractive} </span></h4>);
        break;
      // case "Hotel-CheckOut":
      //   if(this.state.popupheahing==true){
      //     headingvar =popupData.Chain+", "+popupData.Name+", "+popupData.CityCode;
      //     if(headingvar.length>20){
      //       showpopup=true;
      //     }
      //   }
      //   popUpHeading =(<h4 className="travel-data"><span className="hotel_width" onMouseOver={this.popMover}  onMouseOut={this.popMout}>{popupData.Chain}, {popupData.Name}, {popupData.CityCode}</span><span className="trip-pnr-info"><br/>{this.props.DispTripId}<br/>{popupData.BookingInfo.RecordLocator}/{popupData.BookingInfo.AtlasGDS}<br/>{popupData.BookingInfo.pcc}/{intractive} </span></h4>);
      //   break;
      case "Car-PickUp":
        if(this.state.popupheahing==true){
          headingvar =popupData.VendorCode +", "+ popupData.VendorCompany+", "+popupData.PickupCityCode;
          if(headingvar.length>20){
            showpopup=true;
          }
        }
        popUpHeading = (<h4 className="travel-data"><span className="hotel_width" onMouseOver={this.popMover}  onMouseOut={this.popMout}>{popupData.VendorCode} {popupData.VendorCompany=="" || popupData.VendorCompany==null?null:","} {popupData.VendorCompany} {popupData.PickupCityCode=="" || popupData.PickupCityCode==null?null:","} {popupData.PickupCityCode}</span><span className="trip-pnr-info"><br/>{this.props.DispTripId}<br/>{popupData.BookingInfo.RecordLocator}/{popupData.BookingInfo.AtlasGDS}<br/>{popupData.BookingInfo.pcc}/{intractive}</span></h4>);
        break;
      case "Car-DropOff":
        if(this.state.popupheahing==true){
          headingvar =popupData.VendorCode +", "+ popupData.VendorCompany+", "+popupData.DropOffCityCode;
          if(headingvar.length>20){
            showpopup=true;
          }
        }
        popUpHeading=(<h4 className="travel-data"><span className="hotel_width" onMouseOver={this.popMover}  onMouseOut={this.popMout}>{popupData.VendorCode} {popupData.VendorCompany=="" || popupData.VendorCompany==null?null:", "} {popupData.VendorCompany} {popupData.DropOffCityCode=="" || popupData.DropOffCityCode==null?null:","} {popupData.DropOffCityCode}</span><span className="trip-pnr-info"><br/>{this.props.DispTripId}<br/>{popupData.BookingInfo.RecordLocator}/{popupData.BookingInfo.AtlasGDS}<br/>{popupData.BookingInfo.pcc}/{intractive} </span></h4>);
        break;
      case "Limo":
      if(this.state.popupheahing==true){
          headingvar =popupData.LimoVendorCode+","+popupData.LimoVendorName+((popupData.LimostartCityCode == "" ) ? "" : ",")+popupData.LimostartCityCode;
          if(headingvar.length>20){
            showpopup=true;
          }
        }
        popUpHeading = ( <h4 className="travel-data"><span className="limo_width" onMouseOver={this.popMover}  onMouseOut={this.popMout}>{popupData.LimoVendorCode},{popupData.LimoVendorName},{popupData.LimostartCityCode}</span><span className="trip-pnr-info"><br/>{this.props.DispTripId}<br/>{popupData.BookingInfo.RecordLocator}/{popupData.BookingInfo.AtlasGDS}<br/>{popupData.BookingInfo.pcc}/{intractive} </span></h4>);
        break;
      case "Rail":
        popUpHeading = (<h4 className="travel-data">{popupData.departureCode}<span className="to-arrow"></span>{popupData.arrivalCode}<br/><span className="trip-pnr-info">{this.props.DispTripId}<br/>{popupData.BookingInfo.RecordLocator}/{popupData.BookingInfo.AtlasGDS}<br/>{popupData.BookingInfo.pcc}/{intractive} </span></h4>);
        break;
      }

    }
    if(typeof(this.props.tripmodeclass) != "undefined"){
      var tripmodeclass = this.props.tripmodeclass;
    }
    if(typeof(this.props.uniqueId) != "undefined"){
      var tripmodelid = "AddPopup"+this.props.uniqueId;
    }

    return(
      <div className="modal fade modal-add" id={tripmodelid} onKeyUp={this.onKeyPressEvent.bind()}   role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog">
          <div className="modal-content comment_popup">
            <div className="modal-header">
            <div className="col-sm-12">    <button type="button" onClick={this._onClickCancelButton.bind(this)} className="close" data-dismiss="modal">&times;</button>
              <h4 className="modal-title">{this.props.placeHolder.ADD_STATUS_COMMENTS}</h4>
            </div>  </div>
            <div  className="modal-body">
              <div className="col-sm-12">
                <div className="name_container">
                  <h4 className="client">{popUpName}</h4>

                  <h4 className="employee_id"><span className="color_blue_normal">{EmployeeId}</span> {popUpClientName} {popUpClientName==null || popUpClientId==null? null : ", "}   <span className="client-id"><span>{popUpClientId}</span></span>

                  </h4><br/><br/>
                </div>
                <div className="trip row">
                  <div className="side-icon col-sm-1">
                    <div className="top-line no-topline"></div>
                    <div className={tripmodeclass}></div>
                  </div>
                  <div className="travel-details col-sm-8 border_none">
                    {popUpHeading}
                    {showpopup ?<PopElipsisName email={headingvar} />:null}
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            <CallStatusAndCommentsSelection placeHolder={this.props.placeHolder} ref={"qwert_"+this.props.uniqueId} uniqueId={this.props.uniqueId} ShowCallStatusAndComments={this.props.ShowCallStatusAndComments} handleParent={this.props.handleParent} TripSegmentArray={this.props.TripSegmentArray} handleClick={this.handleClick} UpdateTripCommentCount={this.props.UpdateTripCommentCount} UpdateCommentCount={this.props.UpdateCommentCount} isCallStatus={this.props.isCallStatus} reset={this.state.reset} ClickCancel={this.ClickCancel.bind(this)} SaveSucess={this.props.SaveSucess} tripmodelid={tripmodelid} EmailId={this.props.userProfileData.EmailId} TravellerId={this.props.userProfileData.TravelerId} RecordLocator={popupData.BookingInfo.RecordLocator} Source={source} TripId={popupData.TripId} SegmentId={popupData.segmentid}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
