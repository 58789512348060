export const checkCommunicationMobileMessage = (message,id)=>{
    const regex = /\b[a-zA-Z]{0,2}\d{1,6}[a-zA-Z]{0,4}\b/;
    const matches = message && message.match(regex);
    if(matches){
        return true
    }else return false
}

export const checkCommunicationMobileMessageUiLayer = (message)=>{
    const regex = /\b[a-zA-Z]{0,2}\d{1,6}[a-zA-Z]{0,4}\b/;
    const matches = message && message.match(regex);
    if(matches){
        return matches[0]
    }else return 
}
export const groupByCommunicationId = (arr=[]) => {
    if (arr.length > 0) {
        const groups = {};
        arr.forEach((obj, index) => {
            const { knowMeId, communicationId, channel, channelAddress, insertedDateTime, insertedBy, message, messageId, messageFlow, status, tranId, isPhoneNumber, isInApp, inApp } = obj;
            if (!groups[communicationId]) {
                groups[communicationId] = [];
            }
            groups[communicationId].push({ knowMeId, communicationId, channel, channelAddress, insertedDateTime, insertedBy, message, messageId, messageFlow, status, tranId, isPhoneNumber, isInApp, inApp })
        });
        const groupedData = JSON.parse(JSON.stringify(Object.values(groups)));
        groupedData.forEach((gData,index)=>{
            let counter = 0;
            gData.forEach((data,index)=>{
                if(data.channel === 'SMS' && data.messageFlow === "O" && checkCommunicationMobileMessage(data.message,data.communicationId)){
                    counter++;
                    if(counter === 1){
                        gData[gData.length]={
                            channel:data.channel,
                            messageFlow:data.messageFlow,
                            insertedDateTime:data.insertedDateTime,
                            message:data.message,
                            flight:checkCommunicationMobileMessageUiLayer(data.message),
                            communicationId:data.communicationId,
                            unique:1
                        }
                    }
                }
                if(data.channel === 'MOBILE' && data.messageFlow === "O" && checkCommunicationMobileMessage(data.message,data.communicationId)){
                    counter++;
                    if(counter === 1){
                        gData[gData.length]={
                            channel:data.channel,
                            messageFlow:data.messageFlow,
                            insertedDateTime:data.insertedDateTime,
                            message:data.message,
                            flight:checkCommunicationMobileMessageUiLayer(data.message),
                            communicationId:data.communicationId,
                            unique:2
                        }
                    }
                }
                if(data.channel === 'INTERNAL' && data.messageFlow === "I" && data.channelAddress === "PTC_INTERNAL_INFO" && checkCommunicationMobileMessage(data.message,data.communicationId)){
                    counter++;
                    if(counter === 1){
                        gData[gData.length]={
                            channel:data.channel,
                            messageFlow:data.messageFlow,
                            insertedDateTime:data.insertedDateTime,
                            message:data.message,
                            flight:checkCommunicationMobileMessageUiLayer(data.message),
                            communicationId:data.communicationId,
                            unique:3
                        }
                    }
                }
            })
        })
        return groupedData
    } else return;
}
