import React from "react";
import DeleteTab from "../Models/DeleteCommentResolveIssue.js";
import DeleteOrResolve from "../Models/DeleteOrResolve.js";


export default class CallStatusAndCommentsRows extends React.Component {

    constructor(props) {

         // This is called before render function
         //  The object that is returned is assigned to  this.state
         super(props);
         this.state = {callStatusOrCommentsData : [],flag:false};
         this.sort="";
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.callStatusOrComments !== this.props.callStatusOrComments) {
          var callStatusOrCommentsData=nextProps.callStatusOrComments;
          switch(this.sort){
            case"Index":
              if(window.$('.index_sort').hasClass('desc')) {
                  callStatusOrCommentsData.sort(function (a, b) {
                      return naturalSort(a.index,b.index);
                  });
              } else {
                  callStatusOrCommentsData.sort(function (a, b) {
                        return naturalSort(b.index,a.index);
                  });
              }
              this.setState({
                  callStatusOrCommentsData : callStatusOrCommentsData
              });
            break;
            case"Description":
              if(window.$('.description_sort').hasClass('desc')) {
                  callStatusOrCommentsData.sort(function (a, b) {
                      return naturalSort(a.description+","+a.scope,b.description+","+b.scope);
                  });
              } else {
                  callStatusOrCommentsData.sort(function (a, b) {
                      return naturalSort(b.description+","+b.scope,a.description+","+a.scope);
                  });
              }
              this.setState({
                  callStatusOrCommentsData : callStatusOrCommentsData
              });
            break;
            case"User":
              if(window.$('.user_sort').hasClass('desc')) {
                  callStatusOrCommentsData.sort(function (a, b) {
                      return naturalSort(a.user,b.user);
                  });

              } else {
                  callStatusOrCommentsData.sort(function (a, b) {
                      return naturalSort(b.user,a.user);
                  });
              }
              this.setState({
                  callStatusOrCommentsData : callStatusOrCommentsData
               });
            break;
            case"Datetime":
              if(window.$('.datetime_sort').hasClass('desc')) {
                callStatusOrCommentsData.sort(function (a, b) {
                    var expectedFirstDateTime   = formatDateTime(a.datetime);
                    var expectedSecondDateTime  = formatDateTime(b.datetime);
                    return expectedSecondDateTime-expectedFirstDateTime;
                });

              } else {
                callStatusOrCommentsData.sort(function (a, b) {
                    var expectedFirstDateTime   = formatDateTime(a.datetime);
                    var expectedSecondDateTime  = formatDateTime(b.datetime);
                    return expectedFirstDateTime-expectedSecondDateTime;
                });
              }
              this.setState({
                  callStatusOrCommentsData : callStatusOrCommentsData
              });
            break;
            default:
              this.setState({
                flag : true,
                callStatusOrCommentsData : nextProps.callStatusOrComments
              });
            break;
          }

        }
    }

    _sortingIndex(callStatusOrCommentsData) {
        this.sort="Index";
        if(window.$('.index_sort').hasClass('desc')) {
            callStatusOrCommentsData.sort(function (a, b) {
                return naturalSort(b.index,a.index);
            });
            window.$('.index_sort').removeClass('desc');
        } else {
            callStatusOrCommentsData.sort(function (a, b) {
                  return naturalSort(a.index,b.index);
            });
            window.$('.index_sort').addClass('desc');
        }
        this.setState({
            callStatusOrCommentsData : callStatusOrCommentsData
        });
    }

    _sortingDescription(callStatusOrCommentsData) {
        this.sort="Description";
        if(window.$('.description_sort').hasClass('desc')) {
            callStatusOrCommentsData.sort(function (a, b) {
                return naturalSort(b.description+","+b.scope,a.description+","+a.scope);
            });
            window.$('.description_sort').removeClass('desc');
        } else {
            callStatusOrCommentsData.sort(function (a, b) {
                return naturalSort(a.description+","+a.scope,b.description+","+b.scope);
            });
            window.$('.description_sort').addClass('desc');
        }
        this.setState({
            callStatusOrCommentsData : callStatusOrCommentsData
        });
    }

    _sortingUser(callStatusOrCommentsData) {
      this.sort="User";
        if(window.$('.user_sort').hasClass('desc')) {
            callStatusOrCommentsData.sort(function (a, b) {
                return naturalSort(b.user,a.user);
            });
            window.$('.user_sort').removeClass('desc');
        } else {
            callStatusOrCommentsData.sort(function (a, b) {
                return naturalSort(a.user,b.user);
            });
            window.$('.user_sort').addClass('desc');
        }
        this.setState({
            callStatusOrCommentsData : callStatusOrCommentsData
         });
    }

    _sortingDatetime(callStatusOrCommentsData) {
        this.sort="Datetime";
        if(window.$('.datetime_sort').hasClass('desc')) {
            callStatusOrCommentsData.sort(function (a, b) {
                var expectedFirstDateTime   = formatDateTime(a.datetime);
                var expectedSecondDateTime  = formatDateTime(b.datetime);
                return expectedFirstDateTime-expectedSecondDateTime;
            });
            window.$('.datetime_sort').removeClass('desc');
        } else {
            callStatusOrCommentsData.sort(function (a, b) {
                var expectedFirstDateTime   = formatDateTime(a.datetime);
                var expectedSecondDateTime  = formatDateTime(b.datetime);
                return expectedSecondDateTime-expectedFirstDateTime;
            });
            window.$('.datetime_sort').addClass('desc');
        }
        this.setState({
            callStatusOrCommentsData : callStatusOrCommentsData
        });
    }

    showPopupIndex(id,index) {
        if(index.length >14) {
            window.$('#popover-div-'+id).removeClass('hidden');
            window.$('#popover-text-'+id).text(index);
            window.$(".comment_table_body .index").css("cursor", "pointer");
        }
    }

    disablePopupIndex(id) {
        window.$('#popover-div-'+id).addClass('hidden');
        window.$(".comment_table_body .index").css("cursor", "context-menu");
    }

    render() {
        // Render call status and comments rows
        // Iterate data and rendering it
        var callStatusOrCommentsData;
        if(this.props.filterOption == "all") {
            callStatusOrCommentsData  = this.state.callStatusOrCommentsData;
            if(callStatusOrCommentsData.length == 0 && typeof(this.props.callStatusOrComments) != "undefined") {
                callStatusOrCommentsData  = this.props.callStatusOrComments;
            }
        } else {
            callStatusOrCommentsData  = this.props.callStatusOrComments;
        }

        var callStatusCommentsRows    = [];
        var callStatusCommentsHeader  = [];
        var indexValue,descriptionValue,notesValue,userValue,datetimeValue;

        // perform >> accoring to data existance
        // Render Table header and table rows if data available
        if(callStatusOrCommentsData.length != 0) {
            callStatusCommentsHeader.push( <div className="col-sm-12 comment_table_header">
                  <div className="col-sm-2 index"><span className="cursor_pointer" onClick={this._sortingIndex.bind(this,callStatusOrCommentsData)}> Index  <span className="comment_table_header_sort index_sort">&nbsp;</span> </span> </div>
                  <div className="col-sm-2 description"><span className="cursor_pointer"  onClick={this._sortingDescription.bind(this,callStatusOrCommentsData)}>Description  <span className="comment_table_header_sort description_sort">&nbsp;</span> </span> </div>
                  <div className="col-sm-2 notes"><span>Notes</span>  </div>
                  <div className="col-sm-2 user"><span className="cursor_pointer"  onClick={this._sortingUser.bind(this,callStatusOrCommentsData)}>{this.props.placeHolder.USER}  <span className="comment_table_header_sort user_sort">&nbsp;</span> </span> </div>
                  <div className="col-sm-3 inline-block"><span className="cursor_pointer"  onClick={this._sortingDatetime.bind(this,callStatusOrCommentsData)}>{this.props.placeHolder.DATE_TIME}  <span className="comment_table_header_sort datetime_sort">&nbsp;</span> </span> </div>
            </div>)

            // Render table tows
            for(var i=0;i<callStatusOrCommentsData.length;i++) {
              var descriptionName="";
              var scope="";
              let delete_icon_class;
              let hoverText="";
              if(callStatusOrCommentsData[i].description=="Comment,"){
                delete_icon_class="col-sm-1 delete_icon"
                if(callStatusOrCommentsData[i].scope=="Traveler")
                scope=this.props.placeHolder.TRAVELER;
                else if (callStatusOrCommentsData[i].scope=="Trip") {
                scope=this.props.placeHolder.TRIP;
                }
                else if (callStatusOrCommentsData[i].scope=="Segment"){
                scope=this.props.placeHolder.SEGMENT;
                }
                descriptionName=this.props.placeHolder.COMMENT+",";
                hoverText="Delete Comment";
              }
              else{
                  delete_icon_class="col-sm-1 wrench_icon";
                  if(callStatusOrCommentsData[i].description=="Urgent Issue,"){
                    descriptionName=this.props.placeHolder.URGENT_ISSUE+",";
                    scope=this.props.placeHolder.NOT_RESOLVED;
                  }
                  else if (callStatusOrCommentsData[i].description=="Outstanding Issue,") {
                    descriptionName=this.props.placeHolder.OUTSTANDING_ISSUE+",";
                    scope=this.props.placeHolder.NOT_RESOLVED;
                  }
                  else if (callStatusOrCommentsData[i].description=="Held Flight,") {
                    descriptionName=this.props.placeHolder.HELD_FLIGHT+",";
                    scope=this.props.placeHolder.NOT_TICKETED;
                  }
                  hoverText="Resolve Issue";
              }
              let uniqueId = "#DeletePopup"+i;
                callStatusCommentsRows.push(
                        <div key={i} className="col-sm-12 comment_table_body">
                            <div className="col-sm-2 index index1 cursor_default" onMouseOver={this.showPopupIndex.bind(this,callStatusOrCommentsData[i].id,callStatusOrCommentsData[i].fullIndex)}  onMouseOut={this.disablePopupIndex.bind(this,callStatusOrCommentsData[i].id)}> {callStatusOrCommentsData[i].index} <br/> <div className={callStatusOrCommentsData[i].icon}>&nbsp;</div>
                                <div className='hidden fade in popover bottom pop_over_name email_popup' id={"popover-div-"+callStatusOrCommentsData[i].id} >
                                  <div className='arrow'></div>
                                  <div className='popover-content'>
                                      <div className='popover_custom' id={"popover-text-"+callStatusOrCommentsData[i].id}>
                                      </div>
                                  </div>
                                </div>
                            </div>
                            <div className="col-sm-2 description description1">{descriptionName} <br/> {scope}

                             </div>
                            <div className="col-sm-2 notes notes1">{callStatusOrCommentsData[i].notes}</div>
                            <div className="col-sm-2 user user1">{callStatusOrCommentsData[i].user}  </div>
                            <div className="col-sm-2 datetime1">  {callStatusOrCommentsData[i].datetime} </div>
                            {!this.props.historyFlag
                            ?
                            <div>
                            <DeleteOrResolve delete_icon_class={delete_icon_class} hoverText={hoverText} uniqueId={uniqueId}/>
                            <DeleteTab placeHolder={this.props.placeHolder} tripIdsegmentArray={this.props.tripIdsegmentArray} RecordLocator={callStatusOrCommentsData[i].RecordLocator} SegmentId={callStatusOrCommentsData[i].SegmentId} SaveSucess={this.props.SaveSucess} DeleteCallStatus={this.props.DeleteCallStatus} icon={callStatusOrCommentsData[i].icon} UpdateTable={this.props.UpdateTable} UpdateCommentCount={this.props.UpdateCommentCount} source={callStatusOrCommentsData[i].source} issueType={callStatusOrCommentsData[i].description} name={callStatusOrCommentsData[i].index} fullIndex={callStatusOrCommentsData[i].fullIndex} title={callStatusOrCommentsData[i].title} scope={callStatusOrCommentsData[i].level} tripId={callStatusOrCommentsData[i].tripid} notes={callStatusOrCommentsData[i].notes} uniqueId={i} commentId={callStatusOrCommentsData[i].id} travelerId={this.props.travelerId}/>
                            </div>
                            :
                            null }





                        </div>
                )
            } // {End for loop}
        }

        if(this.state.flag == true && callStatusOrCommentsData.length == 0 ) {
           if(this.props.filterOption ==  "all" && this.props.loader == false) {
              callStatusCommentsRows.push(<div className="alert alert-danger" role="alert">{this.props.placeHolder.NO_RESULT} </div>)
            }
        }

        if(this.props.filterOption ==  "callstatus" && callStatusOrCommentsData.length == 0) {
            callStatusCommentsRows.push(<div className="alert alert-danger" role="alert">{this.props.placeHolder.NO_RESULT} </div>)
        } else if(this.props.filterOption == "comments" && callStatusOrCommentsData.length == 0) {
            callStatusCommentsRows.push(<div className="alert alert-danger" role="alert">{this.props.placeHolder.NO_RESULT} </div>)
        } else if(this.props.againCallFlg == true && this.props.filterOption == "all" && callStatusOrCommentsData.length == 0) {
            callStatusCommentsRows.push(<div className="alert alert-danger" role="alert">{this.props.placeHolder.NO_RESULT} </div>)
        }

        return(<div>
                {callStatusCommentsHeader}
                <div className ="callstatus-comments-rows" id="test">
                    {callStatusCommentsRows}
                </div>
              </div>
              );
        }
  }

  function naturalSort(a, b) {
      var ax = [], bx = [];
      a.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
      b.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
      while(ax.length && bx.length) {
          var an = ax.shift();
          var bn = bx.shift();
          var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
          if(nn) return nn;
      }
      return ax.length - bx.length;
  }

  function allValuesSame(a) {
      for(var i = 1; i < a.length; i++)
      {
          if(a[i] !== a[0])
              return false;
      }
      return true;
  }

  function formatDateTime(dt) {
      var dt            =   dt.trim();
      var dateValue     =   dt.substring(0,2);
      var monthNames    =   { "JAN": "01", "FEB": "02", "MAR" :"03", "APR" :"04", "MAY":"05", "JUN":"06",
                              "JUL" :"07", "AUG" :"08","SEP": "09","OCT": "10","NOV": "11", "DEC" :"12"
                            };
      var monthNumber   =   dt.substring(2,5);
      var month         =   monthNames[monthNumber];
      var year          =   "20"+dt.substring(5,7);
      var hourValue     =   dt.substring(8,10);
      var minuteValue   =   dt.substring(11,13);
      var secondsValue  =   0;
      var result        =   new Date(year, month, dateValue, hourValue, minuteValue, secondsValue);
      return result;
  }
