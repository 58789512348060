import React, { Component } from 'react';
import Footer from './footer';
import {getaction} from './Login/Utils';

class ConfirmReset extends Component {

  constructor(props) {
      super(props);
  }
  _EventClick(){
    location.reload(window.location.href);
  }
    render() {
        return (
            <div className="resolution_container_reg container">
                <div id="containerreg">
                    <header>
                        <span id="logoEnglish"></span>
                        <a href="../gbt_viewer_quick_reference.pdf" target="_blank" className="help_icon">&nbsp;</a>
                    </header>
                    <div className="registration-container container error-body" id="registration-container">
                            <p className="thank-header"><b>Thank You</b></p>
                            <p className="thank-message">
                                We have sent {getaction(this.props.action)}  to <span className="mailId">{this.props.email}</span>. If you do not receive an email in your inbox, please check your spam folder.
                            </p>
                            <button type="button" className="btn btn-default confirmbutton" onClick={this._EventClick.bind(this)}>OK</button>
                    </div>
                </div>
                <Footer placeHolder={this.props.placeHolder} />
            </div>
        );
    }
}

export default ConfirmReset;

