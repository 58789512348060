/**
  * @ Registration Component
  * @ File - registration.js
  * Registration Form
  */
import React, { Component } from 'react';
import Footer from '../footer';
import Name from '../registration/name';
import Organization from '../organization/organization';
import Password from '../registration/passwordField';
import Username from '../registration/userName';
import SecurityQuestion from '../organization/securityQuestion';
import BusinessEmail from '../registration/businessEmail';
import CommentBox from '../registration/commentBox';
import JobTitle from '../registration/jobTitle';
import HTTP from '../../services/httpservice';
import {hcapcha_site_key} from '../constants';
import { hashHistory } from 'react-router';
import HCaptcha from '@hcaptcha/react-hcaptcha';
var recaptchaInstance;

/* Define Constants */
const SUBMIT_ERROR_MESSAGE = "Errors exist - please correct";
const ERROR_MESSAGE_CAPTCHA = "Please verify that you are not a robot.";


class Registration extends Component {

    // Constructor called before render function
    // The object that is returned is assigned to  this.state
    constructor(props) {
        super(props);
        this.state = {
            username: {
                valid: false,
                value: ''
            },
            password: {
                valid: false,
                value: ''
            },
            name: {
                fnameValid: false,
                fnameValue: '',
                lnameValid: false,
                lnameValue: ''
            },
            organisation: {
                valid: false,
                value: ''
            },
            email: {
                valid: false,
                value: ''
            },
            comment: {
                valid: false,
                value: ''
            },
            jobTitle: {
                valid: true,
                value: ''
            },
            securityQuestion: {
                valid: false,
                value: []
            },
            emailDomain         : [],
            submitErrors        : '',
            captchaResponse     : '',
            captchaErrorMessage : ''
        }
    }

    // First Name Success Validation
    // Update success state value
    _setFirstname(fname) {
        let first = {
            fnameValid: true,
            fnameValue: fname,
            lnameValid: this.state.name.lnameValid,
            lnameValue: this.state.name.lnameValue
        }
        this.setState({
            name: first
        })
    }

    // First Name Failure Validation
    // Update failure state value
    setFirstNameValidFalse() {
        let first = {
            fnameValid: false,
            fnameValue: '',
            lnameValid: this.state.name.lnameValid,
            lnameValue: this.state.name.lnameValue
        }
        this.setState({
            name: first
        })
    }

    // Last Name success Validation
    // Update success state value
    _setLastname(lname) {
        let last = {
            lnameValid: true,
            lnameValue: lname,
            fnameValid: this.state.name.fnameValid,
            fnameValue: this.state.name.fnameValue
        }
        this.setState({
            name: last
        })
    }

    // Last Name Failure Validation
    // Update failure state value
    setLastNameValidFalse() {
        let last = {
            lnameValid: false,
            lnameValue: '',
            fnameValid: this.state.name.fnameValid,
            fnameValue: this.state.name.fnameValue
        }
        this.setState({
            name: last
        })
    }

    // Success Validation for other fields
    // Change true state
    _setValue(state, value) {
        let temp = {
            valid: true,
            value: value
        }
        this.setState({
            [state]: temp
        });
    }

    // Failure Validation for other fields
    // Change false state
    _setFalse(state) {
        let temp = {
            valid: false,
            value: ''
        }
        this.setState({
            [state]: temp
        });
    }

    // Submit button functionality
    _handleSubmit() {

        let errorMessage = '';
        if (!this.state.name.fnameValid) {
            this.refs.name._validateFirstName();
        }
        if (!this.state.name.lnameValid) {
            this.refs.name._validateLastName();
        }
        if (!this.state.organisation.valid) {
            this.refs.organisation._validateOrganisation();
        }
        if (!this.state.email.valid) {
            this.refs.email._validateEmail();
        }
        if (!this.state.username.valid) {
            this.refs.username.validateUsernameField();
        }
        if (!this.state.password.valid) {
            this.refs.password._validatePasswordField();
            this.refs.password._validateconfirmPasswordField();
        }
        if (!this.state.securityQuestion.valid) {

            this.refs.sequrityqn._validateQuestion(0);
            this.refs.sequrityqn._validateQuestion(1);
            this.refs.sequrityqn._validateQuestion(2);
            this.refs.sequrityqn._validateAnswer(0);
            this.refs.sequrityqn._validateAnswer(1);
            this.refs.sequrityqn._validateAnswer(2);
        }
        if (this.state.captchaResponse == '') {
            this.setState({
                captchaErrorMessage: ERROR_MESSAGE_CAPTCHA,
                captchaResponse: ''
            })
        }
        if (!this.state.name.fnameValid  || this.state.captchaResponse == '' || !this.state.name.lnameValid || !this.state.organisation.valid || !this.state.email.valid || !this.state.username.valid || !this.state.password.valid || !this.state.securityQuestion.valid) {
            errorMessage = SUBMIT_ERROR_MESSAGE;
            this.setState({ submitErrors: errorMessage, captchaErrorMessage: ERROR_MESSAGE_CAPTCHA, });
            recaptchaInstance.reset();
            this.refs.password._resetPasswordAndConfirmPassword();
            this._setFalse('password');
            window.scrollTo(0, 0);
        } else {
            var self = this;
            let securityQuestion = {};
            for (var i = 0; i < this.state.securityQuestion.value.length; i++) {
                securityQuestion[this.state.securityQuestion.value[i].Question] = this.state.securityQuestion.value[i].Answer;
            }
            let request = {
                "Description": this.state.comment.value,
                "Email": this.state.email.value,
                "Firstname": this.state.name.fnameValue,
                "Jobtitle": this.state.jobTitle.value,
                "Lastname": this.state.name.lnameValue,
                "Organization": this.state.organisation.value,
                "Password": this.state.password.value,
                "Securityquestions": securityQuestion,
                "Username": this.state.username.value
            }
            HTTP.postJSON('/users/v1', request)
                .then(function (response) {
                    if(response.Success == "true"){
                        self.setState({ submitErrors: '' });
                        hashHistory.push('/SignupConfirmation/' + response.Email);
                    } else {
                        recaptchaInstance.reset();
                        self.refs.password._resetPasswordAndConfirmPassword();
                        // Submit button Validations
                        if(response.Validations.length == 1) {
                            if(response.Validations[0].Field == "username") {
                                self.refs.username._resetUserName(response.Validations[0].Suggessions);
                            } else if(response.Validations[0].Field == "email") {
                                self.refs.email._resetEmail(response.Validations[0].Message);
                            }
                        } else if(response.Validations.length == 2) {
                            if(response.Validations[0].Field == "email") {
                                self.refs.email._resetEmail(response.Validations[0].Message);
                            } else if(response.Validations[1].Field == "email") {
                                self.refs.email._resetEmail(response.Validations[1].Message);
                            }
                            if(response.Validations[0].Field == "username") {
                                self.refs.email._resetUserName(response.Validations[0].Suggessions);
                            } else if(response.Validations[1].Field == "username") {
                                self.refs.email._resetUserName(response.Validations[1].Suggessions);
                            }
                        }
                        self.setState({ submitErrors: SUBMIT_ERROR_MESSAGE });
                    }
                })
        }
    }

    _ChangeOrganisationBox(task) {
        if (this.refs.organisation != undefined)
            this.refs.organisation.changeBorderColor(task);
    }

    captchaCallback() {

    }

    // Verify captcha success / failure
    verifyCaptchaCallback(response) {
        if (response != '') {
            this.setState({
                captchaErrorMessage: '',
            })
        }
        this.setState({ captchaResponse: response });
    }

    // Render Registration Component
    // Child Components : Name, Organization, BusinessEmail, Username,Password, JobTitle, CommentBox, SecurityQuestion
    render() {
        return (
            <div className="resolution_container_reg container">
                <div id="containerreg">
                    <header>
                        <span id="logoEnglish"></span>
                        <a href="../gbt_viewer_quick_reference.pdf" target="_blank" className="help_icon">&nbsp;</a>
                    </header>
                    <div className="registration-container container" id="registration-container">
                        <h2 className="text-center create-account">Create Account</h2>
                        <form className="registration-margin">
                            {this.state.submitErrors != '' ? <div className="submit-error-block"><p className="submit-errors">{this.state.submitErrors}</p></div> : null}

                            <br />
                            <div className="row inline-textbox">
                                <Name ref='name' fnameValid={this.state.name.fnameValid} setFirstNameValidFalse={this.setFirstNameValidFalse.bind(this)} setFirstname={this._setFirstname.bind(this)} lnameValid={this.state.name.lnameValid} setLastNameValidFalse={this.setLastNameValidFalse.bind(this)} setLastname={this._setLastname.bind(this)} />
                            </div>
                            <Organization ref='organisation' setEmailDomain={(emailDomain) => this.setState({ emailDomain })} valid={this.state.organisation.valid} setFalse={this._setFalse.bind(this)} setValue={this._setValue.bind(this)} />
                            <br />
                            <BusinessEmail ref='email' userValue={this.state.username.value} changeOrganisationToNormal={this._ChangeOrganisationBox.bind(this, '')} changeOrganisationToRed={this._ChangeOrganisationBox.bind(this, 'red')} emailDomain={this.state.emailDomain} valid={this.state.email.valid} setFalse={this._setFalse.bind(this)} setValue={this._setValue.bind(this)}  />
                            <Username ref='username' valid={this.state.username.valid} fnamevalue={this.state.name.fnameValue} lnamevalue={this.state.name.lnameValue} emailvalue={this.state.email.value} setFalse={this._setFalse.bind(this)} setValue={this._setValue.bind(this)} />
                            <Password ref='password' username={this.state.username} valid={this.state.password.valid} setFalse={this._setFalse.bind(this)} setValue={this._setValue.bind(this)} />
                            <JobTitle setValue={this._setValue.bind(this)} />
                            <CommentBox setValue={this._setValue.bind(this)} setFalse={this._setFalse.bind(this)} />
                            <SecurityQuestion ref='sequrityqn' valid={this.state.securityQuestion.valid} setFalse={this._setFalse.bind(this)} setValue={this._setValue.bind(this)} />
                            <div id="captcha">
                                <HCaptcha
                                sitekey={hcapcha_site_key()}
                                ref={(e) => (recaptchaInstance = e)}
                                onLoad={this.captchaCallback.bind(this)}
                                onVerify={this.verifyCaptchaCallback.bind(this)}
                                />
                              { this.state.captchaErrorMessage != '' ? <div className="captcha-error-icon"><img src="/icons/Error.png" alt="W3Schools.com" /></div> : null }
                            </div>
                            <p className="terms-conditions">By submitting it, you agree to the <a href="#/Signup"><span className="color-code">Terms and Conditions</span></a> and <a href="#/Signup"><span className="color-code">Privacy Policy</span></a> for this website</p>
                            <br />
                            <button type="button" onClick={this._handleSubmit.bind(this)} className="btn btn-default custom-submit-button">Submit</button>
                            <p className="login-button-text">Already have an account? <a href="#"><span className="login-now"> Login now </span></a></p>
                        </form>
                    </div>
                </div>
                <Footer placeHolder={this.props.children.placeHolder} />
            </div>
        );
    }
}

export default Registration;
