import React from 'react';

export default class TravelerComments extends React.Component {
	constructor(props) {
		super(props);
		this.gotoAllCommentsTab = this.gotoAllCommentsTab.bind(this);
	}

	gotoAllCommentsTab(travelerId) {
		this.props.gotoAllCommentsTab();
	}

	render() {
		let Comments = this.props.text.map(function(data, i) {
			return (
				<div key={i}>
					{' '}
					<span className="margin_rgt_5">- </span> <span>{data['#text']}</span>
				</div>
			);
		});
		let allComments = false;
		if (this.props.count > 2) {
			allComments = true;
		}

		return (
			<div class="popover_all hidden" id={this.props.travelerId}>
				<div className="fade in popover bottom pop_over_right traveller_popover">
					<div className="arrow" />
					<div className="popover-content">
						<div className="popover_custom">{Comments}</div>
					</div>
					{allComments == true ? (
						<a
							href="#"
							class="goto_comment"
							onClick={this.gotoAllCommentsTab.bind(this, this.props.travelerId)}
						>
							{this.props.placeHolder.GO_TO_COMMENTS}
						</a>
					) : null}
				</div>
			</div>
		);
	}
}
