import React, { Component } from 'react';
import Footer from './footer';
import { hashHistory } from 'react-router';

class AlertType extends Component {
  _EventClick(){
    hashHistory.push('/');
  }
  render(){
    return (
      <div className="resolution_container_reg container">
      <div id="containerreg">
          <header>
              <span id="logoEnglish"></span>
              <a href="../gbt_viewer_quick_reference.pdf" target="_blank" className="help_icon">&nbsp;</a>
          </header>
          <div className="registration-container container error-body" id="registration-container">
          <div className="error-header"><b>ID Locked</b></div>
          <br/>
           <div className="col-sm-1 errorimage"></div>
           <div className="col-sm-11">
            <p className="error-message">
              For security reasons, your account has been locked. Please contact your American Express Global Business Travel support manager to unlock your account
            </p>
            <button type="button" className="btn btn-default confirmbutton" onClick={this._EventClick.bind(this)}>OK</button>
           </div>
          </div>
      </div>
      <Footer placeHolder={this.props.children.placeHolder} />
      </div>
    )
  }
}

export default AlertType;
