import React from "react";
import AirTripDetails from "./TripsAndNotification/AirTripDetails.js";
import CarTripDetails from "./TripComponents/CarTripDetails.js";
import HotelTripDetails from "./TripsAndNotification/HotelTripDetails.js";
import RailTripDetails from "./TripComponents/railTripDetails.js";
import LimoTripDetails from "./TripComponents/LimoTripDetails.js";
import BusTripDetails from "./TripComponents/BusTripDetails.js";
import createClass from 'create-react-class';
let TripDetails = createClass({
    ComponentWillMound() {},
    render: function () {

        var temp;
        var specificTDetails = this.props.specificTD;
        switch (this.props.Tripmode) {
            case "Air":
                temp = (<AirTripDetails
                    openChatWindowMethod={this.props.openChatWindowMethod}
                    openedChatList={this.props.openedChatList}
                    orgId={this.props.orgId}
                    pnrOfNotification={this.props.pnrOfNotification}
                    openDocsTab={this.props.openDocsTab}
                    recordLocatorDocs={this.props.recordLocatorDocs}
                    userId={this.props.userId}
                    userName={this.props.userName}
                    Segkey={this.props.Segkey}
                    popupdata={this.props.popupdata}
                    tripmodeclass={this.props.tripmodeclass}
                    DispTripId={this.props.DispTripId}
                    notificationObject={this.props.selectedNotifiedObject}
                    user={this.props.user}
                    userProfileData={this.props.userProfileData}
                    callApiNotification={this.props.callApiNotification}
                    placeHolder={this.props.placeHolder}
                    MealAssignmentList={this.props.MealAssignmentList}
                    MiInfoList={this.props.MiInfoList}
                    SeatAssignmentList={this.props.SeatAssignmentList}
                    specificTD={specificTDetails}/>);
                break;

            case "Car":
                temp = (<CarTripDetails
                    openDocsTab={this.props.openDocsTab}
                    recordLocatorDocs={this.props.recordLocatorDocs}
                    placeHolder={this.props.placeHolder}
                    specificTD={specificTDetails}
                    />);
                break;
            case "Hotel":
                temp = (<HotelTripDetails
                    premGroup={this.props.premGroup}
                    pnrValue={this.props.pnrValue}
                    ActiveFilter={this.props.ActiveFilter}
                    ClientName={this.props.ClientName}
                    openHreSegment={this.props.openHreSegment}
                    setOfferAsFalse={this.props.setOfferAsFalse}
                    HRESegments={this.props.HRESegments}
                    showOffer={this.props.showOffer}
                    userId={this.props.userId}
                    userName={this.props.userName}
                    openDocsTab={this.props.openDocsTab}
                    recordLocatorDocs={this.props.recordLocatorDocs}
                    placeHolder={this.props.placeHolder}
                    specificTD={specificTDetails}
                    />);
                break;
            case "Rail":
                temp = (<RailTripDetails
                    openDocsTab={this.props.openDocsTab}
                    recordLocatorDocs={this.props.recordLocatorDocs}
                    placeHolder={this.props.placeHolder}
                    EmployeeId={this.props.EmployeeId}
                    customerInfo={this.props.customerInfo}
                    CustomFieldValue={this.props.CustomFieldValue}
                    specificTD={specificTDetails}
                    />);
                break;
            case "Limo":
                temp = (<LimoTripDetails
                    openDocsTab={this.props.openDocsTab}
                    recordLocatorDocs={this.props.recordLocatorDocs}
                    placeHolder={this.props.placeHolder}
                    EmployeeId={this.props.EmployeeId}
                    customerInfo={this.props.customerInfo}
                    CustomFieldValue={this.props.CustomFieldValue}
                    specificTD={specificTDetails}
                    />);
                break;
            case "Bus" :
                temp = (<BusTripDetails popupdata={this.props.popupdata} />)     
            break;
        }
        return (
            <div>{temp}</div>
        );
    }
});
export default TripDetails;
