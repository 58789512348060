/**
  * @ Signup Confirmation Component
  * @ File - signupConfirmation.js
  * Sign up confirmation Box
  */
import React, { Component } from 'react';
import Footer from './footer';

class SignupConfirmation extends Component {
    render() {
      return(
            <div className="resolution_container_reg container">
                <div id="containerreg">
                    <header>
                        <span id="logoEnglish"></span>
                        <a href="../gbt_viewer_quick_reference.pdf" target="_blank" className="help_icon">&nbsp;</a>
                    </header>
                    <div className="signup-confirmation-container container" id="registration-container">
                        <div className="signup-confirmation-content">
                            <p className="welcome-text">Welcome to</p>
                            <p className="american-express-text">American Express </p>
                            <p className="global-business-text">Global Business Travel</p>
                            <p className="request-text">Your request for access has been submitted.</p>
                            <p className="approve-text">You will receive an email at <span className="email-text">{this.props.params.email}</span> once your access has been approved. Please check your email account to complete the registration process.  </p>
                            <p className="close-window">Please close this window. </p>
                        </div>
                    </div>
                </div>
                <Footer placeHolder={this.props.children.placeHolder} />
            </div>
        )
    }
}
export default SignupConfirmation;
