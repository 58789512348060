import React from "react";
import TripDetails from "../TripDetails";
import GameMessage from "../TripsItinerary/GameMessage";
import PopElipsisName from "../../PopElipsisName"
import AddStatusCommentsPopUp from "../Models/AddStatusCommentsPopUp"
import HTTP from '../../../../services/httpservice'
import {
    gtrViewerStatus,
    gtrViewerSegment,
    checkDateStatus,
    getIconsForCancelled,
    toArrayObject,
    checkDateForActiveLink
} from '../../../../services/utils';
import TripRecommender from "../TripsItinerary/TripRecommender"
import {EnvironmentDeployment} from "../../../constants.js"
import createClass from 'create-react-class';
const CUBA_GOVERMENT_INFO = 'Cuba only permissible for government official travel';
const CUBA_CLIENT_INFO = 'Cuba Affidavit Required, Follow Confidential Cuba Process Before Ticketing';

var Segments = createClass({
    getInitialState: function () {
        return {
            openDetails: false,
            class: "travel-plan row",
            typeOfTraval: "",
            specificTD: [],
            display: true,
            modalIsOpen: false,
            popupheahing: false,
            segmentCommentCount: '',
            showPopOverState: false,
            savetemp: false,
            GameMsg: "",
            showRecordLocatorState: false
        }
    },

    componentWillMount: function () {
        if(this.props.selectedNotifiedObject !== undefined && this.props.selectedNotifiedObject !== null && this.props.selectedNotifiedObject !== "" ){
            if(this.props.selectedNotifiedObject.segmentId == this.props.TripDetailsResponse.segmentid) {
                this.onOpenClick();
            }
        }
        if (this.props.openHreSegment) {
            console.log("entering here");
            this.onOpenClick();
        }
    },

    CallStatusClick: function (e) {
        var key = "AddStatusCommentsPopUp_" + this.props.Key;
        this
            .refs[key]
            .focusModal();
        e.stopPropagation();
    },

    disablepopupheahing: function () {
        this.setState({popupheahing: false});

    },
    popupheahing: function () {
        if (this.state.popUpleft) {
            this.setState({popupheahing: false});
        } else {
            this.setState({popupheahing: true});
        }
    },

    showRecordLocator: function () {
        this.setState({showRecordLocatorState: true});
    },
    hideRecordLocator: function () {
        this.setState({showRecordLocatorState: false});
    },
    contextTypes: function () {
        return {
            openDetails: false,
            count: '',
            firstComment: '',
            secondComment: '',
            countClassName: '',
            commentClassName: ''
        }
    },
    onOpenClick: function () {
        var segmentNum = "#ActiveSeg" + this.props.Key;
        if (window.$(segmentNum) != null) {
            setTimeout(function () {
                window.$('html, body').animate({
                    scrollTop: window.$(segmentNum)
                        .offset()
                        .top
                }, 100);

            }, 5);

        }
        this
            .props
            .Change(this.props.Key)
        if (this.state.openDetails) {
            this.setState({openDetails: false});
        } else {
            this.setState({openDetails: true});
        }
    },

    ShowPopOver: function (travelerId) {

        window.$('#' + travelerId).removeClass('hidden');
    },

    DisablePopOver: function (travelerId) {
        setTimeout(function () {
            if (!window.$(".popover_all:hover").length) {
                window.$('#' + travelerId).addClass('hidden');
            }
        }, 200);
    },

    remainPopoverOpen: function (travelerId) {
        window.$('#' + travelerId).removeClass('hidden');
        window.$("#" + travelerId).on("mouseleave", function () {
            window.$('#' + travelerId).addClass('hidden');
        });
    },

    gotoAllCommentsTab: function () {
        this
            .props
            .gotoAllCommentsTab();
    },
    trackLink: function (e) {
        e.stopPropagation();
    },
    trackInfo: function (e) {
        var self = this;
        if (this.props.TripDetailsResponse != null) {
            var GetTripDetailsResponse = this.props.TripDetailsResponse;
            var RecordLocator = GetTripDetailsResponse.BookingInfo.RecordLocator;
            var AtlasGDS = GetTripDetailsResponse.BookingInfo.AtlasGDS;
            var Source = GetTripDetailsResponse.BookingInfo.Source;
            var pcc = GetTripDetailsResponse.BookingInfo.pcc;
            e.stopPropagation();
            var reqData = {
                "RecordLocator": RecordLocator,
                "Source": Source,
                "AtlasGDS": AtlasGDS,
                "pcc": pcc
            }

            var urlData = "https://game.gbt.gbtad.com:9200/gbt-game/v1/pnrs/" + AtlasGDS + "/" + pcc + "/" + RecordLocator;
            //  var urlData = "http://localhost:8086/gbt-game/v1/pnrs";
            if (AtlasGDS == "SABRE" || AtlasGDS == "AMADEUS" || AtlasGDS == "GALILEO" || AtlasGDS == "APOLLO") {
                HTTP
                    .get(urlData)
                    .then(function (result) {

                        //  alert(result);
                        if (result.retCode == 100) {} else if (result.retCode == 101) {
                            self.setState({
                                GameMsg: "Please verify " + AtlasGDS + " is open and you are signed in."
                            });
                            window.$('#GameMessage').modal();
                        } else if (result.retCode == 102) {
                            self.setState({
                                GameMsg: "Please verify an area is available in " + AtlasGDS + " and then retry."
                            });
                            window.$('#GameMessage').modal();
                        } else if (result.retCode == 104) {
                            self.setState({
                                GameMsg: "Unable to retrieve PNR in " + AtlasGDS + " at this time."
                            });
                            window.$('#GameMessage').modal();
                        } else if (result.retCode == 0) {
                            self.setState({
                                GameMsg: "Unable to retrieve PNR in " + AtlasGDS + " at this time."
                            });
                            window.$('#GameMessage').modal();
                        } else if (result.retCode == 103) {
                            self.setState({GameMsg: result.retValue});
                            window.$('#GameMessage').modal();
                        }

                    });
            }

        }
    },

    render: function () {
        var countClassName = '';
        var gotoAllCommentsRow = '';
        var gotoAllCommentsClass = 'hidden';
        if (this.props.segmentCount > 2) {
            gotoAllCommentsRow = <a
                href="#"
                onClick={this.gotoAllCommentsTab}
                className="goto_comment"
                id="segment-comment">{this.props.placeHolder.GO_TO_COMMENTS}</a>
        }
        if (this.props.segmentCount == 0) {
            this.context.countClassName = "hidden";
        } else {
            this.context.countClassName = "";
        }
        this.context.count = this.props.segmentCount;
        var data = this.props.segmentComments;
        if (typeof data != "undefined" && data.length != 0) {
            if (typeof data[0] != "undefined") {
                this.context.firstComment = data[0].Note;
            }
            if (typeof data[1] != "undefined") {
                this.context.secondComment = data[1].Note;
                this.context.commentClassName = "";
            } else {
                this.context.commentClassName = "hidden";
            }
        }
        var classDetails;
        var ticketlogo = "";
        var key = this.props.Key;
        var userProfileData = this.props.userProfileData;
        var tripmodelid = "#AddPopup" + key;
        var state = this.state;

        if (this.props.Key != this.props.openDetailsKey) {
            this.context.openDetails = false;

            classDetails = "trip row";
        } else {
            this.context.openDetails = true;
            //  this.trackInfo();
            classDetails = "trip row open";
        }
        if (this.props.previouskey == this.props.openDetailsKey) {
            this.context.openDetails = false;

            classDetails = "trip row";
        }

        var TripDetailsResponse = this.props.TripDetailsResponse;

        var paxno;

        // if(this.state.openDetails){   classDetails="trip row open"; }else{
        // classDetails="trip row"; }
        var showpopup = false;
        var tripmodeclass = "";
        var location = "";
        var tripSummary = "";
        var tripSummary2 = "";
        var headingvar = "";
        var lastChild = "bottom-line";
        var moreInfo = "";
        var Heading = "";
        var moreInfo2 = "";
        var temp;
        var MiInfoList;
        var SeatAssignmentList;
        var Tripmode;
        var MealAssignmentList;
        var status = "";
        var SegType = "";
        var ConfirmedClass = "confirmed traveling-confirmed future";
        var intractive;
        var pcc;
        var popMover = "";
        var popMout = "";
        let showOffer = false;
        let HRESegments = null;

        if (TripDetailsResponse.BookingInfo.Interactive == "true") {
            intractive = "Online"
        } else if (TripDetailsResponse.BookingInfo.Interactive == "false") {
            intractive = "Offline"
        }

        // var d = new Date(); var currentTime=new Date(d.valueOf() +
        // d.getTimezoneOffset() * 60000);
        //
        // alert(currentTime.getMonth()+" "+currentTime.setMonth(6));

        if (TripDetailsResponse.BookingInfo.pcc != "") {
            pcc = TripDetailsResponse.BookingInfo.pcc;
        }
        if (typeof(this.props.BookingInfo) != "undefined" && this.props.BookingInfo != null) {
            if (this.props.BookingInfo.pcc != "") {
                pcc = this.props.BookingInfo.pcc;
            }

            if (this.props.BookingInfo.Interactive == "true") {
                intractive = "Online"
            } else if (this.props.BookingInfo.Interactive == "false") {
                intractive = "Offline"
            }

        }

        if (this.props.customerInfos != null) {
            var customerInfo = toArrayObject(this.props.customerInfos.CustomerInfo);
            var travellerId = this.props.travellerId;
            for (var l = 0; l < customerInfo.length; l++) {
                if (customerInfo[l].Customer.PersonName["@TravelerId"] == travellerId) {
                    paxno = customerInfo[l].Customer.PersonName["@ProviderPaxId"];
                    break;
                }
            }
        }
        switch (TripDetailsResponse.Type) {
            
            case "Air":
  
                if (TripDetailsResponse.PassengerList != null) {
                    if (TripDetailsResponse.CustomerInfos != null) {
                        SeatAssignmentList = SeatAssignmentHelper(TripDetailsResponse.PassengerList, TripDetailsResponse.CustomerInfos, this.props.tID);
                        MealAssignmentList = MealAssignmentHelper(TripDetailsResponse.PassengerList, TripDetailsResponse.CustomerInfos, this.props.tID)
                        var SeatAssignment = "";
                        for (var i = 0; i < SeatAssignmentList.length; i++) {
                            var tempComa = ",";
                            if (i == 0) {
                                tempComa = "";
                            }
                            SeatAssignment = SeatAssignment + tempComa + SeatAssignmentList[i];
                        }
                    }
                }
                var MiInfoList = MiInfoListHelper(TripDetailsResponse.MIInformation, TripDetailsResponse.CustomerInfos, this.props.tID);
                Tripmode = "Air";

                /* Sanctions */
                var airlineSanctionText = '';
                var airportSanctionText = '';
                if(TripDetailsResponse.sanction !== null && TripDetailsResponse.sanction!='None' && (this.props.SummarySanctions!=null && this.props.SummarySanctions!='None')) {
                    if(TripDetailsResponse.sanction === 'Airline') {
                        if(this.props.SummarySanctions !== null && this.props.SummarySanctions!='None') {
                            airlineSanctionText = this.props.placeHolder.PROHIBITED_TRAVEL_ON_ININERARY;
                        }
                    } else if(TripDetailsResponse.sanction === 'Airport') {
                        if(this.props.SummarySanctions !== null && this.props.SummarySanctions!='None') {
                            var sanctionsArray = this.props.SummarySanctions.split('|');
                            if(sanctionsArray.includes('G')) {
                                airportSanctionText = CUBA_GOVERMENT_INFO;
                            } else if(sanctionsArray.includes('C')) {
                                airportSanctionText = CUBA_CLIENT_INFO;
                            } else {
                                airportSanctionText = this.props.placeHolder.PROHIBITED_TRAVEL_ON_ININERARY;
                            }   
                        }
                    } else if(TripDetailsResponse.sanction === 'Airport|Airline') {
                        if(this.props.SummarySanctions !== null && this.props.SummarySanctions!='None') {
                            var sanctionsArray = this.props.SummarySanctions.split('|');
                            if(sanctionsArray.includes('G')) {
                                airportSanctionText = CUBA_GOVERMENT_INFO;
                                airlineSanctionText = CUBA_GOVERMENT_INFO;
                            } else if(sanctionsArray.includes('C')) {
                                airportSanctionText = CUBA_CLIENT_INFO;
                                airlineSanctionText = CUBA_CLIENT_INFO;
                            } else {
                                airportSanctionText = this.props.placeHolder.PROHIBITED_TRAVEL_ON_ININERARY;
                                airlineSanctionText = this.props.placeHolder.PROHIBITED_TRAVEL_ON_ININERARY;
                            }  
                        }
                    }  else if(TripDetailsResponse.sanction === 'Airline|Airport') {
                        if(this.props.SummarySanctions !== null && this.props.SummarySanctions!='None') {
                            var sanctionsArray = this.props.SummarySanctions.split('|');
                            if(sanctionsArray.includes('G')) {
                                airportSanctionText = CUBA_GOVERMENT_INFO;
                                airlineSanctionText = CUBA_GOVERMENT_INFO;
                            } else if(sanctionsArray.includes('C')) {
                                airportSanctionText = CUBA_CLIENT_INFO;
                                airlineSanctionText = CUBA_CLIENT_INFO;
                            } else {
                                airlineSanctionText = this.props.placeHolder.PROHIBITED_TRAVEL_ON_ININERARY;
                                airportSanctionText = this.props.placeHolder.PROHIBITED_TRAVEL_ON_ININERARY;
                            }
                        }
                    }
                }

                if (this.props.cancel == "true") {
                    tripmodeclass = getIconsForCancelled("aircancel");
                    ConfirmedClass = "confirmed traveling-confirmed cancelled";
                } else {
                    tripmodeclass = checkDateStatus(TripDetailsResponse.EndGMT, TripDetailsResponse.StartGMT, "air",TripDetailsResponse.sanction,this.props.SummarySanctions);
                }

                if (TripDetailsResponse.TicketInfo.ticketed) {
                    if (this.props.PastFlg == true) {
                        ticketlogo = "tickets_icon_grey";
                    } else {
                        ticketlogo = "tickets_icon";
                    }
                } else {
                    ticketlogo = ""
                }

                status = gtrViewerStatus(TripDetailsResponse.Status, "Air");
                Heading = (
                    <h4 className="travel-data">{TripDetailsResponse.DepartureAirport}
                        <span className="to-arrow"></span>{TripDetailsResponse.ArrivalAirport}
                        <span className={ConfirmedClass}>{this.props.placeHolder[status]}</span>
                        <span className={ticketlogo}>&nbsp;</span>
                        <span className="flight-details">{TripDetailsResponse.segmentDuration} {TripDetailsResponse.flightStops}</span>
                        <span
                            className={"comment_icon " + this.context.countClassName}
                            onMouseEnter={this
                            .remainPopoverOpen
                            .bind(this, key)}
                            onMouseOut={this
                            .DisablePopOver
                            .bind(this, key)}
                            onMouseOver={this
                            .ShowPopOver
                            .bind(this, key)}>{this.context.count}

                            <div className={"popover_all " + gotoAllCommentsClass} id={key}>
                                <div className='fade in popover bottom pop_over_right traveller_popover'>
                                    <div className='arrow'></div>
                                    <div className='popover-content'>
                                        <div className='popover_custom'>
                                            <div>- {this.context.firstComment}</div>
                                            <div className={this.context.commentClassName}>- {this.context.secondComment}</div>
                                            {gotoAllCommentsRow}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>

                        <span className="trip-pnr-info"><br/>{this.props.DispTripId}<br/> {(TripDetailsResponse.BookingInfo.AtlasGDS == "SABRE" || TripDetailsResponse.BookingInfo.AtlasGDS == "AMADEUS" || TripDetailsResponse.BookingInfo.AtlasGDS == "GALILEO" || TripDetailsResponse.BookingInfo.AtlasGDS == "APOLLO") && checkDateForActiveLink(TripDetailsResponse.StartGMTTime.split('T'))
                                ? <a onClick={this.trackInfo}>
                                        {TripDetailsResponse.BookingInfo.RecordLocator}/{TripDetailsResponse.BookingInfo.AtlasGDS}</a>
                                : <span>
                                    <span title={TripDetailsResponse.BookingInfo.RecordLocator}>{addElipses(TripDetailsResponse.BookingInfo.RecordLocator,6)}</span>/{TripDetailsResponse.BookingInfo.AtlasGDS}</span>}<br/> {pcc}/{intractive}
                            <div className="air-prem-level-notification">
                                    {
                                        this.props.userProfileData.Remarks === "PREM-3"?<img src="../icons/prem3_icon.png" height="20px" width="20px" alt="prem3" aria-label="prem3"/>:
                                        this.props.userProfileData.Remarks === "PREM-2"?<img src="../icons/prem2_icon.png" height="20px" width="20px" alt="prem2" aria-label="prem2" />:
                                        this.props.userProfileData.Remarks === "PREM-1"?<img src="../icons/prem1_icon.png" height="20px" width="20px" alt="prem1" aria-label="prem1" />:null
                                    }
                                <div
                                    className="add_comment_air_section"
                                    onClick={this.CallStatusClick}
                                    data-toggle="modal"
                                    data-target={tripmodelid}>&nbsp;</div>
                            </div>
                        </span>
                    </h4>
                );
                tripSummary = (
                    <span>{TripDetailsResponse.Code} {TripDetailsResponse.flightNo} {TripDetailsResponse.DepartureDateTime} {this.props.placeHolder.DEP}: {TripDetailsResponse.depTime} {this.props.placeHolder.ARR}: {TripDetailsResponse.arrTime}
                        {TripDetailsResponse.noOfFlightdays}<span className="light_grey">
                            {this.props.placeHolder.SEAT}: {SeatAssignment === "" || SeatAssignment === undefined
                                ? TripDetailsResponse.Coach
                                : SeatAssignment}</span>
                    </span>
                );
                break;

            case "Hotel-CheckIn":
                Tripmode = "Hotel";
                /* Sanctions */
                var hotelSanctionText = null;
                if(TripDetailsResponse.sanction !== null) {
                    if(TripDetailsResponse.sanction === 'Hotel' || TripDetailsResponse.sanction === "true") {
                        if(this.props.SummarySanctions !== null && this.props.SummarySanctions!='None') {
                            var sanctionsArray = this.props.SummarySanctions.split('|');
                            if(sanctionsArray.includes('G')) {
                                hotelSanctionText = CUBA_GOVERMENT_INFO;
                            } else if(sanctionsArray.includes('C')) {
                                hotelSanctionText = CUBA_CLIENT_INFO;
                            } else {
                                hotelSanctionText = this.props.placeHolder.PROHIBITED_TRAVEL_ON_ININERARY;
                            } 
                        } 
                    }
                }

                /*
          *Hre Segments
        */

                HRESegments = this.props.HRESegments[this.props.TripDetailsResponse.TripId]
                if (HRESegments) {
                    let HRESegmentsArray = HRESegments.HRESegment;
                    if (!Array.isArray(HRESegmentsArray)) {
                        HRESegmentsArray = [HRESegmentsArray];
                    }
                    showOffer = HRESegmentsArray.find(item => item === TripDetailsResponse.segmentid + ":" + TripDetailsResponse.ConfirmationNumber) === undefined
                        ? false
                        : true;
                }

                /*
          *Hre Segments
        */

                //tripmodeclass="icon icon-traveling-hotel future"
                if (this.props.cancel == "true") {
                    tripmodeclass = getIconsForCancelled("hotelcancel");
                    ConfirmedClass = "confirmed traveling-confirmed cancelled";
                } else {
                    if(hotelSanctionText !== null && this.props.SummarySanctions != null) {
                        tripmodeclass="icon-sanction icon-traveling-hotel-sanction future";
                      } else  {
                        tripmodeclass="icon icon-traveling-hotel future";
                      }
                }
                var actionlink = false;
                var HotelConfirmationId = [];
                HotelConfirmationId = TripDetailsResponse.HotelConfirmationId;
                for (var i = 0; i < HotelConfirmationId.length; i++) {
                    if(TripDetailsResponse.ConfirmationNumber) {
                        var confirmationNumberSplit = TripDetailsResponse.ConfirmationNumber.split(" ");
                        var confirmationNumberNew = confirmationNumberSplit[0];
                    }
                    if (HotelConfirmationId[i] == confirmationNumberNew) {
                       
                        actionlink = true;
                        break;
                    }
                }
                var hotelNightstemp;
                if (TripDetailsResponse.hotelNights > 1) 
                    hotelNightstemp = TripDetailsResponse.hotelNights + " " + this.props.placeHolder.NIGHTS;
                else if (TripDetailsResponse.hotelNights == 1) {
                    hotelNightstemp = TripDetailsResponse.hotelNights + " " + this.props.placeHolder.NIGHT;
                } else {
                    hotelNightstemp = "";
                }

                if (this.state.popupheahing == true) {
                    headingvar = TripDetailsResponse.Chain + ", " + TripDetailsResponse.Name + ", " + TripDetailsResponse.CityCode;
                    if (headingvar.length > 20) {
                        showpopup = true;
                    }
                }
                if (typeof(gtrViewerStatus(TripDetailsResponse.Status, "Hotel")) != "undefined") {
                    status = gtrViewerStatus(TripDetailsResponse.Status, "Hotel").slice(0, -1);
                }
                //status=gtrViewerStatus(TripDetailsResponse.Status,"Hotel").slice(0, -1);
                SegType = status + gtrViewerSegment(TripDetailsResponse.SegType);
                popMover = this.popupheahing;
                popMout = this.disablepopupheahing;
                var offerTagClassName = '';
                if (this.props.ActiveFilter === "Future") {
                    offerTagClassName = 'offer-icon-top-active';
                } else if (this.props.ActiveFilter === "Past") {
                    offerTagClassName = 'offer-icon-top-past';
                } else if (this.props.ActiveFilter === "Cancelled") {
                    offerTagClassName = 'offer-icon-top-cancelled';
                }

                tripSummary = (
                    <div>{this.props.placeHolder.CHECK_IN}: {TripDetailsResponse.CheckInDateDisplay}&nbsp;<span className="light_grey">{TripDetailsResponse.ConfirmationNumber}</span>
                    </div>
                );
                Heading = (
                    <h4 className="travel-data">
                        <span
                            className="hotel_width"
                            onMouseOver={this.popupheahing}
                            onMouseOut={this.disablepopupheahing}>{TripDetailsResponse.Chain}, {TripDetailsResponse.Name}, {TripDetailsResponse.CityCode}</span>
                        <span>
                            <span className={ConfirmedClass}>{this.props.placeHolder[SegType]}</span>{showOffer
                                ? <span className={offerTagClassName}></span>
                                : null}
                            <span className="flight-details">{hotelNightstemp}</span>
                        </span>
                        <span
                            className={"comment_icon " + this.context.countClassName}
                            onMouseEnter={this
                            .remainPopoverOpen
                            .bind(this, key)}
                            onMouseOut={this
                            .DisablePopOver
                            .bind(this, key)}
                            onMouseOver={this
                            .ShowPopOver
                            .bind(this, key)}>{this.context.count}
                            <div className={"popover_all " + gotoAllCommentsClass} id={key}>
                                <div className='fade in popover bottom pop_over_right traveller_popover'>
                                    <div className='arrow'></div>
                                    <div className='popover-content'>
                                        <div className='popover_custom'>
                                            <div>- {this.context.firstComment}</div>
                                            <div className={this.context.commentClassName}>- {this.context.secondComment}</div>
                                            {gotoAllCommentsRow}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>

                        <span className="trip-pnr-info"><br/>{this.props.DispTripId}<br/> {actionlink
                                ? <span>
                                        {TripDetailsResponse.BookingInfo.RecordLocator.length > 6
                                            ? <div>
                                                    <div
                                                        className="record-locator-style"
                                                        onMouseOver={this.showRecordLocator}
                                                        onMouseOut={this.hideRecordLocator}>
                                                        {TripDetailsResponse.BookingInfo.RecordLocator}
                                                        {this.state.showRecordLocatorState && <div className="popover_all">
                                                            <div className='fade in popover bottom pop_over_right traveller_popover'>
                                                                <div className='arrow'></div>
                                                                <div className='popover-content'>
                                                                    <div className='popover_custom'>
                                                                        <div>{TripDetailsResponse.BookingInfo.RecordLocator}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>}</div>
                                                    <div className="smp-style">/SMP</div>
                                                </div>
                                            : <div>
                                                <span>{TripDetailsResponse.BookingInfo.RecordLocator}/SMP</span><br/></div>
}

                                    </span>
                                : <span>
                                    {(TripDetailsResponse.BookingInfo.AtlasGDS == "SABRE" || TripDetailsResponse.BookingInfo.AtlasGDS == "AMADEUS" || TripDetailsResponse.BookingInfo.AtlasGDS == "GALILEO" || TripDetailsResponse.BookingInfo.AtlasGDS == "APOLLO") && checkDateForActiveLink(TripDetailsResponse.StartGMTTime.split('T'))
                                        ? <a onClick={this.trackInfo}>
                                                {TripDetailsResponse.BookingInfo.RecordLocator}/{TripDetailsResponse.BookingInfo.AtlasGDS}</a>
                                        : <span><span title={TripDetailsResponse.BookingInfo.RecordLocator}>{addElipses(TripDetailsResponse.BookingInfo.RecordLocator,6)}</span>/{TripDetailsResponse.BookingInfo.AtlasGDS}</span>}<br/>
                                </span>
}
                            {pcc}/{intractive}
                            <div
                                className="add_comment"
                                onClick={this.CallStatusClick}
                                data-toggle="modal"
                                data-target={tripmodelid}>&nbsp;</div>
                        </span>
                    </h4>
                );
                moreInfo = TripDetailsResponse.Address + " " + TripDetailsResponse.phone;
                break;

            case "Hotel-CheckOut":
                Tripmode = "Hotel";
                //tripmodeclass="icon icon-traveling-hotel future"

                /* Sanctions */
                var hotelSanctionText = null;
                if(TripDetailsResponse.sanction !== null) {
                    if(TripDetailsResponse.sanction === 'Hotel' || TripDetailsResponse.sanction === "true") {
                        if(this.props.SummarySanctions !== null && this.props.SummarySanctions!='None') {
                            var sanctionsArray = this.props.SummarySanctions.split('|');
                            if(sanctionsArray.includes('G')) {
                                hotelSanctionText = CUBA_GOVERMENT_INFO;
                            } else if(sanctionsArray.includes('C')) {
                                hotelSanctionText = CUBA_CLIENT_INFO;
                            } else {
                                hotelSanctionText = this.props.placeHolder.PROHIBITED_TRAVEL_ON_ININERARY;
                            }  
                        }
                    }
                }

                if (this.props.cancel == "true") {
                    tripmodeclass = getIconsForCancelled("hotelcancel");
                    ConfirmedClass = "confirmed traveling-confirmed cancelled";
                } else {
                    if(hotelSanctionText !== null && this.props.SummarySanctions != null && this.props.SummarySanctions!='None') {
                        tripmodeclass="icon-sanction icon-traveling-hotel-sanction future";
                      } else  {
                        tripmodeclass="icon icon-traveling-hotel future";
                      }
                }

                var actionlink = false;
                var HotelConfirmationId = [];
                HotelConfirmationId = TripDetailsResponse.HotelConfirmationId;
                for (var i = 0; i < HotelConfirmationId.length; i++) {
                    if (HotelConfirmationId[i] == TripDetailsResponse.ConfirmationNumber) {
                        actionlink = true;
                        break;
                    }
                }
                var hotelNightstemp;
                if (TripDetailsResponse.hotelNights > 1) 
                    hotelNightstemp = TripDetailsResponse.hotelNights + " " + this.props.placeHolder.NIGHTS;
                else if (TripDetailsResponse.hotelNights == 1) {
                    hotelNightstemp = TripDetailsResponse.hotelNights + " " + this.props.placeHolder.NIGHT;
                } else {
                    hotelNightstemp = "";
                }

                if (this.state.popupheahing == true) {
                    headingvar = TripDetailsResponse.Chain + ", " + TripDetailsResponse.Name + ", " + TripDetailsResponse.CityCode;
                    if (headingvar.length > 20) {
                        showpopup = true;
                    }
                }

                popMover = this.popupheahing;
                popMout = this.disablepopupheahing;
                if (typeof(gtrViewerStatus(TripDetailsResponse.Status, "Hotel")) != "undefined") {
                    status = gtrViewerStatus(TripDetailsResponse.Status, "Hotel").slice(0, -1);
                }
                SegType = status + gtrViewerSegment(TripDetailsResponse.SegType);
                tripSummary = (
                    <div>{this.props.placeHolder.CHECK_OUT}: {TripDetailsResponse.CheckOutDateDisplay}&nbsp;<span className="light_grey">{TripDetailsResponse.ConfirmationNumber}</span>
                    </div>
                );
                Heading = (
                    <h4 className="travel-data">
                        <span
                            className="hotel_width"
                            onMouseOver={this.popupheahing}
                            onMouseOut={this.disablepopupheahing}>{TripDetailsResponse.Chain}, {TripDetailsResponse.Name}, {TripDetailsResponse.CityCode}</span>
                        <span>
                            <span className={ConfirmedClass}>{this.props.placeHolder[SegType]}</span>
                            <span className="flight-details">{hotelNightstemp}</span>
                        </span>
                        <span
                            className={"comment_icon " + this.context.countClassName}
                            onMouseEnter={this
                            .remainPopoverOpen
                            .bind(this, key)}
                            onMouseOut={this
                            .DisablePopOver
                            .bind(this, key)}
                            onMouseOver={this
                            .ShowPopOver
                            .bind(this, key)}>{this.context.count}
                            <div className={"popover_all " + gotoAllCommentsClass} id={key}>
                                <div className='fade in popover bottom pop_over_right traveller_popover'>
                                    <div className='arrow'></div>
                                    <div className='popover-content'>
                                        <div className='popover_custom'>
                                            <div>- {this.context.firstComment}</div>
                                            <div className={this.context.commentClassName}>- {this.context.secondComment}</div>
                                            {gotoAllCommentsRow}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                        <span className="trip-pnr-info"><br/>{this.props.DispTripId}<br/> {actionlink
                                ? <span>
                                        {TripDetailsResponse.BookingInfo.RecordLocator.length > 6
                                            ? <div>
                                                    <div
                                                        className="record-locator-style"
                                                        onMouseOver={this.showRecordLocator}
                                                        onMouseOut={this.hideRecordLocator}>
                                                        {TripDetailsResponse.BookingInfo.RecordLocator}
                                                        {this.state.showRecordLocatorState && <div className="popover_all">
                                                            <div className='fade in popover bottom pop_over_right traveller_popover'>
                                                                <div className='arrow'></div>
                                                                <div className='popover-content'>
                                                                    <div className='popover_custom'>
                                                                        <div>{TripDetailsResponse.BookingInfo.RecordLocator}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>}</div>
                                                    <div className="smp-style">/SMP</div>
                                                </div>
                                            : <div>
                                                <span>{TripDetailsResponse.BookingInfo.RecordLocator}/SMP</span><br/></div>
}

                                    </span>
                                : <span>
                                    {(TripDetailsResponse.BookingInfo.AtlasGDS == "SABRE" || TripDetailsResponse.BookingInfo.AtlasGDS == "AMADEUS" || TripDetailsResponse.BookingInfo.AtlasGDS == "GALILEO" || TripDetailsResponse.BookingInfo.AtlasGDS == "APOLLO") && checkDateForActiveLink(TripDetailsResponse.StartGMTTime.split('T'))
                                        ? <a onClick={this.trackInfo}>{TripDetailsResponse.BookingInfo.RecordLocator}/{TripDetailsResponse.BookingInfo.AtlasGDS}</a>
                                        : <span><span title={TripDetailsResponse.BookingInfo.RecordLocator}>{addElipses(TripDetailsResponse.BookingInfo.RecordLocator,6)}</span>/{TripDetailsResponse.BookingInfo.AtlasGDS}</span>}<br/>
                                </span>
}
                            {pcc}/{intractive}
                            <div
                                className="add_comment"
                                onClick={this.CallStatusClick}
                                data-toggle="modal"
                                data-target={tripmodelid}>&nbsp;</div>
                        </span>
                    </h4>
                );
                moreInfo = TripDetailsResponse.Address + " " + TripDetailsResponse.phone;
                break;

            case "Car-PickUp":
                Tripmode = "Car";
                //tripmodeclass="icon icon-traveling-car future"
                if (this.props.cancel == "true") {
                    tripmodeclass = getIconsForCancelled("carcancel");
                    ConfirmedClass = "confirmed traveling-confirmed cancelled";
                } else {
                    tripmodeclass = "icon icon-traveling-car future";
                }

                if (this.state.popupheahing == true) {
                    headingvar = TripDetailsResponse.VendorCode + ", " + TripDetailsResponse.VendorCompany + ", " + TripDetailsResponse.PickupCityCode;
                    if (headingvar.length > 20) {
                        showpopup = true;
                    }
                }
                popMover = this.popupheahing;
                popMout = this.disablepopupheahing;
                status = gtrViewerStatus(TripDetailsResponse.Confirmed, "Car");
                tripSummary = (
                    <div>{this.props.placeHolder.PICK_UP}: {TripDetailsResponse.PickUpDateDisplay}&nbsp;<span className="light_grey">{TripDetailsResponse.ConfirmationNumber} {TripDetailsResponse.Car}</span>
                    </div>
                );
                Heading = (
                    <h4 className="travel-data">
                        <span
                            className="hotel_width"
                            onMouseOver={this.popupheahing}
                            onMouseOut={this.disablepopupheahing}>{TripDetailsResponse.VendorCode} {TripDetailsResponse.VendorCompany == "" || TripDetailsResponse.VendorCompany == null
                                ? null
                                : ","}
                            {TripDetailsResponse.VendorCompany}
                            {TripDetailsResponse.PickupCityCode == "" || TripDetailsResponse.PickupCityCode == null
                                ? null
                                : ","}
                            {TripDetailsResponse.PickupCityCode}</span>
                        <span>
                            <span className={ConfirmedClass}>{this.props.placeHolder[status]}</span>
                            <span className="flight-details">{TripDetailsResponse.Days}</span>
                        </span>
                        <span
                            className={"comment_icon " + this.context.countClassName}
                            onMouseEnter={this
                            .remainPopoverOpen
                            .bind(this, key)}
                            onMouseOut={this
                            .DisablePopOver
                            .bind(this, key)}
                            onMouseOver={this
                            .ShowPopOver
                            .bind(this, key)}>{this.context.count}
                            <div className={"popover_all " + gotoAllCommentsClass} id={key}>
                                <div className='fade in popover bottom pop_over_right traveller_popover'>
                                    <div className='arrow'></div>
                                    <div className='popover-content'>
                                        <div className='popover_custom'>
                                            <div>- {this.context.firstComment}</div>
                                            <div className={this.context.commentClassName}>- {this.context.secondComment}</div>
                                            {gotoAllCommentsRow}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>

                        <span className="trip-pnr-info"><br/>{this.props.DispTripId}<br/> {(TripDetailsResponse.BookingInfo.AtlasGDS == "SABRE" || TripDetailsResponse.BookingInfo.AtlasGDS == "AMADEUS" || TripDetailsResponse.BookingInfo.AtlasGDS == "GALILEO" || TripDetailsResponse.BookingInfo.AtlasGDS == "APOLLO") && checkDateForActiveLink(TripDetailsResponse.StartGMTTime.split('T'))
                                ? <a onClick={this.trackInfo}>{TripDetailsResponse.BookingInfo.RecordLocator}/{TripDetailsResponse.BookingInfo.AtlasGDS}</a>
                                : <span><span title={TripDetailsResponse.BookingInfo.RecordLocator}>{addElipses(TripDetailsResponse.BookingInfo.RecordLocator,6)}</span>/{TripDetailsResponse.BookingInfo.AtlasGDS}</span>}<br/> {pcc}/{intractive}
                            <div
                                className="add_comment"
                                onClick={this.CallStatusClick}
                                data-toggle="modal"
                                data-target={tripmodelid}>&nbsp;</div>
                        </span>
                    </h4>
                );
                moreInfo = (
                    <span>{TripDetailsResponse.pickupAddress} {TripDetailsResponse.PickUpLocationCity}
                        {TripDetailsResponse.PickUpLocationProvince == "" || TripDetailsResponse.PickUpLocationProvince == null
                            ? null
                            : ","}
                        {TripDetailsResponse.PickUpLocationProvince}
                        {TripDetailsResponse.PickUpLocationCountry == "" || TripDetailsResponse.PickUpLocationCountry == null
                            ? null
                            : ","}
                        {TripDetailsResponse.PickUpLocationCountry}
                        {TripDetailsResponse.Phone == "" || TripDetailsResponse.Phone == null
                            ? null
                            : ","}
                        {TripDetailsResponse.Phone}
                    </span>
                );

                //  moreInfo=TripDetailsResponse.Address+" "+TripDetailsResponse.Phone;
                break;

            case "Car-DropOff":
                Tripmode = "Car";
                //tripmodeclass="icon icon-traveling-car future"
                if (this.props.cancel == "true") {
                    tripmodeclass = getIconsForCancelled("carcancel");
                    ConfirmedClass = "confirmed traveling-confirmed cancelled";
                } else {
                    tripmodeclass = "icon icon-traveling-car future";
                }

                if (this.state.popupheahing == true) {
                    headingvar = TripDetailsResponse.VendorCode + ", " + TripDetailsResponse.VendorCompany + ", " + TripDetailsResponse.DropOffCityCode;
                    if (headingvar.length > 20) {
                        showpopup = true;
                    }
                }
                popMover = this.popupheahing;
                popMout = this.disablepopupheahing;
                status = gtrViewerStatus(TripDetailsResponse.Confirmed, "Car");
                tripSummary = (
                    <div>{this.props.placeHolder.DROP_OFF}: {TripDetailsResponse.DropOffDateDisplay}&nbsp;<span className="light_grey">{TripDetailsResponse.ConfirmationNumber} {TripDetailsResponse.Car}</span>
                    </div>
                );
                Heading = (
                    <h4 className="travel-data">
                        <span
                            className="hotel_width"
                            onMouseOver={this.popupheahing}
                            onMouseOut={this.disablepopupheahing}>{TripDetailsResponse.VendorCode} {TripDetailsResponse.VendorCompany == "" || TripDetailsResponse.VendorCompany == null
                                ? null
                                : ", "}
                            {TripDetailsResponse.VendorCompany}
                            {TripDetailsResponse.DropOffCityCode == "" || TripDetailsResponse.DropOffCityCode == null
                                ? null
                                : ","}
                            {TripDetailsResponse.DropOffCityCode}</span>
                        <span>
                            <span className={ConfirmedClass}>{this.props.placeHolder[status]}</span>
                            <span className="flight-details">{TripDetailsResponse.Days}</span>
                        </span>
                        <span
                            className={"comment_icon " + this.context.countClassName}
                            onMouseEnter={this
                            .remainPopoverOpen
                            .bind(this, key)}
                            onMouseOut={this
                            .DisablePopOver
                            .bind(this, key)}
                            onMouseOver={this
                            .ShowPopOver
                            .bind(this, key)}>{this.context.count}
                            <div className={"popover_all " + gotoAllCommentsClass} id={key}>
                                <div className='fade in popover bottom pop_over_right traveller_popover'>
                                    <div className='arrow'></div>
                                    <div className='popover-content'>
                                        <div className='popover_custom'>
                                            <div>- {this.context.firstComment}</div>
                                            <div className={this.context.commentClassName}>- {this.context.secondComment}</div>
                                            {gotoAllCommentsRow}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                        <span className="trip-pnr-info"><br/>{this.props.DispTripId}<br/> {(TripDetailsResponse.BookingInfo.AtlasGDS == "SABRE" || TripDetailsResponse.BookingInfo.AtlasGDS == "AMADEUS" || TripDetailsResponse.BookingInfo.AtlasGDS == "GALILEO" || TripDetailsResponse.BookingInfo.AtlasGDS == "APOLLO") && checkDateForActiveLink(TripDetailsResponse.StartGMTTime.split('T'))
                                ? <a onClick={this.trackInfo}>{TripDetailsResponse.BookingInfo.RecordLocator}/{TripDetailsResponse.BookingInfo.AtlasGDS}</a>
                                : <span><span title={TripDetailsResponse.BookingInfo.RecordLocator}>{addElipses(TripDetailsResponse.BookingInfo.RecordLocator,6)}</span>/{TripDetailsResponse.BookingInfo.AtlasGDS}</span>}<br/> {pcc}/{intractive}
                            <div
                                className="add_comment"
                                onClick={this.CallStatusClick}
                                data-toggle="modal"
                                data-target={tripmodelid}>&nbsp;</div>
                        </span>
                    </h4>
                );
                moreInfo = (
                    <span>{TripDetailsResponse.DropOffAL} {TripDetailsResponse.DropOffLocationCity}
                        {TripDetailsResponse.DropOffStateProvince == "" || TripDetailsResponse.DropOffStateProvince == null
                            ? null
                            : ","}
                        {TripDetailsResponse.DropOffStateProvince}
                        {TripDetailsResponse.DropOffLocationCountry == "" || TripDetailsResponse.DropOffLocationCountry == null
                            ? null
                            : ","}
                        {TripDetailsResponse.DropOffLocationCountry}
                        {TripDetailsResponse.Phone == "" || TripDetailsResponse.Phone == null
                            ? null
                            : ","}{TripDetailsResponse.Phone}
                    </span>
                );

                //  moreInfo=TripDetailsResponse.Address+" "+TripDetailsResponse.Phone;
                break;

            case "Limo":
                Tripmode = "Limo";
                //tripmodeclass="icon icon-traveling-limo future"
                if (this.props.cancel == "true") {
                    tripmodeclass = getIconsForCancelled("limocancel");
                    ConfirmedClass = "confirmed traveling-confirmed cancelled";
                } else {
                    tripmodeclass = "icon icon-traveling-limo future";
                }

                status = gtrViewerStatus(TripDetailsResponse.Status, "Limo");

                popMover = this.popupheahing;
                popMout = this.disablepopupheahing;

                if (this.state.popupheahing == true) {

                    headingvar = TripDetailsResponse.LimoVendorCode + "," + TripDetailsResponse.LimoVendorName + ((TripDetailsResponse.LimostartCityCode == "")
                        ? ""
                        : ",") + TripDetailsResponse.LimostartCityCode;
                    if (headingvar.length > 20) {
                        showpopup = true;
                    }
                }
                var tripSourceURL = "";
                if (TripDetailsResponse.Source != null) {
                    if (TripDetailsResponse.ConfirmationNumber != null) {
                        if (EnvironmentDeployment == "E3") 
                            tripSourceURL = "https://ground.amexgbt.com/ground/" + TripDetailsResponse.ConfirmationNumber;
                        else 
                            tripSourceURL = "http://gbt.app-testing.mozio.com/ground/" + TripDetailsResponse.ConfirmationNumber;
                        }
                    }
                Heading = (
                    <h4 className="travel-data">
                        <span
                            className="limo_width"
                            onMouseOver={this.popupheahing}
                            onMouseOut={this.disablepopupheahing}>{TripDetailsResponse.LimoVendorCode},{TripDetailsResponse.LimoVendorName},{TripDetailsResponse.LimostartCityCode}</span>
                        <span>
                            <span className={ConfirmedClass}>{this.props.placeHolder[status]}</span>
                            <span className="flight-details">{} </span></span > {
                                TripDetailsResponse.Source == "MZO"
                                    ? <span className="trip-pnr-info">
                                            <br/>{this.props.DispTripId}<br/>
                                            <a target="_blank" onClick={this.trackLink} href={tripSourceURL}>{TripDetailsResponse.ConfirmationNumber}/MOZIO</a>
                                        </span>
                                    : <span className="trip-pnr-info"><br/>{this.props.DispTripId}<br/> {(TripDetailsResponse.BookingInfo.AtlasGDS == "SABRE" || TripDetailsResponse.BookingInfo.AtlasGDS == "AMADEUS" || TripDetailsResponse.BookingInfo.AtlasGDS == "GALILEO" || TripDetailsResponse.BookingInfo.AtlasGDS == "APOLLO") && checkDateForActiveLink(TripDetailsResponse.StartGMTTime.split('T'))
                                            ? <a onClick={this.trackInfo}>{TripDetailsResponse.BookingInfo.RecordLocator}/{TripDetailsResponse.BookingInfo.AtlasGDS}</a>
                                            : <span>{TripDetailsResponse.BookingInfo.RecordLocator}/{TripDetailsResponse.BookingInfo.AtlasGDS}</span>}<br/> {pcc}/{intractive}
                                    </span>}
                            </h4>
                );
                tripSummary = (
                    <span>{this.props.placeHolder.PICK_UP}: {TripDetailsResponse.limoPickUpDateDisplay}
                        {this.props.placeHolder.DROP_OFF}: {TripDetailsResponse.limoDropOffDateDisplay}
                        {TripDetailsResponse.ConfirmationNumber}</span>
                );
                moreInfo = (
                    <span>{TripDetailsResponse.PickUpLocation} {TripDetailsResponse.VendorPhone}</span>
                );
                break;

            //Bus Segments
            case "Bus":
                Tripmode = "Bus";
                if (this.props.cancel == "true") {
                    tripmodeclass = getIconsForCancelled("buscancel");
                    ConfirmedClass = "confirmed traveling-confirmed cancelled";
                } else {
                    //tripmodeclass = "icon icon-traveling-bus past"
                    tripmodeclass = checkDateStatus(TripDetailsResponse.BusArrivalDateTime.split('T'), TripDetailsResponse.BusDepartureDateTime.split('T'), "bus",null);
                }

                let statusCode = TripDetailsResponse.BusStatusCode !== null ? TripDetailsResponse.BusStatusCode:"MZ"
                status = gtrViewerStatus(statusCode, "Bus");

                var tripSourceURL = "";
                if (TripDetailsResponse.BusSegmentSource != null) {
                    if (TripDetailsResponse.BusConfirmationNumber != null) {
                        if (EnvironmentDeployment == "E3") 
                            tripSourceURL = "https://ground.amexgbt.com/ground/"+TripDetailsResponse.BusConfirmationNumber;
                        else 
                            tripSourceURL = "http://gbt.app-testing.mozio.com/ground/"+TripDetailsResponse.BusConfirmationNumber;
                    }
                }
                Heading = (
                    <h4 className="travel-data">{TripDetailsResponse.StartCityCode}
                        <span className="to-arrow"></span>{TripDetailsResponse.EndCityCode}
                        <span className={ConfirmedClass}>{this.props.placeHolder[status]}</span>
                        <br/>
                        <span className="trip-pnr-info">
                            <span><a target="_blank" style={{'cursor':'pointer'}} onClick={this.trackLink} href={tripSourceURL}>{TripDetailsResponse.BusConfirmationNumber}/MOZIO</a></span>
                        </span>
                    </h4>
                );
                tripSummary = (
                    <span>{TripDetailsResponse.VendorCode} {TripDetailsResponse.BusDepartureDate} {this.props.placeHolder.DEP}: {TripDetailsResponse.depTime} {this.props.placeHolder.ARR}: {TripDetailsResponse.arrivalTime}
                    </span>
                );
                moreInfo = (
                    <span>{TripDetailsResponse.BusDepartureStation} to {TripDetailsResponse.BusArrivalStation}</span>
                );
            break;

            case "Rail":
                Tripmode = "Rail";
                status = gtrViewerStatus(TripDetailsResponse.Status, "Rail");
            
                if (this.props.cancel == "true") {
                    tripmodeclass = getIconsForCancelled("railcancel");
                    ConfirmedClass = "confirmed traveling-confirmed cancelled";
                } else {
                    tripmodeclass = checkDateStatus(TripDetailsResponse.EndGMTTime.split('T'), TripDetailsResponse.StartGMTTime.split('T'), "rail",null);
                }
                var tripSourceURL = "";
                if (TripDetailsResponse.Source != null) {
                    if (TripDetailsResponse.ConfirmationNumber != null) {
                        if (EnvironmentDeployment == "E3") 
                            tripSourceURL = "https://ground.amexgbt.com/ground/" + TripDetailsResponse.ConfirmationNumber;
                        else 
                            tripSourceURL = "http://gbt.app-testing.mozio.com/ground/" + TripDetailsResponse.ConfirmationNumber;
                        }
                    }
                Heading = (
                    <h4 className="travel-data">{TripDetailsResponse.departureCode}
                        <span className="to-arrow"></span>{TripDetailsResponse.arrivalCode}
                        <span className={ConfirmedClass}>{this.props.placeHolder[status]}</span>
                        <span className="flight-details">{TripDetailsResponse.tripDuration}</span>
                        <span
                            className={"comment_icon " + this.context.countClassName}
                            onMouseEnter={this
                            .remainPopoverOpen
                            .bind(this, key)}
                            onMouseOut={this
                            .DisablePopOver
                            .bind(this, key)}
                            onMouseOver={this
                            .ShowPopOver
                            .bind(this, key)}>{this.context.count}
                            <div className={"popover_all " + gotoAllCommentsClass} id={key}>
                                <div className='fade in popover bottom pop_over_right traveller_popover'>
                                    <div className='arrow'></div>
                                    <div className='popover-content'>
                                        <div className='popover_custom'>
                                            <div>- {this.context.firstComment}</div>
                                            <div className={this.context.commentClassName}>- {this.context.secondComment}</div>
                                            {gotoAllCommentsRow}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                        {TripDetailsResponse.Source == "MZO"
                            ? <span className="trip-pnr-info">
                                    <br/>{this.props.DispTripId}<br/>
                                    <a target="_blank" onClick={this.trackLink} href={tripSourceURL}>{TripDetailsResponse.ConfirmationNumber}/MOZIO</a>
                                    <div
                                        className="add_comment"
                                        onClick={this.CallStatusClick}
                                        data-toggle="modal"
                                        data-target={tripmodelid}>&nbsp;</div>
                                </span>
                            : <span className="trip-pnr-info">
                                <br/>{this.props.DispTripId}<br/> {(TripDetailsResponse.BookingInfo.AtlasGDS == "SABRE" || TripDetailsResponse.BookingInfo.AtlasGDS == "AMADEUS" || TripDetailsResponse.BookingInfo.AtlasGDS == "GALILEO" || TripDetailsResponse.BookingInfo.AtlasGDS == "APOLLO") && checkDateForActiveLink(TripDetailsResponse.StartGMTTime.split('T'))
                                    ? <a onClick={this.trackInfo}>{TripDetailsResponse.BookingInfo.RecordLocator}/{TripDetailsResponse.BookingInfo.AtlasGDS}</a>
                                    : <span><span title={TripDetailsResponse.BookingInfo.RecordLocator}>{addElipses(TripDetailsResponse.BookingInfo.RecordLocator,6)}</span>/{TripDetailsResponse.BookingInfo.AtlasGDS}</span>}<br/> {pcc}/{intractive}
                               <div
                                    className="add_comment"
                                    onClick={this.CallStatusClick}
                                    data-toggle="modal"
                                    data-target={tripmodelid}>&nbsp;</div>
                            </span>
}
                    </h4>
                );
                tripSummary = (
                    <span>{TripDetailsResponse.carrier} {TripDetailsResponse.trainNumber}
                        {TripDetailsResponse.railDepartureDate}
                        {this.props.placeHolder.DEP}: {TripDetailsResponse.railDepartureTime}
                        {this.props.placeHolder.ARR}: {TripDetailsResponse.railArrivalTime}
                        {this.props.placeHolder.SEAT}: {TripDetailsResponse.SeatLocation}</span>
                );
                moreInfo = (
                    <span>{TripDetailsResponse.railStartPoint != null
                            ? TripDetailsResponse.railStartPoint
                            : null} {this.props.placeHolder.TO}
                        {TripDetailsResponse.railEndPoint != null
                            ? TripDetailsResponse.railEndPoint
                            : null}</span>
                );
                break;

            case "Other":
                Tripmode = "Other";
                //tripmodeclass="icon icon-traveling-other future"
                if (this.props.cancel == "true") {
                    tripmodeclass = getIconsForCancelled("othercancel");
                    ConfirmedClass = "confirmed traveling-confirmed cancelled";
                } else {
                    tripmodeclass = "icon icon-traveling-other future";
                }
                status = gtrViewerStatus(TripDetailsResponse.Status, "Other");

                Heading = (
                    <h4 className="travel-data">{TripDetailsResponse.startDateDisplay}
                        <span
                            className={"comment_icon " + this.context.countClassName}
                            onMouseEnter={this
                            .remainPopoverOpen
                            .bind(this, key)}
                            onMouseOut={this
                            .DisablePopOver
                            .bind(this, key)}
                            onMouseOver={this
                            .ShowPopOver
                            .bind(this, key)}>{this.context.count}
                            <div className={"popover_all " + gotoAllCommentsClass} id={key}>
                                <div className='fade in popover bottom pop_over_right traveller_popover'>
                                    <div className='arrow'></div>
                                    <div className='popover-content'>
                                        <div className='popover_custom'>
                                            <div>- {this.context.firstComment}</div>
                                            <div className={this.context.commentClassName}>- {this.context.secondComment}</div>
                                            {gotoAllCommentsRow}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>

                        <span className="trip-pnr-info"><br/>{this.props.DispTripId}<br/> {(TripDetailsResponse.BookingInfo.AtlasGDS == "SABRE" || TripDetailsResponse.BookingInfo.AtlasGDS == "AMADEUS" || TripDetailsResponse.BookingInfo.AtlasGDS == "GALILEO" || TripDetailsResponse.BookingInfo.AtlasGDS == "APOLLO") && checkDateForActiveLink(TripDetailsResponse.StartGMTTime.split('T'))
                                ? <a onClick={this.trackInfo}>{TripDetailsResponse.BookingInfo.RecordLocator}/{TripDetailsResponse.BookingInfo.AtlasGDS}</a>
                                : <span>{TripDetailsResponse.BookingInfo.RecordLocator}/{TripDetailsResponse.BookingInfo.AtlasGDS}</span>}<br/> {pcc}/{intractive}
                        </span>
                    </h4>
                );
                tripSummary = (
                    <span>{TripDetailsResponse.Description}<br/>{TripDetailsResponse.tlText}</span>
                );

        }
        var hotelRecObj = null;
        var missingRecObj = null;
        if (typeof(TripDetailsResponse.HotelRecommendation) != "undefined" && TripDetailsResponse.HotelRecommendation != null) {
            hotelRecObj = TripDetailsResponse.HotelRecommendation;
        }
        if (typeof(TripDetailsResponse.MissingRecommendation) != "undefined" && TripDetailsResponse.MissingRecommendation != null) {

            missingRecObj = TripDetailsResponse.MissingRecommendation;
        }
        var segmentNum = "ActiveSeg" + this.props.Key;        
        return (
            <div className="details_container">
                <AddStatusCommentsPopUp
                    placeHolder={this.props.placeHolder}
                    ref={"AddStatusCommentsPopUp_" + key}
                    ShowCallStatusAndComments={this.props.ShowCallStatusAndComments}
                    TripSegmentArray={this.props.TripSegmentArray}
                    handleParent={this.props.handleParent}
                    UpdateTripCommentCount={this.props.UpdateTripCommentCount}
                    UpdateCommentCount={this.props.UpdateCommentCount}
                    isCallStatus={this.props.isCallStatus}
                    SaveSucess={this.props.SaveSucess}
                    uniqueId={key}
                    DispTripId={this.props.DispTripId}
                    popupdata={TripDetailsResponse}
                    tripmodeclass={tripmodeclass}
                    userProfileData={userProfileData}/>

                <div id={segmentNum} className={classDetails} onClick={this.onOpenClick}>

                    <div className="side-icon col-sm-1">
                        <div className="top-line no-topline"></div>
                        <div className={tripmodeclass}></div>
                        <div className={lastChild}></div>
                    </div>
                    <div className="travel-details col-sm-8">
                        {TripDetailsResponse.isFraud?<div style={{color:"red",padding:"5px"}}>PENDING QUALITY CHECK</div>:null}
                        
                        {Heading}

                        {showpopup
                            ? <PopElipsisName email={headingvar}/>
                            : null}
                        {hotelSanctionText !== null ? <p className="santion-segment-style">{hotelSanctionText}</p> : null}
                        {airportSanctionText !== null ? <p className="santion-segment-style">{airportSanctionText}</p> : null}
                        <p className="travel-info">{tripSummary}</p>
                        {airlineSanctionText !== null ? <p className="santion-segment-style">{airlineSanctionText}</p> : null}
                        <p className="address">{moreInfo}</p>
                        </div>
                    {Tripmode != "Other"
                        ? <a href="#" className="caret-down-travel col-sm-3" tabIndex="0">&nbsp;</a>
                        : null}
                    <div className="clearfix"></div>
                </div>
                
                {this.context.openDetails
                    ? <TripDetails
                            openChatWindowMethod={this.props.openChatWindowMethod}
                            openedChatList={this.props.openedChatList}
                            orgId={this.props.orgId}
                            premGroup={this.props.premGroup}
                            ActiveFilter={this.props.ActiveFilter}
                            ClientName={this.props.ClientName}
                            openHreSegment={this.props.openHreSegment}
                            setOfferAsFalse={this.props.setOfferAsFalse}
                            HRESegments={HRESegments}
                            showOffer={showOffer}
                            pnrOfNotification={this.props.pnrOfNotification}
                            pnrValue={this.props.pnrValue}
                            openDocsTab={this.props.openDocsTab}
                            recordLocatorDocs={TripDetailsResponse.BookingInfo.RecordLocator}
                            userId={this.props.userId}
                            userName={this.props.userName}
                            callApiNotification={this.props.callApiNotification}
                            DispTripId={this.props.DispTripId}
                            tripmodeclass={tripmodeclass}
                            user={this.props.user}
                            userProfileData={userProfileData}
                            Segkey={this.props.Key}
                            popupdata={TripDetailsResponse}
                            notificationList={this.props.notificationList}
                            selectedNotifiedObject={this.props.selectedNotifiedObject}
                            placeHolder={this.props.placeHolder}
                            MealAssignmentList={MealAssignmentList}
                            SeatAssignmentList={SeatAssignmentList}
                            specificTD={TripDetailsResponse}
                            MiInfoList={MiInfoList}
                            Tripmode={Tripmode}/>
                    : null}

                {(TripDetailsResponse.Type == "Air" || TripDetailsResponse.Type == "Rail") && (typeof(TripDetailsResponse.HotelRecommendation) != "undefined" || typeof(TripDetailsResponse.MissingRecommendation) != "undefined")
                    ? <TripRecommender
                            placeHolder={this.props.placeHolder}
                            missingRecObj={missingRecObj}
                            hotelRecObj={hotelRecObj}
                            travellerId
                            ={key}/>
                    : null}
                <GameMessage message={this.state.GameMsg}/>
            </div>
        );
    }
});
function SeatAssignmentHelper(SeatAssignmentList, customerInfos, DispTripId) {
    if (SeatAssignmentList != null & customerInfos != null) {
        var Pax;
        var seatList = [];
        var customerList = toArrayObject(customerInfos.CustomerInfo);
        var PassengerList = toArrayObject(SeatAssignmentList.Passenger || SeatAssignmentList[0].Passenger);
        for (var i = 0; i < customerList.length; i++) {
            if (customerList[i].Customer.PersonName['@TravelerId'] == DispTripId) {
                Pax = customerList[i].Customer.PersonName['@ProviderPaxId'];
            }
        }
        for (var i = 0; i < PassengerList.length; i++) {
            if (PassengerList[i]['@PaxRef'] == Pax) {
                if (PassengerList[i].Seat != null) {
                    seatList.push(PassengerList[i].Seat['#text'])
                }
            }
        }
    }
    return seatList;
}
function MiInfoListHelper(MiList, customerInfos, DispTripId) {
    if (MiList != null & customerInfos != null) {
        var Pax;
        var MiInfoList = [];
        var customerList = toArrayObject(customerInfos.CustomerInfo);
        // For missing MultiMIInfo
        var PassengerList = toArrayObject(MiList);
        if (MiList.MultiMIInfo != null && MiList.MultiMIInfo != undefined) {
            PassengerList = toArrayObject(MiList.MultiMIInfo);
        }
        for (var i = 0; i < customerList.length; i++) {
            var CustomerArray = toArrayObject(customerList[i].Customer);
            for (var j = 0; j < CustomerArray.length; j++) {
                if (CustomerArray[j].PersonName['@TravelerId'] == DispTripId) {
                    Pax = CustomerArray[j].PersonName['@ProviderPaxId'];
                }
            }
        }
        for (var i = 0; i < PassengerList.length; i++) {
            if (PassengerList[i]['PaxRef'] == Pax) {
                MiInfoList.push(PassengerList[i]);
            }
        }
        // Resolves repeated popping up empty MI information
        /* if(MiInfoList.length == 0){
    MiInfoList =  PassengerList;
    }
    console.log(MiInfoList);*/
    }
    return MiInfoList;
}
function MealAssignmentHelper(SeatAssignmentList, customerInfos, DispTripId) {
    if (SeatAssignmentList != null & customerInfos != null) {
        var Pax;
        var MealList;
        var customerList = toArrayObject(customerInfos.CustomerInfo);
        var PassengerList = toArrayObject(SeatAssignmentList.Passenger);
        for (var i = 0; i < customerList.length; i++) {
            var CustomerArray = toArrayObject(customerList[i].Customer);
            for (var j = 0; j < CustomerArray.length; j++) {
                if (CustomerArray[j].PersonName['@TravelerId'] == DispTripId) {
                    Pax = CustomerArray[j].PersonName['@ProviderPaxId'];
                }
            }
        }
        for (var i = 0; i < PassengerList.length; i++) {
            if (PassengerList[i]['@PaxRef'] == Pax) {
                if (PassengerList[i].Meal != null) {
                    MealList = PassengerList[i].Meal['#text']
                }
            }
        }
    }
    return MealList;
}
const addElipses = (text,n) => {
    if(!text)
        return text;
    return (text.length > n) ? text.substr(0, n - 1) + '...' : text;
}
export default Segments;
