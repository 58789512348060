import React from 'react';
import { changeNewDateFormat } from '../../../services/utils';
export default class TravelerItineraryItem extends React.Component {
	render() {
		var emailSentDate = changeNewDateFormat(this.props.emailDate);
		return (
			<div className="table_row">
				<div className="color_blue_normal table_cell">
					<span className="table_divider">{this.props.mailStatusText} </span>
				</div>
				<div className="table_cell">
					<div className="align_email">
						<span className="color_dark_grey"> : {emailSentDate} to &nbsp;</span>
						<span className="delivered_email">{this.props.emailId}</span>
					</div>
				</div>
			</div>
		);
	}
}
