import React from "react";
import CallStatusIcon from "../CallStatusCommons/CallStatusIcon"
import _ from "underscore";
import {getClassName, CallStatusiconFilterAndSort}  from '../../../commons/utils'
export default class CallStatusInTravelerItinerary extends React.Component{

  render () {

    if(this.props.CallStatusArr==null||this.props.CallStatusArr.length==0){
      return(
        <div></div>
      )
    }
    let thisref=this;
    let tempCallStatusArray=this.props.CallStatusArr.map(function(CallStatus,i){
      {
        if(CallStatus==null|| CallStatus=="null"){
          return{
            className:"",
            CallStatus:"",
            hoverText:"",
            text:thisref.props.RecordLoc[i],
            onPNRClick:thisref.props.onPNRClick

          }
        }else{
          return {
                className:getClassName(CallStatus["@code"]),
                CallStatus:CallStatus["@code"],
                text:thisref.props.RecordLoc[i],
                hoverText:CallStatus["#text"],
               onPNRClick:thisref.props.onPNRClick

          };
        }
      }
    });
    let CallStatusArray={
      "tempCallStatusArray":tempCallStatusArray
    }

    let callStatus=CallStatusiconFilterAndSort(CallStatusArray.tempCallStatusArray).map((tempCallStatus,key) =>
    {
        if(tempCallStatus.className!=""){
          return  <CallStatusIcon onClickIcon={tempCallStatus.onPNRClick} key={key} text={tempCallStatus.text} className={tempCallStatus.className} hover={true} hoverText={tempCallStatus.hoverText}/>
        }
        else{
          return null;
        }
      }
    );

    return(
      <div>
        <ul className="pnrs_list">
          {callStatus}
        </ul>
      </div>
    );
  }
}

