/**
  * @ SecurityQuestion Component
  * @ File - securityQuestion.js
  * Security questions related functionality
  */
import React, { Component } from 'react';
import HTTP from '../../services/httpservice';

/* Define Constants */
const SEQURITY_QUESTION_URL = '/user/registration/v1?master=securityquestions'
const FORMCLASS = 'form-control';
const FORM_ERROR_CLASS = 'form-control error-icon';
const FORM_SUCESS_CLASS = 'form-control success-icon';
const FORMCLASS_QUESTION = 'btn btn-default dropdown-toggle custom-dropdown ';
const FORM_ERROR_CLASS_QUESTION = 'btn btn-default dropdown-toggle custom-dropdown error-icon-dropdown';
const INVALID_SECURITY_QUESTION = "Please select a Security Question.";
const INVALID_SECURITY_ANSWER = "Please enter at least 3 and no more than 50 characters.";

class SecurityQuestion extends Component {

    // Constructor called before render function
    // The object that is returned is assigned to  this.state
    constructor(props) {
        super(props);
        this.state = {
            questionsArray: ['testing'],
            selectedQuestion: [
                'Select one',
                'Select one',
                'Select one'
            ],
            answer: [
                '',
                '',
                ''
            ],
            formClass: [
                FORMCLASS,
                FORMCLASS,
                FORMCLASS
            ],
            formClassQuestion: [
                FORMCLASS_QUESTION,
                FORMCLASS_QUESTION,
                FORMCLASS_QUESTION
            ],
            answerError: [
                '',
                '',
                ''
            ],
            quetionError: [
                '',
                '',
                ''
            ]
        }
    }

    // API call to get Security questions
    // Assign the response to questionsArray
    componentWillMount() {
        let self = this;
        HTTP.get(SEQURITY_QUESTION_URL)
            .then(function (response) {
                self.setState({
                    questionsArray: response.Questions
                })
            });
    }

    // On change of security questions functionality
    // Select security questions from drop down

    _handleChange(iterator, event) {
        let selectedQuestion = this.state.selectedQuestion;
        selectedQuestion[iterator] = event.target.value;
        this.setState({ selectedQuestion });
    }


    // Set answer for security questions
    _setAnswer(iterator, event) {
        let answer = this.state.answer;
        answer[iterator] = event.target.value;
        this.setState({ answer });
    }
    // Validate security question answer and set state value
    _validateAnswer(i) {
        let formClass = this.state.formClass;
        let answerError = this.state.answerError;
        if (this.state.answer[i].length < 3 || this.state.answer[i].length > 50) {
            formClass[i] = FORM_ERROR_CLASS;
            answerError[i] = INVALID_SECURITY_ANSWER;
            if (this.props.valid) {
                this.props.setFalse('securityQuestion');
            }
            this.setState({ formClass, answerError })
        } else {
            formClass[i] = FORM_SUCESS_CLASS;
            answerError[i] = '';
            this._formatResponse()
            this.setState({ formClass, answerError })
        }
    }
    _validateQuestion(i) {
        let quetionError = this.state.quetionError;
        let formClassQuestion =this.state.formClassQuestion;
        if (this.state.selectedQuestion[i] === 'Select one') {
            quetionError[i] = INVALID_SECURITY_QUESTION;
            formClassQuestion[i] =FORM_ERROR_CLASS_QUESTION;
            this.setState({ quetionError ,formClassQuestion});
            if (this.props.valid) {
                this.props.setFalse('securityQuestion');
            }
        } else {
            quetionError[i] = '';
            formClassQuestion[i] =FORMCLASS_QUESTION;
            this.setState({ quetionError });
            this._formatResponse()
        }
    }
    _formatResponse() {
        for (var i = 0; i < this.state.selectedQuestion.length; i++) {
            if (this.state.selectedQuestion[i] === 'Select one' || this.state.answer[i] === '') {
                this.props.setFalse('securityQuestion');
                return
            }
        }
        let answerArray = this.state.answer;
        let response = this.state.selectedQuestion.map(function (item, i) {
            return {
                "Question": item,
                "Answer": answerArray[i]
            }
        });
        this.props.setValue('securityQuestion', response);

    }
    // Prevent default action
    _handleOnClick(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    // Set dropdown value
    setQuestion(i, question) {
        let selectedQuestion = this.state.selectedQuestion;
        selectedQuestion[i] = question.QuestionText;
        this.setState({ selectedQuestion })
    }
    // Render security questions component
    render() {
        let quetionSet = [];
        let self = this;
        for (let i = 0; i < 3; i++) {
            let options = this.state.questionsArray.map((item, j) => {
                if (self.state.selectedQuestion.indexOf(item.QuestionText) === -1) {
                    return <li role="presentation" key={j} onClick={this.setQuestion.bind(this, i, item)} value={item.QuestionText} id={"option_" + i}><a role="menuitem" tabIndex="-1" href="#">{item.QuestionText}</a></li>
                }
                else if (self.state.selectedQuestion[i] === item.QuestionText) {
                    return <li role="presentation" key={j} onClick={this.setQuestion.bind(this, i, item)} value={item.QuestionText} id={"option_" + i}><a role="menuitem" tabIndex="-1" href="#">{item.QuestionText}</a></li>
                }
                return null
            }
            );

            quetionSet.push(
                <div key={i} className="form-group" id={"security-qn-" + i}>
                    <label for="firstSecurityQn">Security Question {i + 1}*:</label>
                    <div className="dropdown custom-dropdown-div">
                        <button className={this.state.formClassQuestion[i]} onBlur={this._validateQuestion.bind(this, i)} type="button" id="menu1" data-toggle="dropdown">{this.state.selectedQuestion[i]}
                            <span className="caret"></span></button>
                        <ul className="dropdown-menu custom-dropdown-menu" role="menu" aria-labelledby="menu1" onClick={this._handleOnClick.bind(this)} onBlur={this._validateQuestion.bind(this, i)} value={this.state.selectedQuestion[i]} onChange={this._handleChange.bind(this, i)}>
                            {options}
                        </ul>
                        <span className="error">{this.state.quetionError[i]}</span>
                    </div>
                    <input type="text" className={this.state.formClass[i]} id="firstSecurityQnAns" onBlur={this._validateAnswer.bind(this, i)} value={this.state.answer[i]} placeholder="Answer here" onChange={this._setAnswer.bind(this, i)} />
                    <span className="error">{this.state.answerError[i]}</span>
                </div>
            );
        }

        return (
            <div>
                {quetionSet}
            </div>
        );
    }
}
export default SecurityQuestion;
