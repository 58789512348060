import React, {Component} from 'react';
import WebNotification  from 'react-web-notification';

class ToasterNotification extends Component{

  constructor(props){
    super(props);
    this.state={
      ignore: true,
      title : '',
      options:{
        body:'',
        data:''
      }
    }
  }

  onNotificationClick(e,tag){
      window.focus();
      if(this.state.title==='Proactive Traveler Care Notification')
       this.props.toasterClick(e.currentTarget.data.caseObj,e.currentTarget.data.caseArr);
      else
        this.props.toasterClick(e.currentTarget.data);
  }

  removeClick() {
  }

  showNotification(body,data,icon,title){
      this.setState({
        ignore: false,
        title,
        options: {
           body,
           data,
           icon
        }
      })
  }

  render(){
    return(
      <WebNotification
        ignore={this.state.ignore}
        title={this.state.title}
        timeout={5000}
        askAgain={true}
        options={this.state.options}
        onClick={this.state.title !== "Permissions Notification"? this.onNotificationClick.bind(this) : this.removeClick.bind(this)}
      />
    )
  }

}

export default ToasterNotification;
