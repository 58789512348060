import React from "react";
import UserInfoField from "../../commons/UserInfo.js"
import AddNotes from "../../commons/NotesInStatusComments.js"
import ConfirmDeleteTab from "../CallStatusComponents/ConfirmDeletePopup";

export default class DeleteCommentResolveIssue extends React.Component {
  constructor(props) {
    super(props);
    this.state = { CommentText: "", userInfo: "", deleteButtonState: false, reset: false };
    this.noteText = "";
    this.userInfo = "";
    this._Data = this._Data.bind(this);
    this._Detail = this._Detail.bind(this);
  }
  _Data(Text) {
    this.noteText = Text;
    var del = this.checkForDelete.bind(this);
    del();
  }
  _Detail(Text) {
    this.userInfo = Text;
    var del = this.checkForDelete.bind(this);
    del();
  }
  shouldComponentUpdate(newProps, newState) {
    if (this.props.fullIndex == newProps.fullIndex && this.state == newState) {
      return false;
    }
    return true
  }
  _onClickCancelButton() {
    this.setState({
      reset: !this.state.reset,
      deleteButtonState: false
    });
    this.userInfo = "";
    this.noteText = "";
  }


  checkForDelete() {
    if (validationOfForm(this.userInfo, this.noteText)) {
      this.setState({ deleteButtonState: true });
    } else {
      this.setState({ deleteButtonState: false });
    }
  }
  _onClickDeleteButton() {
    if (this.state.deleteButtonState) {
      window.$('#ConfirmDelete' + this.props.uniqueId).modal();
      window.$('#cancel' + this.props.uniqueId).attr("disabled", true);
    } else {
      return false;
    }
  }
  onKeyPressEvent() {
    window.$('.modal-head').keydown(function (e) {
      if (window.$('.last').is(":focus") && (e.which || e.keyCode) == 9) {
        e.preventDefault();
        window.$('.first').focus();
      }
    });
  }

  enableCancel() {
    window.$('#cancel' + this.props.uniqueId).attr("disabled", false);
    window.$('#cancel' + this.props.uniqueId).focus();
  }

  render() {

    var DeleteButtonClass = "save_button";
    if (this.state.deleteButtonState) {
      DeleteButtonClass = " save_button enabled";
    }


    var id = "DeletePopup" + this.props.uniqueId;
    return (
      <span>
        <div className="modal fade modal-head" onKeyUp={this.onKeyPressEvent.bind()} id={id} role="dialog" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog">
            <div className="modal-content delete_comment_popup">
              <div className="modal-header">
                <div className="col-sm-12">    <button type="button first" onClick={this._onClickCancelButton.bind(this)} className="close" data-dismiss="modal">&times;</button>
                  <h4 className="modal-title">{this.props.placeHolder.DELETE_TAB}</h4>
                </div>  </div>
              <div className="modal-body">
                <div className="col-sm-12">

                  <div className="margin_btm_5">  <span className={this.props.icon} id="valign_middle">&nbsp;</span>{this.props.fullIndex}  </div>


                  <div className="col-sm-7 margin_btm_5">{this.props.placeHolder.EXISTING_NOTES}</div>   <div className="col-sm-12 nopadding break_word margin_btm_5">  {this.props.notes}</div>
                </div>

                <div className="col-sm-12"><br />

                  <div className="col-sm-6 margin_btm_5">
                    <div className="margin_btm_5 userinfo-label">{this.props.placeHolder.USER_INFO}</div>
                    <UserInfoField placeHolder={this.props.placeHolder} reset={this.state.reset} Detail={this._Detail.bind(this)} />
                  </div>
                </div>
                <AddNotes placeHolder={this.props.placeHolder} notesLabel={this.props.placeHolder.REASON} reset={this.state.reset} Data={this._Data.bind(this)} />
                <div className="col-sm-12"><br /><br />
                  <div className="col-sm-6 inline-block pull-right width-for-popup-buttons">
                    <button className={DeleteButtonClass} data-toggle="modal" onClick={this._onClickDeleteButton.bind(this)}>{this.props.placeHolder.UPDATE}</button>

                    <button id={"cancel" + this.props.uniqueId} className="button last" data-dismiss="modal" onClick={this._onClickCancelButton.bind(this)}>{this.props.placeHolder.CANCEL}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <ConfirmDeleteTab placeHolder={this.props.placeHolder} ef={"qwert_" + this.props.uniqueId} onClickNo={this.enableCancel.bind(this)} uniqueId={this.props.uniqueId} cancelButton={this._onClickCancelButton.bind(this)} tripIdsegmentArray={this.props.tripIdsegmentArray} SegmentId={this.props.SegmentId} SaveSucess={this.props.SaveSucess} DeleteCallStatus={this.props.DeleteCallStatus} UpdateTable={this.props.UpdateTable} UpdateCommentCount={this.props.UpdateCommentCount} RecordLocator={this.props.RecordLocator} source={this.props.source} issueType={this.props.issueType} scope={this.props.scope} title={this.props.title} tripId={this.props.tripId} commentId={this.props.commentId} travelerId={this.props.travelerId} userInfo={this.userInfo} noteText={this.noteText} />
      </span>
    );
  }
}

function validationOfForm(userInfo, notes) {


  if (validateUserInfo(userInfo) && validateNotes(notes)) {
    return true;
  } else {
    return false;
  }
}
function validateUserInfo(userInfo) {
  if (userInfo != "") {
    //Regex Exp including caracters,Numbers
    //let re =/^[a-zA-Z0-9]*$/;
    let re = /^[a-zA-Z0-9]{3,7}$/g;
    return re.test(userInfo);
  } else {
    return false;
  }
}


function validateNotes(text) {
  //var CommentText = text.replace(/\s/g,'');
  if (text != "") {
    //Regex Exp including caracters,Numbers,hypens,commas,periods
    let re = /^[a-zA-Z0-9\n .,-]{3,90}$/g;
    return re.test(text);
  } else {
    return false;
  }
}
