import React from "react";
import Modal from "../CommonModels/Modal.jsx"
import createClass from 'create-react-class';
import MoreTravellersModal from "../CommonModels/moretravellersmodel"
let LimoTripDetails = createClass({
  handleDocsClick : function(){
    let openTab = this.props.openDocsTab;
    openTab();
  },
  render:function(){
    var LimoPickUpDatearr=this.props.specificTD.limoPickUpDateDisplay.split(" ");
    var LimoDropOffDatearr=this.props.specificTD.limoDropOffDateDisplay.split(" ");
    var miinfostate=false;
var disableclassmoreinformation;
var disableclassMoreTravellers;
var moreinformation=true;
    if(this.props.specificTD.MoreTravelers.length > 1){
              disableclassMoreTravellers = "";
              moreinformation = true;
          }else{
            disableclassMoreTravellers = "disable";
            moreinformation = false;
          }

          if(this.props.specificTD.MIInformation.ShowColumnAs != null || this.props.specificTD.MIInformation.Text != null ||(Array.isArray(this.props.MiInfoList) && this.props.MiInfoList.length >0)){
              disableclassmoreinformation = "";
              miinfostate = true;
          }else{
            disableclassmoreinformation = "disable";
            //miinfostate = false;
          }
    return(
      <div className="trip-details">
        <div className="side-icon">
          <div className="inner-line"></div>
        </div>
          <div className="link-holder">
           <div className="btns">
         <div className="col-sm-4">
                <a  href="#" className={disableclassmoreinformation} data-toggle="modal" data-target="#myModal">{this.props.placeHolder.MI_INFORMATION}</a>
                {miinfostate ? <Modal placeHolder={this.props.placeHolder} CustomFieldValue={this.props.specificTD.MIInformation}/>:null}
            </div>
            <div className="col-sm-4">
                <a  href="#"  data-toggle="modal" className={disableclassMoreTravellers} data-target="#myMoreTravellersModal">{this.props.placeHolder.MORE_TRAVELERS}</a>
                {moreinformation ? <MoreTravellersModal placeHolder={this.props.placeHolder} CustomFieldValue={this.props.specificTD.MoreTravelers} />:null}
            </div>
            <div className="col-sm-4">
                  <a  href="#" onClick={this.handleDocsClick.bind(this)}>Docs</a>
            </div>
          </div>
        </div>

        <div className="flight-time">
                        <p className="time">{this.props.placeHolder.PICK_UP}</p>
                        <p className="date">{LimoPickUpDatearr[0]}</p>
                        <div className="duration_proxy">
&nbsp;
                            </div>
                        <p className="time">{this.props.placeHolder.DROP_OFF}</p>
                        <p className="date">{LimoDropOffDatearr[0]}</p>
        </div>
        <div className="details-line"></div>
        <div className="flight-details">
                      <p className="airport">&nbsp;{this.props.specificTD.PickUpLocation}</p>
                        <p className="airport">&nbsp;</p>
                        <p className="city">&nbsp;</p>
<p className="terminal"></p>
                        <p className="airport">{this.props.specificTD.DropOffLocation}</p>
                        <p className="terminal"></p>
        </div>
<div className="limo_rate">
  <p>{this.props.placeHolder.RATE} :{this.props.specificTD.Rate}</p>
</div>

        <div className="clearfix"></div>
        </div>
    )
  }
});

export default LimoTripDetails;
