/**
  * @ Organization Component
  * @ File - Organization.js
  * Organization functionality includes API call, show suggestions etc.,
  */
import React, { Component } from 'react';
import HTTP from '../../services/httpservice';

/* Define constants */
const alphaSpaceNumberRegex = /^[a-zA-Z0-9\s,)(./-]+$/i;
const FORMCLASS = 'form-control';
const FORM_ERROR_CLASS = 'form-control error-icon';
const FORM_SUCESS_CLASS = 'form-control success-icon';
const SPECIAL_CHAR_ERROR = 'Special Characters are not allowed';
const ERROR_MESSAGE_NOT_FROM_LIST = 'Please enter a valid Organization. If you do not know the Organization please ask your American Express Global Business Travel contact.'
const ERROR_MESSAGE_FOR_NUM_CHAR = 'Organization must be between 3 - 20 characters';
const ORGANISATION_URL = '/utilities/organizations/v1'

Array.prototype.isExits = function (orgName) {
    let arr = this;
    for (let i = 0; i < arr.length; i++) {
        let obj = arr[i];
        if (obj.OrganizationName == orgName) {
            return true;
        }
    }
    return false;
}

class Organization extends Component {

    // Constructor called before render function
    // The object that is returned is assigned to  this.state
    constructor(props) {
        super(props);
        this.state = {
            organisationName: '',
            suggestionArray: [],
            showSuggestion: false,
            formclass: FORMCLASS,
            errorMessage: ''
        }
    }

    // Set organization drop down options
    // API call to get drop down options
    _setOption(e) {
        let self = this;
        this.setState({ organisationName: e.target.value });
        if (e.target.value.length >= 3) {
            let organisationName = e.target.value;
            HTTP.get(ORGANISATION_URL + '?orgName=' + organisationName)
                .then(function (response) {
                    self.setState({
                        suggestionArray: response.Organizations,
                        showSuggestion: true,
                    })
                });
        } else {
            this.setState({ showSuggestion: false });
        }
        if (this.props.valid) {
            this.props.setFalse('organisation');
        }
    }

    // Onclick of options - Select options from drop down
    // Change state value
    _selectOption(obj) {
        this.setState({
            organisationName: obj.OrganizationName,
            //suggestionArray   : [],
            errorMessage: '',
            formclass: FORM_SUCESS_CLASS
        });
        this.props.setValue('organisation', obj.organisationName);
        var EmailDomainArray = [];
        obj.EmailDomain.indexOf(',') === - 1 ? EmailDomainArray.push(obj.EmailDomain) : EmailDomainArray = obj.EmailDomain.split(',');
        this.props.setEmailDomain(EmailDomainArray);
    }

    // validation on organization value
    // Set error messages depends to the validation
    _validateOrganisation() {
        var self = this;
        setTimeout(function () {
            if (self.state.organisationName.length < 3 || self.state.organisationName.length > 20) {
                self.setState({
                    formclass: FORM_ERROR_CLASS,
                    errorMessage: ERROR_MESSAGE_FOR_NUM_CHAR,
                    showSuggestion: false
                })
            } else if (!alphaSpaceNumberRegex.test(self.state.organisationName)) {
                self.setState({
                    formclass: FORM_ERROR_CLASS,
                    errorMessage: SPECIAL_CHAR_ERROR,
                    showSuggestion: false
                })
            } else if (!self.state.suggestionArray.isExits(self.state.organisationName)) {
                self.setState({
                    formclass: FORM_ERROR_CLASS,
                    errorMessage: ERROR_MESSAGE_NOT_FROM_LIST,
                    showSuggestion: false
                })
            } else {
                self.setState({
                    formclass: FORM_SUCESS_CLASS,
                    errorMessage: '',
                    showSuggestion: false
                })
            }
        }, 200);
    }

		// On key Up/Down/Enter functionality for drop down
		_handleOnKeyUp(e) {
				var $listItems 	= window.$('li');
				var key 				= e.keyCode;
				var $selected 	= $listItems.filter('.selected');
				var $current;
				if ( key != 40 && key != 38 && key != 13 ) return;
				if(key == 40) {
					    $listItems.removeClass('selected');
						if ( ! $selected.length || $selected.is(':last-child') ) {
								$current = $listItems.eq(0);
						} else {
								$current = $selected.next();
						}
						$current.addClass('selected');
				}
				else if ( key == 38 ) {
						$listItems.removeClass('selected');
		        if ( ! $selected.length || $selected.is(':first-child') ) {
		            $current = $listItems.last();
		        } else {
		            $current = $selected.prev();
		        }
						$current.addClass('selected');
    		}
				if(e.key == 'Enter' || e.keyCode==13){
		        let currentOrganisation;
		        this.state.suggestionArray.map(function(organisation,i){
		        		if(organisation.OrganizationName == window.$('.selected').text())
		            		currentOrganisation = organisation;
		        });
		        this._selectOption(currentOrganisation)
		        this.setState({showSuggestion : false});
				}
		}

    changeBorderColor(task) {
        task === 'red'
            ?
            this.setState({ formclass: FORM_ERROR_CLASS })
            :
            this.setState({ formclass: FORM_SUCESS_CLASS })
    }

    // Render organization component
    // Show Organization suggestions / error  messages
    render() {
        var self = this;
        let suggestion = "";
        if (typeof this.state.suggestionArray != "undefined") {
            suggestion = this.state.suggestionArray.map((item, i) =>
                <li onClick={self._selectOption.bind(self, item)} tabIndex="1" key={i}><p className="organisation">{item.OrganizationName}</p></li>
            )
        }
        return (<div>
            <div className="form-group" >
                <label for="organization">Organization*:</label>
                <input type="text" value={this.state.organisationName} id="org-input" className={this.state.formclass} placeholder="e.g., Mckinsey" onChange={this._setOption.bind(this)} onBlur={this._validateOrganisation.bind(this)} onKeyUp={this._handleOnKeyUp.bind(this)} />
                {this.state.errorMessage != '' ? <p className='error_absolute'>{this.state.errorMessage}</p> : null}
                {this.state.showSuggestion ? <div className="organisation_option"><ul id="filter_options">{suggestion}</ul></div> : null}
            </div>
        </div>);
    }
}
export default Organization;
