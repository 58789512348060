import React from "react";
import createClass from 'create-react-class';
let TicketModel = createClass({
  render: function () {
let tableData = [];
    for(var i = 0; i < this.props.Ticketing.SortedFareList.length; i++){
      var coupon = this.props.Ticketing.SortedFareList[i].FareCoupon;
      var lineCode = this.props.Ticketing.SortedFareList[i].AirlineCode;
      var flightNum = this.props.Ticketing.SortedFareList[i].FLTNumber;
      let  specialDate=this.props.specificTD.DepartureDateOnly;
       var fareStartCity = this.props.Ticketing.SortedFareList[i].FareStartCity;
      var fareCity = fareStartCity +" "+ this.props.Ticketing.SortedFareList[i].FareEndCity ;
      let specialTime = this.props.specificTD.depTime;
      var fareCode = this.props.Ticketing.SortedFareList[i].FareBasisCode;

      var temp = (
      <div className="row fare_second_results margin_top_0 width_108 sorted">
           <div className="col-xs-1" id="fare_row_width">{coupon}</div>
           <div className="col-xs-1" id="fare_second_row_width">{lineCode}</div>
           <div className="col-xs-1">{flightNum}</div>
           <div className="col-xs-1" id="fare_row_width">{specialDate}</div>
           <div className="col-xs-1" id="fare_row_width">{fareCity}</div>
           <div className="col-xs-1">{specialTime}</div>
           <div className="col-xs-1" id="fare_third_row_width">{fareCode}</div>
           <div className="col-xs-1"></div>
       </div>
      );
      tableData.push(temp);
     }


     return (
    <div className="modal fade"  id="myTicketModal" data-backdrop="static">
        <div className="modal-dialog">
          <div className="modal-content tickets">

          <div className="modal-header">
          {this.props.placeHolder.TICKET}
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div className="modal-body">
            <div className="container tickets">
                <div className="row fare_details_table">
                    <div className="col-xs-3">
                             {this.props.placeHolder.INVOICE_NO}:<br></br>
                           <b>{this.props.Ticketing.TicketInvoice}</b>
                    </div>
                    <div className="col-xs-3">
                           {this.props.placeHolder.REFUNDABLE}:<br></br>
                         <b>{this.props.Ticketing.Refundable}</b>
                    </div>
                    <div className="col-xs-3">
                           {this.props.placeHolder.LOW_FARE}:<br></br>
                         <b>{this.props.Ticketing.LowFareAmount} {this.props.Ticketing.LowFareAmount!=null?this.props.Ticketing.TotalCurrency:null} {this.props.Ticketing.LowFareReason!=null?"("+this.props.Ticketing.LowFareReason+")":null}</b>
                    </div>
                    <div className="col-xs-3">
                           {this.props.placeHolder.FULL_FARE}:<br></br>
                         <b>{this.props.Ticketing.NormalFareAmount} {this.props.Ticketing.NormalFareAmount!=null?this.props.Ticketing.TotalCurrency:null}</b>
                    </div>
              </div>

              <div className="row fare_details_table">
                  <div className="col-xs-3">
                           {this.props.placeHolder.DATE_OF_ISSUE}:<br></br>
                         <b>{this.props.Ticketing.TicketDate}</b>
                  </div>
                  <div className="col-xs-3">
                         {this.props.placeHolder.COST}:<br></br>
                       <b>{this.props.Ticketing.StoredAmount} {this.props.Ticketing.storedCurrency}</b>
                  </div>
                  <div className="col-xs-3">
                         {this.props.placeHolder.TICKET_NO}.:<br></br>
                       <b>{this.props.Ticketing.TicketDetail}{this.props.Ticketing.TicketDetail!=null?"-":null}{this.props.Ticketing.TicketNumberText}</b>
                  </div>
                  <div className="col-xs-3">
                      &nbsp;
                  </div>
              </div>



            <div className="row margin_top_0">
            {this.props.placeHolder.FORM_PAYMENT}:<br></br>
          <b>{this.props.Ticketing.PaymentCCVendOr} {this.props.Ticketing.PaymentCCAcount} {this.props.Ticketing.ExpiryDate}</b>
               </div>


               <div className="row margin_top_0">
          <span className="color_blue">    {this.props.placeHolder.FARE_DETAILS}:</span>
                  </div>
              <div className="row margin_top_0">
              {this.props.placeHolder.STORED_FARE}: <b>{this.props.Ticketing.FareInstructionCode}</b>
              </div>

          <div className="fare_details_table">
             <div className="row">
              <div className="col-xs-3">
              {this.props.placeHolder.TICKET_ID}:<br></br>
            <b>{this.props.Ticketing.TicketNumberText}{this.props.Ticketing.TicketNumberText!=null?"-":null}{this.props.Ticketing.TicketNumberType}</b>
              </div>
            <div className="col-xs-3">
                {this.props.placeHolder.ISSUED}:<br></br>
                  <b>{this.props.Ticketing.TicketDate}</b>
            </div>
              <div className="col-xs-3">
                     {this.props.placeHolder.TICKETING_PCC}:<br></br>
                   <b>{this.props.Ticketing.TicketPCC}</b>
              </div>
               <div className="col-xs-3">
                 {this.props.placeHolder.PRICING_PCC}:<br></br>
               <b>{this.props.Ticketing.PricingPCC}</b>
              </div>
              </div>
              </div>
              <div className="fare_details_table table">

              <div className="row nomargin width_108 sorted">
                       <div className="col-xs-1" id="fare_row_width">{this.props.placeHolder.COUPON_NO}</div>
                       <div className="col-xs-1" id="fare_second_row_width">{this.props.placeHolder.AIRLINE}</div>
                       <div className="col-xs-1">{this.props.placeHolder.FLIGHT}</div>
                       <div className="col-xs-1" id="fare_row_width">Date</div>
                       <div className="col-xs-1" id="fare_row_width">{this.props.placeHolder.CITY_PAIRS}</div>
                       <div className="col-xs-1">{this.props.placeHolder.TIME}</div>
                       <div className="col-xs-1" id="fare_third_row_width">{this.props.placeHolder.FARE_BASIS}</div>
                      <div className="col-xs-1">{this.props.placeHolder.STATUS}</div>
               </div>



            <div>
                {tableData}
            </div>


              </div>

               <div className="row fare_details_table">
                        <div className="col-md-10">{this.props.placeHolder.FARE_CONSTRUCTION}:<br /><b>{this.props.Ticketing.FareConstructionText}</b></div>
                      </div>
                      </div>
                      <div className="container tickets">

                      <div className="row fare_details_table margin_top_0">
                <div className="col-xs-3">
                                {this.props.placeHolder.TICKET_DESIGNATOR}<br></br>
                              <b>{this.props.Ticketing.TicketDesignator}</b>
                </div>
                <div className="col-xs-3">
                              {this.props.placeHolder.NEGOTIATED}<br></br>
                            <b>{this.props.Ticketing.Negotiated}</b>
                </div>
                <div className="col-xs-3">
                              {this.props.placeHolder.NEGOTIATED_TYPE}<br></br>
                            <b>{this.props.Ticketing.NegotiatedFareType}</b>
                </div>
                <div className="col-xs-3">
                          <br></br>
                </div>
              </div>
              <div className="row fare_details_table">
        <div className="col-xs-3">
                     {this.props.placeHolder.BASE_FARE}<br></br>
                   <b>{this.props.Ticketing.BaseFareAmount} {this.props.Ticketing.BaseFareCurrency}</b>
        </div>
        <div className="col-xs-3">
                   {this.props.placeHolder.TAX_AMOUNT}<br></br>
                 <b>{this.props.Ticketing.TotalAmount} {this.props.Ticketing.TotalCurrency}</b>
        </div>
        <div className="col-xs-3">
                   {this.props.placeHolder.TOTAL_FARE}<br></br>
                 <b>{this.props.Ticketing.StoredAmount} {this.props.Ticketing.storedCurrency}</b>
        </div>
        <div className="col-xs-3">
                   {this.props.placeHolder.TAX_DETAIL}<br></br>
                 <b>{this.props.Ticketing.TaxDetail}</b>
        </div>
        </div>

        <div className="row fare_details_table tickets">
         <div className="col-md-8"><p>{this.props.placeHolder.FARE_RULES}:<br></br><b><Tl tl={this.props.Ticketing.FareSummary}/>{this.props.Ticketing.FareConstructionTagLines}</b></p> </div>

         </div>
        </div>

        </div>


        </div>
        </div>
      </div>)
  }
});

 let Tl = createClass({
  render: function () {
    var temp;
    if(this.props.tl!=null&typeof(this.props.tl)!="undefined"&Array.isArray(this.props.tl)){
    temp=this.props.tl.map(function (tttData ,j) {
      return <p key={j}>{tttData}</p>;
  });
}else if(this.props.tl!=null&typeof(this.props.tl)!="undefined"){
  temp=(<p>{this.props.tl}</p>);
}
    return(
      <div>{temp}</div>
    )
  }

});
export default TicketModel;
