import React, { Component } from 'react';
import Footer from '../footer';
import HTTP from '../../services/httpservice';
import ConfirmReset from '../confirmReset';
import { Environment, hcapcha_site_key} from '../constants';
const ERROR_MESSAGE_CAPTCHA = 'Please verify that you are not a robot.';
const VALID_EMAIL_ERROR = 'Please enter a valid email address';
const FORM_ERROR_CLASS = 'form-control error-icon';
const FORM_SUCESS_CLASS = 'form-control success-icon';
const FORMCLASS = 'form-control';
import { hashHistory } from 'react-router';
import HCaptcha from '@hcaptcha/react-hcaptcha';
var recaptchaInstance;
class ForgotUserPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			captchaResponse: '',
			captchaErrorMessage: '',
			email: '',
			emailerror: '',
			emailSuccess: '',
			alertMessage: '',
			securityQuestion: '',
			formClass: FORMCLASS,
			newPageMessage: '',
			securityAnswer: '',
			submitErrors: '',
			selectedOption: '',
			attempt: '',
			page: '',
			action: '',
			emailId: '',
			disabled: false
		};
	}
	captchaCallback() {}
	verifyCaptchaCallback(response) {
		if (response != '') {
			this.setState({
				captchaErrorMessage: true
			});
		}
		this.setState({ captchaResponse: response });
	}
	_handleSubmit() {
		this.setState({
			disabled: true
		});
		if (this.state.captchaResponse == '') {
			this.setState({
				disabled: true,
				captchaErrorMessage: ERROR_MESSAGE_CAPTCHA,
				captchaResponse: ''
			});
		}

		var self = this;
		let request = {
			action: this.state.selectedOption,
			emailId: this.state.email,
			securityQuestion: this.state.securityQuestion,
			securityAnswer: this.state.securityAnswer,
			attempts: this.state.attempt
		};
		HTTP.postJSON('/user/recovery/v1', request).then(function(response) {
			if (response.success == 'false' && response.actionType == 'alert-message') {
				self.setState({
					submitErrors: response.reason,
					attempt: response.attempt,
					securityQuestion: response.nextSecurityQuestion,
					securityAnswer: ''
				});
			} else if (response.success == 'false' && response.actionType == 'alert-alert') {
				hashHistory.push('/Locked');
				self.setState({
					submitErrors: response.reason,
					securityAnswer: ''
				});
			} else if (response.success == 'true') {
				self.setState({
					page: 'confirmReset',
					action: request.action,
					emailId: response.emailId
				});
			}
		});
	}
	_setEmail(e) {
		this.setState({ email: e.target.value });
	}
	_setSecurityAnswer(e) {
		this.setState({ disabled: false, securityAnswer: e.target.value });
	}
	handleOptionChange(changeEvent) {
		this.setState({
			selectedOption: changeEvent.target.value
		});
	}
	_validateEmail() {
		let x = this.state.email;
		let atpos = x.indexOf('@');
		let dotpos = x.lastIndexOf('.');
		if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= x.length) {
			this.setState({
				emailSuccess: '',
				emailerror: VALID_EMAIL_ERROR,
				formClass: FORM_ERROR_CLASS
			});
		} else {
			var self = this;
			HTTP.get('/user/validate/v1?emailId=' + this.state.email).then(function(response) {
				if (response.success == 'true') {
					self.setState({
						securityQuestion: response.users[0].securityQuestion1,
						emailSuccess: true,
						emailerror: '',
						formClass: FORM_SUCESS_CLASS
					});
				} else {
					if (response.actionType == 'alert-alert') {
						self.setState({
							emailSuccess: '',
							alertMessage: response.reason,
							emailerror: '',
							newPageMessage: true
						});
						window.$('#accModal').modal({
							backdrop: 'static',
							keyboard: false
						});
					} else if (response.actionType == 'alert-message') {
						self.setState({
							emailSuccess: '',
							formClass: FORM_ERROR_CLASS,
							emailerror: response.reason
						});
					}
				}
			});
		}
	}
	_EventClick() {
		var self = this;
		self.setState({
			newPageMessage: '',
			email: ''
		});
	}
	render() {
		var headermessage = 'ID Locked';
		if (this.state.page === 'confirmReset') {
			return (
				<ConfirmReset
					placeHolder={this.props.placeHolder}
					action={this.state.action}
					email={this.state.emailId}
				/>
			);
		}
		return (
			<div className="resolution_container_reg container">
				<div id="containerreg">
					<header>
						<span id="logoEnglish" />
						<a href="../gbt_viewer_quick_reference.pdf" target="_blank" className="help_icon">
							&nbsp;
						</a>
					</header>

					{!this.state.newPageMessage ? (
						<div className="registration-container container" id="registration-container">
							<h2 className="text-center recover-user-password">Recover User Name & Reset Password</h2>
							<form className="form-instructions">
								{this.state.submitErrors != '' ? (
									<div className="submit-error-block">
										<p className="submit-errors">{this.state.submitErrors}</p>
									</div>
								) : null}
								<p>
									Enter the details below and we will send you the User Name & instructions to reset
									your password.
								</p>
								<br />
								<p>Please Select what you would like to do?</p>
								<input
									type="radio"
									name="options"
									id="recoveruser"
									value="RECOVERUSERNAME"
									onChange={this.handleOptionChange.bind(this)}
									checked={this.state.selectedOption === 'RECOVERUSERNAME'}
								/>{' '}
								Recover User Name
								<br />
								<input
									type="radio"
									name="options"
									id="resetpwd"
									value="RESETPASSWORD"
									onChange={this.handleOptionChange.bind(this)}
									checked={this.state.selectedOption === 'RESETPASSWORD'}
								/>{' '}
								Reset Password
								<br />
								<input
									type="radio"
									name="options"
									id="recoverandreset"
									value="RECOVERUSERNAME,RESETPASSWORD"
									onChange={this.handleOptionChange.bind(this)}
									checked={this.state.selectedOption === 'RECOVERUSERNAME,RESETPASSWORD'}
								/>{' '}
								Both Recover User Name & Reset Password
								<br />
								<br />
								<br />
								<p>Business Email Address:</p>
								<input
									type="text"
									value={this.state.email}
									className={this.state.formClass + ' recover-textbox'}
									id="businessemail"
									onChange={this._setEmail.bind(this)}
									onBlur={this._validateEmail.bind(this)}
								/>
								<span className="error">{this.state.emailerror}</span>
								<br />
								<br />
								{this.state.emailSuccess ? (
									<div>
										<br />
										<p>Security Question:</p>
										<p>{this.state.securityQuestion}</p>
										<input
											type="text"
											value={this.state.securityAnswer}
											onChange={this._setSecurityAnswer.bind(this)}
											name="securityanswer"
											className="form-control recover-textbox"
										/>
									</div>
								) : null}
								<br />
								{Environment === 'INTERNET' ? (
									<div id="captcha">
										<HCaptcha 
										sitekey={hcapcha_site_key()}
										ref={(e) => (recaptchaInstance = e)}
										onLoad={this.captchaCallback.bind(this)}
										onVerify={this.verifyCaptchaCallback.bind(this)}
										/>
										{this.state.captchaErrorMessage != '' ? (
											<p className="error">{this.state.captchaErrorMessage}</p>
										) : null}
									</div>
								) : null}
								<br />
								{this.state.emailSuccess &&
								(Environment !== 'INTERNET' || this.state.captchaErrorMessage) ? (
									<button
										type="button"
										disabled={this.state.disabled}
										onClick={this._handleSubmit.bind(this)}
										className="btn btn-default test forgot-button"
									>
										Submit
									</button>
								) : (
									<button type="button" className="btn btn-default forgot-button forgot-class" disabled>
										Submit
									</button>
								)}
							</form>
						</div>
					) : (
						<div className="registration-container container error-body" id="registration-container">
							<div className="error-header">
								<b>{headermessage}</b>
							</div>
							<br />
							<div className="col-sm-1 errorimage" />
							<div className="col-sm-11">
								<p className="error-message">{this.state.alertMessage}</p>
							</div>
							<button
								type="button"
								className="btn btn-default confirmbutton"
								onClick={this._EventClick.bind(this)}
							>
								OK
							</button>
						</div>
					)}
				</div>
				<Footer placeHolder={this.props.placeHolder} />
			</div>
		);
	}
}
export default ForgotUserPassword;
