import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import HTTP from "../../services/httpservice.js";
import {Environment} from '../constants'
var GET_TRAVELER_QUERIES_URL = require('../../commons/apiUrls.json').GetTravelerQueriesURL;
class ProfileComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDropdownState: false,
            openPermissionWindow: false,
            isDataAccessContentOpen: true,
            isTravelCareContentOpen: false,
            isElevatedContentOpen: false,
            isPtcAlertSettingsOpen: false,
            expandImage: "/icons/expand.png",
            collapseImage: "/icons/collapse.png",
            gdsInfo: [],
            gdsInfoCopy: [],
            agencyInfo: [],
            agencyInfoCopy: [],
            languageInfo: [],
            languageInfoCopy: [],
            loading: true,
            allGDSOff: false,
            saveButtonDisabled: true,
            saveSuccess: '',
            getPTCSettingsFailed: false,
            gdsSaveActive: [],
            agencySaveActive: [],
            languageSaveActive: [],
            rowIndex: '',
            isGDSfilterOpen: false,
            isAgencyFilterOpen: false,
            isLangFilterOpen: true,
            allAgencyOff: false,
            allLanguageOff: false,
            grantPtcAccessState:false
        }
    }
    componentDidMount() {
    }
    toogleDataAccessContent() {
        this.setState({ isTravelCareContentOpen: false, isElevatedContentOpen: false, isPtcAlertSettingsOpen: false })
        if (!this.state.isDataAccessContentOpen) {
            this.setState({ isDataAccessContentOpen: true })
        } else {
            this.setState({ isDataAccessContentOpen: false })
        }
    }
    toogleTravelCareContent() {
        this.setState({ isDataAccessContentOpen: false, isElevatedContentOpen: false, isPtcAlertSettingsOpen: false })
        if (!this.state.isTravelCareContentOpen) {
            this.setState({ isTravelCareContentOpen: true })
        } else {
            this.setState({ isTravelCareContentOpen: false })
        }
    }
    toggleElevatedContent() {
        this.setState({ isTravelCareContentOpen: false, isDataAccessContentOpen: false, isPtcAlertSettingsOpen: false })
        if (!this.state.isElevatedContentOpen) {
            this.setState({ isElevatedContentOpen: true })
        } else {
            this.setState({ isElevatedContentOpen: false })
        }
    }
    togglePtcSettingsContent() {
        this.setState({
            loading: true, isTravelCareContentOpen: false, isDataAccessContentOpen: false, isElevatedContentOpen: false, getPTCSettingsFailed: false,
            gdsSaveActive: [], agencySaveActive: [], languageSaveActive: [], isGDSfilterOpen: false, isAgencyFilterOpen: false, isLangFilterOpen: true, saveSuccess: ''
        })
        if (!this.state.isPtcAlertSettingsOpen) {
            // Get PTC alert settings
            let self = this;
            let GDSfiltersON = [
                {
                    "source": "AMA",
                    "active": "Y"
                },
                {
                    "source": "APO",
                    "active": "Y"
                },
                {
                    "source": "GAL",
                    "active": "Y"
                },
                {
                    "source": "SAB",
                    "active": "Y"
                }
            ]
            // to set all languages on initially
            let languageFiltersON = [
                {
                    "language": "EN-US",
                    "active": "Y"
                },
                {
                    "language": "FR-FR",
                    "active": "Y"
                },
                {
                    "language": "DE-DE",
                    "active": "Y"
                },
                {
                    "language": "ES-ES",
                    "active": "Y"
                },
                {
                    "language": "FR-CA",
                    "active": "Y"
                },
                {
                    "language": "SE-SE",
                    "active": "Y"
                }
            ];
            //
            HTTP.get('/user/' + this.props.userId + '/preference/v1').then(function (response) {
                if (response.success === "true") {
                    let gdsFilterResponse = [];
                    if (response.gdsFilter !== undefined && response.gdsFilter) {
                        gdsFilterResponse = response.gdsFilter
                    } else {
                        gdsFilterResponse = GDSfiltersON
                    }
                    //to handle enable/disable of save button
                    let agencyInfoPrefArr = [];
                    let tempAgency = [...response.agencyFilter];
                    tempAgency.map(item => {
                        let preferences = [...item.preferences];
                        agencyInfoPrefArr.push(preferences[0]);
                        agencyInfoPrefArr.push(preferences[1]);
                    })
                    //
                    // to sort languages
                    let languageFilterResponse = [];
                    if (response.languageFilter !== undefined && response.languageFilter) {
                        let langResponse = response.languageFilter
                        let languages = ["EN-US", "FR-FR", "DE-DE", "ES-ES", "FR-CA", "SE-SE"];
                        languageFilterResponse = languages.map(item => {
                            return langResponse.find(langitem => langitem.language === item)
                        })
                    } else {
                        languageFilterResponse = languageFiltersON
                    }
                    //
                    self.setState({
                        gdsInfo: gdsFilterResponse,
                        gdsInfoCopy: gdsFilterResponse,
                        agencyInfo: response.agencyFilter,
                        agencyInfoCopy: agencyInfoPrefArr,
                        languageInfo: languageFilterResponse,
                        languageInfoCopy: languageFilterResponse,
                        loading: false,
                        allGDSOff: false,
                        saveButtonDisabled: true,
                        allAgencyOff: false,
                        allLanguageOff: false
                    })
                } else {
                    self.setState({
                        gdsInfo: [],
                        gdsInfoCopy: [],
                        agencyInfo: [],
                        agencyInfoCopy: [],
                        languageInfo: [],
                        languageInfoCopy: [],
                        loading: false,
                        getPTCSettingsFailed: true,
                        allGDSOff: false,
                        saveButtonDisabled: true,
                        allAgencyOff: false,
                        allLanguageOff: false
                    })
                }
            });
            this.setState({ isPtcAlertSettingsOpen: true })
        } else {
            this.setState({ isPtcAlertSettingsOpen: false })
        }
    }
    openDropdown(openDropdownState) {
        this.setState({
            openDropdownState: !openDropdownState
        })
    }
    showModalPopup() {
        this.setState({ openDropdownState: false, openPermissionWindow: true });
        document.body.style.overflow = "hidden";
    }
    showSettingsPopup() {
        this.setState({ openDropdownState: false, openSettingsWindow: true, isPtcAlertSettingsOpen: true, isGDSfilterOpen: false, isAgencyFilterOpen: false, isLangFilterOpen: true });
        document.body.style.overflow = "hidden";
        this.togglePtcSettingsContent();
    }
    closePermissionWindow() {
        this.setState({
            openDropdownState: false, openPermissionWindow: false,
            isPtcAlertSettingsOpen: false, isTravelCareContentOpen: false,
            isDataAccessContentOpen: true, isElevatedContentOpen: false,
            allGDSOff: false, getPTCSettingsFailed: false, allAgencyOff: false, allLanguageOff: false
        });
        document.body.style.overflow = "auto";
    }
    closeSettingsWindow() {
        this.setState({
            openDropdownState: false, openSettingsWindow: false, isPtcAlertSettingsOpen: false, isGDSfilterOpen: false,
            isAgencyFilterOpen: false, isLangFilterOpen: true, saveSuccess: ''
        });
        document.body.style.overflow = "auto";
    }
    logout() {
        this.props.socket.disconnect();
        this
            .props
            .logout(true);
    }
    setPrimaryContent(cell, row, object, index) {
        return <div>
            <label className="switch agencytogbtn">
                <input type="checkbox" id="togBtn" checked={row.primary === "Y" ? true : false}
                    onClick={this.handleAgencyToggle.bind(this, cell, index, "0")} />
                <div className="langslider round">
                    <span className="langon">ON</span>
                    <span className="langoff">OFF</span>
                </div>
            </label>
        </div>
    }
    setRolloverContent(cell, row, object, index) {
        return <div>
            <label className="switch agencytogbtn">
                <input type="checkbox" id="togBtn" checked={row.rollover === "Y" ? true : false}
                    onClick={this.handleAgencyToggle.bind(this, cell, index, "1")} />
                <div className="langslider round">
                    <span className="langon">ON</span>
                    <span className="langoff">OFF</span>
                </div>
            </label>
        </div>
    }
    handleAgencyToggle(cell, index, preferenceIndex) {
        let originalAgencyPref = [...this.state.agencyInfoCopy]
        this.setState({ saveSuccess: '' });
        let updatedPreference = '';
        if (cell === "Y") {
            updatedPreference = "N"
        } else {
            updatedPreference = "Y"
        }
        let updatedAgency = [...this.state.agencyInfo];
        let agencyfilter = { ...updatedAgency[index] };
        let agencyfilterCopy = { ...agencyfilter }
        let agencyPrefernces = [...agencyfilterCopy.preferences];
        let updatedPreferenceAgency = { ...agencyPrefernces[preferenceIndex] }
        let updatedPreferenceAgencyActive = { ...updatedPreferenceAgency }
        updatedPreferenceAgencyActive.active = updatedPreference;
        updatedPreferenceAgency = { ...updatedPreferenceAgencyActive };
        agencyPrefernces[preferenceIndex] = updatedPreferenceAgency;
        agencyfilterCopy.preferences = agencyPrefernces;
        agencyfilter = agencyfilterCopy;
        updatedAgency[index] = agencyfilter;
        // to display error message
        let updatedAgencyPrefArr = [];
        updatedAgency.map(item => {
            updatedAgencyPrefArr.push(item.preferences[0]);
            updatedAgencyPrefArr.push(item.preferences[1]);
        })
        let priRollArray = [];
        updatedAgencyPrefArr.map(item => {
            priRollArray.push(item.active);
        })
        if (priRollArray.includes("Y")) {
            this.setState({ agencyInfo: updatedAgency, allAgencyOff: false })
            //to handle enable/disable of save button
            for (let i = 0; i < updatedAgencyPrefArr.length; i++) {
                if (originalAgencyPref[i].active !== updatedAgencyPrefArr[i].active) {
                    this.state.agencySaveActive[i] = "yes"
                } else {
                    this.state.agencySaveActive[i] = "no"
                }
            }
            if (this.state.agencySaveActive.includes("yes") || this.state.gdsSaveActive.includes("yes") || this.state.languageSaveActive.includes("yes")) {
                this.setState({ saveButtonDisabled: false })
            } else {
                this.setState({ saveButtonDisabled: true })
            }
        } else {
            this.setState({ allAgencyOff: true })
        }
    }
    _handleSave() {
        this.setState({ loading: true, gdsSaveActive: [], agencySaveActive: [], languageSaveActive: [] });
        let request = {
            "agencyFilter": [...this.state.agencyInfo],
            "gdsFilter": [...this.state.gdsInfo],
            "languageFilter": [...this.state.languageInfo]
        }
        let self = this;
        HTTP.postJSON('/userPreferences/' + this.props.userId + '/v1', request)
            .then((response) => {
                if (response.success === "true") {
                    self.setState({ saveSuccess: "Success" })
                    HTTP.get('/user/' + this.props.userId + '/preference/v1').then(function (response) {
                        if (response.success === "true") {
                            //to handle enable/disable of save button
                            let agencyInfoPrefArr = [];
                            let tempAgency = [...response.agencyFilter];
                            tempAgency.map(item => {
                                let preferences = [...item.preferences];
                                agencyInfoPrefArr.push(preferences[0]);
                                agencyInfoPrefArr.push(preferences[1]);
                            })
                            // to sort languages
                            let languageFilterResponse = [];
                            let langResponse = response.languageFilter
                            let languages = ["EN-US", "FR-FR", "DE-DE", "ES-ES", "FR-CA", "SE-SE"];
                            languageFilterResponse = languages.map(item => {
                                return langResponse.find(langitem => langitem.language === item)
                            })
                            //
                            self.setState({
                                gdsInfo: response.gdsFilter,
                                gdsInfoCopy: response.gdsFilter,
                                agencyInfo: response.agencyFilter,
                                agencyInfoCopy: agencyInfoPrefArr,
                                languageInfo: languageFilterResponse,
                                languageInfoCopy: languageFilterResponse,
                                loading: false,
                                allGDSOff: false,
                                saveButtonDisabled: true,
                                isGDSfilterOpen: false,
                                isAgencyFilterOpen: false,
                                isLangFilterOpen: true,
                                allAgencyOff: false,
                                allLanguageOff: false
                            })
                            self.props.getCasesList();
                        } else {
                            self.setState({
                                gdsInfo: [],
                                gdsInfoCopy: [],
                                agencyInfo: [],
                                agencyInfoCopy: [],
                                languageInfo: [],
                                languageInfoCopy: [],
                                loading: false,
                                allGDSOff: false,
                                saveButtonDisabled: true,
                                getPTCSettingsFailed: true,
                                isGDSfilterOpen: false,
                                isAgencyFilterOpen: false,
                                isLangFilterOpen: true,
                                allAgencyOff: false,
                                allLanguageOff: false
                            })
                        }
                    });
                } else {
                    self.setState({ saveSuccess: "Failed" })
                }
            });
        setTimeout(() => {
            this.setState({ saveSuccess: '' })
        }, 3000);
    }
    handleGDSTogBtn(index, item) {
        this.setState({ saveSuccess: '' });
        const gdsInfoCopy = [...this.state.gdsInfoCopy]
        let togbtnVal = '';
        if (item.active === "Y") {
            togbtnVal = "N"
        } else {
            togbtnVal = "Y"
        }
        let updatedGds = [...this.state.gdsInfo];
        let updatedGDSfilter = { ...updatedGds[index] };
        updatedGDSfilter.active = togbtnVal;
        updatedGds[index] = updatedGDSfilter;
        if (updatedGds[0].active === "N" && updatedGds[1].active === "N" && updatedGds[2].active === "N" && updatedGds[3].active === "N") {
            this.setState({ allGDSOff: true })
        } else {
            this.setState({ gdsInfo: updatedGds, allGDSOff: false })
            //to handle enable/disable of save button
            for (let i = 0; i < updatedGds.length; i++) {
                if (updatedGds[i].active !== gdsInfoCopy[i].active) {
                    this.state.gdsSaveActive[i] = "yes"
                } else {
                    this.state.gdsSaveActive[i] = "no"
                }
            }
            if (this.state.gdsSaveActive.includes("yes") || this.state.agencySaveActive.includes("yes") || this.state.languageSaveActive.includes("yes")) {
                this.setState({ saveButtonDisabled: false })
            } else {
                this.setState({ saveButtonDisabled: true })
            }
        }
    }
    handleLangTogBtn(index, item) {
        this.setState({ saveSuccess: '' });
        const languageInfoCopy = [...this.state.languageInfoCopy]
        let togbtnVal = '';
        if (item.active === "Y") {
            togbtnVal = "N"
        } else {
            togbtnVal = "Y"
        }
        let updatedLanguage = [...this.state.languageInfo];
        let updatedLanguagefilter = { ...updatedLanguage[index] };
        updatedLanguagefilter.active = togbtnVal;
        updatedLanguage[index] = updatedLanguagefilter;
        if (updatedLanguage[0].active === "N" && updatedLanguage[1].active === "N" && updatedLanguage[2].active === "N" && updatedLanguage[3].active === "N" && updatedLanguage[4].active === "N" && updatedLanguage[5].active === "N") {
            this.setState({ allLanguageOff: true })
        } else {
            this.setState({ languageInfo: updatedLanguage, allLanguageOff: false })
            //to handle enable/disable of save button
            for (let i = 0; i < updatedLanguage.length; i++) {
                if (updatedLanguage[i].active !== languageInfoCopy[i].active) {
                    this.state.languageSaveActive[i] = "yes"
                } else {
                    this.state.languageSaveActive[i] = "no"
                }
            }
            if (this.state.gdsSaveActive.includes("yes") || this.state.agencySaveActive.includes("yes") || this.state.languageSaveActive.includes("yes")) {
                this.setState({ saveButtonDisabled: false })
            } else {
                this.setState({ saveButtonDisabled: true })
            }
        }
    }
    // showDesc(cell, row, enumObject, index) {
    //     let rowValue = this.state.rowIndex;
    //     return row.agency !== undefined && row.agency.length > 13
    //         ?
    //         <p onMouseOver={() => this.setState({ rowIndex: index })} onMouseOut={() => this.setState({ rowIndex: '' })} className="agency-name">{row.agency}
    //             <div className={rowValue === index ? 'show fade in popover bottom pop_over_right extra_width' : 'hide fade in popover bottom pop_over_right extra_width'}>
    //                 <div className='arrow'></div>
    //                 <div className='popover_custom'>
    //                     {row.agency}
    //                 </div>
    //             </div>
    //         </p>
    //         : <p> {row.agency} </p>
    // }
    showDesc(cell, row, enumObject, index) {
        let rowValue = this.state.rowIndex;
        return row.agency !== undefined && row.agency.length > 13
            ? <div>
                <OverlayTrigger
                    // trigger={['hover']}
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-bottom" className="show">
                        {row.agency}
                    </Tooltip>}>
                    <div
                        className="agency-name">{row.agency}</div>
                </OverlayTrigger>
            </div>
            :
            <p>{row.agency}</p>
    }
    handleGDScontent() {
        if (this.state.isGDSfilterOpen) {
            this.setState({ isGDSfilterOpen: false, saveSuccess: '' })
        } else {
            this.setState({ isGDSfilterOpen: true, isAgencyFilterOpen: false, isLangFilterOpen: false, saveSuccess: '' })
        }
    }
    handleAgencycontent() {
        if (this.state.isAgencyFilterOpen) {
            this.setState({ isAgencyFilterOpen: false, saveSuccess: '' })
        } else {
            this.setState({ isAgencyFilterOpen: true, isGDSfilterOpen: false, isLangFilterOpen: false, saveSuccess: '' })
        }
    }
    handleLanguagecontent() {
        if (this.state.isLangFilterOpen) {
            this.setState({ isLangFilterOpen: false, saveSuccess: '' })
        } else {
            this.setState({ isLangFilterOpen: true, isGDSfilterOpen: false, isAgencyFilterOpen: false, saveSuccess: '' })
        }
    }
    componentDidUpdate() {
        let agencyTableDiv = document.getElementsByClassName('react-bs-table-bordered')[0]
        if (agencyTableDiv && this.state.agencyInfo.length > 3) {
            agencyTableDiv.style.overflow = "auto";
            agencyTableDiv.style.overflowX = "hidden";
            agencyTableDiv.style.height = "128px";
            agencyTableDiv.id = "style1"
        }
        // to align language filter values
        let langFilterEnglishDiv = document.getElementById('English');
        if (langFilterEnglishDiv) {
            langFilterEnglishDiv.style.marginLeft = "-20px";
        }
        let langFilterGermanDiv = document.getElementById('German');
        if (langFilterGermanDiv) {
            langFilterGermanDiv.style.marginLeft = "-20px";
        }
        let langFilterSpanishDiv = document.getElementById('Spanish');
        if (langFilterSpanishDiv) {
            langFilterSpanishDiv.style.marginLeft = "-20px";
        }
        let langFilterSwedishDiv = document.getElementById('Swedish');
        if (langFilterSwedishDiv) {
            langFilterSwedishDiv.style.marginLeft = "-20px";
        }
        //
    }
    render() {
        const { openDropdownState, openPermissionWindow, openSettingsWindow, gdsInfo, agencyInfo, languageInfo } = this.state;
        const { dataAccessGroup, proactiveGroup, elevatedPermissions, elevatedInfo } = this.props;
        let dataAccessGroupValue = null;
        let proactiveGroupValue = null;
        if (dataAccessGroup.length > 0) {
            dataAccessGroupValue = dataAccessGroup.map((item) => {
                return <p>{item}</p>
            })
        }
        if (proactiveGroup.length > 0) {
            proactiveGroupValue = proactiveGroup.map((item) => {
                return <p>{item}</p>
            })
        }
        let bodyContentClass = "panel-collapse collapse";
        let travelContentClass = "panel-collapse collapse";
        let elevatedContentClass = "panel-collapse collapse";
        let ptcSettingsContentClass = "panel-collapse collapse";
        let GDSFilterSettingOpen = "panel-collapse collapse";
        let agencyFilterSettingOpen = "panel-collapse collapse";
        let langFilterSettingOpen = "panel-collapse collapse";

        if (this.state.isDataAccessContentOpen) {
            bodyContentClass = bodyContentClass + " in";
        }

        if (this.state.isTravelCareContentOpen) {
            travelContentClass = travelContentClass + " in";
        }

        if (this.state.isElevatedContentOpen) {
            elevatedContentClass = elevatedContentClass + " in";
        }

        if (this.state.isPtcAlertSettingsOpen) {
            ptcSettingsContentClass = ptcSettingsContentClass + " in";
        }
        if (this.state.isGDSfilterOpen) {
            GDSFilterSettingOpen = GDSFilterSettingOpen + " in";
        }
        if (this.state.isAgencyFilterOpen) {
            agencyFilterSettingOpen = agencyFilterSettingOpen + " in";
        }
        if (this.state.isLangFilterOpen) {
            langFilterSettingOpen = langFilterSettingOpen + " in";
        }
        let agencyData = [];
        agencyInfo.map(function (item) {
            let tempObj = {
                agency: item.agencyName,
                primary: item.preferences[0].active,
                rollover: item.preferences[1].active
            }
            agencyData.push(tempObj)
        })
        let gdsName = [{ "AMA": "Amadeus", "SAB": "Sabre", "APO": "Apollo", "GAL": "Galileo" }]
        let gdsList = gdsInfo.map((item, index) => {
            return <div className="gds-filter-table"><div className="col-md-3 col-sm-3 gds-text">{gdsName[0][item.source]}</div>
                <div className="col-md-1 col-sm-1">
                    <label className="switch">
                        <input type="checkbox"
                            id="togBtn"
                            checked={item.active === "Y" ? true : false}
                            onClick={this.handleGDSTogBtn.bind(this, index, item)}
                        />
                        <div className="langslider round">
                            <span className="langon">ON</span>
                            <span className="langoff">OFF</span>
                        </div>
                    </label>
                </div>
                <div className="col-md-2 col-sm-2" />
            </div>
        })
        let actionMessage = null;
        if (this.state.saveSuccess === "Success") {
            actionMessage = <div className="ptc-setting-success">Updates saved</div>
        } else if (this.state.saveSuccess === "Failed") {
            actionMessage = <div className="ptc-setting-error">Updates failed</div>
        }
        const options = {
            toolBar: createCustomToolBar.bind(this, this.props),
            afterSearch: this.afterSearch,
            that: this
        };

        //to handle PTC settings height dynamically
        let ptcSettingheight = "ptc-setting-block";
        if (this.state.isAgencyFilterOpen && agencyData.length > 3) {
            ptcSettingheight = "ptc-setting-gds-agency";
        }
        //
        let langName = [{ "EN-US": "English", "FR-FR": "French", "DE-DE": "German", "ES-ES": "Spanish", "FR-CA": "FR Canadian", "SE-SE": "Swedish" }]
        let languageList = languageInfo.map((item, index) => {
            return <div className="gds-filter-table"><div className="col-md-3 col-sm-3 gds-text">{langName[0][item.language]}</div>
                <div className="col-md-1 col-sm-1" id={langName[0][item.language]}>
                    <label className="switch">
                        <input type="checkbox"
                            id="togBtn"
                            checked={item.active === "Y" ? true : false}
                            onClick={this.handleLangTogBtn.bind(this, index, item)}
                        />
                        <div className="langslider round">
                            <span className="langon">ON</span>
                            <span className="langoff">OFF</span>
                        </div>
                    </label>
                </div>
            </div>
        })
        
        return (
            <div>
                <div
                    className={elevatedPermissions ? this.props.codeRed ? "code-red-profile-icon-style" : "profile-elevated-icon-style" : "profile-icon-style"}
                    onClick={this
                        .openDropdown
                        .bind(this, openDropdownState)}></div>
                <div className="profile-dropdown">
                    {openDropdownState && <div
                        className='fade in popover bottom profile-pop-over-right custom-profile-content'>
                        <div className='arrow'></div>
                        <div className='profile-popover-content'>
                            <div className='popover_custom'>
                                <a
                                    onClick={this
                                        .showModalPopup
                                        .bind(this)}
                                    className="dropdown-content-style">Permissions</a>
                            </div>
                            {Environment==='INTRANET' && (this.props.grantPtcAccess || this.state.grantPtcAccessState)?
                            <div className='popover_custom'>
                                <a
                                    onClick={this
                                        .showSettingsPopup
                                        .bind(this)}
                                    className="dropdown-content-style">Settings</a>
                            </div>:null}
                            <div className='popover_custom'>
                                <a
                                    onClick={this
                                        .logout
                                        .bind(this)}
                                    className="dropdown-content-style">{this.props.placeHolder.LOGOUT}</a>
                            </div>
                        </div>
                    </div>}

                    {openSettingsWindow && <div className="modal show permissionModal" id="myModal" role="dialog" data-backdrop="static">
                        <div className="modal-dialog">
                            <div className="modal-content permission-modal-content settings-modal">
                                <div className="modal-header">
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        onClick={this
                                            .closeSettingsWindow
                                            .bind(this)}>&times;</button>
                                    <h4 id="modal-title">Settings</h4>
                                    <a href="https://gbt.jiveon.com/docs/DOC-68274" target="_blank" className="permissions-help-icon">&nbsp;</a>
                                </div>
                                <div className="modal-body settings-modal-body">
                                    <div className="panel-group" id="accordion">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <div className="row">
                                                        <div className="col-md-10 permission-header-style">
                                                            <a
                                                                className="accordion-toggle collapsed a-tag"
                                                                data-toggle="collapse"
                                                                data-parent="#accordion"
                                                                href="#collapseThree">Proactive Traveler Care Settings
                                                            </a>
                                                        </div>
                                                        <div className="col-sm-1 expand-icon-style">
                                                            <img
                                                                alt="contentopen"
                                                                className="img-icon-style"
                                                                src={this.state.isPtcAlertSettingsOpen === false
                                                                    ? this.state.expandImage
                                                                    : this.state.collapseImage}
                                                                onClick={this
                                                                    .togglePtcSettingsContent
                                                                    .bind(this)} />
                                                        </div>
                                                    </div>
                                                </h4>
                                            </div>
                                            <div id="collapseThree" className={ptcSettingsContentClass}>
                                                {this.state.loading ?
                                                    <div className="ptc-setting-loader">&nbsp;</div>
                                                    : this.state.getPTCSettingsFailed ? <div className="ptc-setting-error ptc-get-failed">Something went wrong, Please try again later</div> :
                                                        <div className={"panel-body ptc-alerts-settings " + ptcSettingheight} >
                                                            {actionMessage}
                                                            <p>Notification and Pending Queue will filter to selection.<button className={this.state.saveButtonDisabled ? "gds-save-disabled" : "gds-save-enabled"} id="saveBtn" disabled={this.state.saveButtonDisabled} onClick={this._handleSave.bind(this)}>Save</button>
                                                            </p>
                                                            <hr />
                                                            <div className="row">
                                                                <div className="col-md-12 col-sm-12">
                                                                    <div className="gds-filter-text">
                                                                        <p className="col-md-11">Select Language Filter</p>
                                                                        <div className="col-sm-1 expand-icon-style">
                                                                            <img
                                                                                alt="contentopen"
                                                                                className="img-icon-style gds-agency-collapse"
                                                                                src={this.state.isLangFilterOpen === false
                                                                                    ? this.state.expandImage
                                                                                    : this.state.collapseImage}
                                                                                onClick={this
                                                                                    .handleLanguagecontent
                                                                                    .bind(this)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="collapsefour" className={langFilterSettingOpen}>
                                                                    {languageList}
                                                                    {this.state.allLanguageOff ? <div className="col-md-12 col-sm-12 gds-error">At least 1 Language must be selected.</div> : null}
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-12 col-sm-12">
                                                                    <div className="gds-filter-text">
                                                                        <p className="col-md-11">Select GDS Filter</p>
                                                                        <div className="col-sm-1 expand-icon-style">
                                                                            <img
                                                                                alt="contentopen"
                                                                                className="img-icon-style gds-agency-collapse"
                                                                                src={this.state.isGDSfilterOpen === false
                                                                                    ? this.state.expandImage
                                                                                    : this.state.collapseImage}
                                                                                onClick={this
                                                                                    .handleGDScontent
                                                                                    .bind(this)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="collapsefour" className={GDSFilterSettingOpen}>
                                                                    {gdsList}
                                                                    {this.state.allGDSOff ? <div className="col-md-12 col-sm-12 gds-error">At least 1 GDS must be selected.</div> : null}
                                                                    <div className="col-md-12 col-sm-12 gds-override-text"><p>GDS filter overrides elevated permissions.</p></div>
                                                                </div>
                                                            </div>

                                                            {/* <div className="row">
                                                                <div className="col-md-12 col-sm-12">
                                                                    <div className="gds-connect-client-text">
                                                                        <p className="col-md-11">Select Connect client agencies to service</p>
                                                                        <div className="col-sm-1 expand-icon-style">
                                                                            <img
                                                                                alt="contentopen"
                                                                                className="img-icon-style gds-agency-collapse"
                                                                                src={this.state.isAgencyFilterOpen === false
                                                                                    ? this.state.expandImage
                                                                                    : this.state.collapseImage}
                                                                                onClick={this
                                                                                    .handleAgencycontent
                                                                                    .bind(this)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="collapsefour" className={agencyFilterSettingOpen}>
                                                                    <div className="col-md-12 col-sm-12 gds-override-text"><p>Elevated permissions will override agency settings.</p></div>
                                                                    {this.state.allAgencyOff ? <div className="col-md-12 col-sm-12 agency-err">At least 1 must be selected.</div> : null}
                                                                    <BootstrapTable
                                                                        className="agency-table"
                                                                        data={agencyData}
                                                                        borderless={true}
                                                                        options={options}
                                                                        search
                                                                        searchPlaceholder='Search by Agency Name'>
                                                                        <TableHeaderColumn width="40%" dataField='agency' dataFormat={this.showDesc.bind(this)} isKey>Agency </TableHeaderColumn>
                                                                        <TableHeaderColumn width="30%" dataField='primary' dataFormat={this.setPrimaryContent.bind(this)}>Primary</TableHeaderColumn>
                                                                        <TableHeaderColumn width="30%" dataField='rollover' dataFormat={this.setRolloverContent.bind(this)}>Rollover</TableHeaderColumn>
                                                                    </BootstrapTable>
                                                                </div>
                                                            </div> */}
                                                        </div>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {openPermissionWindow && <div className="modal show permissionModal" id="myModal" role="dialog" data-backdrop="static">
                        <div className="modal-dialog">
                            <div className="modal-content permission-modal-content">
                                <div className="modal-header">
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        onClick={this
                                            .closePermissionWindow
                                            .bind(this)}>&times;</button>
                                    <h4 id="modal-title">Permissions</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="panel-group" id="accordion">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <div className="row">
                                                        <div className="col-md-10 permission-header-style">
                                                            <a
                                                                className="accordion-toggle a-tag"
                                                                data-toggle="collapse"
                                                                data-parent="#accordion"
                                                                href="#collapseOne">{this.props.placeHolder.DATA_ACCESS}
                                                            </a>
                                                        </div>
                                                        <div className="col-sm-1 expand-icon-style">
                                                            <img
                                                                alt="contentopen"
                                                                className="img-icon-style"
                                                                src={this.state.isDataAccessContentOpen === false
                                                                    ? this.state.expandImage
                                                                    : this.state.collapseImage}
                                                                onClick={this
                                                                    .toogleDataAccessContent
                                                                    .bind(this)} />
                                                        </div>
                                                    </div>

                                                </h4>
                                            </div>
                                            <div id="collapseOne" className={bodyContentClass}>
                                                <div className="panel-body">
                                                    {this.props.environment === "INTERNET"
                                                        ? this.props.orgName
                                                        : dataAccessGroupValue}
                                                </div>
                                            </div>
                                        </div>
                                        {elevatedPermissions ? <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <div className="row">
                                                        <div className="col-md-10 permission-header-style">
                                                            <a
                                                                className="accordion-toggle collapsed a-tag"
                                                                data-toggle="collapse"
                                                                data-parent="#accordion"
                                                                href="#collapseTwo">{this.props.placeHolder.ELEVATED_PROACTIVE_TRAVELER_CARE_ACCESS}
                                                            </a>
                                                        </div>
                                                        <div className="col-sm-1 expand-icon-style">
                                                            <img
                                                                alt="contentopen"
                                                                className="img-icon-style"
                                                                src={this.state.isElevatedContentOpen === false
                                                                    ? this.state.expandImage
                                                                    : this.state.collapseImage}
                                                                onClick={this
                                                                    .toggleElevatedContent
                                                                    .bind(this)} />
                                                        </div>
                                                    </div>

                                                </h4>
                                            </div>
                                            <div id="collapseTwo" className={elevatedContentClass}>
                                                <div className="panel-body">
                                                    <p>{this.props.placeHolder.ELEVATED_PERMISSION_PARA1_1} <span><b>{elevatedInfo.elevatedCountries}</b></span> {this.props.placeHolder.ELEVATED_PERMISSION_PARA1_2} <span><b>{elevatedInfo.elevatedInsertedBy}</b></span> {this.props.placeHolder.ELEVATED_PERMISSION_PARA1_3} <span><b>{elevatedInfo.elevatedPurpose}.</b></span></p>
                                                    <br />
                                                    <p>{this.props.placeHolder.ELEVATED_PERMISSION_PARA2}</p>
                                                    <p>{this.props.placeHolder.ELEVATED_PERMISSION_PARA3}</p>
                                                </div>
                                            </div>
                                        </div> : null}

                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <div className="row">
                                                        <div className="col-md-10 permission-header-style">
                                                            <a
                                                                className="accordion-toggle collapsed a-tag"
                                                                data-toggle="collapse"
                                                                data-parent="#accordion"
                                                                href="#collapseTwo">{this.props.placeHolder.PROACTIVE_TRAVELER_CARE_ACCESS}
                                                            </a>
                                                        </div>
                                                        <div className="col-sm-1 expand-icon-style">
                                                            <img
                                                                alt="contentopen"
                                                                className="img-icon-style"
                                                                src={this.state.isTravelCareContentOpen === false
                                                                    ? this.state.expandImage
                                                                    : this.state.collapseImage}
                                                                onClick={this
                                                                    .toogleTravelCareContent
                                                                    .bind(this)} />
                                                        </div>
                                                    </div>

                                                </h4>
                                            </div>
                                            <div id="collapseTwo" className={travelContentClass}>
                                                <div className="panel-body">
                                                    <p>{proactiveGroupValue}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}

export default ProfileComponent;

const createCustomToolBar = (props, toolBar) => {
    return (
        <div className="ptc-settings-custom-toolbar">
            <div className='col-xs-12 col-sm-12 col-md-12'>
                {toolBar.components.searchPanel}
            </div>
        </div>
    );
}