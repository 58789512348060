import React from 'react';
import createClass from 'create-react-class';

let Filter = createClass({
	onclickfilter: function() {
		this.props.updateFilter(this.props.type);
	},
	render: function() {
		return (
			<li tabIndex="1" href="#" ref="filterInput" onClick={this.onclickfilter}>
				<p className="filter-email">
					{this.props.searchData} {this.props.placeHolder.IN}{' '}
					<b name={this.props.type}>{this.props.placeHolder[this.props.type]}</b>
				</p>
			</li>
		);
	}
});

export default Filter;
