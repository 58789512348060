'use strict';
const SELECT="SELECT_WITHOUT_COLON"
import React from "react";
import { ButtonToolbar,DropdownButton,NavItem} from 'react-bootstrap';
export default class DropDownBox extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      title:SELECT
    };
  }
  _onSelect(eventKey,event){
    this.setState({
      title:eventKey
    });
    this.props.onSelectDropDown(eventKey);

  }
  shouldComponentUpdate(newProps,newState){
    if(newProps.Selected ==this.props.Selected && this.state==newState){
      return false;
    }
    return true
  }
  componentWillReceiveProps(newProps){
    if(newProps.Selected !=this.props.Selected || newProps.reset !=this.props.reset){
          this.setState({title:SELECT})
    }
  }


  render () {
    let self=this;
    let menuItemsArr;
    let menu;
    let title=getTitle(this.state.title,this.props.placeHolder[this.state.title]);
    if(this.props.Selected=="Comment"){
       menu=["TRAVELER","TRIP","SEGMENT"];
       menuItemsArr=menu.map(function(menuItem,i){
        return <NavItem eventKey={menuItem}>{self.props.placeHolder[menuItem]}</NavItem>
      });
    }else{
      menu=["URGENT_ISSUE","OUTSTANDING_ISSUE","HELD_FLIGHT"];
      menuItemsArr=menu.map(function(menuItem,i){
        return <NavItem key={i} eventKey={menuItem}><span className={getIconClass(menuItem)}> &nbsp; </span>{self.props.placeHolder[menuItem]}</NavItem>
      });
    }
    return(
      <ButtonToolbar>
        <DropdownButton title={title} key={this.state.title} onSelect={this._onSelect.bind(this)} id="dropdown-size-medium" className="custom_option">
          {menuItemsArr}
        </DropdownButton>
      </ButtonToolbar>
    );
  }
}
function getIconClass(menuItem){
  switch(menuItem){
    case "HELD_FLIGHT":
      return "held_flight";
    case "OUTSTANDING_ISSUE":
      return "outstanding_issue";
    case "URGENT_ISSUE":
      return "urgent_issue";
  }
}
function getTitle(title,text){
  switch(title){
    case "HELD_FLIGHT":
    case "OUTSTANDING_ISSUE":
    case "URGENT_ISSUE":
      return (<a tabIndex="1" href="#"><span className={getIconClass(title)}>&nbsp; </span>{text}</a>);
      break;
    case SELECT:
      return text;
      break
    default:
      return text;
  }
}
