import React from "react";
import {CallStatusAndComments} from "../../CallStatusCommons/CallStatusAndComments.Object"
import HTTP from '../../../../services/httpservice';
export default class ConfirmDeletePopup extends React.Component{
   constructor(props) {
    super(props);
    this.state = {
      Result:{}
    };
  }

  _onClickYesButton(){
    let self=this;
    var Test=new CallStatusAndComments();
    var  SegmentArr=this.props.SegmentId;
    var commentArr=this.props.commentId
    if(this.props.title=="comments"){
        if(this.props.scope=="Trip"){
          let TripArr=findSegmentIdByTripId(this.props.tripIdsegmentArray,this.props.tripId);
          SegmentArr=TripArr.SegmentId;
          var commentArrtemp=[];
          for(var i=0;i<SegmentArr.length;i++){
            commentArrtemp.push(this.props.commentId);
          }
          commentArr=commentArrtemp;
        }
        Test.setCallStatusAndCommentsValues(this.props.userInfo,this.props.scope,this.props.noteText,"comment",true);
    }else if(this.props.title=="callstatus"){
        Test.setCallStatusAndCommentsValues(this.props.userInfo,this.props.issueType,this.props.noteText,this.props.title,true);
    }
    Test.setTripInfo(this.props.tripId,SegmentArr,this.props.RecordLocator,this.props.source);
    //Test.setTripInfoDelete("Traveler","87hgk768u-4rwt-8546-v49o-gfdh5qwsh66543","1234rfdtphxsfo08120904",this.props.Source);
    Test.setUserInforDel(this.props.EmailId,this.props.travelerId);
    Test.setCommentId(this.props.noteText,commentArr);
    HTTP.postJSON('/gtr/trip/traveler/notes/v1/delete',Test).then(function(response){
      self.setState({
        Result:response
      });
    });
    this.props.cancelButton();
    var ConfirmDelete='#ConfirmDelete'+this.props.uniqueId;
    var DeletePopup='#DeletePopup'+this.props.uniqueId;
    window.$(ConfirmDelete).modal('hide');
    window.$(DeletePopup).modal('hide');
  }
  shouldComponentUpdate(newProps,newState){
    if(this.state==newState){
      return false;
    }
    return true
  }
  componentDidUpdate(prevProps, prevState){
    if(prevState.Result!=this.state.Result){
      if(this.state.Result.DeleteCallStatusCommentsResponse!=null){
        if(this.state.Result.DeleteCallStatusCommentsResponse.CommentsSummary!=null){
          if(this.props.title=="comments"){
              if(this.props.scope=="Traveler"){
                this.props.UpdateCommentCount(this.state.Result.DeleteCallStatusCommentsResponse.CommentsSummary.Traveler);
              }
          }else{
              let TempTraveler=this.state.Result.DeleteCallStatusCommentsResponse.CommentsSummary.CallStatus;
              this.props.DeleteCallStatus(TempTraveler);
          }
          this.props.UpdateTable(this.state.Result.DeleteCallStatusCommentsResponse.Traveler);
        }
      }
      if(this.state.Result.DeleteCallStatusCommentsResponse!=null){
        if(this.state.Result.DeleteCallStatusCommentsResponse.Success=="True"){
          this.props.SaveSucess(this.props.title,"Delete",true);
        }else{
          this.props.SaveSucess(this.props.title,"Delete",false);
        }
      }
    }
  }

  focusModal(){
    var key = "qwert_"+this.props.uniqueId;
    this.refs[key].focusModal();
  }
  onKeyPressEvent() {
    window.$('.modal_delete').keydown(function(e) {
      if(window.$('.last').is(":focus") && (e.which || e.keyCode) == 9) {
            e.preventDefault();
           window.$('.first').focus();
        }
     });
  }


  render () {

    var uniqueId="ConfirmDelete"+this.props.uniqueId;
    return (
      <div className="modal modal_delete fade" onKeyUp={this.onKeyPressEvent.bind()} id={uniqueId} role="dialog" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog">
            <div className="modal-content confirmation">
              <div className="modal-header">
              <div className="col-sm-12">    <button ref={"qwert_"+this.props.uniqueId} type="button" className="close" onClick={this.props.onClickNo} data-dismiss="modal">&times;</button>
                <h4 className="modal-title">Confirmation</h4>
              </div>  </div>
              <div className="modal-body">
                <div className="col-sm-12">
                    Are you sure you want to delete this comment or resolve  this issue?


<div className="button_holder">
  <button className="save_button enabled" data-dismiss="modal" onClick={this._onClickYesButton.bind(this)}>{this.props.placeHolder.YES}</button>
  <button className="button last" data-dismiss="modal" data-dismiss="modal" onClick={this.props.onClickNo} >{this.props.placeHolder.NO}</button>
  </div>



                </div>
              </div>
            </div>
          </div>
        </div>

    );
}
}
function findSegmentIdByTripId(TripSegmentArray,tripId){
  for(let i=0;i<TripSegmentArray.length;i++){
    if(TripSegmentArray[i].tripId==tripId){
      return TripSegmentArray[i];
    }
  }
}
