import React from "react";
import Segments from "../TripsItinerary/Segments.js";
import Secondaryfilter from "../secondaryfilter";
import HTTP from '../../../../services/httpservice.js';
import _ from "underscore";
import {EnvironmentDeployment} from "../../../constants";
import {otaSummaryException} from '../../../../services/utils';
import createClass from 'create-react-class';
const CHUNK_FOR_TRIPIDS = 10;

var PastTripsItenary = createClass({
    getInitialState: function () {
        return {
            openDetailsKey: "",
            TripSummaryJson: null,
            segmentCommentsJson: null,
            tripSummaryAppendArray:[],
            showMoreCount:null,
            update: null,
            date: '',
            selected: '',
            errorMessage: false,
            showMoreIteration:0,
            loading:false,
            iconstate: {
                Air: true,
                Car: true,
                Hotel: true,
                Other: true
            }
        }
    },

    handleShowMoreLink: function() {
        this.setState({showMoreIteration:this.state.showMoreIteration+1,loading:true})
        this.getTripDetailsResponseAction(this.state.showMoreIteration+1);  
    },

    componentDidMount: function () {
       this.getTripDetailsResponseAction(this.state.showMoreIteration);
    },
    getTripDetailsResponseAction(showMoreIteration) {
        var that = this;
        this
            .props
            .socket
            .on('update', function (obj) {
                Number.prototype.padLeft = function (base, chr) {
                    var len = (String(base || 10).length - String(this).length) + 1;
                    return len > 0
                        ? new Array(len).join(chr || '0') + this
                        : this;
                }
                var d = new Date;
                var dformat = [
                    (d.getMonth() + 1).padLeft(),
                    d
                        .getDate()
                        .padLeft(),
                    d.getFullYear()
                ].join('/') + ' ' + [
                    d
                        .getHours()
                        .padLeft(),
                    d
                        .getMinutes()
                        .padLeft()
                ].join(':');  
                that.setState({TripSummaryJson:obj.data, date: dformat, update: true})
                that.forceUpdate();
            });
       
        var summaryData;
        var segmentCommentsData = [];
        var reqXmlStart = "<GetTripDetailsRequest><socketID>" + this.props.sID + "</socketID><Travelers><TravelerId>" + this.props.tID + "</TravelerId></Travelers><Trips>";
        var reqXmlend = "</Trips></GetTripDetailsRequest>";
        var reqXml = "";
        
        //Show more data condition
        if(this.props.pastTripIds.length>CHUNK_FOR_TRIPIDS) {
            var chunk=CHUNK_FOR_TRIPIDS, k,m;
            var splitArray = [];
            for (k=0,m=this.props.pastTripIds.length; k<m; k+=chunk) {
                splitArray.push(this.props.pastTripIds.slice(k,k+chunk));
            }
            if(this.state.showMoreCount === null) {
                this.setState({showMoreCount:splitArray.length-1});
            } else {
                this.setState({showMoreCount:this.state.showMoreCount-1});
            }
            for (var i = 0; i < splitArray[showMoreIteration].length; i++) {
                reqXml = reqXml + "<TripId>" + splitArray[showMoreIteration][i] + "</TripId>";
            }
        } else {
            for (var i = 0; i < this.props.pastTripIds.length; i++) {
                reqXml = reqXml + "<TripId>" + this.props.pastTripIds[i] + "</TripId>";
            }
        }
        
        var reXml = reqXmlStart + reqXml + reqXmlend;
        var self = this;
        var tripIdObject = [];
        for (var i = 0; i < this.props.TripIdDetailsArray.length; i++) {
            tripIdObject.push({"tripid": this.props.TripIdDetailsArray[i]});
        }
        var postBody = {
            "Traveler": {
                "Id": this.props.traveller.TravelerId,
                "email": this.props.traveller.EmailId[0]
            },
            "TripIds": tripIdObject
        };

        let socketConnectionPostBody = {
            socketId: this.props.sID,
            knowmeId: this.props.traveller.TravelerId,
            previousknowmeId: this.props.previousclickkey
        }
        
        if (this.props.pastTripIds.length !== 0) {
            HTTP
                .post('/api/otasummary', reXml)
                .then(function (response) {
                    const temporaryResponse = response.GetTripDetailsResponse;
                    otaSummaryException(temporaryResponse,"otasummary");
                    summaryData = response;
                    if (self.props.notificationList != null && self.props.selectedNotifiedObject != undefined && self.props.pnrOfNotification != null && self.props.pnrOfNotification != "" && response.GetTripDetailsResponse.length == 0) {
                        console.log("OTA summary is empty..going to cancelled tab");
                       // if (self.context.firstrender) {
                            //self.context.firstrender = false;
                            if(self.props.pastKey === null){
                                self
                                .props
                                .changeToCancel();
                            self.setState({TripSummaryJson: summaryData});
                            self.forceUpdate();
                            }
                        //}
                    }else if(self.props.notificationList != null && self.props.selectedNotifiedObject != undefined && self.props.pnrOfNotification != null && self.props.pnrOfNotification != ""){
                        let isFound=false;
                        for(let i=0;i<summaryData.GetTripDetailsResponse.length;i++){
                              let segmentObj = summaryData.GetTripDetailsResponse[i];
                              if(segmentObj.segmentid==self.props.selectedNotifiedObject.segmentId){
                                    isFound=true;
                                    break;
                              }
                        }
                        if(!isFound)
                             self.props.changeToCancel();
                    }

                    // Append trip summary json to already existing json object for Show More functionality
                    if(self.props.pastTripIds.length>CHUNK_FOR_TRIPIDS) {
                        if(summaryData !== undefined) {
                            if(summaryData.GetTripDetailsResponse !== undefined) {
                                for(var r=0;r<summaryData.GetTripDetailsResponse.length;r++) {
                                    self.state.tripSummaryAppendArray.push(summaryData.GetTripDetailsResponse[r]);
                                }
                            }    
                        }
                        delete summaryData.GetTripDetailsResponse;
                        summaryData.GetTripDetailsResponse = self.state.tripSummaryAppendArray;

                        //chronological ordering for trip details response
                        summaryData.GetTripDetailsResponse.sort( function (a,b) {
                            var valA = a.SortDateTime;
                            var valB = b.SortDateTime;
                            return valB-valA;
                          });
                        self.setState({loading:false});
                    }
                    
                    self.setState({TripSummaryJson:summaryData});
                    self.forceUpdate();
                })
        } else {
            if(self.props.selectedNotifiedObject != undefined && self.props.pnrOfNotification != null && self.props.pnrOfNotification != ""){
                // self
                // .props
                // .changeToCancel(false);
            }
            self.setState({TripSummaryJson: {}});
        }

        //send the socket Id
        HTTP
            .socket('/socket', socketConnectionPostBody, {
                action: socketConnectionPostBody.previousknowmeId === ""
                    ? "save"
                    : "upsert"
            })
            .then(function (result) {
                console.log("deleted");
            });

        HTTP
            .postJSON('/gtr/trip/traveler/segmentnotes/v1', postBody)
            .then(function (result) {
                segmentCommentsData = result;
                self.setState({segmentCommentsJson: segmentCommentsData});
                self.forceUpdate();
            });
    },

    contextTypes: function () {
        return {value: "", firstrender: false, previousclickkey: "", secondpreviousclickkey: " "}
    },
    resetkeys: function (key) {
        this.setState({openDetailsKey: key})
        this.context.previousclickkey = "";
    },
    hadlingdropdown: function (key) {
        this.context.secondpreviousclickkey = this.context.previousclickkey;
        this.context.previousclickkey = this.state.openDetailsKey;
        if (this.state.openDetailsKey == this.context.secondpreviousclickkey) {
            this.resetkeys(key);
        } else {
            this.setState({openDetailsKey: key})
        }
    },
    addgroundpopup: function () {
        window.$('#addgroup').modal('show');
    },
    displayMessage: function () {
        this.setState({errorMessage: true})
    },
    dismissModal: function () {
        window.$('#addgroup').modal('hide');
        this.setState({errorMessage: false})
    },
    componentWillReceiveProps: function (nextProps) {
        if (this.props.PastFlg != nextProps.PastFlg || this.props.cancelFlg != nextProps.cancelFlg) {
            var iconstatetemp = {
                Air: true,
                Car: true,
                Hotel: true,
                Other: true
            }
            this.setState({iconstate: iconstatetemp});
        }
    },
    componentWillMount: function () {
        var iconTemp = {
            Air: "airplane",
            Car: "car",
            Hotel: "hotel",
            Other: "others"
        };
        this.context.iconCss = iconTemp;
    },

    contextTypes: function () {
        return {
            iconCss: {
                Air: "airplane",
                Car: "car",
                Hotel: "hotel",
                Other: "others"
            }
        }
    },
    clickOnTripMode: function (iconstate, tripMode) {
        var tempIcon = ["Air", "Car", "Hotel", "Other"];
        var trueIcons = 0;
        for (var iterator = 0; iterator < tempIcon.length; iterator++) {
            if (this.state.iconstate[tempIcon[iterator]]) {
                trueIcons++;
            }
        }
        if (trueIcons == tempIcon.length) {
            var iconstatetemp = {
                Air: false,
                Car: false,
                Hotel: false,
                Other: false
            }
            iconstatetemp[tripMode] = true;
            this.setState({iconstate: iconstatetemp});
        } else {
            var iconstatetemp = this.state.iconstate;
            if (iconstatetemp[tripMode]) {
                iconstatetemp[tripMode] = false;
                this.setState({iconstate: iconstatetemp});
            } else {
                iconstatetemp[tripMode] = true;
                this.setState({iconstate: iconstatetemp});
            }
        }

    },
    onclickicon: function (tripMode) {
        var iconstate = this.clickOnTripMode(this.state.iconstate, tripMode)
    },
    resetToDefaultfn: function () {
        var iconstatetemp = {
            Air: true,
            Car: true,
            Hotel: true,
            Other: true
        }
        this.setState({iconstate: iconstatetemp});
    },
    UpdateTripCommentCount: function (responsejson) {
        let duplicate;
        var TripSummaryJsontemp = this.state.segmentCommentsJson;
        for (var i = 0; i < TripSummaryJsontemp.CallStatusOrComments.Traveler.Segments.length; i++) {
            for (var j = 0; j < responsejson.Segments.length; j++) {
                if (TripSummaryJsontemp.CallStatusOrComments.Traveler.Segments[i].Comments[0].SegmentId == responsejson.Segments[j].Comments[0].SegmentId) {
                    TripSummaryJsontemp.CallStatusOrComments.Traveler.Segments[i] = responsejson.Segments[j];
                    duplicate = true;
                }
            }
        }
        for (var i = 0; i < responsejson.Segments.length; i++) {
            var temp = false;
            for (var j = 0; j < TripSummaryJsontemp.CallStatusOrComments.Traveler.Segments.length; j++) {
                if (TripSummaryJsontemp.CallStatusOrComments.Traveler.Segments[j].Comments[0].SegmentId == responsejson.Segments[i].Comments[0].SegmentId) {
                    temp = true;
                }
            }
            if (!temp) {
                TripSummaryJsontemp
                    .CallStatusOrComments
                    .Traveler
                    .Segments
                    .push(responsejson.Segments[i]);
            }
        }
        this.setState({segmentCommentsJson: TripSummaryJsontemp});
    },
    handleParent: function (result) {
        var Segment = result.Segments[0].Comments[0];
        var segmentCommentsJsontemp = this.state.segmentCommentsJson;
        var Duplicate = false;
        for (var i = 0; i < segmentCommentsJsontemp.CallStatusOrComments.Traveler.Segments.length; i++) {
            var segmentiterator = segmentCommentsJsontemp.CallStatusOrComments.Traveler.Segments[i];
            if (segmentiterator.Comments[0].SegmentId == Segment.SegmentId) {
                segmentCommentsJsontemp.CallStatusOrComments.Traveler.Segments[i] = result.Segments[0];
                this.setState({segmentCommentsJson: segmentCommentsJsontemp});
                Duplicate = true;
            }
        }
        if (!Duplicate) {
            segmentCommentsJsontemp
                .CallStatusOrComments
                .Traveler
                .Segments
                .push(result.Segments[0]);
            this.setState({segmentCommentsJson: segmentCommentsJsontemp});
        }
    },
    render: function () {
        var ActivePNR = [];
        var tripSourceURL = "";
        if (EnvironmentDeployment == "E3") 
            tripSourceURL = "https://ground.amexgbt.com/trip/" + this.state.selected;
        else 
            tripSourceURL = "http://gbt.app-testing.mozio.com/trip/" + this.state.selected;
        var tempiconCss = {
            Air: "airplane",
            Car: "car",
            Hotel: "hotel",
            Other: "others"
        }
        var innerSrchTxt = this
            .props
            .innerSrchTxt
            .toLowerCase();
        var userProfileData = this.props.traveller;
        var tempIcon = ["Air", "Car", "Hotel", "Other"];
        this.context.iconCss = tempiconCss;
        for (var iterator = 0; iterator < tempIcon.length; iterator++) {
            if (!this.state.iconstate[tempIcon[iterator]]) {
                this.context.iconCss[tempIcon[iterator]] = this.context.iconCss[tempIcon[iterator]] + " inactive";
            }
        }
        var hadlingdropdown = this.hadlingdropdown;
        var openDetailsKey = this.state.openDetailsKey;
        var previousclickkey = this.context.previousclickkey;
        var innersrchFlg = this.props.innersrchFlg;
        var DisplayAr = [];
        var iconarray = [];
        var ActiveClass = "";
        let CommentSegmentArray = [];
        var update = this.state.update;
        if (this.props.PastFlg) {
            ActiveClass = 'past_all';
        }
        var ProductScope = "";
        if (this.props.ProductScope.indexOf('MOZIO') >= 0) 
            ProductScope = "Present"
        else {
            ProductScope = "";
        }
        var ActiveFilter;
        if (this.props.active) {
            ActiveFilter = "Future";
        } else if (this.props.PastFlg) {
            ActiveFilter = "Past";
        }

        if (this.state.TripSummaryJson != null && this.state.segmentCommentsJson != null) {
            if (this.state.TripSummaryJson.activerecordlocator != null) {
                for (var j = 0; j < this.state.TripSummaryJson.activerecordlocator.length; j++) 
                    ActivePNR[j] = this.state.TripSummaryJson.activerecordlocator;
                }
            var newActivePNR = [];
            if (ActivePNR[0] != undefined) {
                newActivePNR = ActivePNR[0].filter(function (item, index, inputArray) {
                    return inputArray.indexOf(item) == index;
                });
            }

            if (this.state.TripSummaryJson.GetTripDetailsResponse != null) {
                var GetTripDetailsResponse = this.state.TripSummaryJson.GetTripDetailsResponse;
                if (this.state.segmentCommentsJson.CallStatusOrComments != null && this.state.segmentCommentsJson.CallStatusOrComments != undefined) {
                    var callStatusCommentsResponseData = this.state.segmentCommentsJson.CallStatusOrComments.Traveler.Segments;
                    for (let i = 0; i < GetTripDetailsResponse.length; i++) {
                        var duplicate = false;
                        if (callStatusCommentsResponseData.length == 0) {
                            CommentSegmentArray.push({data: GetTripDetailsResponse[i], segmentId: GetTripDetailsResponse[i].segmentid, count: 0, comments: []});
                            CommentSegmentArray.push(GetTripDetailsResponse[i]);
                            duplicate = true;
                        } else {
                            _
                                .each(callStatusCommentsResponseData, function (data) {
                                    if (data.Comments[0].SegmentId == GetTripDetailsResponse[i].segmentid) {
                                        CommentSegmentArray.push({data: GetTripDetailsResponse[i], segmentId: data.Comments[0].SegmentId, count: data.Count, comments: data.Comments});
                                        duplicate = true;
                                    }
                                });
                        }
                        if (!duplicate) {
                            CommentSegmentArray.push({data: GetTripDetailsResponse[i], segmentId: GetTripDetailsResponse[i].segmentid, count: 0, comments: []});
                        }
                    }
                }
                let notificationopen = false;

                if (this.props.notificationList != null && this.props.pnrOfNotification != "undefined" && this.props.pnrOfNotification != "") {
                    if (this.props.notificationList.travelerQueries != null) {
                        for (let i = 0; i < this.props.notificationList.travelerQueries.length; i++) {
                            if (this.props.notificationList.travelerQueries[i].FlightNumber == GetTripDetailsResponse[iterator].Code + GetTripDetailsResponse[iterator].flightNo) {
                                notificationopen = true;
                            }
                        }
                    }
                }
                let TripSegmentArray = constructSegmentsByTripIds(this.state.TripSummaryJson.GetTripDetailsResponse);
                if (this.props.selectedNotifiedObject != null && this.props.initial && this.props.pnrOfNotification != undefined && this.props.selectedNotifiedObject != undefined) {
                    let flag = true;
                    for (var iterator = 0; iterator < this.state.TripSummaryJson.GetTripDetailsResponse.length; iterator++) {
                        if (this.props.selectedNotifiedObject.flightNumber == GetTripDetailsResponse[iterator].Code + GetTripDetailsResponse[iterator].flightNo) {
                            flag = false;
                        }
                    }
                    if (flag) {
                        this.context.firstrender = false;
                        this
                            .props
                            .changeToCancel();
                    }
                }
                let firstHotelSegment = false;
                let showOffer;
                for (var iterator = 0; iterator < this.state.TripSummaryJson.GetTripDetailsResponse.length; iterator++) {
                    if (this.props.openHreSegment) {
                        let TripDetailsResponse = this.state.TripSummaryJson.GetTripDetailsResponse[iterator];
                        let HRESegments = this.props.HRESegments[TripDetailsResponse.TripId]
                        if (HRESegments) {
                            let HRESegmentsArray = HRESegments.HRESegment;
                            if (!Array.isArray(HRESegmentsArray)) {
                                HRESegmentsArray = [HRESegmentsArray];
                            }
                            showOffer = HRESegmentsArray.find(item => item === TripDetailsResponse.segmentid + ":" + TripDetailsResponse.ConfirmationNumber) === undefined
                                ? false
                                : true;
                        }
                        if (TripDetailsResponse.Type === "Hotel-CheckIn" && showOffer) {
                            firstHotelSegment = iterator;
                            break;
                        }
                    }
                }

                for (var iterator = 0; iterator < this.state.TripSummaryJson.GetTripDetailsResponse.length; iterator++) {
                    let openHreSegment = false;
                    if (this.props.openHreSegment && iterator === firstHotelSegment) {
                        openHreSegment = true;
                    }
                    if (this.props.selectedNotifiedObject != null && this.props.initial && this.props.pnrOfNotification != undefined && this.props.selectedNotifiedObject != undefined && (this.props.selectedNotifiedObject.flightNumber === GetTripDetailsResponse[iterator].Code + GetTripDetailsResponse[iterator].flightNo)) {
                        if (GetTripDetailsResponse[iterator].ActiveFilter === 'Past' && ActiveFilter != 'Past') {
                            this
                                .props
                                .changeToPast();
                        }
                    }

                    if (GetTripDetailsResponse[iterator].ActiveFilter == ActiveFilter) {
                        if (iconarray.indexOf(getIcon(GetTripDetailsResponse[iterator].Type)) == -1) {
                            iconarray.push(getIcon(GetTripDetailsResponse[iterator].Type));
                        }
                        let isCallStatus = getCallStatus(this.props.CallStatusArr, this.props.RecordLoc, GetTripDetailsResponse[iterator].BookingInfo.RecordLocator);
                        var segmentCounts = 0;
                        var segmentComments = [];
                        if (typeof CommentSegmentArray != "undefined") {
                            if (typeof CommentSegmentArray[iterator] != "undefined") {
                                if (typeof CommentSegmentArray[iterator].count != "undefined") {
                                    segmentCounts = CommentSegmentArray[iterator].count;
                                    segmentComments = CommentSegmentArray[iterator].comments;
                                }
                            }
                        }
                        if (SegmentFilter(GetTripDetailsResponse[iterator], this.state.iconstate, innersrchFlg, innerSrchTxt)) {
                            DisplayAr.push(<Segments
                                openChatWindowMethod={this.props.openChatWindowMethod}
                                openedChatList={this.props.openedChatList}
                                orgId={this.props.orgId}
                                SummarySanctions={this.props.SummarySanctions}
                                premGroup={this.props.premGroup}
                                ClientName={this.props.ClientName}
                                openHreSegment={openHreSegment}
                                setOfferAsFalse={this.props.setOfferAsFalse}
                                HRESegments={this.props.HRESegments}
                                changeToPast={this.props.changeToPast}
                                openDocsTab={this.props.openDocsTab}
                                userId={this.props.userId}
                                userName={this.props.userName}
                                ActiveFilter={ActiveFilter}
                                callApiNotification={this.props.callApiNotification}
                                notificationopen={notificationopen}
                                user={this.props.user}
                                pnrOfNotification={this.props.pnrOfNotification}
                                notificationList={this.props.notificationList}
                                selectedNotifiedObject={this.props.selectedNotifiedObject}
                                settingsState={this.props.settingsState}
                                placeHolder={this.props.placeHolder}
                                gotoAllCommentsTab={this.props.gotoAllCommentsTab}
                                ShowCallStatusAndComments
                                ={this.props.ShowCallStatusAndComments}
                                handleParent={this.handleParent}
                                segmentComments={segmentComments}
                                UpdateTripCommentCount={this.UpdateTripCommentCount}
                                segmentCount={segmentCounts}
                                TripSegmentArray={TripSegmentArray}
                                isCallStatus={isCallStatus}
                                UpdateCommentCount={this.props.UpdateCommentCount}
                                SaveSucess={this.props.SaveSucess}
                                key={iterator}
                                Key={iterator + 1}
                                userProfileData={userProfileData}
                                previouskey={previousclickkey}
                                openDetailsKey={openDetailsKey}
                                Change={hadlingdropdown}
                                TripDetailsResponse={GetTripDetailsResponse[iterator]}
                                tID={this.props.tID}
                                DispTripId={this.props.DispTripId}/>);
                        }
                    }
                }
            }

            var RecordLocDisplay = newActivePNR.map((item, i) => <div
                className="recordloc_options"
                key={i}
                onClick={(e) => {
                this.setState({selected: item})
            }}>
                <input type="radio" name="options" value={item}/>{item}
                <br/>
            </div>);
            iconarray = sortArray(iconarray);

            if (DisplayAr.length == 0) {
                return (
                    <div className={ActiveClass}>
                        {update
                            ? <div className="updated_at">
                                    <b>Last Updated:</b>
                                    {this.state.date}
                                </div>
                            : null}
                        {iconarray.length > 0
                            ? <a href="#" className="reset" onClick={this.resetToDefaultfn} tabIndex="-1">{this.props.placeHolder.RESET}</a>
                            : null}
                        <Secondaryfilter
                            iconCss={this.context.iconCss}
                            onclickicon={this.onclickicon}
                            array={iconarray}/>
                        <div className="alert alert-danger" role="alert">
                            {this.props.PastFlg
                                ? this.props.placeHolder.NO_PAST
                                : this.props.placeHolder.NO_ACTIVE}
                        </div>
                    </div>
                )
            }

            return (
                <div className={ActiveClass}>
                    {update
                        ? <div className="updated_at">
                                <b>Last Updated:</b>
                                {this.state.date}
                            </div>
                        : null}
                    {iconarray.length > 0
                        ? <a href="#" className="reset" onClick={this.resetToDefaultfn} tabIndex="-1">{this.props.placeHolder.RESET}</a>
                        : null}
                    <Secondaryfilter
                        iconCss={this.context.iconCss}
                        onclickicon={this.onclickicon}
                        array={iconarray}/> 
                        {ActiveFilter == "Future" && ProductScope == "Present"
                        ?   <div className="add_group">
                                <a onClick={this.addgroundpopup}>{this.props.placeHolder.ADD_GROUND}</a>
                            </div>
                        : null
                        }
                    {DisplayAr}

                    {this.props.pastTripIds.length > CHUNK_FOR_TRIPIDS && this.state.showMoreCount > 0 && this.state.showMoreCount !== null ?
                    <div className="show-more-div">
                        <a className="show-more-link" onClick={this.handleShowMoreLink} >Show More</a>
                    </div>:null}
                    
                    {this.state.loading ? <div className={ActiveClass}>
                    <div className="loading_image trip_loader">&nbsp;</div>
                    </div>:null}

                    <div className="holder">
                        <div
                            className="modal modal_delete fade"
                            id="addgroup"
                            role="dialog"
                            data-backdrop="static"
                            data-keyboard="false">
                            <div className="modal-dialog">
                                <div className="modal-content password">
                                    <div className="modal-header">
                                        <div className="col-sm-12">Add Ground to:<button type="button" className="close alert-close" data-dismiss="modal">&times;</button>
                                        </div>
                                    </div>
                                    <div className="modal-body add-group-body">
                                        {this.state.errorMessage == true
                                            ? <div className="option_message">Please select a PNR.<br/></div>
                                            : null
                                        }
                                        <div className="col-sm-12 record-locators">
                                            {RecordLocDisplay}
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="button_holder">
                                                {this.state.selected != ""
                                                    ? <a target="_blank" href={tripSourceURL}>
                                                            <button
                                                                className="save_button reset-save-button enabled"
                                                                onClick={this.dismissModal}>Send</button>
                                                        </a>
                                                    : <button
                                                        className="save_button reset-save-button enabled"
                                                        onClick={this.displayMessage}>Send</button>
                                                }
                                                <button className="button last" data-dismiss="modal" data-dismiss="modal">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={ActiveClass}>
                    <div className="loading_image trip_loader">&nbsp;</div>
                </div>
            )
        }
    }
});
function sortArray(array) {
    var tempArray = [];
    if (array.indexOf("Air") != -1) {
        tempArray.push("Air");
    }
    if (array.indexOf("Hotel") != -1) {
        tempArray.push("Hotel");
    }
    if (array.indexOf("Car") != -1) {
        tempArray.push("Car");
    }
    if (array.indexOf("Other") != -1) {
        tempArray.push("Other");
    }
    return tempArray.reverse();
}

function getIcon(icon) {
    switch (icon) {
        case 'Air':
            return 'Air';
        case 'Car-PickUp':
        case 'Car-DropOff':
            return 'Car';
        case 'Hotel-CheckOut':
        case 'Hotel-CheckIn':
            return 'Hotel';
        case 'Other':
        case 'Limo':
        case 'Bus':
        case 'Rail':
            return 'Other';
    }
}

function SegmentFilter(segmentJson, iconList, innersrchFlg, innerSrchTxt) {
    if (iconList[getIcon(segmentJson.Type)]) {
        if (!innersrchFlg || CheckForInnerText(segmentJson, innerSrchTxt)) {
            return true;
        }
    }
    return false;
}

function CheckForInnerText(segmentJson, innerSrchTxt) {
    var filterStrings = [];
    var lowerCaseFilterStrings = [];
    console.log(segmentJson);

    switch (segmentJson.Type) {
        case 'Air':
            filterStrings.push(segmentJson.DepartureDateTime.substring(0, 7));
            break;
        case 'Car-PickUp':
            filterStrings.push(segmentJson.PickUpDateDisplay.substring(0, 7));
            break;
        case 'Car-DropOff':
            filterStrings.push(segmentJson.DropOffDateDisplay.substring(0, 7));
            break;
        case 'Hotel-CheckOut':
            filterStrings.push(segmentJson.CheckOutDateDisplay.substring(0, 7));
            break;
        case 'Hotel-CheckIn':
            filterStrings.push(segmentJson.CheckInDateDisplay.substring(0, 7));
            break;
        case 'Other':
            filterStrings.push(segmentJson.startDateDisplay.substring(0, 7));
            break;
        case 'Limo':
            filterStrings.push(segmentJson.limoPickUpDateDisplay.substring(0, 7));
            filterStrings.push(segmentJson.limoDropOffDateDisplay.substring(0, 7));
            break;
        case 'Bus':
            filterStrings.push(segmentJson.BusArrivalDate.substring(0, 7));
            break;
        case 'Rail':
            filterStrings.push(segmentJson.railArrivalDate.substring(0, 7));
            break;
    }
    filterStrings.push(segmentJson.BookingInfo.RecordLocator);

    for (var iterator = 0; iterator < filterStrings.length; iterator++) {
        lowerCaseFilterStrings.push(filterStrings[iterator].toLowerCase());
    }
    if (lowerCaseFilterStrings.indexOf(innerSrchTxt.toLowerCase()) != -1) {
        return true;
    }
    return false;
}

function getCallStatus(CallStatusArr, RecordLocArr, RecordLocator) {
    if (RecordLocArr.indexOf(RecordLocator) != -1) {
        if (CallStatusArr[RecordLocArr.indexOf(RecordLocator)] != null) 
            return true;
        }
    return false
}

function constructSegmentsByTripIds(GetTripDetailsResponseArray) {
    let TripSegmentArray = [];
    function trip() {
        this.tripId = "",
        this.SegmentId = []
    }
    for (let i = 0; i < GetTripDetailsResponseArray.length; i++) {
        var duplicate = false;
        if (TripSegmentArray.length == 0) {
            let object = new trip();
            object.tripId = GetTripDetailsResponseArray[i].TripId;
            object
                .SegmentId
                .push(GetTripDetailsResponseArray[i].segmentid);
            TripSegmentArray.push(object);
            duplicate = true;
        } else {
            _.each(TripSegmentArray, function (trip) {
                if (trip.tripId == GetTripDetailsResponseArray[i].TripId) {
                    trip
                        .SegmentId
                        .push(GetTripDetailsResponseArray[i].segmentid);
                    duplicate = true;
                }
            });
        }
        if (!duplicate) {
            let object = new trip();
            object.tripId = GetTripDetailsResponseArray[i].TripId;
            object
                .SegmentId
                .push(GetTripDetailsResponseArray[i].segmentid);
            TripSegmentArray.push(object);
        }
    }
    return TripSegmentArray;
}
export default PastTripsItenary;
