var $ = require('jquery');

var service = {
    get: function (url, headersApp) {
        var resp = "";
        var settings = {
            "url": url,
            "cache": false,
            "method": "GET",
            "headers": {
                "accept": "application/json",
                ...headersApp
            }
        }

        resp = $
            .ajax(settings)
            .done(function (result) {
                const dataArray = result;
                for (let i = 0; i < dataArray.length; i++) {
                    if (dataArray[i].EmailId.length === 0 ) {
                        dataArray[i].EmailId[0] = " ";
                    }
                    if (dataArray[i].Name.length === 0) {
                        dataArray[i].Name[0] = " ";
                    }
                    if (dataArray[i].phone.length === 0) {
                        dataArray[i].phone[0] = " ";
                    }
                }
                result = dataArray;
                return result;
            });
        return resp;
    },

    post: function (url, xml) {
        var resp = "";
        var settings = {
            "url": url,
            "method": "POST",
            "cache": false,
            "headers": {
                "accept": "application/json",
                "content-type": "application/xml"
            },
            "data": xml
        }

        resp = $
            .ajax(settings)
            .done(function (result) {
                return result;
            });
        return resp;
    },
    postJSON: function (url, requestjson) {
        var resp = "";
        var settings = {
            "url": url,
            "method": "POST",
            "cache": false,
            "headers": {
                "accept": "application/json",
                "content-type": "application/json"
            },
            "data": JSON.stringify(requestjson)
        }

        resp = $
            .ajax(settings)
            .done(function (result) {
                return result;
            });
        return resp;
    },
    postJSONHeader: function (url, header, requestjson) {
        var resp = "";
        var settings = {
            "url": url,
            "method": "POST",
            "cache": false,
            "headers": header,
            "data": JSON.stringify(requestjson)
        }
        resp = $
            .ajax(settings)
            .done(function (result) {
                return result;
            });
        return resp;
    },
    putJSON: function (url, requestjson) {
        var resp = "";
        var settings = {
            "url": url,
            "method": "PUT",
            "cache": false,
            "headers": {
                "accept": "application/json",
                "content-type": "application/json"
            },
            "data": JSON.stringify(requestjson)
        }

        resp = $
            .ajax(settings)
            .done(function (result) {
                return result;
            });
        return resp;
    },
    postEmpty: function (url, headers) {
        var resp = "";
        var settings = {
            "url": url,
            "method": "POST",
            "headers": {
                "SMP-App-Id": "Offline/Gtr/TripViewer"
            }
        }
        resp = $
            .ajax(settings)
            .done(function (result) {
                return result;
            })
            .catch(function (r) {
                console.log(r)
                return r;
            });
        return resp;
    },
    socket: function (url, postBody, headers) {
        headers = {
            ...headers,
            "accept": "application/json",
            "content-type": "application/json"
        }
        var resp = "";
        var settings = {
            "url": url,
            "method": "POST",
            "headers": headers,
            "data": JSON.stringify(postBody)
        }
        resp = $
            .ajax(settings)
            .done(function (result) {
                return result;
            });
        return resp;
    },
    delete: function (url, postBody) {
        // console.log(postBody);
        var resp = "";
        var settings = {
            "url": url,
            "method": "DELETE",
            "cache": false,
            "headers": {
                "accept": "application/json",
                "content-type": "application/json"
            },
            "data": JSON.stringify(postBody)
        }
        resp = $
            .ajax(settings)
            .done(function (result) {
                return result;
            });
        return resp;
    }
};



module.exports = service;
