import React from "react";
import createClass from 'create-react-class';
let PopElipsisName  = createClass({
render: function () {
      return (
            <div>
                <div className='fade in popover bottom pop_over_name email_popup'>
                  <div className='arrow'></div>
                  <div className='popover-content'>
                      <div className='popover_custom'>
                      {this.props.email}
                      </div>
                  </div>
                </div>
            </div>
      );
      }
})
export default PopElipsisName;
