/**
  * @ Job Title Component
  * @ File - jobTitle.js
  * API call to get Job Title & Render the component
  */
import React, { Component } from 'react';
import HTTP from '../../services/httpservice';

// Define constants
const JOB_TITLE_URL = '/utilities/users/registration/v1?master=userroles';
class JobTitle extends Component {

  // Constructor called before render function
  // The object that is returned is assigned to  this.state
    constructor(props){
        super(props);
        this.state = {
            titleArray        : [],
            selectedJobtitle  : 'Select'
      }
    }

    // API call to get job title details.
    componentWillMount() {
        let self  = this;
        HTTP.get(JOB_TITLE_URL)
        .then(function (response) {
            self.setState({
              titleArray  :   response.AllUserRoles
            })
        });
    }

    // Prevent default action
    _handleOnClick(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    // Set Job title value
    _setJobTitle(item) {
        this.setState({selectedJobtitle:item.UserRoleText});
    }

    // Render Job Title Component
    // Map Job title options to select box
    render() {
        let options = null;
        if(this.state.titleArray !== undefined)
            options=this.state.titleArray.map((item,j)=>
               <li role="presentation" key={j} value={item.UserRoleText} onClick={this._setJobTitle.bind(this,item)}><a role="menuitem" tabIndex="-1" href="#">{item.UserRoleText}</a></li>
             );
        return (<div className="form-group">
                    <label for="jobtitle">Job Title:</label>
                    <div className="dropdown custom-jobtitle-dropdown-div">
                        <button className="btn btn-default dropdown-toggle custom-jobtitle-dropdown" type="button" id="menu1" data-toggle="dropdown">{this.state.selectedJobtitle}
                        <span className="caret"></span></button>
                        <ul className="dropdown-menu" role="menu" aria-labelledby="menu1" value={this.state.selectedJobtitle} onClick={this._handleOnClick.bind(this)} onBlur={()=>this.props.setValue('jobTitle',this.state.selectedJobtitle)} onChange={(e)=>this.setState({selectedJobtitle:e.target.value})}>
                            {options}
                        </ul>
                    </div>
                </div>);
    }
}
export default JobTitle;
