
import React, { Component } from 'react';
const SUGGESTION_TEXT             =   'This user name is not available. Please change the user name or pick it from the following available suggestions';
export default class UserNameSuggetion extends Component {

// Render userName suggestions component
render() {
    let self    = this;
    let options = this.props.userNameSugession.map((item, i) =>
        <div className="radio" key={i} onClick={(e) => self.props.setUserName(item)}>
            <label><input type="radio" name='usename' value="" />{item}</label>
            <br/>
        </div>
    );
    return (<div>
                <p className="username-suggestion-text">{SUGGESTION_TEXT}</p>
                <p className="sug-text">User Name Suggestions:</p>
                {options}
            </div>);
}
}