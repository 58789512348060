/**
  * @ Call status and comments table Component
  * @ File - CallStatusAndCommentsTable.js
  * This Component displays all call status and comments
  */
import React from "react";
import CallStatusAndCommentsRows from "./CallStatusComponents/CallStatusAndCommentsRows";
import { filterCallStatusAndCommentsResponse } from "../../../services/utils";
import _ from "underscore";

export default class CallStatusTable extends React.Component {

    constructor(props) {

       // This is called before render function
       //  The object that is returned is assigned to  this.state
       super(props);
       this.state = {callStatusAndCommentsData : []};
    }

    render() {
      var innersrchFlg=this.props.innersrchFlg;
      var innerSrchTxt=this.props.innerSrchTxt.toLowerCase();
      var callStatusOrComments=null;
      callStatusOrComments=filterCallStatusAndCommentsResponse(this.props.data,this.props.filterOption,this.props.traveller,this.props.RecordLoc);
      if(innersrchFlg && innerSrchTxt!=""){
        var filteredArray=[];
        for(var i=0;i<callStatusOrComments.length;i++){
          if(callStatusOrComments[i].RecordLocator.toLowerCase()==innerSrchTxt || callStatusOrComments[i].datetime.substring(0,7).toLowerCase()==innerSrchTxt){
            filteredArray.push(callStatusOrComments[i]);
          }
        }
callStatusOrComments=filteredArray;
      }
        return(<div className="inline-block width100per">
                  {this.props.loader ? <div className="loading_image trip_loader">&nbsp;</div>:null}
                  <CallStatusAndCommentsRows historyFlag={this.props.historyFlag} placeHolder={this.props.placeHolder} againCallFlg={this.props.againCallFlg} SegmentId={this.props.SegmentId} SaveSucess={this.props.SaveSucess} DeleteCallStatus={this.props.DeleteCallStatus} UpdateTable={this.props.UpdateTable} UpdateCommentCount={this.props.UpdateCommentCount} loader={this.state.loader} travelerId={this.props.travelerId} filterOption={this.props.filterOption} callStatusOrComments ={callStatusOrComments} />
               </div>
        );
    }
}
