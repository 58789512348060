/**
  * @ Comment Box Component
  * @ File - CommentBox.js
  * Comment box Text area
  */
import React, { Component } from 'react';

const FORMCLASS                   = 'form-control';
const FORM_ERROR_CLASS            = 'form-control error-icon';
const FORM_SUCESS_CLASS           = 'form-control success-icon';
const COMMENTBOX_ERROR_MESSAGE    = 'Please enter at least 3 characters and no more than 120 characters';

class CommentBox extends Component {

    // Constructor called before render function
    // The object that is returned is assigned to  this.state
    constructor(props) {
        super(props);
        this.state = {
            comment           : '',
            errorMessage      : '',
            formclass         : FORMCLASS,
        }
    }
    _onValidateCommentBox() {
        if(this.state.comment !==''){
            if(this.state.comment.length <3 ||this.state.comment.length >120){
                this.setState({
                    errorMessage:COMMENTBOX_ERROR_MESSAGE,
                    formclass:FORM_ERROR_CLASS
                });
                this.props.setFalse('comment')
            }else{
                this.setState({
                    errorMessage:'',
                    formclass:FORM_SUCESS_CLASS
                });
                this.props.setValue('comment', this.state.comment)
            }
        }else{
            this.props.setValue('comment', this.state.comment)
            this.setState({
                errorMessage:'',
                formclass:FORMCLASS
            });
        } 
    }
    // Render comment box Component
    render() {
        return (<div className="form-group">
            <label for="comment">Please briefly explain your business need:</label>
            <textarea className={this.state.formclass} rows="5" id="comment" value={this.state.comment} onBlur={this._onValidateCommentBox.bind(this)} onChange={(e) => this.setState({ comment: e.target.value })} />
            <div>
            { this.state.errorMessage != '' ? <p className='error'>{this.state.errorMessage}</p> : null }
            </div>
        </div>);
    }
}
export default CommentBox;
