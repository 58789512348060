import React from "react";
import HTTP from '../../../../services/httpservice';

export default class CancelHotel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirm: false,
            loading: false,
            fault: null,
            faultMessageFirst:null,
            faultMessageSecond:null
        };
    }
    callHotelCancelReservation() {
        /*let mode = EnvironmentDeployment == 'E3'
            ? 'real'
            : 'fake';*/
        this.setState({loading: true});
        let self = this;
        let tripId = self.props.tripId;
        let userId=self.props.userId;
        let segToken = self.props.segToken
        let companyId = this.props.ClientName;
        let reservationId = this.props.SMPId;
        var requestBodyData = {
            reservationId: reservationId,
            clientId: companyId,
            userId:userId,
            scope:self.props.premGroup,
            source:self.props.source==='SMP'?self.props.primarySRC:self.props.source,
            recordLocator:self.props.source==='SMP'?self.props.primaryRC:self.props.recordLocator,
            segToken:segToken.replace(/\//g,"")
        }

        HTTP
            .delete('/hotel/cancel/v1',requestBodyData)
            .then(function (response) {
                if(response.success === "true") {
                    switch(response.status) {
                        case "cancelled":
                            if(response.cancellationNumber !== undefined && response.cancellationNumber !== "") {
                                //With Cancellation No
                                self.setState({loading: false, confirm: true, 
                                    fault: 'Success', faultMessageFirst: 'Hotel has been cancelled'}); 
                                /*TODO:
                                    move this hotel segments to cancel tab
                                    Display cancellation number on cancel tab 
                                */
                                self.props.greyOutActionLink(); 
                            } else {
                                //without cancellation No
                                self.setState({loading: false, confirm: true, 
                                    fault: 'Success', faultMessageFirst: 'Hotel has been cancelled',
                                    faultMessageSecond: 'It may take a few minutes for SMP to send the cancel message to viewer'});
                                self.props.greyOutActionLink();
                            }
                        break;
                        case "alreadyCancelled":
                        if(response.cancellationNumber !== undefined && response.cancellationNumber !== "") {
                            self.setState({loading: false, confirm: true, 
                                fault: 'Success', faultMessageFirst: 'Your hotel has already been cancelled.'
                            });
                            self.props.greyOutActionLink();
                        } else {
                            self.setState({loading: false, confirm: true, 
                                fault: 'Success', faultMessageFirst: 'Your hotel has already been cancelled.',
                                faultMessageSecond: 'It may take a few minutes for SMP to send the cancel message to viewer'
                            });
                            self.props.greyOutActionLink();
                        }
                        break;
                        case "PENDING":
                                self.setState({loading: false, confirm: true, 
                                    fault: null, faultMessageFirst: null,
                                    faultMessageSecond: null
                                });
                                self.props.greyOutActionLink();
                                //polling
                                //still pending : Unable to Cancel. Please call the hotel directly to cancel reservation
                                // Greyout Ation link
                                // Your hotel cancellation for XXXXXX has been confirmed.
                                    //XXXXXX - PNR hyperlink - opens cancel tab where the hotel is displayed
                        break;
                    }
                }  else if(response.success === "false") {
                    self.setState({loading: false, confirm: true, 
                        fault: 'Success', faultMessageFirst:response.reason});
                    self.props.greyOutActionLink();
                } 

                // if (response.status === "done" && response.warnings.length !== 0 && response.warnings[0].code === "alreadyCancelled") {
                //     self.setState({loading: false, confirm: true, fault: 'Success', faultMessageFirst: 'Your hotel has already been cancelled.', faultMessageSecond: 'It may take a few minutes for SMP to send the cancel message to viewer'});
                // } else if (response.status === "done" && response.cancellationNumber !== undefined && response.warnings.length === 0) {
                //     self.setState({loading: false, confirm: true, fault: 'Success', faultMessageFirst: 'Hotel has been cancelled'});
                // } else if (response.status === "done" && response.errors !== undefined && response.errors.length !== 0) {
                //     self.setState({loading: false, confirm: true, fault: 'Error', faultMessageFirst: 'Unable to cancel.Please call the hotel directly to cancel reservation.'});
                //     //Grey out the Action/Cancel Link
                // } else if (response.status === "pending") {
                //     // polling for 2 mins until get status is done After polling status==="done" &&
                //     // cancellation number => cancelled successfully After polling status==="done"
                //     // && cancellation number blank && cancellationOperationWarning &&
                //     // cancellationOperationError Hotel has not cancelled after 2 mins still status
                //     // === 'pending' =>Unable to cancel.Please call the hotel directly to cancel
                //     // reservation.
                // }

                // // else if (response.errors != undefined && response.errors.length !== 0) { let
                // // message = "";     if (response.errors[0].code != undefined) message =
                // // response.errors[0].code + ":" + response.errors[0].message; else message =
                // // response.errors[0].message; self.setState({loading: false, confirm: true,
                // // fault: message}); } else { self.setState({loading: false, confirm: true,
                // // fault: null});     let userId = self.props.userId;     let confirmationNumber
                // // = "";     confirmationNumber = response.cancellationNumber;     let
                // // operationId = "";     operationId = response.operationId;     let request = {
                // //         "confirmationNumber": confirmationNumber,         "operationId":
                // // operationId     }     let headers = {         "accept": "application/json",
                // //       "content-type": "application/json",         "userId": userId     }
                // // HTTP .postJSONHeader('/trip/' + tripId + '/segment/' + segToken +
                // // '/cancel/v1', headers, request)         .then(function (response) {
                // //   if (response.success) {                 console.log("Success");
                // // }   }) }

            })
            .catch(function (errResponse) {
                let response = errResponse.responseJSON;
                if (errResponse.status === 400) {
                    let message = response.fault.detail != null
                        ? response.fault.detail.errorcode + ":"
                        : "";
                    self.setState({
                        loading: false,
                        confirm: true,
                        fault: message + response.fault.faultstring
                    });
                } else if (errResponse.status === 500) {
                    let message = "Unable to process your request. Please try again later";
                    self.setState({loading: false, confirm: true, fault: message});
                }
            })
    }
    render() {
        return (
            <div className="modal fade" id="cancelAction" role="dialog" data-backdrop="static">
                <div className="modal-dialog">
                    <div className="modal-content confirmation">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Cancel Hotel Segment</h4>
                        </div>
                        <div className="modal-body">
                            {!this.state.confirm
                                ? <div className="col-sm-12">
                                        Are you sure you want to cancel the hotel reservation?
                                        <div className="button_holder">
                                            <button
                                                className="save_button enabled"
                                                onClick={() => this.callHotelCancelReservation()}>{this.state.loading
                                                    ? <div className="loading-small"></div>
                                                    : <span>Yes</span>}</button>
                                            <button className="button last" data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                : <div className="col-sm-12">
                                    {this.state.fault === null
                                        ? <div>
                                                <div>Your hotel cancelation is in progress</div>
                                                <br/>
                                                <div>Once the hotel cancelation is completed you will see the hotel on Cancelled Tab</div>
                                                <div className="button_holder">
                                                    <button
                                                        className="save_button enabled"
                                                        onClick={() => this.setState({confirm: false})}
                                                        data-dismiss="modal">OK</button>
                                                </div>
                                            </div>
                                        : <div>
                                            <div>{this.state.faultMessageFirst}</div>
                                            <div>{this.state.faultMessageSecond}</div>
                                            <div className="button_holder">
                                                <button
                                                    className="save_button enabled"
                                                    onClick={() => this.setState({confirm: false, fault: null})}
                                                    data-dismiss="modal">OK</button>
                                            </div>
                                        </div>
}

                                </div>
}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
