/**
  * @ Name Component
  * @ File - name.js
  * First Name & Last Name Validation
  */
import React, { Component } from 'react';
const alphaRegex = /^[a-zA-Z]+$/i;

/* Define constants */
const FIRSTNAME_MIN_CHARACTER_ERROR = "First Name should have min 2 characters";
const FIRSTNAME_MAX_CHARACTER_ERROR = "First Name can have max 50 characters";
const LASTNAME_MIN_CHARACTER_ERROR  = "Last Name should have min 2 characters";
const LAST_NAME_MAX_CHARACTER_ERROR = "Last Name can have max 50 characters";
const FORMCLASS                     = 'form-control';
const FORM_ERROR_CLASS              = 'form-control error-icon';
const FORM_SUCCESS_CLASS            = 'form-control success-icon';
const ALPHABETS_ERROR               = "Only Alphabets are allowed";
class Name extends Component {

    // Constructor called before render function
    // The object that is returned is assigned to  this.state
    constructor(props) {
        super(props);
        this.state = {
          fname           : '',
          lname           : '',
          fnameError      : '',
          lnameError      : '',
          fnameFormClass  : FORMCLASS,
          lnameFormClass  : FORMCLASS
        }
    }

    // Validate First name field.
    // Check Valid First Name (Min & Max characters)
    _validateFirstName() {
        let self = this;
        if(this.state.fname.length < 2){
            this.setState({
              fnameError      : FIRSTNAME_MIN_CHARACTER_ERROR,
              fnameFormClass  : FORM_ERROR_CLASS
            })
        }
        else if (this.state.fname.length > 50) {
            this.setState({
              fnameError      : FIRSTNAME_MAX_CHARACTER_ERROR,
              fnameFormClass  : FORM_ERROR_CLASS
            })
        }
        else if(!alphaRegex.test(this.state.fname)){
          this.setState({
            fnameError: ALPHABETS_ERROR,
            fnameFormClass: FORM_ERROR_CLASS
          });
        }
        else {
            self.setState({
              fnameError      : '',
              fnameFormClass  : FORM_SUCCESS_CLASS
            });
            self.props.setFirstname(self.state.fname);
        }
    }

    // Validate Last name field.
    // Check Valid Last Name (Min & Max characters)
    _validateLastName() {
        var self = this;
        if(this.state.lname.length < 2){
            this.setState({
              lnameError      : LASTNAME_MIN_CHARACTER_ERROR,
              lnameFormClass  : FORM_ERROR_CLASS
            })
        }
        else if (this.state.lname.length > 50) {
            this.setState({
              lnameError      : LAST_NAME_MAX_CHARACTER_ERROR,
              lnameFormClass  : FORM_ERROR_CLASS
            })
        }
        else if(!alphaRegex.test(this.state.lname)){
            this.setState({
              lnameError      : ALPHABETS_ERROR,
              lnameFormClass  : FORM_ERROR_CLASS
            });
          }
        else {
            self.setState({
              lnameError      : '',
              lnameFormClass  : FORM_SUCCESS_CLASS
            });
            self.props.setLastname(self.state.lname);
        }
    }

    // Set first name value to text box
    _setFirstname(e) {
        this.setState({ fname   : e.target.value });
        if (this.props.fnameValid) {
          this.props.setFirstNameValidFalse();
        }
    }

    // Set last name value to text box
    _setLastname(e) {
        this.setState({ lname : e.target.value });
        if (this.props.lnameValid) {
          this.props.setLastNameValidFalse();
        }
    }

    // Render Name Component
    render() {
        return (<div>
                    <div className="form-group col-xs-6 inline-padding-left">
                      <label for="firstName">First Name*:</label>
                      <input type="text" value={this.state.fname} className={this.state.fnameFormClass} onChange={this._setFirstname.bind(this)} onBlur={this._validateFirstName.bind(this)}/>
                      <span className='error'>{this.state.fnameError}</span>
                    </div>
                    <div className="form-group col-xs-6 inline-padding-right">
                      <label for="lastName">Last Name*:</label>
                      <input type="text" value={this.state.lname} className={this.state.lnameFormClass} onChange={this._setLastname.bind(this)} onBlur={this._validateLastName.bind(this)}/>
                      <span className='error'>{this.state.lnameError}</span>
                    </div>
                </div>);
    }
}
export default Name;
