import React from "react";
import Modal from "../CommonModels/Modal.jsx"
import MoreTravellersModal from "../CommonModels/moretravellersmodel"
import CancelHotel from "../CommonModels/CancelHotel"
import HTTP from '../../../../services/httpservice';
import SucessMessage from '../../commons/SucessMessage'


const FORM_ERROR_CLASS = 'form-control hre-offer-error-icon';
const FORM_SUCESS_CLASS = 'form-control hre-offer-success-icon';
const ERROR_MESSAGE_FOR_NUM_CHAR = 'Rate Code must be between 2 - 25 characters';
const ERROR_MESSAGE_FOR_RATE_CODE = 'Please enter a valid Rate Code';
const ERROR_MESSAGE_FOR_CURRENCY_STRING = 'Currency must be in alphabets';
const ERROR_MESSAGE_FOR_CURRENCY = 'Currency must be 3 characters';
const ERROR_MESSAGE_FOR_REQUIRED = 'Field is required';
const ERROR_MESSAGE_FOR_TOTAL_NET_COST_DECIMAL = 'Total Net Cost must be in decimal'
const ERROR_MESSAGE_FOR_TOTAL_GROSS_COST_DECIMAL = 'Total Gross Cost must be in decimal'

export default class HotelTripDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRateDescPopover: false,
      rateDescIndex: 0,
      classTripDetails: "trip-details",
      hotelReshopResponse: null,
      curentItemRejection: null,
      reasoncode: 0,
      selectedIndex:0,
      CommentText: "",
      isChecked:false,
      rateCodeFormclass:'form-control required rebookable-input',
      currencyFormclass:'form-control required rebookable-input',
      netCostFormclass:'form-control required rebookable-input',
      grossCostFormclass:'form-control required rebookable-input',
      rateCode:'',
      currency:'',
      totalNetCost:'',
      totalGrossCost:'',
      rateCodeValid:false,
      currencyValid:false,
      totalNetCostValid:false,
      totalGrossCostValid:false,
      errorMessage: '',
      currencyErrorMessage:'',
      totalNetCostErrorMessage:'',
      totalGrossCostErrorMessage:'',
      greyOutActionLink:false,
      rejectReasons:null,
      rateAvilable:false
    };
  }

  _handleRejectClick(curentItemRejection) {
    window.$('.modal-offer-reason').modal('show');
    this.setState({curentItemRejection,rateAvilable:false})
  }
  handleDocsClick() {
    let openTab = this.props.openDocsTab;
    openTab(this.props.recordLocatorDocs);
  }
  componentWillMount() {
    let {showOffer, specificTD} = this.props;
    let self = this;
    HTTP
    .get('/api/v1/hre/getReasonCode')
    .then(function (result) {
        self.setState({rejectReasons:result.reasons});
    });

    if (showOffer) {
      HTTP
        .get(`/api/v1/hre/fetchOfferDetails/${specificTD.TripId}`)
        .then(function (result) {
          let hotelReshopResponse = result
            .segmentinfo
            .find(item => item.segtoken + item.confirmationnumber === specificTD.segmentid + specificTD.ConfirmationNumber);
          self.setState({hotelReshopResponse});
        });
    }
  }
  componentDidMount() {
    console.log(this.props.userName);
    window.addEventListener('mousedown', this._pageClick.bind(this), false);
  }
  _pageClick(e) {
    if (this.refs.sucessmsg != null) 
      this.refs.sucessmsg.disableSucessMessage();
    }
  _handleChange(event) {
    this.setState({CommentText: event.target.value});
  }
  handleAccept(offer) {
    const {specificTD,userId,userName} = this.props;
    var data = {
      "tripid": specificTD.TripId,
      "offerresponse": {
        "offerid": offer.offerid,
        "agentcode": userId,
        "agentname": userName,
        "status": "accept"
      }
    }
    this._handleApiActions(data,"accept");

  }
  handleReject() {
    let {hotelReshopResponse, curentItemRejection, reasoncode} = this.state;
    const {specificTD,userId,userName} = this.props;
    this.setState({CommentText: ""})
    var data = {
      "tripid": specificTD.TripId,
      "offerresponse": {
        "offerid": curentItemRejection.offerid,
        "agentcode": userId,
        "agentname": userName,
        "status": "reject",
        "reasoncode": "" + reasoncode,
        "reasondescription": this.state.CommentText
      }
    }
    this._handleApiActions(data,"reject");
  }
  showRateDescription(i,rateDescription) {
    if(rateDescription !== null & rateDescription.length >100) {
      this.setState({showRateDescPopover: true, rateDescIndex: i})
    } else {
      this.setState({showRateDescPopover: false, rateDescIndex: i})
    }
  }
  hideRateDescription(i) {
    this.setState({showRateDescPopover: false, rateDescIndex: i})
  }
  handleCheckbox(e) {
    this.setState({
      isChecked:e.target.checked,
      rateCode:'',
      currency:'',
      totalNetCost:'',
      totalGrossCost:'',
      rateCodeFormclass:'form-control',
      currencyFormclass:'form-control',
      netCostFormclass:'form-control',
      grossCostFormclass:'form-control',
      errorMessage: '',
      currencyErrorMessage:'',
      totalNetCostErrorMessage:'',
      totalGrossCostErrorMessage:'',
      confirmSave:false,
      submitErrors:false
    });
  }
  _handleApiActions(data,action) {
    var message = '';
    const {specificTD} = this.props;
    var self = this;
    switch(action) {
      case "accept":
      message = "The offer has been accepted successfully"
      break;
      case "reject":
      message = "The offer has been rejected successfully";
      break;
      case "other":
      window.$('#buttonLoader').removeClass('hide');
      window.$('#buttonText').addClass('hide');
      message ="Another offer has been accepted successfully";
      break;
    }
    HTTP
    .postJSON('/api/v1/hre/postFeedback', data)
    .then(function (result) {
      let hotelReshopResponse;
      if (result.responsestatus === "success") {
      window.$('.modal-offer-reason').modal('hide');
       self.setState({isChecked:false});
       HTTP
       .get(`/api/v1/hre/fetchOfferDetails/${specificTD.TripId}`)
       .then(function (result) {
        window.$('.modal-confirm').modal('hide');
        window.$('.modal-offer-reason').modal('hide');
        window.$('#buttonLoader').addClass('hide');
        window.$('#buttonText').removeClass('hide');
        self.refs.sucessmsg.showSucessMessage(message);
         hotelReshopResponse = result
           .segmentinfo
           .find(item => item.segtoken + item.confirmationnumber === specificTD.segmentid + specificTD.ConfirmationNumber);
         self.setState({hotelReshopResponse});
       });
      } else if (result.reason === "matching offer rate found") {
         self.setState({rateAvilable:true})
      } 
      else {
        window.$('.modal-confirm').modal('hide');
        window.$('.modal-offer-reason').modal('hide');
        window.$('#buttonLoader').addClass('hide');
        window.$('#buttonText').removeClass('hide');
        self
          .refs
          .sucessmsg
          .showSucessMessage(result.errordescription);
        self.setState({hotelReshopResponse});
        
      }
    })
  }
  handleConfirmBoxClick(confirmSave){
    const {specificTD} = this.props;
    if(confirmSave) {
        var data = {
          "tripid" : specificTD.TripId,
            "otherofferresponse" :
            {
              "ratecode": this.state.rateCode.trim(),
              "currencycode":this.state.currency.trim(),
              "totalnetcost":this.state.totalNetCost.trim(),
              "totalgrosscost":this.state.totalGrossCost.trim(),
              "segtoken":specificTD.segmentid,
              "confirmationnumber":specificTD.ConfirmationNumber
            }
        }
        this._handleApiActions(data,"other");   
    } else {
        window.$('.modal-confirm').modal('hide');
    }   
  }
  _handleSubmit(e) {
    e.preventDefault();
    window.$('#buttonLoader').addClass('hide');
    window.$('#buttonText').removeClass('hide');
    const{rateCodeValid,currencyValid,totalNetCostValid,totalGrossCostValid,confirmSave} = this.state;
    if(rateCodeValid && currencyValid && totalNetCostValid && totalGrossCostValid) {
      window.$('.modal-confirm').modal('show'); 
    } else {
       this.setState({submitErrors:true})
    }
  }
  _handleCancel(e) {
      e.preventDefault();
      this.setState({isChecked: false});
  }
  handleChangeRateCode(e) {
      this.setState({rateCode:e.target.value})
  }
  handleChangeCurrency(e) {
    this.setState({currency:e.target.value})
  }
  handleChangeTotalNetCost(e) {
    this.setState({totalNetCost:e.target.value})
  }
  handleChangeTotalGrossCost(e) {
    this.setState({totalGrossCost:e.target.value})
  }
_validateRateCode() {
    var re = /^[ A-Za-z0-9_,./-]*$/
    if (this.state.rateCode.trim() !== ''&& !re.test(this.state.rateCode.trim())) {
      this.setState({
          rateCodeFormclass: FORM_ERROR_CLASS,
          errorMessage: ERROR_MESSAGE_FOR_RATE_CODE,
          rateCodeValid:false
      })
    }
    else if (this.state.rateCode.trim() !== '' && (this.state.rateCode.length < 2 || this.state.rateCode.length > 25)) {
        this.setState({
            rateCodeFormclass: FORM_ERROR_CLASS,
            errorMessage: ERROR_MESSAGE_FOR_NUM_CHAR,
            rateCodeValid:false
        })
    } else if (this.state.rateCode.trim() === '') {
      this.setState({
          rateCodeFormclass: FORM_ERROR_CLASS,
          errorMessage: ERROR_MESSAGE_FOR_REQUIRED,
          rateCodeValid:false
      })
  }
    
    else {
        this.setState({
          rateCodeFormclass: FORM_SUCESS_CLASS,
            errorMessage: '',
            rateCodeValid:true
        })
    }  
    if(this.state.rateCodeValid && this.state.currencyValid && this.state.totalNetCostValid && this.state.totalGrossCostValid) {
        this.setState({submitErrors:false})
    }  
  }
  _validateCurrency() {
    var re = /^[A-Za-z]+$/;
      if(this.state.currency.trim() !== '' && !re.test(this.state.currency.trim())) {
          this.setState({
            currencyFormclass: FORM_ERROR_CLASS,
            currencyErrorMessage: ERROR_MESSAGE_FOR_CURRENCY_STRING,
            currencyValid:false
        })
      }
      else if (this.state.currency.trim() !== '' && this.state.currency.length !== 3) {
          this.setState({
              currencyFormclass: FORM_ERROR_CLASS,
              currencyErrorMessage: ERROR_MESSAGE_FOR_CURRENCY,
              currencyValid:false
          })
      } 
      else if (this.state.currency.trim() === '') {
        this.setState({
            currencyFormclass: FORM_ERROR_CLASS,
            currencyErrorMessage: ERROR_MESSAGE_FOR_REQUIRED,
            currencyValid:false
        })
    } 
    
      else {
          this.setState({
            currencyFormclass: FORM_SUCESS_CLASS,
            currencyErrorMessage: '',
            currencyValid:true
          })
      }
      if(this.state.rateCodeValid && this.state.currencyValid && this.state.totalNetCostValid && this.state.totalGrossCostValid) {
        this.setState({submitErrors:false})
    }
  }

  _validateTotalNetCost() {
    var re = /^[1-9]\d*(\.\d+)?$/
    if(this.state.totalNetCost.trim() !== '' && !re.test(this.state.totalNetCost.trim())) {
      this.setState({
        netCostFormclass: FORM_ERROR_CLASS,
        totalNetCostErrorMessage: ERROR_MESSAGE_FOR_TOTAL_NET_COST_DECIMAL,
        totalNetCostValid:false
      }); 
    } 
    
    else if(this.state.totalNetCost.trim() === '') {
      this.setState({
        netCostFormclass: FORM_ERROR_CLASS,
        totalNetCostErrorMessage: ERROR_MESSAGE_FOR_REQUIRED,
        totalNetCostValid:false
      }); 
    }
    else {
        this.setState({
          netCostFormclass: FORM_SUCESS_CLASS,
            totalNetCostErrorMessage: '',
            totalNetCostValid:true
        })
    }
    if(this.state.rateCodeValid && this.state.currencyValid && this.state.totalNetCostValid && this.state.totalGrossCostValid) {
        this.setState({submitErrors:false})
    }
  }

  _validateTotalGrossCost() {
    var re = /^[1-9]\d*(\.\d+)?$/
    if(this.state.totalGrossCost.trim() !== '' && !re.test(this.state.totalGrossCost.trim())) {
          this.setState({
            grossCostFormclass: FORM_ERROR_CLASS,
            totalGrossCostErrorMessage: ERROR_MESSAGE_FOR_TOTAL_GROSS_COST_DECIMAL,
            totalGrossCostValid:false
          })
      } 
      else if(this.state.totalGrossCost.trim() === '') {
        this.setState({
          grossCostFormclass: FORM_ERROR_CLASS,
          totalGrossCostErrorMessage: ERROR_MESSAGE_FOR_REQUIRED,
          totalGrossCostValid:false
        })
    }  
    else {
          this.setState({
            grossCostFormclass: FORM_SUCESS_CLASS,
              totalGrossCostErrorMessage: '',
              totalGrossCostValid:true
          })
      }
      if(this.state.rateCodeValid && this.state.currencyValid && this.state.totalNetCostValid && this.state.totalGrossCostValid) {
        this.setState({submitErrors:false})
      } 
  }

  _chkEnterbtn(event) {
    if(this.state.rateCodeValid && this.state.currencyValid && this.state.totalNetCostValid && this.state.totalGrossCostValid) {
      if(event.key=='Enter' || event.keyCode==13){
        this._handleSubmit();
      }
    }
  }
  greyOutActionLink() {
    this.setState({greyOutActionLink:true});
  }

  onChangeReason(reasonCode,index,e){
    e.preventDefault();
    e.stopPropagation();
    this.setState({reasoncode: reasonCode,selectedIndex:index})
  }

  render() {
    var actionlink = false;
    var HotelConfirmationId = [];
    var message = "";
    var messageClass = "col-sm-5 reject_char_count";
    var rejectReasonsList = null;
    let rejectReasonArray = [];
    var self = this;
    if(this.state.rejectReasons !== null)  {
        rejectReasonsList = this.state.rejectReasons.map(function(item,index){
              rejectReasonArray.push(item.reasondesc);
              return <li
              role="presentation"
              value={item.reasoncode}
              onClick={self.onChangeReason.bind(self,item.reasoncode,index)}>
              <a role="menuitem" tabIndex="-1" href="#">{item.reasondesc}</a>
            </li>
        })
    }

    const maxCommentText_length = 100;
    if (this.state.CommentText.length == 0) {
      message = "100 characters remaining";
    } else {
      if (maxCommentText_length - this.state.CommentText.length > 1 || maxCommentText_length - this.state.CommentText.length < -1) {
        message = `${maxCommentText_length - this.state.CommentText.length} ` + this.props.placeHolder.CHARACTERS_COUNT;
      } else {
        message = `${maxCommentText_length - this.state.CommentText.length} ` + this.props.placeHolder.CHARACTERS_COUNT;
      }
    }
    if (this.state.CommentText.length > maxCommentText_length) {
      messageClass = messageClass + " error";
    }
    var SMPId = "";
    HotelConfirmationId = this.props.specificTD.HotelConfirmationId;
    for (var i = 0; i < HotelConfirmationId.length; i++) {
      
     try{
      const smpHotelPin = this.props.specificTD.ConfirmationNumber+'P'+this.props.specificTD.PinCode;
      if(this.props.specificTD.ConfirmationNumber.includes(HotelConfirmationId[i]) ||
        smpHotelPin.includes(HotelConfirmationId[i])){
        SMPId = this.props.specificTD.SMPId[i];
        actionlink = true;
        break;
      }
     }catch(err){
       console.log(err)
     }

      /*if (HotelConfirmationId[i] == this.props.specificTD.ConfirmationNumber) {
        SMPId = this.props.specificTD.SMPId[0];
        actionlink = true;
        break;
      }*/
    }
    var miinfostate = false;
    var disableclassmoreinformation;
    var disableclassMoreTravellers;
    var moreinformation = true;

    var offer = null;
    if (this.state.hotelReshopResponse) {
      offer = this
        .state
        .hotelReshopResponse
        .offerinfo
        .map((item, i) =><div><div className="hotel-offers-block">
          <div className="inner-line"></div>
          <div className="trip row">
            <span className="offer-icon">&nbsp;{item.offerratetype + " Offer"}</span>
            {item.hasOwnProperty('offerStatusText') ?
            <div className="hre-offer-status-text">{item.hasOwnProperty('offerStatusText') ? item.offerStatusText:null}</div>
            :
            <span>
            <button
              className="btn noti-seg-close-button float-right-css reject-button"
              onClick={this
              ._handleRejectClick
              .bind(this, item)}>Reject</button>
            <button
              className="btn noti-seg-accept-button float-right-css"
              onClick=
              {()=>this.handleAccept(item)}>Accept</button></span>}
            <div className="trip row info-block offer-text-color">
              <div className="col-md-4">Commissionable : {item.commissionable === "true"
                  ? "Yes"
                  : "No"}
              </div>
              <div className="col-md-4">Rate : {item.offertotalprice !== null && parseFloat(Math.round(item.offertotalprice* 100) / 100).toFixed(2) + " " + item.offercurrency}
              </div>
              <div className="col-md-4">Room Type :{item.offerroomtype}
              </div>
            </div>
            <div className="trip row offer-text-color">
              <div className="col-md-4">Bed Type : {item.offerbedtype}
              </div>
              <div className="col-md-4">Cancellation Policy : {item.offercancelpolicy}
              </div>
            </div>
          </div>
          {/* Display Amenities */}
          <div className="trip row info-block offer-text-color">
            <div className="col-md-12">
              Amenities :
            </div>
            <br/>
            <div className="col-md-12">
              <span>
                {item.offeramenities}</span>
            </div>
          </div>
          {/* Display Rate Description */}
          <div className="trip row info-block offer-text-color">
            <div className="col-md-12">
              Rate Description :
            </div>
            <br/>
            <div className="col-md-12">
              <span
                onMouseOver={this
                .showRateDescription
                .bind(this, i,item.rateDescription)}
                onMouseOut={this
                .hideRateDescription
                .bind(this, i)}>
                {item.rateDescription !== null
                  ? addElipses(item.rateDescription, 100)
                  : null}</span>
            </div>
          </div>
          {this.state.showRateDescPopover && this.state.rateDescIndex === i
            ? <div>
                <div className='fade in popover bottom pop_over_name email_popup hre-rate-desc'>
                  <div className='arrow'></div>
                  <div className='popover-content'>
                    <div className='popover_custom'>
                      {item.rateDescription}
                    </div>
                  </div>
                </div>
              </div>
            : null}
            {item.offerStatusText === "Rejected" ?
            <div>
              <div className="trip row info-block offer-text-color">
                <div className="col-md-12">
                  Reason : {item.rejectionratedescription}{item.hasOwnProperty('rejectionfeedbackcomment') && ' - '+ item.rejectionfeedbackcomment}
                </div>
            </div>
          </div>:null}
        </div></div>)
    }

    if (this.props.specificTD.MoreTravelers.length > 1) {
      disableclassMoreTravellers = "";
      moreinformation = true;
    } else {
      disableclassMoreTravellers = "disable";
      moreinformation = false;
    }

    if (this.props.specificTD.MIInformation.ShowColumnAs != null || this.props.specificTD.MIInformation.Text != null) {
      disableclassmoreinformation = "";
      miinfostate = true //else if(Array.isArray(this.props.specificTD.MIInformation.MultiMIInfo) && this.props.specificTD.MIInformation.MultiMIInfo[0].ShowColumnAs != null || Array.isArray(this.props.specificTD.MIInformation.MultiMIInfo) && this.props.specificTD.MIInformation.MultiMIInfo[0].Text != null){
      //Checks available MI Information;
    } else if (Array.isArray(this.props.MiInfoList) && this.props.MiInfoList.length > 0) {
      disableclassmoreinformation = "";
      miinfostate = true;
    } else {
      disableclassmoreinformation = "disable";
      miinfostate = false;
    }

    return (
      <div className="hotel_section">
        <div className="notification-msg-hotelreshop">
          <SucessMessage ref="sucessmsg"/>
        </div>

        <div className={this.state.classTripDetails}>
          <div className="side-icon">
            <div className="inner-line"></div>
          </div>
          <div className="link-holder">
            <div className="btns">
              <div className="col-sm-3 col-xs-3">
                <a
                  href="#"
                  className={disableclassmoreinformation}
                  data-toggle="modal"
                  data-target="#myModal">{this.props.placeHolder.MI_INFORMATION}</a>
                {miinfostate
                  ? <Modal
                      placeHolder={this.props.placeHolder}
                      CustomFieldValue={this.props.specificTD.MIInformation}/>
                  : null}
              </div>
              <div className="col-sm-3 col-xs-3">
                <a
                  href="#"
                  data-toggle="modal"
                  className={disableclassMoreTravellers}
                  data-target="#myMoreTravellersModal">{this.props.placeHolder.MORE_TRAVELERS}</a>
                {moreinformation
                  ? <MoreTravellersModal
                      placeHolder={this.props.placeHolder}
                      CustomFieldValue={this.props.specificTD.MoreTravelers}/>
                  : null}
              </div>
              <div className="col-sm-3 col-xs-3 padding_left">
                <a
                  href="#"
                  onClick={this
                  .handleDocsClick
                  .bind(this)}>Docs</a>
              </div>
              <div className="col-sm-3 col-xs-3">
              {this.props.specificTD.Type === "Hotel-CheckIn" && 
                <div className="dropdown-hotel-action">
                  {actionlink && this.state.greyOutActionLink === false
                    ? <a className="dropbtn-hotel-action">Action</a>
                    : <div className="disableaction">Action</div>
}
                  <div className="dropdown-content-hotel-action">
                    {actionlink && this.state.greyOutActionLink === false
                      ? <div className="fade in popover bottom pop_over_name email_popup">
                          <div className="arrow"></div>
                          <div className="popover-content">
                            <div className="popover_custom">
                              <a href="#" data-toggle="modal" data-target="#cancelAction">Cancel</a>
                            </div>
                          </div>
                        </div>
                      : null}
                  </div>
                </div>}
                <CancelHotel
                  ClientName={this.props.ClientName}
                  SMPId={SMPId}
                  recordLocator={this.props.specificTD.BookingInfo.RecordLocator}
                  primaryRC={this.props.specificTD.BookingInfo.primaryRC}
                  primarySRC={this.props.specificTD.BookingInfo.primarySRC}
                  source={this.props.specificTD.BookingInfo.Source}
                  userId={this.props.userId}
                  tripId={this.props.specificTD.TripId}
                  segToken={this.props.specificTD.segmentid}
                  premGroup={this.props.premGroup}
                  greyOutActionLink={this.greyOutActionLink.bind(this)}
                  />
              </div>
            </div>
            <div className="add_info">
              <p>{this.props.placeHolder.HOTEL_MEMBERSHIP}
                : {this.props.specificTD.MembershipNumber}</p>
              <p>
                {this.props.placeHolder.CORP_ID}: {this.props.specificTD.CoporateID}</p>
            </div>
          </div>
          <div className="flight-time hotel_details">
            <div className="col-sm-7">
              <p>{this.props.placeHolder.RATE}:</p>
              <p>{this.props.specificTD.RateBeforeTax} {this.props.specificTD.RateCurrency}</p>
              <p>&nbsp;</p>
              <p>
                <span className="col-sm-6">{this.props.placeHolder.RATE_TYPE}</span>
                :
              </p>
              <p>
                <span className="col-sm-6">{this.props.placeHolder.REASON_CODE}</span>
                : {this.props.specificTD.ReasonCode}
              </p>
            </div>
            <div className="rgt_section">
              <p>&nbsp;</p>
              <p className="hotel_des">{this.props.specificTD.RoomUnitAndOccupants} {this.props.specificTD.RoomDescription}
                {this.props.specificTD.RoomTypeCode}{this.props.specificTD.RoomBkgCode}</p>
              <p className="cancel">
                <span className="col-sm-4">{this.props.placeHolder.CANCEL_BY}</span>
                : {this.props.specificTD.CancelPolicyDescription}</p>
              <p>
                <span className="col-sm-5">{this.props.placeHolder.GUARANTEED_TO}
                </span>
                : {this.props.specificTD.GuaranteeCardCode}
                {this.props.specificTD.GuaranteeCardNumber}</p>
              <p>
                <span className="col-sm-5">{this.props.placeHolder.SPECIAL_REQ}</span>
                : {this.props.specificTD.SIText}
              </p>
            </div>
          </div>
          {offer
            ? <div className="flight-time hotel-offer-details hotel-offer-details--border">
                <div>
                  <hr/>
                  {this.props.ActiveFilter === "Future" &&
                  <div className="form-group mycheckboxdiv">
                      <label>
                          Other rebookable rate found
                          <input type="checkbox" checked={this.state.isChecked} onClick={this.handleCheckbox.bind(this)}  className="pull-right mycheckbox" />
                      </label>
                  </div>}
                  {this.state.isChecked ?
                  <div className="rebookable-form-block">
                    {this.state.submitErrors ? <div className="offer-submit-error-block"><p className="submit-errors">All fields are mandatory</p></div> : null}
                    <form className="form-horizontal">
                      <div className="row rebook-row">
                      <div className="col-md-6">
                        <div className="form-group">
                            <label for="rateCode" className="control-label col-sm-3 label-text">Rate Code</label>
                            <div className="col-sm-7">
                            <input type="text" name="rateCode" 
                            onKeyUp={this._chkEnterbtn.bind(this)}
                            autoComplete="off"
                            onChange={this.handleChangeRateCode.bind(this)}
                            onBlur={this._validateRateCode.bind(this)} 
                            value={this.state.rateCode} className={this.state.rateCodeFormclass} />
                            {this.state.errorMessage != '' ? <p className='error_absolute'>{this.state.errorMessage}</p> : null}
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                            <label for="currency" className="control-label col-sm-3 label-text">Currency</label>
                            <div className="col-sm-7">
                            <input type="text" name="currency"
                            autoComplete="off"
                            onKeyUp={this._chkEnterbtn.bind(this)}
                            onChange={this.handleChangeCurrency.bind(this)}
                            onBlur={this._validateCurrency.bind(this)}
                            value={this.state.currency} className={this.state.currencyFormclass}
                            />
                            {this.state.currencyErrorMessage != '' ? <p className='error_absolute'>{this.state.currencyErrorMessage}</p> : null}
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row rebook-row">
                      <div className="col-md-6">
                        <div className="form-group">
                            <label for="totalNetCost" className="control-label col-sm-3 label-text">Total Net Cost</label>
                            <div className="col-sm-7">
                            <input type="text" name="totalNetCost"
                            autoComplete="off"
                            onKeyUp={this._chkEnterbtn.bind(this)}
                            onChange={this.handleChangeTotalNetCost.bind(this)}
                            onBlur={this._validateTotalNetCost.bind(this)}
                            value={this.state.totalNetCost} className={this.state.netCostFormclass}
                            />
                            {this.state.totalNetCostErrorMessage != '' ? <p className='error_absolute'>{this.state.totalNetCostErrorMessage}</p> : null}
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                            <label for="totalGrossCost" className="control-label col-sm-3 label-text">Total Gross Cost</label>
                            <div className="col-sm-7">
                            <input type="text" name="totalGrossCost" 
                            autoComplete="off"
                            onKeyUp={this._chkEnterbtn.bind(this)}
                            onChange={this.handleChangeTotalGrossCost.bind(this)}
                            onBlur={this._validateTotalGrossCost.bind(this)}
                            value={this.state.totalGrossCost} className={this.state.grossCostFormclass}
                            />
                            {this.state.totalGrossCostErrorMessage != '' ? <p className='error_absolute'>{this.state.totalGrossCostErrorMessage}</p> : null}
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                      </div>
                    </div>
                  <div>
                  
                  <button className="btn noti-seg-close-button float-right-css cancel-button-other-offer"
                      onClick={this
                        ._handleCancel
                        .bind(this)}> Cancel</button>
                        <button onClick={this
              ._handleSubmit
              .bind(this)} className="btn noti-seg-accept-button float-right-css"> Save</button>
                     
                      </div>
                      </form>
                  </div>:null}
                   {offer}
                </div>
              </div>
            : null}

        </div>
        <br/>
        {/* Confirm popup */}
        <div className="modal fade modal-confirm" id="modalConfirm" role="dialog" data-backdrop="static">
            <div className="modal-dialog">
                <div className="modal-content confirmation">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Save Other Re-bookable Rate</h4>
                    </div>
                    <div className="modal-body">
                            <div className="col-sm-12">
                                Are you sure you want to save other Re-bookable rate?
                                <div className="button_holder">
                                    <button className="save_button enabled" 
                                    onClick={this.handleConfirmBoxClick.bind(this,true)}>
                                     <i id="buttonLoader" className="fa fa-spinner hide fa-spin"></i> <span id="buttonText">Yes</span></button>
                                    <button className="button last" data-dismiss="modal" onClick={this.handleConfirmBoxClick.bind(this,false)} >No</button>
                                </div>
                            </div>
    
                    </div>
                </div>  
            </div>
        </div>

        <div className="clearfix"></div>
        <div
          className="modal fade modal-offer-reason"
          role="dialog"
          data-backdrop="static"
          data-keyboard="false">
          <div className="modal-dialog">
            <div className="modal-content comment_popup">
              <div className="modal-header">
                <div className="col-sm-12">
                  <h4 className="modal-title">Reject Offer
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                  </h4>

                </div>
              </div>
              <div className="modal-body">
                <div className="col-sm-12"><br/><br/>
                  <div className="col-sm-7 margin_btm_5">
                    Reason:
                  </div>
                  <br/>
                  <div className="dropdown custom-dropdown-div">
                    <button
                      className="btn btn-default dropdown-toggle custom-dropdown"
                      value="Please select a reason"
                      type="button"
                      id="menu1"
                      data-toggle="dropdown">
                     {rejectReasonArray[this.state.selectedIndex]}
                      <span className="caret"></span>
                    </button>

                    <ul className="dropdown-menu reject-dropdown" role="menu" aria-labelledby="menu1">
                      {rejectReasonsList}
                    </ul>
                  </div>
                  <div className={messageClass}>{message}</div>
                  <br/>
                  <textarea
                    value={this.state.CommentText}
                    onChange={this
                    ._handleChange
                    .bind(this)}
                    className="textarea"></textarea>
                    {this.state.rateAvilable && <div className="error_absolute">Rate you are rejecting is still available in the GDS</div>}
                  <br/>
                  <br/>
                  <div className="col-sm-6 inline-block pull-right button-block">
                    {messageClass.indexOf("error") == -1 && this.state.reasoncode != 0 && this.state.rateAvilable === false
                      ? <button
                          className="save_button enabled"
                          onClick={this
                          .handleReject
                          .bind(this)}>Submit</button>
                      : this.state.rateAvilable ? <button disabled className="save_button">Submit</button> :<button className="save_button">Submit</button>
                      }
                    <button className="button last" data-dismiss="modal">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function addElipses(text, n) {
  return (text.length > n)
    ? text.substr(0, n - 1) + '...'
    : text;
}
