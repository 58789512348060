import React from "react";
import Segments from "../TripsItinerary/Segments.js";
import HTTP from '../../../../services/httpservice';
import Secondaryfilter from "../secondaryfilter";
import _ from "underscore";
import NotificationSegment from "../TripComponents/notificationSegment";
import {otaSummaryException} from '../../../../services/utils';
import createClass from 'create-react-class';
var CancelledSegmentClass= createClass({
  getInitialState: function () {
    return {
          displayNotification: false,
          openDetailsKey:"",
          cancelledTripDetails:null,
          TripSummaryJson:null,
          segmentCommentsJson : null,
          CarClass:"car",
          airClass:"airplane",
          railClass:"others",
          hotelClass:"hotel",
          limoClass:"others",
          otherClass:"others",
          airstate:true,
          carstate:true,
          hotelstate:true,
          railstate:true,
          limostate:true,
          otherstate:true,
          iconstate:{
          Air:true,
          Car:true,
          Hotel:true,
          Other:true
      }
    };
  },
  resetToDefaultfn:function(){

    this.setState({
      CarClass:"car",
      airClass:"airplane",
      railClass:"others",
      hotelClass:"hotel",
      limoClass:"limo",
      otherClass:"others",
      airstate:true,
      carstate:true,
      hotelstate:true,
      railstate:true,
      limostate:true,
      otherstate:true
    });
  },


  componentWillReceiveProps: function() {
    if(this.props.resetIconFilters){
  this.setState({
    CarClass:"car",
    airClass:"airplane",
    railClass:"others",
    hotelClass:"hotel",
    limoClass:"limo",
    otherClass:"others",
    airstate:true,
    carstate:true,
    hotelstate:true,
    railstate:true,
    limostate:true,
    otherstate:true
  });
}
},


 clickOnTripMode:function(iconstate,tripMode){
    var tempIcon=["Air","Car","Hotel","Other"];
    var trueIcons=0;
    for(var iterator=0;iterator<tempIcon.length;iterator++){
      if(this.state.iconstate[tempIcon[iterator]]){
        trueIcons++;
      }
    }
    if(trueIcons==tempIcon.length){
      var iconstatetemp={Air:false,Car:false,Hotel:false,Other:false}
      iconstatetemp[tripMode]=true;
      this.setState({
        iconstate:iconstatetemp
      });
    }else{
      var iconstatetemp=this.state.iconstate;
      if(iconstatetemp[tripMode]){
        iconstatetemp[tripMode]=false;
        this.setState({
          iconstate:iconstatetemp
        });
      }else{
        iconstatetemp[tripMode]=true;
        this.setState({
          iconstate:iconstatetemp
        });
      }
    }

  },
onclickicon:function(tripMode){
    var iconstate = this.clickOnTripMode(this.state.iconstate,tripMode)
  },

  contextTypes:  function (){
             return{
               previousclickkey:"",
               secondpreviousclickkey:" "
             }
   },
   resetkeys:function(key){
    this.setState({
      openDetailsKey:key
    })
    this.context.previousclickkey="";
    //this.context.previousclickkey="";
 },

 hadlingdropdown:function (key) {
    this.context.secondpreviousclickkey=this.context.previousclickkey;
    this.context.previousclickkey=this.state.openDetailsKey;
    if(this.state.openDetailsKey==this.context.secondpreviousclickkey){
          this.resetkeys(key);
      }else{
        this.setState({
          openDetailsKey:key
        })
      }
  },
  resetToDefaultfn:function(){
    var iconstatetemp={Air:true,Car:true,Hotel:true,Other:true}
    this.setState({iconstate:iconstatetemp});
  },
  componentWillMount: function(){
    var iconTemp={
      Air:"airplane",
      Car:"car",
      Limo:"limo",
      Hotel:"hotel",
      Other:"others"
    };
    this.context.iconCss=iconTemp;
   var cancelreqXmlStart="<GetTripCancelledSegmentsRequest><Travelers><TravelerId>"+this.props.tID+"</TravelerId></Travelers><Trips>";
    var cancelreqXmlend="</Trips></GetTripCancelledSegmentsRequest>";
    var cancelXml="";
     var cancelReqXml="";
     var reqXmlStart="<GetTripDetailsRequest><socketID>"+this.props.sID+"</socketID><Travelers><TravelerId>"+this.props.tID+"</TravelerId></Travelers><Trips>";
     var reqXmlend="</Trips></GetTripDetailsRequest>";
    var reqXml="";
    var summaryData;
    var segmentCommentsData = [];

    for(var i=0;i<this.props.TripIdDetailsArray.length;i++){

      cancelXml=cancelXml+"<Trip source="+"\""+this.props.Source+"\""+" recordlocator="+"\""+this.props.RecordLoc[i]+"\""+">"+this.props.TripIdDetailsArray[i]+"</Trip>";
      reqXml=reqXml+"<TripId>"+this.props.TripIdDetailsArray[i]+"</TripId>";
    }

    var reXml=reqXmlStart+reqXml+reqXmlend;

    var self=this;
    HTTP.post('/api/otasummary', reXml).then(function (response) {
      const temporaryResponse = response.GetTripDetailsResponse;
      otaSummaryException(temporaryResponse,"cancelledsummary");
      summaryData = response;
      self.setState({
        TripSummaryJson: summaryData
      });
    });

     cancelReqXml=cancelreqXmlStart+cancelXml+cancelreqXmlend;
    HTTP.post('/api/cancelledsummary',cancelReqXml).then(function(response){
                  summaryData = response;
                  let isFlightNumberMatch = false;
              if(summaryData && summaryData.GetTripCancelledSegmentsResponse && summaryData.GetTripCancelledSegmentsResponse.length>0){
                    for(var i=0;i<summaryData.GetTripCancelledSegmentsResponse.length;i++){
                         let flightNumer = summaryData.GetTripCancelledSegmentsResponse[i].Code+summaryData.GetTripCancelledSegmentsResponse[i].flightNo;
                         if(self.props.selectedNotifiedObject && self.props.selectedNotifiedObject.flightNumber===flightNumer){
                          isFlightNumberMatch=true
                           break;
                         }
                    }
                    if(!isFlightNumberMatch){
                      self.setState({
                        displayNotification: true
                      })
                    }
              }else if(summaryData && summaryData.GetTripCancelledSegmentsResponse && summaryData.GetTripCancelledSegmentsResponse["#text"]=="No Trip Found" && self.props.selectedNotifiedObject && self.props.selectedNotifiedObject.flightNumber){
                self.setState({
                  displayNotification: true
                })
              }
              self.setState({
                cancelledTripDetails:summaryData

              });
            }.bind(this));

            var tripIdObject = [];
            for(var i=0;i<this.props.TripIdDetailsArray.length;i++){
              tripIdObject.push({
                "tripid" :  this.props.TripIdDetailsArray[i]
              });
            }
            var postBody = {
                 "Traveler":{
                   "Id": this.props.traveller.TravelerId,
                   "email": this.props.traveller.EmailId[0]
                 },
                 "TripIds": tripIdObject
                };
            HTTP.postJSON('/gtr/trip/traveler/segmentnotes/v1',postBody).then(function(result){
                segmentCommentsData = result;
                self.setState({
                  segmentCommentsJson : segmentCommentsData
                });
                self.forceUpdate();
            });
},
handleParent :function(result) {
  var Segment=result.Segments[0].Comments[0];
  var segmentCommentsJsontemp=this.state.segmentCommentsJson;
  var Duplicate=false;
  for(var i=0;i<segmentCommentsJsontemp.CallStatusOrComments.Traveler.Segments.length;i++){
    var segmentiterator=segmentCommentsJsontemp.CallStatusOrComments.Traveler.Segments[i];
    if(segmentiterator.Comments[0].SegmentId==Segment.SegmentId){
      segmentCommentsJsontemp.CallStatusOrComments.Traveler.Segments[i]=result.Segments[0];
      this.setState({
        segmentCommentsJson : segmentCommentsJsontemp
      });
      Duplicate=true;
    }
  }
  if(!Duplicate){
    segmentCommentsJsontemp.CallStatusOrComments.Traveler.Segments.push(result.Segments[0]);
    this.setState({
      segmentCommentsJson : segmentCommentsJsontemp
    });
  }
},
UpdateTripCommentCount:function(responsejson){
  var duplicate=false;
  var TripSummaryJsontemp=this.state.segmentCommentsJson;
  for(var i=0;i<TripSummaryJsontemp.CallStatusOrComments.Traveler.Segments.length;i++){
    for(var j=0;j<responsejson.Segments.length;j++){
      if(TripSummaryJsontemp.CallStatusOrComments.Traveler.Segments[i].Comments[0].SegmentId==responsejson.Segments[j].Comments[0].SegmentId){
        TripSummaryJsontemp.CallStatusOrComments.Traveler.Segments[i]=responsejson.Segments[j];
        duplicate=true;
      }
    }
  }
  for(var i=0;i<responsejson.Segments.length;i++){
    var temp=false;
    for(var j=0;j<TripSummaryJsontemp.CallStatusOrComments.Traveler.Segments.length;j++){
      if(TripSummaryJsontemp.CallStatusOrComments.Traveler.Segments[j].Comments[0].SegmentId==responsejson.Segments[i].Comments[0].SegmentId){
        temp=true;
      }
    }
    if(!temp){
      TripSummaryJsontemp.CallStatusOrComments.Traveler.Segments.push(responsejson.Segments[i]);
    }
  }
  this.setState({
    segmentCommentsJson:TripSummaryJsontemp
  });
},

        render:function(){
          var userProfileData = this.props.traveller;
          var innerSrchTxt=this.props.innerSrchTxt.toLowerCase();
          var innersrchFlg=this.props.innersrchFlg;
          var DisplayAr=[];
          var iconarray=[];
          var hadlingdropdown=this.hadlingdropdown;
          var openDetailsKey=this.state.openDetailsKey;
          var previousclickkey=this.context.previousclickkey;
          var secondpreviousclickkey=this.context.secondpreviousclickkey;
          var resetkeys=this.resetkeys;
          var BookingInfo;
          var RecordLocator="";
          var AtlasGDS="";

          if(this.state.TripSummaryJson!=null){
            if(this.state.TripSummaryJson.GetTripDetailsResponse!=null && this.state.TripSummaryJson.GetTripDetailsResponse.length>0)
            {
              BookingInfo=this.state.TripSummaryJson.GetTripDetailsResponse[0].BookingInfo;

        }else if(this.state.TripSummaryJson.BookingInfo!=null){
          BookingInfo=this.state.TripSummaryJson.BookingInfo;
        }
      }



          var tempiconCss={
            Air:"airplane",
            Car:"car",
            Hotel:"hotel",
            Other:"others"
          }
          var tempIcon=["Air","Car","Hotel","Other"];
          this.context.iconCss=tempiconCss;
          for(var iterator=0;iterator<tempIcon.length;iterator++){
            if(!this.state.iconstate[tempIcon[iterator]]){
              this.context.iconCss[tempIcon[iterator]]=this.context.iconCss[tempIcon[iterator]]+" inactive";
            }
          }
    if(this.state.cancelledTripDetails!=null){
       if(!Array.isArray(this.state.cancelledTripDetails.GetTripCancelledSegmentsResponse)){
                               return(
                                <div>

                                  {
                                    this.state.displayNotification && this.props.ptcFlow? 
                                              <NotificationSegment 
                                              openChatWindowMethod={this.props.openChatWindowMethod}
                                              openedChatList={this.props.openedChatList}
                                              userId={this.props.userId}
                                              outOfSync={true}
                                              outOfSyncPnr={this.props.selectedNotifiedObject.recordLocator}
                                              userName={this.props.userName}
                                              placeHolder={this.props.placeHolder}
                                              callApiNotification={this.props.callApiNotification}
                                              DispTripId={this.props.DispTripId}
                                              tripmodeclass={this.props.tripmodeclass}
                                              userProfileData={this.props.userProfileData}
                                              Segkey={this.props.Segkey}
                                              user={this.props.user}
                                              popupdata={this.props.popupdata}
                                              notificationObject={this.props.selectedNotifiedObject}
                                          />:null
                                  }

                                  <div className="alert alert-danger" role="alert">
                                     {this.props.placeHolder.NO_CANCELLED}
                                  </div>
                                </div>
                              )
       }
      if(this.state.cancelledTripDetails.GetTripCancelledSegmentsResponse!=null){
        var GetTripCancelSegmentResponse= this.state.cancelledTripDetails.GetTripCancelledSegmentsResponse;
        var callStatusCommentsResponseData = "";
        if(this.state.segmentCommentsJson !== null && this.state.segmentCommentsJson !==undefined){
          if(this.state.segmentCommentsJson.CallStatusOrComments!=undefined)
          callStatusCommentsResponseData = this.state.segmentCommentsJson.CallStatusOrComments.Traveler.Segments;
        }

        let CommentSegmentArray=[];
        for(let i=0;i<GetTripCancelSegmentResponse.length;i++) {
              var duplicate=false;
              if(callStatusCommentsResponseData.length == 0) {
                  CommentSegmentArray.push({
                      data      : GetTripCancelSegmentResponse[i],
                      segmentId : GetTripCancelSegmentResponse[i].segmentid,
                      count     : 0,
                      comments  : []
                  });
                  CommentSegmentArray.push(GetTripCancelSegmentResponse[i]);
                  duplicate=true;
                } else {
                    _.each(callStatusCommentsResponseData, function(data) {
                          if(data.Comments[0].SegmentId==GetTripCancelSegmentResponse[i].segmentid){
                              CommentSegmentArray.push({
                                  data : GetTripCancelSegmentResponse[i],
                                  segmentId : data.Comments[0].SegmentId,
                                  count     : data.Count,
                                  comments  : data.Comments
                              });
                              duplicate=true;
                        }
                  });
            }
            if(!duplicate){
                CommentSegmentArray.push({
                    data      : GetTripCancelSegmentResponse[i],
                    segmentId : GetTripCancelSegmentResponse[i].segmentid,
                    count     : 0,
                    comments  : []
                });
            }
        }

        let TripSegmentArray=constructSegmentsByTripIds(this.state.cancelledTripDetails.GetTripCancelledSegmentsResponse);
        for (var iterator=0;iterator<this.state.cancelledTripDetails.GetTripCancelledSegmentsResponse.length;iterator++){
          let isCallStatus=getCallStatus(this.props.CallStatusArr,this.props.RecordLoc,GetTripCancelSegmentResponse[iterator].BookingInfo.RecordLocator);
          switch(GetTripCancelSegmentResponse[iterator].Type){


            case "Air":
              if(iconarray.indexOf("Air")==-1){
                iconarray.push("Air");
              }
              if(this.state.iconstate['Air']){
                if(!innersrchFlg){
                  DisplayAr.push( <Segments SummarySanctions={this.props.SummarySanctions} openDocsTab={this.props.openDocsTab} callApiNotification={this.props.callApiNotification}  userName={this.props.userName} userId={this.props.userId} pnrOfNotification={this.props.pnrOfNotification} notificationList={this.props.notificationList} selectedNotifiedObject={this.props.selectedNotifiedObject} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} key={iterator} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} handleParent={this.handleParent} SaveSucess={this.props.SaveSucess} UpdateCommentCount={this.props.UpdateCommentCount}  userProfileData = {userProfileData} Key={iterator+1} BookingInfo={BookingInfo} previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                }
                else if(GetTripCancelSegmentResponse[iterator].DepartureDateTime.substring(0,7).toLowerCase()==innerSrchTxt || GetTripCancelSegmentResponse[iterator].BookingInfo.RecordLocator.toLowerCase()==innerSrchTxt){
                  DisplayAr.push( <Segments SummarySanctions={this.props.SummarySanctions} openDocsTab={this.props.openDocsTab} callApiNotification={this.props.callApiNotification}  userName={this.props.userName} userId={this.props.userId} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} pnrOfNotification={this.props.pnrOfNotification} notificationList={this.props.notificationList} selectedNotifiedObject={this.props.selectedNotifiedObject} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} key={iterator} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} handleParent={this.handleParent} SaveSucess={this.props.SaveSucess}  UpdateCommentCount={this.props.UpdateCommentCount} userProfileData = {userProfileData}  Key={iterator+1} BookingInfo={BookingInfo} previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                }
              }

            break;

            case "Hotel-CheckOut":
              if(iconarray.indexOf("Hotel")==-1){
                  iconarray.push("Hotel");
                }
                if(this.state.iconstate['Hotel']){
                  if(!innersrchFlg){
                    DisplayAr.push( <Segments userName={this.props.userName}  SummarySanctions={this.props.SummarySanctions} setOfferAsFalse = {this.props.setOfferAsFalse} HRESegments= {this.props.HRESegments} changeToPast={this.props.changeToPast} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} key={iterator} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} handleParent={this.handleParent} SaveSucess={this.props.SaveSucess} UpdateCommentCount={this.props.UpdateCommentCount} userProfileData = {userProfileData} Key={iterator+1} BookingInfo={BookingInfo} previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                  }
                  else if(GetTripCancelSegmentResponse[iterator].CheckOutDateDisplay.substring(0,7).toLowerCase()==innerSrchTxt || GetTripCancelSegmentResponse[iterator].BookingInfo.RecordLocator.toLowerCase()==innerSrchTxt){
                    DisplayAr.push( <Segments userName={this.props.userName}  SummarySanctions={this.props.SummarySanctions} setOfferAsFalse = {this.props.setOfferAsFalse} HRESegments= {this.props.HRESegments} changeToPast={this.props.changeToPast} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} key={iterator} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray}  handleParent={this.handleParent} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} SaveSucess={this.props.SaveSucess} UpdateCommentCount={this.props.UpdateCommentCount} userProfileData = {userProfileData} Key={iterator+1} BookingInfo={BookingInfo}  previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                  }
                }
              break;
            case 'Hotel-CheckIn':
              if(iconarray.indexOf("Hotel")==-1){
                iconarray.push("Hotel");
              }
              if(this.state.iconstate['Hotel']){
                if(!innersrchFlg){
                  DisplayAr.push( <Segments userName={this.props.userName}  SummarySanctions={this.props.SummarySanctions} ActiveFilter="Cancelled" setOfferAsFalse = {this.props.setOfferAsFalse} HRESegments= {this.props.HRESegments} changeToPast={this.props.changeToPast} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} key={iterator} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count}  handleParent={this.handleParent} SaveSucess={this.props.SaveSucess} UpdateCommentCount={this.props.UpdateCommentCount} userProfileData = {userProfileData}  Key={iterator+1} BookingInfo={BookingInfo}  previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                }
                else if(GetTripCancelSegmentResponse[iterator].CheckInDateDisplay.substring(0,7).toLowerCase()==innerSrchTxt || GetTripCancelSegmentResponse[iterator].BookingInfo.RecordLocator.toLowerCase()==innerSrchTxt){
                  DisplayAr.push( <Segments userName={this.props.userName} SummarySanctions={this.props.SummarySanctions} ActiveFilter="Cancelled" setOfferAsFalse = {this.props.setOfferAsFalse} HRESegments= {this.props.HRESegments} changeToPast={this.props.changeToPast} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} key={iterator} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} handleParent={this.handleParent} SaveSucess={this.props.SaveSucess}  UpdateCommentCount={this.props.UpdateCommentCount} userProfileData = {userProfileData} Key={iterator+1} BookingInfo={BookingInfo}  previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                }
              }
            break;
            case "Car-PickUp":
              if(iconarray.indexOf("Car")==-1){
                  iconarray.push("Car");
                }
                if(this.state.iconstate['Car']){
                  if(!innersrchFlg){
                    DisplayAr.push( <Segments userName={this.props.userName}  SummarySanctions={this.props.SummarySanctions} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} key={iterator} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} handleParent={this.handleParent} SaveSucess={this.props.SaveSucess} UpdateCommentCount={this.props.UpdateCommentCount} userProfileData = {userProfileData} Key={iterator+1} BookingInfo={BookingInfo}  previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                  }
                  else if(GetTripCancelSegmentResponse[iterator].PickUpDateDisplay.substring(0,7).toLowerCase()==innerSrchTxt || GetTripCancelSegmentResponse[iterator].BookingInfo.RecordLocator.toLowerCase()==innerSrchTxt){
                    DisplayAr.push( <Segments userName={this.props.userName}  SummarySanctions={this.props.SummarySanctions} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} key={iterator} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} handleParent={this.handleParent} SaveSucess={this.props.SaveSucess} UpdateCommentCount={this.props.UpdateCommentCount} userProfileData = {userProfileData} Key={iterator+1} BookingInfo={BookingInfo}  previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                  }
                }
              break;
            case 'Car-DropOff':
              if(iconarray.indexOf("Car")==-1){
                iconarray.push("Car");
              }
              if(this.state.iconstate['Car']){

                  if(!innersrchFlg){
                      DisplayAr.push( <Segments userName={this.props.userName}  SummarySanctions={this.props.SummarySanctions} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} handleParent={this.handleParent} key={iterator} SaveSucess={this.props.SaveSucess} UpdateCommentCount={this.props.UpdateCommentCount} userProfileData = {userProfileData} Key={iterator+1} BookingInfo={BookingInfo}  previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                  }
                  else if(GetTripCancelSegmentResponse[iterator].DropOffDateDisplay.substring(0,7).toLowerCase()==innerSrchTxt ||GetTripCancelSegmentResponse[iterator].BookingInfo.RecordLocator.toLowerCase()==innerSrchTxt){
                  DisplayAr.push( <Segments userName={this.props.userName}  SummarySanctions={this.props.SummarySanctions} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} handleParent={this.handleParent} key={iterator} SaveSucess={this.props.SaveSucess} UpdateCommentCount={this.props.UpdateCommentCount} Key={iterator+1} userProfileData = {userProfileData} BookingInfo={BookingInfo} previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                  }
                }
            break;
            case 'Limo':
              if(iconarray.indexOf("Other")==-1){
                iconarray.push("Other");
              }
              if(this.state.iconstate['Other']){
                if(!innersrchFlg){
                    DisplayAr.push( <Segments userName={this.props.userName} SummarySanctions={this.props.SummarySanctions} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} key={iterator} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} handleParent={this.handleParent} SaveSucess={this.props.SaveSucess} UpdateCommentCount={this.props.UpdateCommentCount}  Key={iterator+1} userProfileData = {userProfileData}  BookingInfo={BookingInfo}  previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                }
                else if(GetTripCancelSegmentResponse[iterator].limoPickUpDateDisplay.substring(0,7).toLowerCase()==innerSrchTxt || GetTripCancelSegmentResponse[iterator].limoDropOffDateDisplay.substring(0,7).toLowerCase()==innerSrchTxt ||GetTripCancelSegmentResponse[iterator].BookingInfo.RecordLocator.toLowerCase()==innerSrchTxt){
                DisplayAr.push( <Segments userName={this.props.userName} SummarySanctions={this.props.SummarySanctions} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} key={iterator} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} handleParent={this.handleParent} SaveSucess={this.props.SaveSucess} UpdateCommentCount={this.props.UpdateCommentCount} Key={iterator+1} userProfileData = {userProfileData} BookingInfo={BookingInfo}  previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                }
              }
            break;

            //Bus segments
            case 'Bus':

              if(iconarray.indexOf("Other")==-1){
                iconarray.push("Other");

              }
              if(this.state.iconstate['Other']){
                if(!innersrchFlg){
                    DisplayAr.push( <Segments userName={this.props.userName} SummarySanctions={this.props.SummarySanctions} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} key={iterator} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} handleParent={this.handleParent} SaveSucess={this.props.SaveSucess} UpdateCommentCount={this.props.UpdateCommentCount}  Key={iterator+1} userProfileData = {userProfileData}  BookingInfo={BookingInfo}  previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                }
                else if(GetTripCancelSegmentResponse[iterator].BusDepartureDate.substring(0,7).toLowerCase()==innerSrchTxt || GetTripCancelSegmentResponse[iterator].BusArrivalDate.substring(0,7).toLowerCase()==innerSrchTxt ||GetTripCancelSegmentResponse[iterator].BookingInfo.RecordLocator.toLowerCase()==innerSrchTxt){
                    DisplayAr.push( <Segments userName={this.props.userName} SummarySanctions={this.props.SummarySanctions} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} key={iterator} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} handleParent={this.handleParent} SaveSucess={this.props.SaveSucess} UpdateCommentCount={this.props.UpdateCommentCount} Key={iterator+1} userProfileData = {userProfileData} BookingInfo={BookingInfo}  previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                }
              }
            break;

            case "Rail":
              if(iconarray.indexOf("Other")==-1){
                iconarray.push("Other");
              }
              if(this.state.iconstate['Other']){
                if(!innersrchFlg){
                  DisplayAr.push( <Segments userName={this.props.userName}  SummarySanctions={this.props.SummarySanctions} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} handleParent={this.handleParent} key={iterator} SaveSucess={this.props.SaveSucess}  UpdateCommentCount={this.props.UpdateCommentCount} Key={iterator+1} userProfileData = {userProfileData} BookingInfo={BookingInfo}  previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                }
                else if(GetTripCancelSegmentResponse[iterator].railArrivalDate.substring(0,7).toLowerCase()==innerSrchTxt || GetTripCancelSegmentResponse[iterator].BookingInfo.RecordLocator.toLowerCase()==innerSrchTxt){
                  DisplayAr.push( <Segments userName={this.props.userName}  SummarySanctions={this.props.SummarySanctions} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} handleParent={this.handleParent} key={iterator} SaveSucess={this.props.SaveSucess} UpdateCommentCount={this.props.UpdateCommentCount} Key={iterator+1} userProfileData = {userProfileData} BookingInfo={BookingInfo}  previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                }
              }
            break;

            case 'Other':
              if(iconarray.indexOf("Other")==-1){
                iconarray.push("Other");
              }
              if(!innersrchFlg){
                DisplayAr.push( <Segments userName={this.props.userName}  SummarySanctions={this.props.SummarySanctions} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} key={iterator} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} handleParent={this.handleParent} SaveSucess={this.props.SaveSucess} UpdateCommentCount={this.props.UpdateCommentCount}  Key={iterator+1} userProfileData = {userProfileData} BookingInfo={BookingInfo}  previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
              }
              else if(this.state.iconstate['Other']){
                if(GetTripCancelSegmentResponse[iterator].startDateDisplay.substring(0,7).toLowerCase()==innerSrchTxt || GetTripCancelSegmentResponse[iterator].BookingInfo.RecordLocator.toLowerCase()==innerSrchTxt){
                DisplayAr.push( <Segments userName={this.props.userName}  SummarySanctions={this.props.SummarySanctions} openDocsTab={this.props.openDocsTab} placeHolder={this.props.placeHolder} gotoAllCommentsTab={this.props.gotoAllCommentsTab} ShowCallStatusAndComments ={this.props.ShowCallStatusAndComments} isCallStatus={isCallStatus} UpdateTripCommentCount={this.UpdateTripCommentCount} TripSegmentArray={TripSegmentArray} key={iterator} handleParent={this.handleParent} segmentComments={CommentSegmentArray[iterator].comments} segmentCount={CommentSegmentArray[iterator].count} SaveSucess={this.props.SaveSucess} UpdateCommentCount={this.props.UpdateCommentCount}  Key={iterator+1} userProfileData = {userProfileData} BookingInfo={BookingInfo}  previouskey={previousclickkey} openDetailsKey={openDetailsKey} Change={hadlingdropdown} cancel="true" TripDetailsResponse={GetTripCancelSegmentResponse[iterator]} DispTripId={this.props.DispTripId} />);
                }
              }
            break;
          }
        }
      }
          iconarray=sortArray(iconarray);
          if(DisplayAr.length==0){
            return (
              <div>
              {iconarray.length>0? <a href="#" className="reset" onClick={this.resetToDefaultfn}>{this.props.placeHolder.RESET}</a>:null}
              <Secondaryfilter  iconCss={this.context.iconCss} onclickicon={this.onclickicon} array={iconarray}/>

              {this.state.displayNotification ? 
                                              <NotificationSegment 
                                              userId={this.props.userId}
                                              userName={this.props.userName}
                                              placeHolder={this.props.placeHolder}
                                              callApiNotification={this.props.callApiNotification}
                                              DispTripId={this.props.DispTripId}
                                              tripmodeclass={this.props.tripmodeclass}
                                              userProfileData={this.props.userProfileData}
                                              Segkey={this.props.Segkey}
                                              user={this.props.user}
                                              popupdata={this.props.popupdata}
                                              notificationObject={this.props.selectedNotifiedObject}
                                          />:null
                                  }

              <div className="alert alert-danger" role="alert">{this.props.placeHolder.NO_CANCELLED}</div>
              </div>
            )
          }
      return (
        <div>
          {iconarray.length>0? <a href="#" className="reset" onClick={this.resetToDefaultfn}>{this.props.placeHolder.RESET}</a>:null}
          <Secondaryfilter  iconCss={this.context.iconCss} onclickicon={this.onclickicon} array={iconarray}/>

          {
            this.state.displayNotification ? 
            <NotificationSegment 
            userId={this.props.userId}
            userName={this.props.userName}
            placeHolder={this.props.placeHolder}
            callApiNotification={this.props.callApiNotification}
            DispTripId={this.props.DispTripId}
            tripmodeclass={this.props.tripmodeclass}
            userProfileData={this.props.userProfileData}
            Segkey={this.props.Segkey}
            user={this.props.user}
            popupdata={this.props.popupdata}
            notificationObject={this.props.selectedNotifiedObject}
        />:null

          }

          {DisplayAr}
        </div>
      )
    }else{
      return (
        <div>
          <div className="loading_image trip_loader">&nbsp;</div>
        </div>
      )
    }
  }

 });
 function sortArray(array) {
   var tempArray=[];
   if(array.indexOf("Air")!=-1){
     tempArray.push("Air");
   }
   if(array.indexOf("Hotel")!=-1){
     tempArray.push("Hotel");
   }
   if(array.indexOf("Car")!=-1){
     tempArray.push("Car");
   }
   if(array.indexOf("Limo")!=-1){
     tempArray.push("Limo");
   }
   if(array.indexOf("Other")!=-1){
     tempArray.push("Other");
   }
   return tempArray.reverse();
 }
 function constructSegmentsByTripIds(GetTripDetailsResponseArray){
   let TripSegmentArray=[];
   function trip(){
     this.tripId="",
     this.SegmentId=[]
   }
   for(let i=0;i<GetTripDetailsResponseArray.length;i++){
     var duplicate=false;
     if(TripSegmentArray.length==0){
       let object=new trip();
       object.tripId=GetTripDetailsResponseArray[i].TripId;
       object.SegmentId.push(GetTripDetailsResponseArray[i].segmentid);
       TripSegmentArray.push(object);
       duplicate=true;
     }else{
       _.each(TripSegmentArray, function(trip){
         if(trip.tripId==GetTripDetailsResponseArray[i].TripId){
           trip.SegmentId.push(GetTripDetailsResponseArray[i].segmentid);
           duplicate=true;
         }
       });
     }
     if(!duplicate){
       let object=new trip();
       object.tripId=GetTripDetailsResponseArray[i].TripId;
       object.SegmentId.push(GetTripDetailsResponseArray[i].segmentid);
       TripSegmentArray.push(object);
     }
   }
   return TripSegmentArray;
 }
 function getCallStatus(CallStatusArr,RecordLocArr,RecordLocator){

   if(RecordLocArr.indexOf(RecordLocator)!=-1){
     if(CallStatusArr[RecordLocArr.indexOf(RecordLocator)]!=null)
       return true;
   }
   return false
 }

export default CancelledSegmentClass;
