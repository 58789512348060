'use strict';
import React from "react";
import UserInfoField from "../../commons/UserInfo.js"
import RadioButton from "../../commons/RadioButton.js"
import AddNotes from "../../commons/NotesInStatusComments.js"
import DropDownBox from "../../CallStatusCommons/DropDownBox"
import HTTP from '../../../../services/httpservice';
import {CallStatusAndComments} from "../../CallStatusCommons/CallStatusAndComments.Object";

export default class CallStatusAndCommentsSelection extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      Selected: this.props.isCallStatus ? "Comment":"CallStatus",
      saveButtonState:false,
      Result:{},
      segmentCommentCount : '',
      tempResponse :{}
    };
    this.noteText="";
    this.userInfo="";
    this.dropDownResult="";
  }
  focusModal(){
    var key = "qwerty_"+this.props.uniqueId;
    this.refs[key].focusModal();
  }
  _Data(Text){
    this.noteText=Text;
    var save=this._checkForSave.bind(this);
    save();
  }
  _Detail(Text){
    this.userInfo=Text;
    var save=this._checkForSave.bind(this);
    save();
  }
  _getRadioButtonValue(selectedRadioButton){
    this.setState({Selected:selectedRadioButton});
    this.dropDownResult="";
    var save=this._checkForSave.bind(this);
    save();
  }
  _dropDownResultfunction(text){
    this.dropDownResult=text;
    var save=this._checkForSave.bind(this);
    save();
  }
  _checkForSave(){
    if(validationOfForm(this.userInfo,this.dropDownResult,this.noteText)){
      this.setState({saveButtonState:true});
      document.getElementById("saveBtn").disabled=false;
    }else{
      document.getElementById("saveBtn").disabled=true;
      this.setState({saveButtonState:false});
    }
  }
  shouldComponentUpdate(newProps,newState){
    if((newProps.reset ==this.props.reset && this.state==newState)){
      return false;
    }
    return true
  }
  componentWillReceiveProps(newProps){
    if(this.props.isCallStatus!=newProps.isCallStatus){
      this.setState ({
          Selected: newProps.isCallStatus ? "Comment":"CallStatus",
      });
    }
  }
  _onClickSaveButton(){
    var tripmodelid=this.props.tripmodelid;
    let self=this;

    if(this.state.saveButtonState){
      var Test=new CallStatusAndComments();
      Test.setCallStatusAndCommentsValues(this.userInfo,this.dropDownResult,this.noteText,this.state.Selected,false);
      let segmentArray;
      if(this.dropDownResult=="TRIP"){
        segmentArray=findSegmentArrayByTripId(this.props.TripSegmentArray,this.props.TripId);
        Test.setTripInfo(this.props.TripId,segmentArray,this.props.RecordLocator,this.props.Source);
      }else{
          Test.setTripInfo(this.props.TripId,this.props.SegmentId,this.props.RecordLocator,this.props.Source);
      }
      Test.setUserInfo(this.props.EmailId,this.props.TravellerId);
      HTTP.postJSON('/gtr/trip/traveler/Addnotes/v1',Test).then(function(response){
          if(response.AddCallStatusCommentsResponse.Traveler.Segments.length != 0) {
              var segmentCommentCount = response.AddCallStatusCommentsResponse.Traveler.Segments[0].Count;
              var topTwoComments = response.AddCallStatusCommentsResponse.Traveler.Segments[0];
              self.setState({
                segmentCommentCount :topTwoComments,
                tempResponse :response.AddCallStatusCommentsResponse
              });
          }
          self.setState({
              Result:response,
          });
      });

      window.$('#'+tripmodelid).modal('hide');
    }

  }
  showCallStatus(){
   // alert("clicked");
    var tripmodelid=this.props.tripmodelid;
    window.$('#'+tripmodelid).modal('hide');
    let showCalls = this.props.ShowCallStatusAndComments;
    showCalls();

  }
  _onClickCancelButton(){
    this.setState({
      Selected:this.props.isCallStatus ? "Comment":"CallStatus",
      saveButtonState:false,
      Result:{}
    });
    this.userInfo="";
    this.dropDownResult="Select";
    this.noteText="";
    let ClickCancel=this.props.ClickCancel;
    ClickCancel();
 }
  componentDidUpdate(prevProps, prevState){
    if(prevState.Result!=this.state.Result){
        if(this.state.Result.AddCallStatusCommentsResponse!=null){
          let ignoringUpdateTravellerLevelArray=["TRIP","SEGMENT"];
          console.log(this.state.Result.AddCallStatusCommentsResponse);
          if(ignoringUpdateTravellerLevelArray.indexOf(this.dropDownResult)==-1){
              this.props.UpdateCommentCount(this.state.Result.AddCallStatusCommentsResponse.Traveler);
          }else if(this.dropDownResult=="TRIP"){
            this.props.UpdateTripCommentCount(this.state.Result.AddCallStatusCommentsResponse.Traveler);
          }else if(this.dropDownResult=="SEGMENT"){
              this.props.handleParent(this.state.Result.AddCallStatusCommentsResponse.Traveler);
          }
          if(this.state.Result.AddCallStatusCommentsResponse.Success=="True"){
            if(this.state.Selected=="CallStatus" & this.state.Result.AddCallStatusCommentsResponse.Traveler.Message=="Active CallStatus Is Already Present"){
              this.props.SaveSucess(this.state.Selected,"Active CallStatus Is Already Present",false);
            }else{
                this.props.SaveSucess(this.state.Selected,"Add",true);
            }
            this._onClickCancelButton();
          }else{
            this.props.SaveSucess(this.state.Selected,"Add",false);
          }
        }
    }
  }

  render () {
    var headText="Notes";
    var SaveButtonClass="save_button";
    if(this.state.saveButtonState){
      SaveButtonClass="save_button enabled";
    }
    return(
    <div>
      <RadioButton  placeHolder={this.props.placeHolder} ref={"qwerty_"+this.props.uniqueId} uniqueId={this.props.uniqueId} tripmodelid={this.props.tripmodelid} isCallStatus={this.props.isCallStatus} RadioSelected={this.state.Selected} Reset={this.props.reset} RadioButton={this._getRadioButtonValue.bind(this)}/>
      <div className="col-sm-12"><br/>
        {this.props.isCallStatus ? <p className="note">{this.props.placeHolder.WISH_TO_ADD_CALL_STATUS} <a onClick={this.showCallStatus.bind(this)}>"<u>{this.props.placeHolder.CALL_STATUS_COMMENTS}</u>"</a> {this.props.placeHolder.RESOLVE_EXISTING}</p>:null}

        <div className="col-sm-6">
           <br/>
          <div className="margin_btm_5">{this.state.Selected=="CallStatus" ? this.props.placeHolder.CALL_STATUS+":" : this.props.placeHolder.COMMENTS_ON}</div>
          <DropDownBox placeHolder={this.props.placeHolder} reset={this.props.reset} onSelectDropDown={this._dropDownResultfunction.bind(this)} Selected={this.state.Selected}/>
        </div>
        <div className="col-sm-6 margin_btm_5">
          {this.props.placeHolder.USER_INFO.length >15 ? null :<br/>}
          <div className="margin_btm_5 userinfo-label">{this.props.placeHolder.USER_INFO}</div>
          <UserInfoField placeHolder={this.props.placeHolder} reset={this.props.reset}  Selected={this.state.Selected} Detail={this._Detail.bind(this)}/>
        </div>
      </div>
      <AddNotes placeHolder={this.props.placeHolder} notesLabel="Notes:" reset={this.props.reset}  Selected={this.state.Selected} Data={this._Data.bind(this)} headText={headText}/>
      <div className="col-sm-12"><br/><br/>
      <div className="col-sm-5 inline-block pull-right width-for-popup-buttons">
        <button className={SaveButtonClass} id="saveBtn" onClick={this._onClickSaveButton.bind(this)}>{this.props.placeHolder.SAVE}</button>
        <button className="button last" onClick={this._onClickCancelButton.bind(this)} data-dismiss="modal">{this.props.placeHolder.CANCEL}</button>
      </div>
      </div>
    </div>
    );
  }
}
function validationOfForm(userInfo,status,notes){
  if(validateUserInfo(userInfo) && validateNotes(notes) && validatestatus(status)){
    return true;
  }else{
    return false;
  }
}
function validateUserInfo(userInfo){
  if(userInfo!=""){
    //Regex Exp including caracters,Numbers
    //let re =/^[a-zA-Z0-9]*$/;
    let re =/^[a-zA-Z0-9]{3,7}$/g;
    return re.test(userInfo);
  }else{
    return false;
  }
}
function validatestatus(status){
  if(status!="Select" && status!="")
    return true;
  else
    return false
}

function validateNotes(text){
//var CommentText = text.replace(/\s/g,'');

  if(text!=""){
    //Regex Exp including caracters,Numbers,hypens,commas,periods
    let re =/^[a-zA-Z0-9\n .,-]{3,90}$/g;
    return re.test(text);
  }else{
    return false;
  }
}

function findSegmentArrayByTripId(TripSegmentArray,tripId){
  for(let i=0;i<TripSegmentArray.length;i++){
    if(TripSegmentArray[i].tripId==tripId){
      return TripSegmentArray[i].SegmentId;
    }
  }
}
