import React from "react";

export default class PopOvers extends React.Component{
  render() {
    return (
      <div className="popover_all">
        <div className='fade in popover bottom pop_over_right'>
          <div className='arrow'></div>
          <div className='popover-content'>
            <div className='popover_custom'>
              {this.props.text}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
